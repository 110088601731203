<template>
  <div>
    <div
        slot="box"
        :class="['pr-1 pb-4','form-elements-container',state.dragging ? 'drop-area-outer' : '',]">
      <div class="form-section mb-4 p-3 mt-2">
        <h2 class="vform-editor-section-title">{{ $t('Behaviour') }}</h2>
        <label
            :class="['container d-inline-block vform-editor-body', hasSingleSlidePerStep(activeStepObject) ? 'checkbox-label-disabled' : '']"
            :for="'autoPlay' + $vnode.key"
            style="padding-left: 5px !important"
        >
          <input
              :id="'autoPlay' + $vnode.key"
              v-model="autoPlay"
              :disabled="hasSingleSlidePerStep(activeStepObject)"
              type="checkbox"
              @change="setAutoPlay()"
          >
          <span :class="['checkmark', hasSingleSlidePerStep(activeStepObject) ? 'disabled' : '' ]"/>
          <div class="ml-4">{{ $t("vform.autoPlay") }}</div>
        </label>
        <div>
          <label
              v-if="activeStepObject.autoPlay"
              :for="'loop' + $vnode.key"
              class="container d-inline-block vform-editor-body"
              style="padding-left: 5px !important"
          >
            <input
                :id="'loop' + $vnode.key"
                v-model="loop"
                type="checkbox"
                @change="setLoop()"
            >
            <span class="checkmark"/>
            <div class="ml-4">{{ $t("vform.loop") }}</div>
          </label>
        </div>
        <div v-if="activeStepObject.autoPlay">
          <label class="vform-editor-label section mt-0">Delay</label>
          <label class="section ml-2 vform-editor-body slider-label">{{ autoPlayInterval }} seconds</label>
          <slider :default-value="2.5" :initial-value="autoPlayInterval"
                  :interval="0.5" :max="10" :min="2"
                  @change="(val) => { this.$set(this, 'autoPlayInterval', val) }"/>
        </div>
        <div v-if="activeStepObject.autoPlay">
          <label class="vform-editor-label section">{{ $t("transition") }}</label>
          <select v-model="transition" class="custom-select form-text-dark" @change="setTransition()">
            <option value="none">None</option>
            <option value="fade">Fade</option>
            <option value="zoom">Zoom</option>
          </select>
        </div>
      </div>

      <h2 class="ml-2 vform-editor-section-title clickable">{{ $t("vform.globalPanels") }}</h2>
      <div
          v-for="(panel, panelIndex) in getGlobalPanels()"
          :key="panel.uuid"
          class="mb-3"
      >
        <step-settings-panel-properties
            @addAllStepsToGlobalPanel="(panel) => {$emit('addAllStepsToGlobalPanel', panel)}"
            @removeBlock="(id, bool) => {$emit('removeBlock', id, bool)}"
            :steps="steps"
            :global="global"
            :active-step-object="activeStepObject"
            :initial-expanded="getInitialExpanded(panel)"
            :panel="panel"
            :panel-index="panelIndex"
            :slides="slides"
            :state="state" />
      </div>
      <div class="mt-2 mr-2 d-flex justify-content-end">
        <div class="clickable " @click="addPanel(true)">
          <icon :size="'2.0'" type="fad-square-plus"/>
        </div>
      </div>

      <h2 class="ml-2 vform-editor-section-title clickable">{{ $t("vform.localPanels") }}</h2>
      <div
          v-for="(panel, panelIndex) in activeStepObject.panels.filter((p) => { return !p.isMaster })"
          v-if="activeStepObject"
          :key="panel.uuid"
      >
        <step-settings-panel-properties
            @applyForAll="$emit('applyForAll', panelIndex, panel)"
            @removeBlock="(id, bool) => {$emit('removeBlock', id, bool)}"
            :steps="steps"
            :global="global"
            :active-step-object="activeStepObject"
            :initial-expanded="getInitialExpanded(panel)"
            :panel="panel"
            :panel-index="panelIndex"
            :slides="slides"
            :state="state"
        />
      </div>
      <div class="mt-2 mr-2 d-flex justify-content-end">
        <div class="clickable" @click="addPanel(false)">
          <icon :size="'2.0'" type="fad-square-plus"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import {v4 as uuidv4} from "uuid";
import Slider from "@/components/widgets/editors/Slider";
import StepSettingsPanelProperties from "@/components/vForm/StepSettingsPanelProperties";
import {defaultPanelVersion} from "@/enum";

export default {
  name: "StepSettingsPanel",
  components: {
    StepSettingsPanelProperties,
    Icon,
    Slider
  },
  props: {
    steps: {type: Array, default: () => {return []}},
    config: {type: Object, default: null},
    global: {type: Object, default: () => {return []}},
    state: {type: Object, default: null},
    activeStepObject: {type: Object, default: null},
    slides: {type: Array, default: null}
  },
  data() {
    return {
      colorPickrId: null,
      bottomColorPickerId: null,
      fontColorPickrId: null,
      autoPlay: false,
      autoPlayInterval: 2.5,
      loop: false,
      showSliders: false,
      enableMasterPanelsFeature: true,
      transition: 'zoom',
      hideTopPadding: false
    };
  },
  watch: {
    activeStepObject: {
      deep: true,
      handler() {
        this.setData();
        if (this.hasSingleSlidePerStep(this.activeStepObject)) {
          this.$set(this.activeStepObject, "autoPlay", false);
        }
      }
    },
    autoPlayInterval() {
      this.$set(this.activeStepObject, "autoPlayInterval", this.autoPlayInterval);
    },
  },
  beforeMount() {
    this.setData();
  },
  methods: {
    getInitialExpanded(panel) {
      return this.state.currentlyEditingPanel === panel.uuid;
    },
    getGlobalPanels() {
      if (this.global && this.global.panels) {
        let globalStepPanels = [];

        globalStepPanels = this.global.panels;
        return globalStepPanels;
      }

      return [];
    },
    setData() {
      this.autoPlay = this.activeStepObject.autoPlay ? this.activeStepObject.autoPlay : false;
      this.autoPlayInterval = this.activeStepObject.autoPlayInterval ? this.activeStepObject.autoPlayInterval : 2.5;
      this.loop = this.activeStepObject.loop ? this.activeStepObject.loop : false;
      this.transition = this.activeStepObject.transition ? this.activeStepObject.transition : 'zoom';
    },
    setTransition() {
      this.$set(this.activeStepObject, 'transition', this.transition);
    },
    setAutoPlay() {
      this.$set(this.activeStepObject, "autoPlay", this.autoPlay);
    },
    setLoop() {
      this.$set(this.activeStepObject, "loop", this.loop);
    },

    addPanel(isGlobal) {
      
      const uuid = uuidv4();
      let newPanel = {
        uuid: uuid,
        identifierColor: "#" + Math.floor(Math.random() * 16777215).toString(16),
        isMaster: isGlobal,
        globalVisibleOnSteps: isGlobal ? [] : null,
        version: defaultPanelVersion,
        hideTopPadding: true,
        pin: null,
        collapseDirection: null,
        collapsible: null,
        initiallyCollapsed: null,
      };

      if (isGlobal) {
        this.global.panels.push(newPanel);
        this.state.currentlyEditingPanel = uuid;
        this.$emit('globalPanelAdded', newPanel);
      } else {
        this.activeStepObject.panels.push(newPanel);
        this.$emit('panelAdded')
      }
    },
    hasSingleSlidePerStep(step) {
      const firstSlideOfStep = step.linkedSlide;
      const slideIndex = this.slides.findIndex((s) => {
        return s.uuid === firstSlideOfStep;
      });
      const nextSlideIndex = this.slides.length > slideIndex + 1 ? slideIndex + 1 : 0;
      if (nextSlideIndex) {
        const nextSlideUuid = this.slides[nextSlideIndex].uuid;
        const nextStep = this.steps.find((s) => {
          return s.linkedSlide === nextSlideUuid;
        });
        if (nextStep) {
          return true;
        }

        return false;
      }

      return false;
    }
  },
};
</script>
<style lang="scss">
.vform-container {
  .step-settings-color-pickr {
    .form-submit-button {
      border: 0 solid transparent;
      font-size: 0.9rem;

      .a {
        border: 0 solid transparent;
        border-radius: 4px;
      }

      .content {
        padding: 5px 8px;
        height: auto;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.checkbox-label-disabled {
  cursor: not-allowed !important;
}

.input-box {
  position: relative;
}

.draw-up {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 18px;
}
</style>
