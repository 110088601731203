// store/modules/auth.js
import {
    getAccessToken,
    setAccessToken,
    setRefreshToken,
    setSessionId,
    getRefreshToken,
    getSessionId,
    setAppMode
} from '@/tokenManager';
import {sleep} from "@/utils";
const state = {
    authToken: '',
    refreshToken: '',
    sessionId: '',
    refreshTokenExp: 0,
    isAuthenticated: false,
    clientId: '',
    currentUserId: '',
    mfaQrCode: '',
    mfaCodeEnter: false,
    isvSTAGEMode: false
};

const getters = {
    authToken: state => state.authToken,
    getAuthToken: state => state.authToken,
    refreshToken: state => state.refreshToken,
    sessionId: state => state.sessionId,
    isAuthenticated: state => !!state.authToken,
    clientId: state => state.clientId,
    currentUserId: state => state.currentUserId,
    getCurrentUserId: state => state.currentUserId,
    getSessionId: state => state.sessionId,
    getMfaQrCode: state => state.mfaQrCode,
    getMfaCodeEnter: state => state.mfaCodeEnter,
    refreshTokenExp: state => state.refreshTokenExp,
    isvSTAGEMode: state => state.isvSTAGEMode,
};

const mutations = {
    SET_AUTH_TOKEN(state, authToken) {
        state.authToken = authToken;
    },
    SET_REFRESH_TOKEN(state, refreshToken) {
        state.refreshToken = refreshToken;
    },
    SET_SESSION_ID(state, sessionId) {
        state.sessionId = sessionId;
    },
    SET_REFRESH_TOKEN_EXP(state, exp) {
        state.refreshTokenExp = exp;
    },
    SET_AUTHENTICATED(state, status) {
        state.isAuthenticated = status;
    },
    SET_CLIENT_ID(state, clientId) {
        state.clientId = clientId;
    },
    SET_CURRENT_USER_ID(state, userId) {
        state.currentUserId = userId;
    },
    setMfaQrCode(state, qrCode) {
        state.mfaQrCode = qrCode;
    },
    setMfaCodeEnter(state, codeEnter) {
        state.mfaCodeEnter = codeEnter;
    },
    isvSTAGEMode(state, isvSTAGEMode) {
        state.isvSTAGEMode = isvSTAGEMode;
    }
};

const actions = {
    async authLogin({ commit, getters, dispatch }, credentials) {
        console.log('authLogin', credentials)
        try {
            const strategy = getters.getStrategy;
            console.log(strategy)
            const name = getters.getStrategyName;
            console.log('client auth with name ', name);
            const data = await strategy.authenticate(credentials);
            const { authToken, refreshToken, sessionId, refreshTokenExp } = data;

            commit('SET_AUTH_TOKEN', authToken);
            commit('SET_REFRESH_TOKEN', refreshToken);
            commit('SET_SESSION_ID', sessionId);
            commit('SET_REFRESH_TOKEN_EXP', refreshTokenExp);

            //await dispatch('commitLoginInfo', { authToken, refreshToken, sessionId, refreshTokenExp })

            setAccessToken(authToken);
            setRefreshToken(refreshToken);
            console.log('setting sessionId', sessionId);
            setSessionId(sessionId);
            await dispatch('checkLogin', { authToken });

            return data;
        } catch (error) {
            console.log(error)
            throw error;
        }
    },
    async authNewAuthToken({getters, commit, dispatch}, errorCounter = 0) {
        try {
            const strategy = getters.getStrategy;
            console.log(strategy);
            const data = await strategy.getNewAuthToken();
            const { authToken, refreshToken, sessionId, refreshTokenExp } = data;
            commit('SET_AUTH_TOKEN', authToken);
            commit('SET_REFRESH_TOKEN', refreshToken);
            commit('SET_SESSION_ID', sessionId);
            commit('SET_REFRESH_TOKEN_EXP', refreshTokenExp);
            setAccessToken(authToken);
            setRefreshToken(refreshToken);
        } catch (error) {
            if(errorCounter < 4) {
                errorCounter = errorCounter + 1;
                await sleep(1000);
                return await dispatch('authNewAuthToken', errorCounter)
            } else {
                console.error('Failed to refresh tokens:', error);
                throw new Error('Token refresh failed');
            }
        }
    },
    async commitLoginInfo({ dispatch }, loginInfo) {
        const { authToken, refreshToken, sessionId, refreshTokenExp } = loginInfo;
        console.log('sessionId: ', sessionId)
        await dispatch('setAuthToken', authToken);
        await dispatch('setSessionId', sessionId);
        await dispatch('setRefreshToken', refreshToken);
        await dispatch('setRefreshTokenExp', refreshTokenExp);
        await dispatch('checkLogin', { authToken });
    },
    setPassivMode(state, opts) {
        if(!opts) {
            throw new Error('cannot set passiv mode');
        }
        const {passivMode} = opts;
        setAppMode(passivMode);
    },
    isvSTAGEMode({commit}) {
        commit('isvSTAGEMode', true);
    },
    async checkLogin({ commit, dispatch }, opts) {
        const authToken = opts && opts.authToken ? opts.authToken : getAccessToken();
        if (!authToken) {
            commit('SET_AUTHENTICATED', false);
            commit('setLoading', false);
            return;
        }
        if(opts && opts.passiveMode) {
            setAppMode(opts.passivMode);
        }
        commit('SET_AUTH_TOKEN', authToken);
        setAccessToken(authToken);
        commit('SET_AUTHENTICATED', true);
        commit('SET_REFRESH_TOKEN', getRefreshToken());
        commit('SET_SESSION_ID', getSessionId());
        // eslint-disable-next-line no-undef
        const currentUserOrg = $cookies.get('currentUserOrg');
        // eslint-disable-next-line no-undef
        //const currentUserTeam = $cookies.get('currentUserTeam');
        return dispatch('clientGetCurrentUser', {include:'organizations,teams'})
            .then(user => {
                if(!currentUserOrg || currentUserOrg === 'null') {
                    const defaultOrg = user.organizations.filter(item => {
                        return item.members.default === true;
                    });
                    if(defaultOrg[0]) {
                        commit('setCurrentUserOrg', defaultOrg[0].id);
                    }
                    else {
                        commit('setCurrentUserOrg', user.organizations[0].id);
                    }
                } else {
                    commit('setCurrentUserOrg', currentUserOrg);
                }
                commit('SET_CURRENT_USER_ID', user.id);
                user['editLink'] = '/admin/users/' + user.id + '/general';
                commit('loadUserMutation', {data: user, listName: 'globalUser'});
                commit('login');

                commit('setLoading', false);
                return user;
            }).catch(() => {
                commit('setLoading', false);
            })
    },
    setAuthToken({ commit }, authToken) {
        commit('SET_AUTH_TOKEN', authToken);
    },
    setSessionId({ commit }, sessionId) {
        commit('SET_SESSION_ID', sessionId);
    },
    setRefreshToken({ commit }, refreshToken) {
        commit('SET_REFRESH_TOKEN', refreshToken);
    },
    setRefreshTokenExp({ commit }, exp) {
        commit('SET_REFRESH_TOKEN_EXP', exp);
    },
    authLogout({ commit }) {
        commit('SET_AUTH_TOKEN', null);
        setAccessToken(null);
        commit('SET_REFRESH_TOKEN', null);
        setRefreshToken(null);
        commit('SET_SESSION_ID', null);
        setSessionId(null);
        commit('SET_AUTHENTICATED', false);
        commit('SET_CURRENT_USER_ID', null);
        commit('setCurrentUserOrg', null);
        commit('setMfaQrCode', null);
        commit('setMfaCodeEnter', false);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
