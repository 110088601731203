<template>
  <div
    :style="{
      left: left,
      right: right,
    }"
    :key="forceReRenderKey"
    class="panel-top-bar"
>
<div
    :class="['icon-container', panelEditMode ? 'active bg-highlight' : '']"
    @click="$emit('switchToPanelEdit')">
  <icon size="0.8" type="pencil-alt"/>
  <div class="hover-label">{{ $t('edit') }}</div>
</div>
<div :class="['icon-container', panel.autoFit ? 'active bg-highlight' : '']"
     @click="$emit('setPanelAutoFit', !panel.autoFit)">
  <icon type="arrows-up-down"/>
  <div class="hover-label">{{ $t('autoFit') }}</div>
</div>
    <!--collapsible-->
  <div
    :class="['icon-container', panel.collapsible ? 'active bg-highlight' : '', panel.pin ? 'usable' : 'unusable']"
    @click="panel.pin ? togglePanelCollapsible(panel) : () => {}">
  <icon class="collapsible-icon" :type="getArrowIcon(panel)"/>
  <div class="hover-label">{{ panel.pin ? $t('makeCollapsible') : $t('haveToPinFirst') }}</div>
  </div>
  <div
    v-if="panel.pin && panel.collapsible"
    :class="['icon-container',
            !panel.collapsed ? 'bg-disabled' : '',
            panel.initiallyCollapsed ? 'active bg-highlight' : '',
          ]"
    @click="setPanelInitiallyCollapsed">
  <icon type="minus"/>
  <div class="hover-label">Initially Collapsed</div>
</div>

<!-- Pin Menu Popup -->
<!-- Popup for choosing which side to pin the panel -->
<div :class="['icon-container', 'pin-container-toggle', !!panel.pin ? 'active bg-highlight' : '']">
  <icon class="pin-icon" type="thumbtack"/>
  <!-- <div class="hover-label">Pin Menu</div> -->
  <div class="pin-container-outer-popup">
    <div class="pin-container-popup">
      <div class="pin-container-row">
        <div :class="['icon-container', panel.pin === pinPositions.TOP_LEFT ? 'active bg-highlight' : '']"
             @click="$emit('togglePinButton', pinPositions.TOP_LEFT, 'collapse-left');">
          <icon type="arrow-up-left"/>
        </div>

        <div :class="['icon-container', panel.pin === pinPositions.TOP ? 'active bg-highlight' : '']"
             @click="$emit('togglePinButton', pinPositions.TOP, 'collapse-top');">
          <icon type="arrow-up"/>
        </div>

        <div :class="['icon-container', panel.pin === pinPositions.TOP_RIGHT ? 'active bg-highlight' : '']"
             @click="$emit('togglePinButton', pinPositions.TOP_RIGHT, 'collapse-right');">
          <icon type="arrow-up-right"/>
        </div>
      </div>

      <div class="pin-container-row">
        <div :class="['icon-container', panel.pin === pinPositions.LEFT ? 'active bg-highlight' : '']"
             @click="$emit('togglePinButton', pinPositions.LEFT, 'collapse-left');">
          <icon type="arrow-left"/>
        </div>

        <div :class="['icon-container', panel.pin === pinPositions.RIGHT ? 'active bg-highlight' : '']"
             @click="$emit('togglePinButton', pinPositions.RIGHT, 'collapse-right');">
          <icon type="arrow-right"/>
        </div>
      </div>

      <div class="pin-container-row">
        <div :class="['icon-container', panel.pin === pinPositions.BOTTOM_LEFT ? 'active bg-highlight' : '']"
             @click="$emit('togglePinButton', pinPositions.BOTTOM_LEFT, 'collapse-left');">
          <icon type="arrow-down-left"/>
          <!-- <div class="hover-label">{{ $t('pinToBottomLeft') }}</div> -->
        </div>

        <div :class="['icon-container', panel.pin === pinPositions.BOTTOM ? 'active bg-highlight' : '']"
             @click="$emit('togglePinButton', pinPositions.BOTTOM, 'collapse-bottom');">
          <icon type="arrow-down"/>
          <!-- <div class="hover-label">{{ $t('pinToBottom') }}</div> -->
        </div>

        <div :class="['icon-container', panel.pin === pinPositions.BOTTOM_RIGHT ? 'active bg-highlight' : '']"
             @click="$emit('togglePinButton', pinPositions.BOTTOM_RIGHT, 'collapse-right');">
          <icon type="arrow-down-right"/>
          <!-- <div class="hover-label">{{ $t('pinToBottomRight') }}</div> -->
        </div>
      </div>
      <div class="pin-container-row" style="justify-content: center;">
        <!-- generate a button that says "clear" -->
        <div class="settings-button d-inline-block mt-1" @click="$emit('removePanelPin')">
          {{ $t('clearPin') }}
        </div>
      </div>
    </div>
  </div>
</div>
    <div class="panel-bottom-icons">
      <div :class="['icon-container', panel.hideBottomBar ? 'active' : 'inactive']"
           @click="$emit('setPanelBottomBar')">
        <icon class="eye-icon" type="eye"/>
        <div class="hover-label">{{ $t('hideBottomBar') }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {pinPositions} from "@/enum";
import Icon from "@/components/Icon.vue";
export default {
  name: "PanelOptions.vue",
  components: {Icon},
  props: {
    panelEditMode: {type: Boolean, default: false},
    left: {type: String, default: ''},
    right: {type: String, default: ''},
    panel: {type: Object, required: true},
  },
  data() {
    return {
      pinPositions: pinPositions,
      forceReRenderKey: 0,
    }
  },
  methods: {
    setPanelInitiallyCollapsed() {
      this.$set(this.panel, 'initiallyCollapsed', !this.panel.initiallyCollapsed);
    },
    togglePanelCollapsible() {
      this.$set(this.panel, 'collapsible', !this.panel.collapsible);
    },
    getArrowIcon() {
      const {pin} = this.panel;
      if(!pin) {
        return 'angle-double-left';
      }
      if(pin.toLowerCase().includes('top')) {
        return 'angle-double-up';
      } else if(pin.toLowerCase().includes('bottom')) {
        return 'angle-double-down';
      } else if(pin.toLowerCase().includes('left')) {
        return 'angle-double-left';
      } else if(pin.toLowerCase().includes('right')) {
        return 'angle-double-right';
      }
    }
  }
}
</script>

<style scoped lang="scss">
.panel-top-bar {
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: all 300ms ease;
  position: absolute;
  width: 30px;
  top: 5px;
  z-index: 156;
  height: 100%;
}
.hover-label {
  position: absolute;
  left: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 1);
  padding: 3px;
  border-radius: 3px;
  display: inline-block;
  min-width: 200px;
  opacity: 0;
  pointer-events: none;
  font-size: 0.9rem;
  z-index: 5;
}
.icon-container {
  opacity: 0.6;
  z-index: 4;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin: auto;
  margin-bottom: 3px;
  background-color: var(--ccdefault-normal-button-hover-background);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  position: relative;

  &:hover {
    opacity: 1;
    background-color: var(--ccdefault-normal-button-hover-background);

    .hover-label {
      animation: forwards 0.5s fadeIn;
      animation-delay: 0.5s;
    }
  }

  &.active {
    background-color: $highlight;
  }

  &.bg-highlight {
    background-color: #c55303;
  }

  &.usable {
    cursor: pointer;
  }
  &.unusable {
    cursor: not-allowed;
    &:hover {
      .hover-label {
        animation: none;
        opacity: 1;
      }
    }
  }
}

.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.9rem !important;
  display: inline-block;
  &.collapsible-icon, &.pin-icon {
    margin-top: 1px;
  }
  &.eye-icon {
    margin-top: 1px;
    margin-left: 1px;
  }
}
.panel-bottom-icons {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  padding-bottom: 0;
  z-index: 100;
}

.pin-container-toggle {
  .pin-container-outer-popup {
    opacity: 0;
    display: none;
    z-index: 1000;
  }
  &:hover, &:focus {
    .pin-container-outer-popup {
      display: block;
      opacity: 1;
      position: absolute;
      z-index: 15;
      right: -160px;
      padding: 15px 25px 15px 15px;
      top: -63px;
    }
    .pin-container-popup {
      background-color: gray;
      border-radius: 5px;
      color: #fff;
    }
  }
}
.pin-container-row {
  display: flex;
  justify-content: space-between;
  opacity: 1;

  .icon-container {
    margin: 3px;
    padding: 0;
    width: 30px;
    height: 30px;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>