<template>
    <div class="dropzone-outer">
    {{ attachedData }}
        <div class="dropzoneAndPreview">
            <div class="loading-overlay" v-if="isLoading">
              <div class="text">
              {{ $t('pleaseWait') }}
              <div class="small">{{ $t('thisCanTakeSeveralSeconds', {num: 10}) }}</div>
              </div>
              <loading-spinner />
            </div>
            <preview
                    v-if="['preview', 'previewAndEdit'].includes(status) && !isLoading && !showProgressBar && !hidePreview"
                    :preview-uri="localPreviewUri"
                    :editable="editable"
                    @edit="startEditing"
                    @preview="status !== 'previewAndEdit' ? finishEditing : null"
                    @clearPreviewUri="savePreviewUriToTarget('', '', true)"
                    :preview-id="formId"
                    :downloadable="downloadable"
                    :removable="removable"
                    :source="targetType"
                    :preview-data="previewData"
                    :type="previewType"
            />

            <div class="dropzone-square" :style="['previewAndEdit', 'upload'].includes(status) ? 'opacity: 1' : 'opacity:0'">
                <div class="abort" v-if="abortable && localPreviewUri" @click="finishEditing">
                    <icon type="times" size="normal"/>
                </div>
                <div :class="['dropzone-wrapper', error ? 'error' : '']">
                    <form :id="'dropzone_' + formId" :class="['dropzonejs', assetId, hover ? 'hover' : '']">
                        <div class="fallback">
                            <input name="file" type="file">
                        </div>
                        <div class="upload-text">
                          <div v-if="!storageClient">
                            loading...
                          </div>
                            <slot/>

                                <div v-if="!showProgressBar && (!fileQueue || !fileQueue.length)">
                                    <icon type="file-upload" size="normal"/>
                                    <br>
                                    {{ $t('fileupload') }}<br >
                                  <span v-if="acceptedFileTypes">({{ acceptedFileTypes ? acceptedFileTypes.replaceAll(',', ', ') : '' }})</span>
                                </div>
                              <div class="has-pointer mt-2 mb-2" v-if="fileQueue && fileQueue.length">
                                <div :key="file.upload.uuid" v-for="file in fileQueue">
<span class="lighter">{{ $t('selectedFiles') }}:</span><br >
                                  {{ file.upload.filename }}<br >
                                  <div v-if="!showProgressBar" @click.stop="removeFile(file)"><Button class="mt-2"><icon class="mr-2" type="times" alt="Click me to remove the file." />{{ $t('removeFile') }}</Button></div>
                                </div>
                              </div>
                                <div v-if="showProgressBar">
                                    {{ $t('uploadWaitDisclaimer') }}
                                </div>
                                <div v-if="showProgressBar" class="mt-2 dz-progress"><div :style="{'width': progress + '%'}" class="dz-upload"/></div>
                                <div v-if="totalChunks">{{ sentChunks }} / {{ totalChunks }}</div>
</div>
                    </form>
                </div>
                <div class="form-error" v-if="error">{{ $t(error) }}</div>
            </div>

            <div class="info">
                <slot name="info"/>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Preview from "../preview/Preview";
    import Icon from "../Icon";
    import {mapState} from 'vuex';
    import FileTypeMixin from '@/components/files/FileTypeMixin.js';
    import * as dropzone from '@/dropzone';
    import i18n from "../../translation";
    import {urls} from "@/components/files/uploadConfig";
    import LoadingSpinner from "@/components/LoadingSpinner";
    import Button from "@/components/forms/Button";
    import {v4 as uuidv4} from "uuid";

    Vue.use(dropzone);

    export default {
        name: "UploadDropZone",
        components: {
          LoadingSpinner,
          Preview,
          Icon,
          Button,
        },
        mixins: [FileTypeMixin],
        props: {
            /**
             * @previewUri String a previewUri if there already is one
             */
            previewUri: {type: String, default: ''},
            /**
             * @formId String a unique identifier for this upload form/preview
             */
            formId: {type: String, required: true},
            /**
             * @abortable Boolean whether or not you can abort the upload and return to preview-mode or not
             */
            abortable: {type: Boolean, default: true},
            /**
             * @removable Boolean whether or not you can remove the file entirely (reset)
             */
            removable: {type: Boolean, default: true},
            /**
             * @editable Boolean if you want to show the preview only
             */
            editable: {type: Boolean, default: true},
            /**
             * @assetId String if the target type is an assetId, provide the assetId-ID
             */
            assetId: {type: String, default: null}, //Asset/Project/Instance ID
            instanceId: {type: String, default: null},
            /**
             * @projectId String if the target type is a project, provide the project-ID
             */
            projectId: {type: String, default: null},
            /**
             * @userId String if the target type is a user, provide the user-ID
             */
            userId: {type: String, default: null},
            /**
             * @teamId String if the target type is a team, provide the team-ID
             */
            teamId: {type: String, default: null},
            /**
             * @targetType String The target to which the asset should be attached to
             * e.g. Organization, User, etc...
             * will be used to update/save the previewUri if autoUpdatePreview=true
             * is used for selecting the correct url in "@/components/files/uploadConfig"
             */
            targetType: {type: String, required: true},
            /**
             * @organizationId String the organizationId used for the upload/creation
             */
            organizationId: {type: String, default: null},
            /**
             * @downloadable Boolean whether or not to add the download-button
             */
            downloadable: {type: Boolean, default: true},
            /**
             * If an asset needs to be created: type, name and description can be set
             * @assetType String e.g. media, helper, assembly etc.
             * @assetName String a name
             * @assetDescription String an optional description
             */
            assetType: {type: String, default: 'media'},
            assetName: {type: String, default: ''},
            assetDescription: {type: String, default: ''},
            attributes: {type: String, default: ""},
            /**
             * @acceptedFileTypes String the allowed file types, comma separated list: .pdf,.jpg
             */
            acceptedFileTypes: {type: String, default: null},
            /**
             * @maxFileSize Number the maximal upload file size in MB
             */
            maxFileSize: {type: Number, default: null},
            /**
             * @autoUpload Boolean whether or not to upload the item directly on drag and drop
             * if false, you need to call resumeUpload programmatically when you are ready
             */
            autoUpload: {type: Boolean, default: true}, //Whether or not to upload the file right after drag and drop event
            /**
             * @autoUpdatePreview Boolean whether or not to save the previewUri to the target item
             */
            autoUpdatePreview: {type: Boolean, default: true},
            /**
             * @loading Boolean
             */
            isLoading: {type: Boolean, default: false},
          /**
           * @useDefaultKey whether or not to use the default key instead of the filename
           * default keys are defined in enum.js/defaultKeys
           * */
            useDefaultKey: {type: Boolean, default: true},
          /**
           * @makeChunks Whether or not to upload the file in chunks
           * */
            makeChunks: {type: Boolean, default: false},
            fileName: {type: String, default: ''},

            /**
            * An array of objects:
            * {
            * key: 'mykey',
            * value: 'myvalue'
            * }
            * */
            attachedData: {type: Array, default: () => {}},
            hidePreview: {type: Boolean, default: false},
        },
        data() {
            return {
                parsableFileTypes: ['zip', 'xml', 'csv'],
                totalChunks: 0,
                sentChunks: 0,
                uuid: '',
                previewType: '',
                previewData: '',
                localAssetId: this.assetId,
                localProjectId: this.projectId,
                localInstanceId: this.instanceId,
                originalKey: null,
                targetKey: null,
                status: 'preview',
                error: '',
                localPreviewUri: '',
                uploadDropzone: null,
                hover: false,
                creatingNew: false,
                progress: 0,
                showProgressBar: false,
                fileQueue: [],
                defaultArgs: {
                    autoDiscover: false,
                    dictDefaultMessage: '',
                    dictFileTooBig: this.$t('errors.fileTooBig'),
                    previewTemplate: '<div></div>',
                    withCredentials: false,
                    paramName: "file", // The name that will be used to transfer the file
                    maxFilesize: this.maxFileSize ? this.maxFileSize : 8000, // MB
                    //timeout: 0,
                    chunkSize: 50000000, // 10000000
                    retryChunks: true,
                    retryChunksLimit: 10,
                    chunking: this.makeChunks,

                    timeout: 3600000,
                    url: 'http://www.placeholder.ch',
                    parallelUploads: 1,//only send 1 file at a time, vHub isn't ready for multi uploads
                    autoProcessQueue: this.autoUpload,
                    acceptedFiles: this.acceptedFileTypes,
                    dictInvalidFileType: this.$t('errors.fileTypeNotAllowed', {fileTypes: this.acceptedFileTypes}),
                    headers: {
                          //id: this.targetId,
                          "Authorization": 'Bearer ' + this.$store.getters.authToken,
                          "Access-Control-Allow-Headers": "*",
                      },
                },
            };
        },
        computed: {
            ...mapState({storageClient: state => state.client.storageClient}),
            storageClientURL: function () {
                return this.storageClient && this.storageClient.url ? this.storageClient.url.replace('api-docs/storage.yaml', '').split("?")[0] : '';
            },
        },
        watch: {
            previewUri: function () {
                this.localPreviewUri = this.previewUri;
                this.status = this.previewUri ? 'preview' : 'upload';
            },
            acceptedFileTypes: function() {
              this.uploadDropzone.options.acceptedFiles = this.acceptedFileTypes ? this.acceptedFileTypes + ',' + this.acceptedFileTypes.toUpperCase() : '';
              this.uploadDropzone.options.dictInvalidFileType = this.$t('errors.fileTypeNotAllowed', {fileTypes: this.acceptedFileTypes});
              this.error = '';
            },
            autoUpload: function() {
              this.uploadDropzone.options.autoProcessQueue = this.autoUpload;
            },
            storageClient() {
              this.initDropZone();
            },
            assetId(val) {
              if(val) {
                this.localAssetId = val;
              }
            }
        },
        beforeMount() {
          //set the sanitizeFileName as the renaming-Method
            this.defaultArgs.renameFile = this.sanitizeFileName;
            this.localPreviewUri = this.previewUri;
            this.status = this.previewUri ? 'preview' : 'upload';
            if(!this.storageClient || !this.storageClient.url) {
              this.$store.dispatch('loadStorageClient');
            }
            this.localAssetId = this.assetId;
        },
        mounted() {
            this.initDropZone();
        },
        methods: {
            startEditing: function () {
                this.status = 'upload';
                this.error = '';
                if(!this.uploadDropzone) {
                    this.initDropZone();
                }
            },
            finishEditing: function () {
                this.status = 'preview';
                this.fileQueue = [];
                if(this.uploadDropzone) {
                    this.destroyExistingDropZone();
                }
            },
            destroyExistingDropZone() {
                if (this.uploadDropzone) {
                    this.uploadDropzone.destroy();
                    this.uploadDropzone = null;
                }
            },
            initDropZone() {
                if(!this.editable || !this.storageClientURL || this.uploadDropzone) {
                  Vue.$log.warn('cannot init dropzone – either already intiated or something is missing');
                  return;
                }
                // eslint-disable-next-line no-undef
                this.uploadDropzone = new Dropzone("#dropzone_" + this.formId, this.defaultArgs);
                this.uploadDropzone.options.maxFilesize = this.maxFileSize;
                const $this = this;

                /**
                 * File dropped
                 */
                this.uploadDropzone.on('addedfile', function (file) {
                  const ext = $this.getExtension(file.name);
                  if(!$this.validateFileType(ext)) {
                    // remove the wrong file from the dropzone
                    this.files = [];
                    return;
                  }
                  if(!$this.autoUpload) {
                    $this.setFileKeys(file);
                    if(file.size < 25003452 || ($this.parsableFileTypes.includes(ext.toLowerCase()))) { // max filesize for direct previews
                      $this.fileContentReader(this, ext);
                    }

                    // todo: quickfix!!
                    if(file.size > 2000000000) {
                      $this.$emit('data', '');
                    }
                    $this.previewType = $this.allowPreviewsFor.includes(ext.toLowerCase()) ? $this.getTypeByFileEnding(file.name) : 'file';

                    //$this.fileQueue.push(file); //if later switching to multiple-uploads, use this
                    $this.fileQueue = [file];
                    $this.$emit('filesInQueue', $this.fileQueue, $this.formId);
                    $this.status = 'previewAndEdit';
                    //console.log($this.uploadDropzone.getQueuedFiles());
                  }
                });

                 /**
                 * File shortly before sending
                 */
                this.uploadDropzone.on('processing', function (file) {
                    $this.showProgressBar = true;
                    $this.setFileKeys(file);
                    $this.setTargetURL();
                    if($this.targetType === 'Project') {
                      $this.uploadDropzone.options.paramName = 'part';
                    }
                });

                 /**
                 * File sending
                 */
                this.uploadDropzone.on('sending', function (file, xhr, formData) {
                  $this.uploadDropzone.options.headers.Authorization = 'Bearer ' + $this.$store.getters.authToken;
                  if($this.attachedData && $this.attachedData.length) {
                    $this.attachedData.map(item => {
                      formData.append(item.key, item.value);
                    })

                  }
                  $this.totalChunks = file.upload.totalChunkCount;
                  $this.sentChunks++;
                  // for chunk uploads we need a given uuid
                  if(!$this.localAssetId && !$this.projectId) {
                    if(!$this.uuid) {
                      $this.uuid = uuidv4();
                    }
                    formData.append('uuid', $this.uuid);
                  }
                    if($this.creatingNew) {
                        //if the asset gets created through the dropzone, we need to provide name, type and description as well
                        formData.append('name', $this.assetName ? $this.assetName : `${$this.$uuid.v4()}_${$this.targetKey}`);
                        formData.append('type', $this.assetType); //assetType = "media"
                        if($this.organizationId) {
                          formData.append('organizationId', $this.organizationId);
                        }
                        if($this.teamId) {
                          formData.append('teams', JSON.stringify([$this.teamId]));
                        }

                        if($this.assetDescription) {
                          formData.append('description', $this.assetDescription);
                        }
                    }
                    if($this.attributes && Object.keys($this.attributes).length) {
                      formData.append('attributes', $this.attributes);
                    }
                    if($this.getMediaCategory(file.name)) {
                      formData.append('mediaCategory', $this.getMediaCategory(file.name));
                    }
                });

                /**
                 * After Upload Handling
                 */
                this.uploadDropzone.on("success", function (file, res) {
                  $this.sentChunks = 0;
                  let response;
                  let tmpResponse = res ? res : file.xhr.response;
                  try {
                    response = JSON.parse(tmpResponse);
                  } catch {
                    response = tmpResponse;
                  }
                  if($this.autoUpdatePreview) {
                    $this.savePreviewUriToTarget(response.id, $this.targetKey);
                  }
                  else {
                    $this.$emit('fileReceived', $this.targetType.toLowerCase() === 'project' ? $this.projectId : response.id, $this.targetKey);
                    $this.$emit('response', res.body);
                  }
                  //const id = $this[$this.targetType.toLowerCase() + 'Id'];
                  $this.localPreviewUri = `${response.id ? response.id : $this.projectId}/${$this.targetKey}`;
                  $this.status = 'preview';
                  $this.showProgressBar = false;
                  $this.progress = 0;
                });

                /**
                 * Error Handling
                 */
                this.uploadDropzone.on("error", function (file, response) {
                    $this.error = response.message ? response.message : response;
                    /*if (response.errorCode === 1003) {
                        $this.error = $this.$t('duplicateFileError', {fileName: response.data && response.data[1] ? response.data[1].value : file.name});
                    } else {
                        $this.error = response.message ? response.message : response;
                    }*/
                    $this.hover = false;
                });
                /**
                 * Progress Bar
                 */
                this.uploadDropzone.on("uploadprogress", function(dada, progress) {
                    $this.progress = progress;
                });
                this.$emit('ready');
            },

          /**
           * Reads the content of the file and emits it as data (for zips)
           * or uses them for temporary preview
           */
          fileContentReader(input, ext) {
            let fileReference = input.files && input.files[0];

            if(fileReference){
              const reader = new FileReader();

              reader.onload = (event) => {
                this.previewData = event.target.result;
                this.$emit('data', event.target.result);
              }
              if(this.parsableFileTypes.includes(ext.toLowerCase())) {
                reader.readAsArrayBuffer(fileReference);
              }
              else {
                reader.readAsDataURL(fileReference);
              }
            }
          },
            validateFileType(ext) {
              if(this.acceptedFileTypes && this.acceptedFileTypes.length) {
                return this.acceptedFileTypes.includes('.' + ext.toLowerCase());
              }
              return true;
            },

            savePreviewUriToTarget(assetId, key, reset = false) {
                let method = 'clientSave' + this.targetType;
                const previewId = this.targetType.toLowerCase() === 'project' ? this.projectId : assetId;
                const targetId = this[this.targetType.toLowerCase() + 'Id'] ? this[this.targetType.toLowerCase() + 'Id'] : previewId; //= assetId, projectId, userId
                let args = {
                    id: targetId,
                    previewUri: previewId + '/' + key,
                };
                if(this.targetType === 'User') {
                    args.id = this.userId;
                    args.profilePictureUri = args.previewUri;
                    //delete args.previewUri;
                }
                else if(this.targetType === 'Organization') {
                  args.id = this.organizationId;
                }
                if(reset) {
                    if(this.targetType === 'User') {
                        args.profilePictureUri = null;
                    }
                    else {
                        args.previewUri = null;
                    }
                    this.previewUri = '';
                    this.localPreviewUri = null;
                    this.setTargetURL();
                    this.$store.dispatch('createNotification', {'text': i18n.t('events.pictureRemoved')});
                }
                this.$store.dispatch(method, args).then(() => {
                    this.$emit('fileReceived', previewId, key);

                    //if a new asset got created to be linked, create a previewUri for it as well...
                    if(this.targetType.toLowerCase() !== 'asset' && this.targetType.toLowerCase() !== 'project') {
                      args.id = assetId;
                      this.$store.dispatch('clientSaveAsset', args);
                    }
                });
            },
            /**
             * Sets the targetURL for the POST request depending on the targetType
             */
            setTargetURL() {
              if(!this.assetId && this.previewUri && this.previewUri.length) {
                this.localAssetId = this.previewUri.split('/')[0];
                this.assetId = this.previewUri.split('/')[0];
              }
              this.error = '';
              this.uploadDropzone.options.url = `${this.storageClientURL}${this.assetId || this.instanceId ? urls.update[this.targetType] : urls.create[this.targetType]}`;
              this.uploadDropzone.options.url = this.uploadDropzone.options.url
                  .replace('%key', this.targetKey)
                  .replace('%assetId', this.localAssetId)
                  .replace('%projectId', this.localProjectId)
                  .replace('%instanceId', this.localInstanceId)
                  .replace('%orgid', this.organizationId ? this.organizationId : this.$store.getters.getCurrentUserOrg);

              this.creatingNew = !this.localAssetId;
              Vue.$log.warn('setTargetUrl to ' + this.uploadDropzone.options.url);
            },
            /**
             * Sets the originalKey and if necessary the targetKey
             * @file file a file object
             */
            setFileKeys(file) {
              const extension = this.getExtension(file.name);
              if(this.useDefaultKey && ['fbx', 'glb'].includes(extension.toLowerCase())) {
                this.targetKey = this.getDefaultKeyByFileEnding(file.name.toLowerCase());
              }
              else if(['zip'].includes(extension.toLowerCase())) {
                this.targetKey = this.sanitizeFileName(file.name);
              }
              else if(this.fileName) {
                this.targetKey = this.sanitizeFileName(this.fileName);
              }
              else {
                this.targetKey = this.sanitizeFileName(file.name);
              }
            },
            /**
             * Resumes the stopped process queue
             * (only necessary if autoUpload = false)
             */
            resumeUpload() {
              this.uploadDropzone.processQueue();
            },
            /**
             * Removes a file from the upload queue
             * (only working if autoUpload = false)
             */
            removeFile(file) {
              this.uploadDropzone.removeFile(file);
              this.fileQueue = [];
              this.fileQueue = this.uploadDropzone.getQueuedFiles() && this.uploadDropzone.getQueuedFiles().length ? this.uploadDropzone.getQueuedFiles().upload : [];
              if(!this.fileQueue || !this.fileQueue.length) {
                this.$emit('queueEmpty');
              }
            },
        },
    }
</script>

<style lang="scss">
    .dropzone-outer {
      -webkit-transition: all 500ms ease;
      transition: all 500ms ease;
    }
    .dz-progress {
        width: 100%;
        height:30px;
        pointer-events:none;
        background:$tab-item-background;
    }
    .dz-upload {
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        background-color: $highlight;
        height:30px;
    }
    .dropzoneAndPreview {
        position: relative;
        width: 100%;
        padding-top: 100%;
        .preview {
            position: absolute;
            width: 100%;
            top: 0;
            z-index: 5;
            background-color: $tab-item-background;
            video {
                width: 100%;
            }
        }
        .form-error {
            position: absolute;
            width: 100%;
            bottom: 0px;
            left: 50%;
            transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            word-break: break-all;
        }
    }

    .dropzone-square {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 100%;
        padding-top: 100%;
    }

    .square-image .dropzonejs, .dropzone-square .dropzonejs {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 88%;
        height: 88%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        border: 1px dashed rgba(255,255,255,0.7);
        border-radius: 4px;
        cursor: pointer;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;

        &:hover, &.hover {
            background-color: darken($info-panel-color, 10%);
        }

        .fallback, .fallback input {
            width: 100%;
            position: absolute;
            top: 90%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }

        .upload-text {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            text-align: center;
            width: 90%;
            pointer-events: none;
            word-wrap: break-word;
            font-size: 0.8rem;
            .has-pointer {
              pointer-events:auto;
            }

            i {
                font-size: 2em;
                margin-top: 15px;
            }
        }
    }

    .dropzone-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0,0,0,0.2);
    }

    .abort, .remove {
        position: absolute;
        right: 30px;
        top: 30px;
        font-size: 1em;
        //padding: 6px 8px 6px 10px;
        width: 2.3em;
        height: 2.3em;
        cursor: pointer;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        z-index: 5;
        opacity: 0.7;

        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
        }

        &:hover {
            opacity: 1;
        }
    }
    .remove {
        position:absolute;
        left: 30px;
        top: 30px;
    }
    .loading-overlay {
      width: 100%;
      height:100%;
      background-color: $tab-item-background;
      position:absolute;
      top:0;
      z-index:2;
      .half-circle-spinner, .text {
        position:absolute;
        top:55%;
        left:50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
      }
      .text {
        top: 40%;
        font-size: 1.2rem;
      }
    }
</style>
