<template>
    <div>
      <label class="vform-editor-label section">Options</label>
      <div
          class="dflex"
      >
        <div class="col p-0">
        <textarea
            v-model="element.label.dix[$store.getters.getSelectedFormLanguage]"
            :placeholder="
                        $store.getters.getSelectedFormLanguage === 'Unknown'
                            ? 'Summary'
                            : $t('vform.translationMessage', {
                                  key: $store.getters.getSelectedFormLanguage,
                                  content: element.label.dix['Unknown'],
                              })
                    "
            :title="element.label.dix['Unknown']"
            class="mb-1 form-text v-form-label form-text-dark"
            type="text"
        />
        </div>
      </div>
    </div>
</template>

<script>
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";
import { vFormControls, placeholders } from "@/enum";
export default {
    name: "vFormFORM_SUMMARY",
    mixins: [vFormElementMixin],
    props: {
        stepFormData: {type: Object, default: () => {return {}}}
    },
    data() {
        return {
            forceReRenderKey: 0,
            colorPickrId: null,
            vFormControls: vFormControls,
            placeholders: placeholders,
        };
    },
    beforeMount() {
        this.element.formElementType = vFormControls.FORM_SUMMARY;
    },
};
</script>
