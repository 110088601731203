var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
          'vform-slide-control-element',
          _vm.tiles ? 'vform-tiles' : '',
          !_vm.tiles ? _vm.config.alignment : '',
          'border-radius'
      ],style:({
          paddingTop: _vm.config.height && _vm.config.showastiles ? _vm.config.height / 2 + 'vh' : '',
          backgroundColor: _vm.config.backgroundColor && _vm.tiles ? _vm.config.backgroundColor : '',
          borderColor: _vm.config.borderColor ? _vm.config.borderColor : (_vm.config.backgroundColor ? _vm.config.backgroundColor : ''),
          color: _vm.config.fontColor && _vm.tiles ? _vm.config.fontColor : (_vm.fontColor ? _vm.fontColor : '')
      }),attrs:{"id":_vm.buttonId},on:{"click":function($event){return _vm.handleGoTo(null, 'checkout')},"mouseenter":function($event){_vm.tiles ? _vm.setColors(_vm.buttonId) : function () {}},"mouseleave":function($event){_vm.tiles ? _vm.unsetColors(_vm.buttonId) : function () {}}}},[_vm._t("dropzone"),(_vm.editable)?_c('editor-overlay',{attrs:{"active":_vm.editorSelected,"has-template":!!_vm.config.templateId},on:{"removeBlock":function($event){return _vm.$emit('removeBlock')},"editProperty":_vm.editProperty,"editTemplate":_vm.editTemplate}}):_vm._e(),(_vm.tiles)?_c('div',{class:[
              'clickable inner d-flex BUTTON_ITEM',
              _vm.tiles ? 'p-2' : 'simple-button',
              _vm.config.bottomDropZone ? 'next-button' : '',
              !_vm.tiles ? _vm.config.alignment : '' ],style:({
              'justify-content': 'center',
              'align-items': 'flex-start',
          })},[(_vm.getImage(_vm.config, _vm.lang))?_c('preview',{staticClass:"vform-icon-img",style:({
                  width: _vm.config.iconSize ? _vm.config.iconSize + '%' : '40%',
              }),attrs:{"may-load-now":_vm.isActive,"preview-id":_vm.getImage(_vm.config, _vm.lang),"source":"asset","asset-id":_vm.getImage(_vm.config, _vm.lang),"removable":false,"classes":"square-image","offline-mode":_vm.offlineMode,"spinner-size":"default","show-spinner-message":false},on:{"contentLoaded":_vm.increaseCounter}}):_vm._e(),_c('div',{staticClass:"tile-button-text"},[_vm._v(" "+_vm._s(_vm.getLabel(_vm.config, _vm.lang) ? _vm.getLabel(_vm.config, _vm.lang) : _vm.$t("logout"))+" ")])],1):_c('div',{class:[
              'clickable inner d-flex align-items-center BUTTON_ITEM',
              _vm.tiles ? 'p-2' : 'pt-2 pb-2 pr-3 pl-3',
              _vm.config.bottomDropZone ? 'next-button' : '',
              !_vm.tiles ? _vm.config.alignment : '',
              _vm.config.textAlignment ? ("text-" + (_vm.config.textAlignment)) : '' ],style:({
            backgroundColor: _vm.config.backgroundColor ? _vm.config.backgroundColor : '',
            borderColor: _vm.config.backgroundColor ? _vm.config.backgroundColor : '',
            color: _vm.config.fontColor ? _vm.config.fontColor : (_vm.fontColor ? _vm.fontColor : '')
        }),attrs:{"id":_vm.buttonId},on:{"mouseenter":function($event){!_vm.tiles ? _vm.setColors(_vm.buttonId) : function () {}},"mouseleave":function($event){!_vm.tiles ? _vm.unsetColors(_vm.buttonId) : function () {}}}},[(_vm.getImage(_vm.config, _vm.lang))?_c('preview',{staticClass:"mini vform-preview-img vform-icon-img mr-1",style:({
                  flexBasis: _vm.config.iconSize ? _vm.getInlineIconSize(_vm.config.iconSize) + '%' : '40%',
              }),attrs:{"may-load-now":_vm.isActive,"preview-id":_vm.getImage(_vm.config, _vm.lang),"source":"asset","asset-id":_vm.getImage(_vm.config, _vm.lang),"removable":false,"offline-mode":_vm.offlineMode},on:{"contentLoaded":_vm.increaseCounter}}):_vm._e(),_c('div',{class:['text', _vm.getImage(_vm.config, _vm.lang) ? '' : 'without-icon']},[(!_vm.config.buttonStyle ||  _vm.config.buttonStyle === 'Text' || _vm.config.buttonStyle === 'TextAndArrow')?[_vm._v(" "+_vm._s(_vm.getLabel(_vm.config, _vm.lang) ? _vm.getLabel(_vm.config, _vm.lang) : _vm.$t("logout"))+" ")]:[_c('div',{domProps:{"innerHTML":_vm._s('&nbsp;')}})]],2),(_vm.config.buttonStyle === 'Arrow' || _vm.config.buttonStyle === 'TextAndArrow')?_c('div',{staticClass:"ml-3"},[_c('icon',{attrs:{"type":"angle-right"}})],1):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }