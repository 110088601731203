<template>
  <div></div>
</template>

<script>
const errorLimit = 4;
import {
  newProjectStructureVersion,
  vFormFiles,
  SpecialUuids,
  projectLoadingModes,
  vformLoadingModes,
  fontCuts,
  vFormControls,
} from "../../../enum";
import {compareVersion} from "@/utils";
import MetaSetsMixin from "@/components/mixins/MetaSetsMixin.js.vue";
import {checkURLValidity} from "@/components/vForm/utils";
import {sleep} from "@/utils";
export default {
  name: "vFormAndProjectMixin.js",
  mixins: [MetaSetsMixin],
  data() {
    return {
      checkURLValidity: checkURLValidity,
    };
  },
  methods: {
    getPanelIndex(uuid, activeStepObject, globalObject, isLocal = true) {
      if(isLocal) {
        return activeStepObject.panels.findIndex(item => {
          return item.uuid === uuid
        });
      } else {
        return globalObject.panels.findIndex(item => {
          return item.uuid === uuid
        });
      }
    },
    getPanelByUuid(uuid, activeStepObject, globalObject) {
      const index = activeStepObject.panels.findIndex(item => {
        return item.uuid === uuid
      });
      if (index !== -1) {
        return activeStepObject.panels[index];
      } else if (globalObject.panels) {
        const index2 = globalObject.panels.findIndex(item => {
          return item.uuid === uuid
        });
        if (index2 !== -1) {
          return globalObject.panels[index2];
        }
      }
    },
    async loadFormProject(projectId) {
      return this.$store.dispatch('loadProject', {id: projectId});
    },
    /**
     * ----------- TEMPLATE LOADING
     * @returns {object} - the template as an object
     *   - templates {Array} - the array of templates
     *   - templateAssetId {Uuid} - the id of the template asset
     * */
    async loadTemplates(organizationId, config = {}, errorCounter = 0) {
      const {loadingMode, projectId} = config;
      try {
        if (loadingMode === projectLoadingModes.OFFLINE) {
          return await this.loadStaticTemplates(projectId);
        } else if (loadingMode === projectLoadingModes.CURRENT) {
          return await this.loadTemplatesFromStorage(organizationId);
        } else {
          console.log('invalid loading mode for template loading')
        }
      } catch (e) {
        console.log(e);
        // retry x times before giving up
        if (errorCounter > errorLimit) {
          throw new Error('loading templates failed');
        } else {
          errorCounter = errorCounter + 1;

          return await this.loadTemplates(organizationId, config, errorCounter);
        }
      }
    },
    async loadStaticTemplates(vformProjectId, errorCounter = 0) {
      console.log('loading static templates', `${window.location.pathname}vforms/${vformProjectId}/${vFormFiles.TEMPLATE_CONF}`)
      return await fetch(`${window.location.pathname}vforms/${vformProjectId}/${vFormFiles.TEMPLATE_CONF}`)
          .then(async (response) => {
            try {
              console.log('response', response)
              const templates = await response.json();
              console.log('templates after response', templates)
              return {templates, templateAssetId: null};
            } catch {
              return {
                templates: [],
                templateAssetId: null,
              }
            }
          }).catch(e => {
            console.log(e);
            if([500, 404].includes(e.statusCode)) {
              return;
            }
            // retry x times before giving up
            if (errorCounter > errorLimit) {
              throw new Error('loading templates failed');
            } else {
              errorCounter = errorCounter + 1;
              return this.loadStaticTemplates(vformProjectId, errorCounter);
            }
          })
    },
    async loadTemplatesFromStorage(organizationId, errorCounter = 0) {
      const templateAssetId = await this.loadTemplateAsset(organizationId);
      const templates = await this.$store.dispatch('loadBlockTemplates', {id: templateAssetId}).catch(async e => {
        console.log('error loading templates')
        console.log(e);
        console.log(e.statusCode)
        if(e.statusCode === 404) {
          console.log('no templates available');
          return {templates: [], templateAssetId};
        }
        if (errorCounter > errorLimit) {
          throw new Error('loading templates failed');
        } else {
          await sleep(1000);
          errorCounter = errorCounter + 1;
          return this.loadTemplatesFromStorage(organizationId, errorCounter);
        }
      });
      return {templates, templateAssetId};
    },
    async loadTemplateAsset(organizationId, errorCounter = 0) {
      return this.$store.dispatch("clientLoadAssets", {
            filter: "organizationId eq " + organizationId + ",type eq template",
          }).then(assets => {
            if(assets && assets[0]) {
              console.log('template asset found: ' + assets[0].id)
              return assets[0].id;
            }
          }).catch(e => {
            console.log(e);
            // retry x times before giving up
            if (errorCounter > errorLimit) {
              throw new Error('loading template asset failed');
            } else {
              errorCounter = errorCounter + 1;
              return this.loadTemplateAsset(organizationId, errorCounter);
            }
          });
    },
    async loadFormInstance(projectId) {
      return this.$store
          .dispatch("clientGetCrossProjectInstances", {
            filter: "projectId eq " + projectId + ",type eq form",
          })
          .then(async (instances) => {
            if (instances.length) {
              // vForm is connected to only one single project
              const instance = instances[0];
              return instance.assetId;
            } else {
              throw new Error('instance could not be found')
            }
          });
    },
    getText(config, lang) {
      return config.text.dix[lang] ? config.text.dix[lang] : config.text.dix.Unknown;
    },
    getSelectionElementsFromElementList(elements) {
      return elements ? elements.filter(item => {
        return [vFormControls.CHECKBOXES, vFormControls.RADIOBUTTONS].includes(item.formElementType);
      }) : []
    },
    getStepById(uuid, steps) {
      const res = steps.filter(item => {
        return item.uuid === uuid
      });
      return res && res[0] ? res[0] : null;
    },
    getSlides(config) {
      return config.slides;
    },
    getElementsByPanel(uuid, globalObject, stepObject, includeUuidNull = false) {
      const allEls = globalObject && globalObject.elements ? [...stepObject.elements, ...globalObject.elements] : stepObject.elements;
      return allEls.filter(item => {
        return item.panelUuid === uuid || (includeUuidNull && !item.panelUuid);
      })
    },
    /**
     * Gets the transition time of a slide which is composed between:
     * - transitionDuration (default value: 1s)
     * - stillDuration (default value: 2s)
     * If the properties are not set on the slide, the default values apply.
     * */
    getSlideTransitionTime(slide) {
      let totalTime = 0.0;
      const {transitionDuration, stillDuration} = slide;
      totalTime += transitionDuration !== undefined ? transitionDuration : 1.0;
      totalTime += stillDuration !== undefined ? stillDuration : 2.0;
      return totalTime;
    },
    getSlideId(slide) {
      if (!slide) {
        throw new Error('slide is missing')
      }
      return slide.id;
    },
    async getProjectLoadingMode(projectId) {
      // eslint-disable-next-line no-undef
      /*const path = $cookies.get('configPath');

      if(window.location.protocol === 'file:' || path) {
        return projectLoadingModes.LOCAL_FILE;
      }*/
      return this.$store.dispatch('clientLoadProjectMetaValues', {id: projectId})
          .then(res => {
            let loadingMode = projectLoadingModes.LEGACY;
            if (res.length) {
              const versionField = res.filter(item => {
                return item.metaFieldId === SpecialUuids.PROJECT_VERSION_METAFIELD;
              })
              if (versionField.length) {
                const {value} = versionField[0];
                if (value && value.includes('.')) {
                  if (compareVersion(newProjectStructureVersion, value) <= 0) {
                    return projectLoadingModes.CURRENT;
                  }
                }
              }
            }
            console.log('no project version given, loading legacy as default')
            return loadingMode;
          })
    },
    async loadProjectVideos(projectId) {
      return await this.$store.dispatch('clientListProjectParts', {id: projectId})
          .then(parts => {
            return parts.length ? parts.filter(item => {
              return item.contentType === 'video/mp4'
            }) : [];
          })
    },

    async loadFirstThumbnailIfNewProject(projectId) {
      const projectConfig = await this.loadProjectConfigFileFromStorageNew(projectId).catch(() => {
        console.log('old project')
      });
      if(projectConfig) {
       const {slides} = projectConfig;
        return projectId + '/' + slides[0].id + '.png';
      }
      return null;
    },

    /**
     * ----------- THUMBNAIL LOADING
     * */
    /***
     * Loads the thumbnail for each slide
     * and triggers the setUpConfig
     * @params activeSlideUuid
     * @params limit - the amount of images to be loaded
     * @params slideContainer – the object containing all slides
     * @params config
     *  - callback - a method in case we want a callback
     *  - width - target width of thumbnails (default: full size)
     *  - height - target heigth of thumbnails (default: full size)
     *  @returns {base64} the base 64 encoded image
     * */
    async loadThumbnailNew(projectId, slide, index, config = {}, errorCounter = 0) {
      if (!projectId) {
        throw new Error('cannot load thumbnail without project id')
      }
      if (!slide) {
        throw new Error('cannot load thumbnail without slide object')
      }
      try {
        const {loadingMode} = config;
        if (loadingMode === projectLoadingModes.OFFLINE) {
          // mode 1: offline mode
          return await this.loadThumbnailFromDisk(projectId, slide, index, config);
        } else if (loadingMode === projectLoadingModes.LEGACY) {
          // mode 2: offline and legacy
          return await this.loadThumbnailFromStorageLegacy(projectId, slide, index, config);
        } else if (loadingMode === projectLoadingModes.CURRENT) {
          // mode 3: online and current
          return await this.loadThumbnailFromStorage(projectId, slide, config);
        } else {
          console.log('invalid loading mode for thumbnail loading')
        }
      } catch (e) {
        console.log(e);
        // retry x times before giving up
        if (errorCounter > errorLimit) {
          throw new Error('loading thumbnail failed');
        } else {
          errorCounter = errorCounter + 1;
          return this.loadThumbnailNew(projectId, slide, index, config, errorCounter);
        }
      }
    },
    getThumbnailName(slide, index, config) {
      const {loadingMode, isThumbnail} = config;
      if (loadingMode === projectLoadingModes.OFFLINE) {
        // mode 1: offline mode
        // todo: if new structure with uuids, deliver that one! (new state: offline_legacy and offline)
        const num = index + 1;
        return "_slides/" + ("000000000" + num).substr(-3) + "/thumbnail.png";
      } else if (loadingMode === projectLoadingModes.LEGACY) {
        // mode 2: online and legacy
        const num = index + 1;
        return "_slides/" + ("000000000" + num).substr(-3) + "/thumbnail.png";
      } else if (loadingMode === projectLoadingModes.CURRENT) {
        // mode 3: online and current
        if (isThumbnail) {
          return `${slide.id}.t120.png`;
        }
        return `${slide.id}.png`;
      } else {
        console.log('invalid loading mode for thumbnail loading')
      }
    },
    /**
     * Loads the thumbnails of a vSTAGE project
     * This method refers to the old project structure with the presentation.zip
     * */
    async loadThumbnailFromStorageLegacy(projectId, slide, index, config = {}) {
      const {mini, format, width, height} = config;
      let args = {
        id: projectId,
        key: 'presentation.zip',
        fileName: this.getThumbnailName(slide, index, config),
      };

      if (mini) {
        args.width = width ? width : 10;
        args.height = height ? height : 5;
        args.resizeMethod = 'cover';
      }

      return await this.$store.dispatch('clientDownloadProjectZipPart', args)
          .then(async (data) => {
            if(format === 'url') {
              return this.getImageAsObjectURL(data);
            } else {
              return await this.getImageAsBase64(data);
            }
          })
    },
    /**
     * Loads a thumbnail of a vSTAGE project (new project structure)
     * */
    async loadThumbnailFromStorage(projectId, slide, config) {
      const {format, width, height} = config;
      let args = {
        id: projectId,
        key: this.getThumbnailName(slide, null, config)
      };

      if (width && height) {
        args.width = width;
        args.height = height;
        args.resizeMethod = 'cover';
      }

      return await this.$store.dispatch("clientDownloadProject", args)
          .then(async (data) => {
            if(format === 'url') {
              return this.getImageAsObjectURL(data);
            } else {
              return await this.getImageAsBase64(data);
            }
          })
    },
    /**
     * Loads a thumbnail from a local directory inside of the vuejs folder (webapp folder)
     * this method is already based on the new project structure
     */
    async loadThumbnailFromDisk(projectId, slide, index/*, config*/) {
      //const {format} = config;
      let fileName = this.getThumbnailName(slide, index, {loadingMode: projectLoadingModes.CURRENT});
      let thumbnail = `${window.location.pathname}vforms/${projectId}/${fileName}`;
      console.log('fetching: ' + thumbnail);
      let contentType = "";
      return await fetch(thumbnail)
          .then((response) => {
            contentType = response.headers["content-type"];
            return response.arrayBuffer();
          })
          .then((buff) => {
            const imgBase64 = new Buffer(buff, "binary").toString(
                "base64"
            );
            return `data:${contentType ? contentType : 'image/png'};base64,${imgBase64}`;
          })
      /*return await fetch(thumbnail)
          .then(async (data) => {
            // todo: check why this is not working with object urls...
            // eslint-disable-next-line no-constant-condition
            if(format === 'url') {
              return await this.getImageAsObjectURL(data, false);
            } else {
              return await this.getImageAsBase64(data);
            }
          })*/
    },

    async saveFormNew(config, errorCounter = 0) {
      const {formId, content, isFile} = config;
      try {
        if (!isFile) {
          return await this.saveFormToContentField(formId, content);
        } else {
          return await this.saveFormToFile(formId, content);
        }
      } catch (e) {
        console.log(e);
        // retry x times before giving up
        if (errorCounter > errorLimit) {
          throw new Error('saving form failed');
        } else {
          errorCounter = errorCounter + 1;
          return await this.saveFormNew(config, errorCounter);
        }
      }
    },
    async saveFormToFile(formId, content) {
      return await this.$store.dispatch('createAssetTextFile', {
        id: this.formId,
        fileName: vFormFiles.VFORM_CONF,
        fileContent: JSON.stringify(content)
      });
    },
    async saveFormToContentField(formId, content) {
      return await this.$store.dispatch("updateForm", {
        id: formId,
        content: JSON.stringify(content), // this is to minify the json code
        vformProjectId: this.projectId,
      })
    },

    /**
     * ----------- FORM LOADING
     * */
    /**
     * Loads a vForm
     * @returns {object} - the form as an object
     */
    async loadFormNew(config, errorCounter = 0) {
      const {projectId, formId} = config;
      if (!projectId) {
        throw new Error('cannot load form without project id')
      }
      try {
        const {loadingMode} = config;
        console.log('loading mode for vforms config: ' + loadingMode)
        if (loadingMode === vformLoadingModes.OFFLINE) {
          // mode 1: offline mode
          return await this.loadStaticForm(projectId);
        } else if (loadingMode === vformLoadingModes.CONTENT) {
          const res = await this.loadFormFromStorage(formId);
          if (res) {
            return res;
          }
          return await this.loadFormFromDB(formId);
        } else {
          console.log('invalid loading mode for form loading')
        }
        throw new Error('loading form failed')
      } catch (e) {
        console.log(e);
        // retry x times before giving up
        if (errorCounter > errorLimit) {
          throw new Error('loading form failed');
        } else {
          errorCounter = errorCounter + 1;
          return await this.loadFormNew(projectId, config, errorCounter);
        }
      }
    },

    async loadFormFromDB(id) {
      return this.$store
          .dispatch("loadForm", {
            id,
            include: "tags,metaSets",
          }).then((form) => {
            try {
              return JSON.parse(form.content)
            } catch {
              console.log('form content either empty or not parsable')
              return {};
            }
          });
    },
    /**
     * OFFLINE form loading
     * */
    async loadStaticForm(projectId) {
      return await fetch(`${window.location.pathname}vforms/${projectId}/${vFormFiles.VFORM_CONF}`)
          .then((response) => {
            return response.json();
          });
    },
    /**
     * Loads the form from the asset storage instead of the DB
     * */
    async loadFormFromStorage(id) {
      let args = {id, key: vFormFiles.VFORM_CONF};
      return await this.$store.dispatch("clientDownloadAsset", args)
          .then(async (file) => {
            return JSON.parse(file.text);
          }).catch(e => {
            if (e.response.errors.statusCode === 404) {
              return null;
            } else {
              throw new Error(e);
            }
          })
    },

    /**
     * ----------- PROJECT CONFIG LOADING
     * */

    /**
     * Loads the config.json from a vSTAGE project
     * @params {uuid} - projectId - the id of the project
     *
     * @returns {json} - the parsed json if available
     * */
    async loadProjectConfigFileNew(projectId, config, errorCounter = 0) {
      if (!projectId) {
        throw new Error('cannot load thumbnail without project id')
      }
      try {
        const {loadingMode} = config;
        console.log('loading mode for project config: ' + loadingMode)
        if (loadingMode === projectLoadingModes.OFFLINE) {
          // mode 1: offline mode – the config is already normalized to the new version
          return await this.loadStaticProjectConfig(projectId);
        } else if (loadingMode === projectLoadingModes.LEGACY) {
          const res = await this.loadProjectConfigFileLegacy(projectId);
          return this.processLegacyProjectConfig(res);
        } else if (loadingMode === projectLoadingModes.CURRENT) {
          return await this.loadProjectConfigFileFromStorageNew(projectId);
        } else {
          console.log('invalid loading mode for thumbnail loading')
        }
      } catch (e) {
        // retry x times before giving up
        if (errorCounter > errorLimit) {
          throw new Error('loading project config failed');
        } else {
          errorCounter = errorCounter + 1;
          return this.loadProjectConfigFileNew(projectId, config, errorCounter);
        }
      }
    },
    /**
     * The legacy version has slides in the form:
     *  {id: 0, uuid: some-uuid}
     *
     *  The new version has slides without the uuid property:
     *  {id: some-uuid}
     * */
    processLegacyProjectConfig(config) {
      if (!config) {
        return;
      }
      const {slides} = config;
      if (slides && slides.length) {
        for (let i = 0; i < slides.length; i++) {
          let slide = slides[i];
          slide.id = slide.uuid;
          delete slide.uuid;
          // eslint-disable-next-line no-prototype-builtins
          if(slide.hasOwnProperty('isActive')) {
            slide.active = slide.isActive;
          }
          config.slides[i] = slide;
        }
      }
      return config;
    },
    async loadProjectConfigFileLegacy(projectId) {
      return await this.$store
          .dispatch("clientDownloadProjectZipPart", {
            id: projectId,
            key: "presentation.zip",
            fileName: "config.json",
          }).then(res => {
            return res.body;
          })
    },

    async loadStaticProjectConfig(projectId) {
      return await fetch(`${window.location.pathname}vforms/${projectId}/config.json`)
          .then((response) => {
            return response.json();
          })
    },

    async loadProjectConfigFileFromStorageNew(projectId) {
      let args = {id: projectId, key: `config.json`};
      return await this.$store.dispatch("clientDownloadProject", args)
          .then(async (file) => {
            return file.body;
          })
    },
    /**
     * Loads the video config file from the storage of the vform asset
     * @returns {json} - a json array containing the config
     * */
    async loadVideoConfigFileNew(config, errorCounter = 0) {
      const {formId, projectId} = config;
      try {
        const {loadingMode} = config;
        // there is no loading type content because the video config was always a file
        if (loadingMode === vformLoadingModes.OFFLINE) {
          console.log('mode 1: offline mode videoconfig')
          return await this.loadStaticVideoConfigFile(projectId);
        } else if (loadingMode === vformLoadingModes.FILE) {
          return await this.loadVideoConfigFile(formId);
        } else {
          console.log('invalid loading mode for videoconfig loading')
        }
      } catch (e) {
        // retry x times before giving up
        if (errorCounter > errorLimit) {
          console.log('loading videoconfig failed'); // do not throw an error because the videoconfig is optional
        } else {
          errorCounter = errorCounter + 1;
          return this.loadVideoConfigFileNew(config, errorCounter);
        }
      }
    },
    async loadVideoConfigFile(formId) {
      return await this.$store.dispatch('clientDownloadAsset', {
        id: formId,
        key: vFormFiles['VIDEO_CONF'],
      }).then(data => {
        return data.body;
      }).catch(e => {
        if (e.response.errors.statusCode === 404) {
          return null;
        } else {
          throw new Error(e);
        }
      })
    },
    /**
     * Loads the static video config file from webapp directory
     * @params {uuid} - projectId - the id of the project belonging to the vform
     * */
    async loadStaticVideoConfigFile(projectId) {
      return fetch(`${window.location.pathname}vforms/${projectId}/${vFormFiles.VIDEO_CONF}`)
          .then((response) => {
            if (response) {
              // videoconfig might also not exist
              return response.json();
            }
            return null;
          })
          .then((videoConfig) => {
            return videoConfig;
          })
          .catch((e) => {
            console.log('Failed loading project videoconfig.json from disk.');
            console.log(e);
            this.videoConfigError = e;
          });
    },
    /**
     * Gets the step for a specific slide
     * @params slides {Array} - the array of slides coming from the presentation.zip/config.json
     * @params steps {Array} - the array of steps coming from the vForm asset
     * @params uuid {Uuid} - the uuid of the slide
     * @params activeSlideUuid {Uuid} - the uuid of the current active slide
     * @params activeStepIndex {Uuid} - the fallback in case there are no slides given
     * @params uuid {Uuid} - the uuid of the slide
     * @returns {Object} - the step object
     * This method needs the following properties to be set (see above):
     *  - slide {Array}
     *  - activeSlideUuid {Uuid}
     *  - config {Object}
     * **/
    getStepForSlide(slides, steps, uuid, activeSlideUuid, activeStepIndex = null) {
      // case 1: no slides
      if (!slides || !slides.length) {
        console.log('Error: no slides given, cannot get step for slide, giving back current step instead');
        const res = steps && steps.length ? steps.filter((item) => {
          return item.uuid === activeStepIndex;
        }) : [];
        return res && res.length ? res[0] : null;
      }
      if (uuid) {
        const res = steps && steps.length ? steps.filter((item) => {
          return (item.linkedSlide === uuid || (item.linkedSlides && item.linkedSlides.includes(uuid)));
        }) : [];
        // direct linked slide
        if (res && res.length) {
          return res[0];
        }
        // going backwards through the slides, looking for the next step
        const index = slides.findIndex(item => {
          return item.id === activeSlideUuid
        });
        for (let i = index; i >= 0; i--) {
          const slide = slides[i].id;
          const res = steps.filter(item => {
            return item.linkedSlide === slide || (item.linkedSlides && item.linkedSlides.includes(slide))
          });
          if (res && res.length) {
            return res[0];
          }
        }
      }
      return null;
    },
    /*getFontName(projectId, fontName, fontId, config) {
      const {loadingMode} = config;
      if (loadingMode === projectLoadingModes.OFFLINE) {
        // mode 1: offline mode
        return `vforms-${projectId}-${fontName}`;
      } else if (loadingMode === projectLoadingModes.CURRENT) {
        // mode 3: online and current
        return `${fontName}`;
      } else {
        console.log('invalid loading mode for thumbnail loading')
      }
    },*/
    /**
     * Lists all files for a given font (e.g. font-bold.ttf, font-regular.ttf and so on)
     * */
    async listFontFiles(projectId, fontId, config = {}, errorCounter = 0) {
      try {
        const {loadingMode} = config;
        // there is no loading type content because the video config was always a file
        if (loadingMode === projectLoadingModes.OFFLINE) {
          console.log('mode 1: offline mode list font files')
          return await this.loadStaticFontList(projectId);
        } else if (loadingMode === projectLoadingModes.CURRENT) {
          return await this.listFontFilesFromStorage(fontId);
        } else {
          console.log('invalid loading mode for videoconfig loading')
        }
      } catch (e) {
        console.log(e);
        // retry x times before giving up
        if (errorCounter > errorLimit) {
          console.log('listFontFiles failed'); // do not throw an error because the videoconfig is optional
        } else {
          errorCounter = errorCounter + 1;
          return this.listFontFiles(projectId, fontId, config, errorCounter);
        }
      }
    },
    /**
     * Loads the font list for published projects in the webapp folder
     * */
    async loadStaticFontList(projectId, errorCounter = 0) {
      return fetch(`${window.location.pathname}vforms/${projectId}/${vFormFiles.FONT_CONF}`)
          .then((response) => {
            if (response) {
              // videoconfig might also not exist
              return response.json();
            }
            return null;
          })
          .then((fontConfig) => {
            return fontConfig;
          })
          .catch((e) => {
            console.log('Failed loading project fonts.json from disk.');
            console.log(e);
            // retry x times before giving up
            if (errorCounter > errorLimit) {
              console.log('listFontFiles failed'); // do not throw an error because the videoconfig is optional
            } else {
              errorCounter = errorCounter + 1;
              return this.loadStaticFontList(projectId, errorCounter);
            }
          });
    },
    /**
     * @params {id} - id of the font asset
     * */
    async listFontFilesFromStorage(fontAssetId) {
      return this.$store.dispatch('loadAssetFiles', {
        id: fontAssetId
      })
    },
    // this filters out all items without the font attributes – they cannot be used anyway
    // sets item.fontTypes so it is easier to access
    normalizeAndFilterFontArray(fileList) {
      const newFonts = [];
      fileList.map(item => {
        const {metaData} = item;
        if (metaData) {
          const {attributes} = metaData;
          if (attributes) {
            const {sfxFontTypes} = attributes;
            if (sfxFontTypes && sfxFontTypes.length) {
              item.fontTypes = sfxFontTypes;
              newFonts.push(item);
            }
          }
        }
      })
      return newFonts;
    },
    applyFonts(fontUrls, fontName) {
      const cuts = Object.values(fontCuts);
      let firstUrl = "";
      let styleString = "";
      for (let i = 0; i < cuts.length; i++) {
        const cut = cuts[i];
        const index = fontUrls.findIndex(item => {
          return item.cut === cut;
        })
        if (index !== -1) {
          const {cut, url} = fontUrls[index];
          if (cut === 'normal') {
            firstUrl = url;
          }
          styleString += ` @font-face { font-family: "${fontName + ' ' + cut}"; src: url("${url}");}`;
        } else if (firstUrl) {
          styleString += ` @font-face { font-family: "${fontName + ' ' + cut}"; src: url("${firstUrl}");}`;
        } else {
          console.log('could not apply font ' + fontName + ' for cut ' + cut)
        }
      }
      const style = document.createElement('style');
      style.appendChild(document.createTextNode(styleString));
      document.getElementsByTagName('head')[0].appendChild(style);
    },
    /**
     * @params newFonts {Array} - an array of objects.
     *  {
     *    key: 'fontkey'
     *
     *  }
     * @returns array of objects:
     *  - cut {string} - name of the cut (e.g. bold)
     *  - url {url} - objecturl to the font
     * */
    async loadFontFiles(projectId, fontId, fontName, newFonts, config) {
      // this method doesn't have a catch method because both loading methods have them
      // we do not want this method to fail in case one single font cut cannot be loaded
      const {loadingMode} = config;
      const cuts = Object.values(fontCuts);
      const fontUrls = [];
      for (let i = 0; i < cuts.length; i++) {
        let url = null;
        const currentCut = cuts[i];
        const index = newFonts.findIndex(item => {
          return item.fontTypes.includes(currentCut);
        })
        if (index !== -1) {
          const {key} = newFonts[index];

          // there is no loading type content because the video config was always a file
          if (loadingMode === projectLoadingModes.OFFLINE) {
            url = await this.loadStaticFont(projectId, key);
          } else if (loadingMode === projectLoadingModes.CURRENT) {
            url = await this.loadFontFileFromStorage(fontId, key);
          } else {
            console.log('invalid loading mode for font list loading')
            break;
          }
          if (url) {
            fontUrls.push({
              cut: currentCut,
              url: url
            });
          }
        }
      }
      return fontUrls;
    },
    async loadStaticFont(projectId, key, errorCounter = 0) {
      let contentType = "";
      const fileName = `${key}`;
      return await fetch(fileName)
          .then((response) => {
            contentType = response.headers["content-type"];
            return response.arrayBuffer();
          })
          .then((buff) => {
            const imgBase64 = new Buffer(buff, "binary").toString(
                "base64"
            );
            return `data:${contentType};base64,${imgBase64}`;
          }).catch(e => {
            console.log(e);
            // retry x times before giving up
            if (errorCounter > errorLimit) {
              console.log('loadStaticFont failed'); // do not throw an error because the videoconfig is optional
            } else {
              errorCounter = errorCounter + 1;
              return this.loadStaticFont(projectId, key, errorCounter);
            }
          })
    },
    async loadFontFileFromStorage(fontId, key, errorCounter = 0) {
      return await this.$store.dispatch('clientDownloadAsset', {id: fontId, key})
          .then(async data => {
            if (!data) {
              return null;
            }
            return window.URL.createObjectURL(data.text);
          }).catch(e => {
            console.log(e);
            // retry x times before giving up
            if (errorCounter > errorLimit) {
              console.log('loading font file failed'); // do not throw an error because the videoconfig is optional
            } else {
              errorCounter = errorCounter + 1;
              return this.loadFontFileFromStorage(fontId, key, errorCounter);
            }
          })
    },
    /**
     * Loads all font cuts of a specific font and sets the @font-face property in the css
     * */
    async loadAndApplyFonts(projectId, fontId, fontName, config) {
      // load the list of all available font files
      const fileList = await this.listFontFiles(projectId, fontId, config)
      // filter out all non applicable font files (there might be more than we actually use)
      const newFonts = this.normalizeAndFilterFontArray(fileList);
      // load the file for each font and set its object url
      const fontUrls = await this.loadFontFiles(projectId, fontId, fontName, newFonts, config);
      this.applyFonts(fontUrls, fontName);
    },
    getSlideIndex(slides, slideUuid) {
      const slidesWithoutGhosts = this.getSlidesWithoutGhosts(slides);
      if(slidesWithoutGhosts) {
        return slidesWithoutGhosts.findIndex(item => {
          return item.id === slideUuid;
        })
      }
      return -1;
    },
    getSlidesWithoutGhosts(slides) {
      return slides.filter(item => {
        // eslint-disable-next-line no-prototype-builtins
        return !item.ghostSlide && (!item.hasOwnProperty('active') || (!item.hasOwnProperty('active') && item.active))
      });
    },
    getLastSlideUuid(allSlides) {
      const slides = this.getSlidesWithoutGhosts(allSlides);
      return slides[slides.length - 1].id;
    },
    getFirstSlideUuid(allSlides) {
      const slides = this.getSlidesWithoutGhosts(allSlides);
      return slides[0].id;
    },
    getNextSlideUuid(allSlides, activeSlideUuid) {
      const slidesWithoutGhosts = this.getSlidesWithoutGhosts(allSlides);
      const index = slidesWithoutGhosts.findIndex(item => {
        return item.id === activeSlideUuid
      });
      const uuid = slidesWithoutGhosts[index + 1] && slidesWithoutGhosts[index + 1].id ? slidesWithoutGhosts[index + 1].id : slidesWithoutGhosts[0].id;
      return uuid;
    },
    getLastStepUuid(steps) {
      return steps[steps.length - 1].uuid;
    },
    getFirstStepUuid(steps) {
      return steps[0].uuid;
    },
    /**
     * Returns an image from a response as a base64 string
     * */
    async getImageAsBase64(data) {
      const contentType = data.headers["content-type"];
      const mimeType = contentType ? contentType.toLowerCase() : 'image/png';
      const arrayBuffer = await new Response(data.text).arrayBuffer();
      const imgBase64 = new Buffer(arrayBuffer, "binary").toString(
          "base64"
      );
      return "data:" + mimeType + ";base64," + imgBase64;
     },
    /**
     * Returns an image from a response as an object URL
     * */
    async getImageAsObjectURL(data) {
      try {
        return URL.createObjectURL(data.text);
      } catch {
       return data.text;
      }
    }
  }
}
</script>