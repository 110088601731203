import Vue from 'vue'
import VueRouter from 'vue-router'

import SFXEditLinkedData from "./views/SFXEditLinkedData";
import SFXEditMetaData from "./views/SFXEditMetaData";
import SFXEditAssembly from "./views/SFXEditAssembly";
import SFXEditDefault from "./views/SFXEditDefault";
import SFXEditInstance from "@/views/SFXEditInstance";
import SFXAssemblyUpdate from "@/views/SFXAssemblyUpdate";
import SFXEditExportProfiles from "@/views/SFXEditExportProfiles";

import SFXAdd from './views/SFXAdd.vue'
import SFXAddAssetBundle from "@/views/SFXAddAssetBundle";

import AdminProjects from "@/views/AdminProjects";

import Projects from './views/Projects.vue'
import ProjectEdit from './views/ProjectEdit.vue'
import ProjectEditScene from './views/ProjectEditScene.vue'
import ProjectEditLinkedData from "./views/ProjectEditLinkedData";

import Organizations from "./views/Organizations";
import OrganizationAdd from "./views/OrganizationAdd";
import OrganizationEdit from "./views/OrganizationEdit";
import OrganizationDashboard from "./views/OrganizationDashboard";

import ProjectAdd from './views/ProjectAdd.vue'

import FileBrowserView from "@/views/FileBrowserView";
import VersionView from "@/views/VersionView";

import SFXData from './views/SFXData.vue'

import Media from './views/Media.vue'
import MediaAdd from './views/MediaAdd.vue'
//import MediaUpload from "./views/MediaUpload";
import MediaEdit from './views/MediaEdit.vue'
import FontEdit from "./views/FontEdit";

import TextureAdd from "./views/TextureAdd";
import TextureEdit from "./views/TextureEdit";
import Texture from "./views/Texture";

import Apps from './views/Apps.vue'
import AppAdd from './views/AppAdd.vue'
import AppEdit from "./views/AppEdit";
import AppEditProject from "./views/AppEditProject";
import WebappEditLinkedData from "./views/WebappEditLinkedData";

import DataSets from './views/DataSets.vue';
import DatasetAdd from "./views/DatasetAdd";
import DatasetEdit from './views/DatasetEdit.vue'

import DataSources from './views/DataSources.vue';
import DataSourceAdd from "./views/DataSourceAdd";
import DatasourceEdit from "./views/DataSourceEdit";

import Forms from "./views/Forms";
import FormEdit from "./views/FormEdit";
import FormAdd from "./views/FormAdd";
//import ProjectFormView from "./views/ProjectFormView";

import ExportProfiles from "./views/ExportProfiles";
import ExportProfilesEdit from "./views/ExportProfilesEdit";

import TagsEdit from "./views/TagsEdit";
import MetaSets from "./views/MetaSets";
import Services from "./views/Services";
import ServiceAdd from "@/views/ServiceAdd";
import ServiceEdit from "@/views/ServiceEdit";

import QrCodes from "./views/QrCodes";
import QrCodeAdd from "./views/QrCodeAdd";
import QrCodeEdit from "./views/QrCodeEdit";

import MaterialMappers from "./views/MaterialMappers";
import MaterialMapperAdd from "./views/MaterialMapperAdd";
import MaterialMapperEdit from "./views/MaterialMapperEdit";
import Templates from "@/views/Templates.vue";

import AccessDenied from "./views/AccessDenied";
import NotFound from "./views/NotFound";
import GDPR from "@/views/GDPR";
import SAAS_AGB from "@/views/SAAS_AGB";
import SAAS_SERVICES from "@/views/SAAS_SERVICES";
import INFO from "@/views/INFO";

import MetaSetEdit from "./views/MetaSetEdit";
import MetaSetAdd from "./views/MetaSetAdd";

import Reports from "./views/Reports";
import ReportEdit from "./views/ReportEdit";
import ReportAdd from "./views/ReportAdd";

import Texts from "./views/Texts";
import TextAdd from "./views/TextAdd";
import TextEdit from "./views/TextEdit";

import Users from "./views/Users";
import UserEdit from "./views/UserEdit";
import UserAdd from "./views/UserAdd";

import Permissions from "@/views/Permissions";
import PermissionAdd from "@/views/PermissionAdd";
import PermissionEdit from "@/views/PermissionEdit";

import Roles from "@/views/Roles";
import RoleAdd from "@/views/RoleAdd";
import RoleEdit from "@/views/RoleEdit";

import Help from "./views/Help";
import Ticketing from "./views/Ticketing";

import Teams from "@/views/Teams";
import TeamAdd from "@/views/TeamAdd";
import TeamEdit from "@/views/TeamEdit";

import Materials from "@/views/Materials";
import MaterialAdd from "@/views/MaterialAdd";
import MaterialEdit from "@/views/MaterialEdit";
import Scenes from "@/views/Scenes";
import SceneAdd from "@/views/SceneAdd";
import SceneEdit from "@/views/SceneEdit";

import VirtualMachines from "@/views/VirtualMachines";
import VMLogEntries from "@/views/VMLogEntries";
import VMLogEntriesStats from "./views/VMLogEntriesStats";
import CostOverview from "@/views/CostOverview";
import VirtualMachineAdd from "@/views/VirtualMachineAdd";
import CSVImport from "@/views/CSVImport";

import Furioos from "./components/Furioos";
import Regex from "@/views/Regex.vue";

import {SpecialUuids} from "@/enum";
import TemplateEdit from "@/views/TemplateEdit.vue";
import SearchTool from "@/views/SearchTool.vue";
import BluePrints from './views/BluePrints.vue';
import BluePrintEdit from './views/BluePrintEdit.vue';
import BluePrintAdd from './views/BluePrintAdd.vue';
import store from "@/store/store";
Vue.use(VueRouter);

const router = new VueRouter({
    routes: getRoutes(),
    // Doesn't fit with vhub Apps:
    // mode: 'history',
})
export default router;


function getRoutes() {
    return [
        {
            path: '/',
            name: 'login',
            meta: {
                title: 'vHub CC | ' + 'Login',
                icon: 'folder',
                pagetitle: 'login',
            },
        },
        {
            path: '/logout',
            name: 'logout',
            meta: {
                title: 'vHub CC | ' + 'Logout',
                icon: 'folder',
                pagetitle: 'logout',
            },
        },
        {
            path: '/help',
            name: 'help',
            component: Help,
            meta: {
                title: 'vhub CC | Help',
                icon: 'question-circle',
                pagetitle: 'help',
            },
        },
        {
            path: '/ticketing',
            name: 'ticketing',
            component: Ticketing,
            meta: {
                title: 'vhub CC | Ticketing',
                icon: 'question-circle',
                pagetitle: 'ticketing',
            },
        },
        {
            path: '/app',
            name: 'app',
            component: Apps,
            meta: {
                title: 'vhub CC | Apps',
                icon: 'mobile',
                pagetitle: 'apps',
                accessPermission: 'read',
                staticPermission: true,
                type: null,
            },
            navbar: true,
            appbackbutton: true,
            webappbackbutton: true,
            appspanel: true
        },
        {
            path: '/apps/cclights',
            name: 'cclights',
            component: Apps,
            meta: {
                title: 'vhub CC | Apps',
                icon: 'mobile',
                pagetitle: 'cclights',
                accessPermission: 'read',
                staticPermission: true,
                type: 'cclight'
            },
            webappbackbutton: true,
            appspanel: true
        },
        {
            path: '/apps/appPanels',
            name: 'appPanels',
            component: Apps,
            meta: {
                title: 'vhub CC | Apps',
                icon: 'mobile',
                pagetitle: 'appPanels',
                accessPermission: 'read',
                staticPermission: true,
                type: 'appPanel'
            },
            webappbackbutton: true,
            appspanel: true
        },
        {
            path: '/access-denied',
            name: 'access_denied',
            component: AccessDenied,
            meta: {
                title: 'vhub CC | Access Denied',
                icon: 'ban',
                pagetitle: 'access_denied',
            },
        },
        {
            path: '/not-found',
            name: 'not_found',
            component: NotFound,
            meta: {
                title: 'vhub CC | Resource not found',
                icon: 'ban',
                pagetitle: 'not_found',
            },
        },
         {
            path: '/gdpr',
            name: 'gdpr',
            component: GDPR,
            meta: {
                title: 'vhub CC | GDPR',
                icon: 'ban',
                pagetitle: 'gdpr',
                singlePage: true,
                component: 'GDPR',
            },
        },
        {
            path: '/saas-agb',
            name: 'saas-agb',
            component: SAAS_AGB,
            meta: {
                title: 'vhub CC | SAAS AGB',
                icon: 'file',
                pagetitle: 'saasAgb',
                singlePage: true,
                component: 'SAAS_AGB',
            },
        },
         {
            path: '/saas-services',
            name: 'saas-services',
            component: SAAS_SERVICES,
            meta: {
                title: 'vhub CC | SAAS SERVICES',
                icon: 'file',
                pagetitle: 'saasServices',
                singlePage: true,
                component: 'SAAS_SERVICES',
            },
        },
         {
            path: '/vhub-info',
            name: 'info',
            component: INFO,
            meta: {
                title: 'vhub CC | Info',
                icon: 'ban',
                pagetitle: 'info',
            },
        },
        
        {
            path: '/project',
            name: 'project',
            component: Projects,
            meta: {
                title: 'vHub CC | ' + 'Projects',
                icon: 'folder',
                pagetitle: 'projects',
                accessPermission: 'read',
                staticPermission: true,
            },
            navbar: true,
            projectbackbutton: true,
        },
        {
            path: '/report',
            name: 'reports',
            component: Reports,
            meta: {
                title: 'vHub CC | ' + 'Reports',
                icon: 'chart-bar',
                pagetitle: 'reports',
                accessPermission: 'accessReportsPage',
                staticPermission: true,
            },
            navbar: true,
            reportbackbutton: true,
        },
        {
            path: '/report/add',
            name: 'reports_add',
            component: ReportAdd,
            meta: {
                title: 'vHub CC | ' + 'Add Report',
                icon: 'chart-bar',
                pagetitle: 'reports',
                accessPermission: 'accessReportsPage',
                staticPermission: true,
            },
        },
        {
            path: '/library/3d-data',
            name: 'sfxdata',
            component: SFXData,
            meta: {
                title: 'vhub CC | 3D-Data',
                icon: 'cube',
                pagetitle: 'sfxdata',
                accessPermission: 'read',
                staticPermission: true,
            },
            // navbar: true,
            librarypanel: true,
            sfxbackbutton: true,
            modelbackbutton: true,
            assemblybackbutton: true,
        },
        {
            path: '/library/blueprint',
            name: 'blueprint',
            component: BluePrints,
            props: true,
            meta: {
                title: 'vhub CC | 3D-Data',
                icon: 'compass-drafting',
                pagetitle: 'blueprint',
                accessPermission: 'read',
                staticPermission: true,
            },
            librarypanel: true,
            blueprintsbackbutton: true,
        },
        {
            path: '/library/blueprint/:id/general',
            name: 'blueprintEdit',
            component: BluePrintEdit,
            props: true,
            meta: {
                title: 'vhub CC | 3D-Data',
                icon: 'compass-drafting',
                pagetitle: 'sfxdata',
                accessPermission: 'read',
                staticPermission: true,
            },
            blueprinttab: true,
        },
        {
            path: '/blueprint/add',
            name: 'Add Blueprint',
            component: BluePrintAdd,
            meta: {
                title: 'vhub CC | Add bluePrint',
                icon: 'folder',
                pagetitle: 'blueprints',
                accessPermission: 'create',
                staticPermission: true,
            },
        },
        {
            path: '/library/:type/:id/files',
            name: 'files',
            component: FileBrowserView,
            meta: {
                title: 'vhub CC',
                icon: 'file',
                pagetitle: 'files',
                //accessPermission: 'accessFileBrowser',
                staticPermission: true,
                allowSFXUsers: true,
            },
            blueprinttab: true,
        },
        {
            path: '/library/scenes',
            name: 'scenes',
            component: Scenes,
            meta: {
                title: 'vhub CC | Scenes',
                icon: 'image',
                pagetitle: 'scenes',
                accessPermission: 'accessScenesPanel',
                staticPermission: true,
                allowSFXUsers: true,
            },
            //navbar: true,
            librarypanel: true,
            scenebackbutton: true,
        },
        {
            path: '/library',
            name: 'library',
            redirect: { name: 'sfxdata' },
            props: true,
            //component: Library,
            component: SFXData,
            meta: {
                title: 'vhub CC | Library',
                icon: 'book',
                pagetitle: 'library',
                accessPermission: 'read',
                staticPermission: true,
            },
            navbar: true,
        },
        {
            path: '/organizing',
            name: 'organizing',
            redirect: { name: 'tags' },
            props: true,
            //component: Library,
            component: SFXData,
            meta: {
                title: 'vhub CC | Organizing',
                icon: 'book',
                pagetitle: 'organizing'
            },
            navbar: true,
        },
        {
            path: '/organizing/tags',
            name: 'tags',
            component: TagsEdit,
            meta: {
                title: 'vhub CC | Tags',
                icon: 'tag',
                pagetitle: 'tags'
            },
            //navbar: true,
            organizingpanel: true,
        },
        {
            path: '/organizing/metaSets',
            name: 'metaSets',
            component: MetaSets,
            meta: {
                title: 'vhub CC | MetaSets',
                icon: 'list-ul',
                pagetitle: 'metaSets',
                accessPermission: 'createMetaSet',
                staticPermission: true,
            },
            //navbar: true,
            organizingpanel: true,
            metasetbackbutton: true,
        },
        {
            path: '/organizing/regex',
            name: 'regex',
            component: Regex,
            meta: {
                title: 'vhub CC | Regex',
                icon: 'code',
                pagetitle: 'regex',
            },
            //navbar: true,
            organizingpanel: true,
        },
        {
            path: '/organizing/adminproject',
            name: 'adminproject',
            component: AdminProjects,
            meta: {
                title: 'vHub CC | ' + 'Projects',
                icon: 'folder',
                pagetitle: 'projects',
                accessPermission: 'isSuperAdmin',
                staticPermission: true,
            },
            organizingpanel: true,
        },
        {
            path: '/organizing/metaSets/:id/general',
            name: 'metaSets_general',
            props: true,
            component: MetaSetEdit,
            meta: {
                title: 'vhub CC | Edit Metasets General',
                icon: 'list-ul',
                pagetitle: 'metasets',
                accessPermission: 'writeMetaSet'
            },
            firstItem: true,
        },
        {
            path: '/organizing/metaSets/add',
            name: 'metaSets_add',
            props: true,
            component: MetaSetAdd,
            meta: {
                title: 'vhub CC | Add Metasets',
                icon: 'list-ul',
                pagetitle: 'metasets',
                accessPermission: 'createMetaSet',
                staticPermission: true,
            },
            firstItem: true,
        },
        {
            path: '/organizing/csv/add',
            name: 'csv_import',
            props: true,
            component: CSVImport,
            meta: {
                title: 'vhub CC | Import from CSV',
                icon: 'file-csv',
                pagetitle: 'csv',
                accessPermission: 'importCSV',
                staticPermission: true,
            },
            firstItem: true,
            organizingpanel: true,
        },
        {
            path: '/furioos',
            name: 'furioos',
            props: true,
            component: Furioos,
            meta: {
                title: 'vhub CC | Furioos',
                icon: 'file-csv',
                pagetitle: 'csv',
                accessPermission: 'furioos',
                staticPermission: true,
                singlePage: true,
            },
            firstItem: true,
        },
        {
            path: '/admin',
            name: 'admin',
            redirect: { name: 'teams' },
            props: true,
            //component: Library,
            component: Teams,
            meta: {
                title: 'vhub CC | Admin',
                icon: 'book',
                pagetitle: 'admin',
            },
            navbar: true,
        },

        {
            path: '/admin/organizations',
            name: 'organizations_overview',
            component: Organizations,
            meta: {
                title: 'vhub CC | organizations',
                icon: 'building',
                pagetitle: 'organizations',
                accessPermission: 'writeOrganization',
                staticPermission: true,
            },
            adminpanel: true,
            organizationbackbutton: true,
        },
        {
            path: '/admin/organizations/add',
            name: 'organizations_add',
            component: OrganizationAdd,
            meta: {
                title: 'vhub CC | organizations',
                icon: 'building',
                pagetitle: 'organizations',
                accessPermission: 'createOrganization',
                staticPermission: true,
            },
        },
        {
            path: '/admin/organizations/:id/general',
            name: 'organizations',
            component: OrganizationEdit,
            meta: {
                title: 'vhub CC | organizations',
                icon: 'building',
                pagetitle: 'organizations',
                accessPermission: 'writeOrganization'
            },
            organizationtab: true,
        },
        {
            path: '/admin/organizations/:id/dashboard',
            name: 'organization_dashboard',
            component: OrganizationDashboard,
            meta: {
                title: 'vhub CC | organizations',
                icon: 'building',
                pagetitle: 'organization',
                //accessPermission: 'writeOrganization',
                staticPermission: true,
                servicePermission: 'organization_dashboard',
            },
            organizationtab: true
        },
        {
            path: '/admin/teams',
            name: 'teams',
            component: Teams,
            meta: {
                title: 'vhub CC | Teams',
                icon: 'users',
                pagetitle: 'teams',
            },
            //navbar: true,
            adminpanel: true,
            teambackbutton: true,
        },
        {
            path: '/admin/teams/add',
            name: 'teams_add',
            component: TeamAdd,
            meta: {
                title: 'vhub CC | Teams',
                icon: 'users',
                pagetitle: 'teams',
                accessPermission: 'createTeam',
                staticPermission: true,
            },
        },
        {
            path: '/admin/teams/:id/general',
            name: 'team_general',
            component: TeamEdit,
            meta: {
                title: 'vhub CC | Teams',
                icon: 'users',
                pagetitle: 'teams',
                accessPermission: 'createTeam'
            },
            teamtab: true,
        },
        {
            path: '/admin/users',
            name: 'users',
            component: Users,
            meta: {
                title: 'vhub CC | users',
                icon: 'user',
                pagetitle: 'users',
                accessPermission: 'writeUser',
                staticPermission: true,
                orgPermissionOnly: true
            },
            //navbar: true,
            adminpanel: true,
            userbackbutton: true,
        },
        {
            path: '/admin/users/add',
            name: 'Add User',
            component: UserAdd,
            meta: {
                title: 'vhub CC | Add User',
                icon: 'user',
                pagetitle: 'users',
                accessPermission: 'createUser',
                staticPermission: true,
            },
        },
        {
            path: '/admin/services',
            name: 'services',
            component: Services,
            meta: {
                title: 'vhub CC | Services',
                icon: 'cog',
                pagetitle: 'services',
                accessPermission: 'accessServicePanel',
                staticPermission: true,
            },
            //navbar: true,
            adminpanel: true,
            servicebackbutton: true,
        },
        {
            path: '/admin/services/add',
            name: 'services_add',
            component: ServiceAdd,
            meta: {
                title: 'vhub CC | Services',
                icon: 'tag',
                pagetitle: 'services',
                accessPermission: 'accessServicePanel',
                staticPermission: true,
            },
            //navbar: true,
        },
        {
            path: '/admin/services/:id/general',
            name: 'service_edit',
            component: ServiceEdit,
            meta: {
                title: 'vhub CC | Services',
                icon: 'tag',
                pagetitle: 'services',
                accessPermission: 'accessServicePanel',
                staticPermission: true,
            },
            servicetab: true,
            //navbar: true,
        },
        {
            path: '/admin/permissions/:id/general',
            name: 'permission_edit',
            component: PermissionEdit,
            meta: {
                title: 'vhub CC | Permissions',
                icon: 'ban',
                pagetitle: 'permissions',
                accessPermission: 'accessPermissionPanel'
            },
            permissiontab: true,
            //navbar: true,
        },
        {
            path: '/admin/permissions',
            name: 'permissions',
            component: Permissions,
            meta: {
                title: 'vhub CC | Permissions',
                icon: 'ban',
                pagetitle: 'permissions',
                accessPermission: 'accessPermissionPanel',
                staticPermission: true,
            },
            //navbar: true,
            adminpanel: true,
            permissionsbackbutton: true,
        },
        {
            path: '/admin/permissions/add',
            name: 'Add Permission',
            component: PermissionAdd,
            meta: {
                title: 'vhub CC | Add Permission',
                icon: 'ban',
                pagetitle: 'permissions',
                accessPermission: 'accessPermissionPanel',
                staticPermission: true,
            },
        },

        {
            path: '/admin/roles/:id/general',
            name: 'role_edit',
            component: RoleEdit,
            meta: {
                title: 'vhub CC | Roles',
                icon: 'user-tag',
                pagetitle: 'roles',
                accessPermission: 'writeRole'
            },
            //navbar: true,
        },
        {
            path: '/admin/roles',
            name: 'roles',
            component: Roles,
            meta: {
                title: 'vhub CC | Roles',
                icon: 'user-tag',
                pagetitle: 'roles',
                accessPermission: 'readRole',
                staticPermission: true,
            },
            //navbar: true,
            adminpanel: true,
            rolebackbutton: true,
        },
        {
            path: '/admin/roles/add',
            name: 'Add Role',
            component: RoleAdd,
            meta: {
                title: 'vhub CC | Add Role',
                icon: 'user-tag',
                pagetitle: 'roles',
                accessPermission: 'createRole',
                staticPermission: true,
            },
        },
        {
            path: '/admin/vm/add',
            name: 'createVirtualMachine',
            component: VirtualMachineAdd,
            meta: {
                title: 'vhub CC | Add Virtual Machine',
                icon: 'desktop',
                pagetitle: 'createVirtualMachine',
                accessPermission: 'createVM',
                staticPermission: true,
            },
            vmtab: true,
        },
        {
            path: '/admin/vm/:id/general',
            name: 'createVirtualMachine',
            component: VirtualMachineAdd,
            meta: {
                title: 'vhub CC | Add Virtual Machine',
                icon: 'desktop',
                pagetitle: 'createVirtualMachine',
                accessPermission: 'createVM',
                staticPermission: true,
            },
        },
        {
            path: '/admin/taskmanager/:type/:id',
            params: {id: SpecialUuids['TASKMANAGER_DATASET'], type: 'taskmanager'},
            name: 'taskmanager',
            props: {
                id: SpecialUuids['TASKMANAGER_DATASET'], type: 'taskmanager'
            },
            component: DatasetEdit,
            meta: {
                title: 'vhub CC | ManageTasks',
                icon: 'tasks',
                pagetitle: 'taskmanager',
                accessPermission: 'adminTaskmanager',
                staticPermission: true,
            },
            adminpanel: true,
        },
        {
            path: '/admin/vm/',
            name: 'vmOverview',
            component: VirtualMachines,
            meta: {
                title: 'vhub CC | VM Overview',
                icon: 'desktop',
                pagetitle: 'VM Overview',
                accessPermission: 'adminVMOverview',
                staticPermission: true,
            },
            adminpanel: true,
            vmbackbutton: true
        },
        {
            path: '/admin/vm-usage/',
            name: 'vmUsageAll',
            component: VMLogEntries,
            meta: {
                title: 'vhub CC | VM Usage',
                icon: 'chart-pie',
                pagetitle: 'VM Usage',
                // accessPermission: 'accessVMLogsPanel',
                accessPermission: 'nonExistingPermission',
                staticPermission: true,
                allowSFXBilling: true
            },
            adminpanel: true,
        },
        {
            path: '/admin/vm-stats/',
            name: 'vmUsageAllStats',
            component: VMLogEntriesStats,
            meta: {
                title: 'vhub CC | VM Usage',
                icon: 'chart-pie',
                pagetitle: 'VM Usage',
                // accessPermission: 'accessVMLogsPanel',
                accessPermission: 'nonExistingPermission',
                staticPermission: true,
            },
            adminpanel: true,
        },
        {
            path: '/admin/cost-overview/',
            name: 'costOverview',
            component: CostOverview,
            meta: {
                title: 'vhub CC | Cost overview',
                icon: 'dollar-sign',
                pagetitle: 'costOverview',
                // accessPermission: 'accessVMLogsPanel',
                accessPermission: 'nonExistingPermission',
                staticPermission: true,
                allowSFXBilling: true,
            },
            adminpanel: true,
        },
        {
            path: '/admin/search',
            name: 'search',
            component: SearchTool,
            meta: {
                title: 'vhub CC | Search',
                icon: 'search',
                pagetitle: 'search',
                accessPermission: 'search',
                staticPermission: true,
            },
            adminpanel: true,
        },
        {
            path: '/library/media',
            name: 'media',
            component: Media,
            meta: {
                title: 'vhub CC | Media',
                icon: 'film',
                pagetitle: 'media',
                accessPermission: 'read',
                staticPermission: true,
            },
            //navbar: true,
            librarypanel: true,
            mediabackbutton: true,
        },
        {
            path: '/library/texts',
            name: 'texts',
            component: Texts,
            meta: {
                title: 'vHub CC | ' + 'Texts',
                icon: 'align-left',
                pagetitle: 'texts',
                accessPermission: 'read',
                staticPermission: true,
                spacer: true
            },
            librarypanel: true,
            textbackbutton: true,
        },
        {
            path: '/library/forms',
            name: 'forms',
            component: Forms,
            meta: {
                title: 'vHub CC | ' + 'Forms',
                icon: 'poll-h',
                pagetitle: 'forms',
            },
            librarypanel: true,
            formbackbutton: true,
        },
        {
            path: '/library/templates',
            name: 'templates',
            component: Templates,
            meta: {
                title: 'vHub CC | ' + 'Templates',
                icon: 'copy',
                pagetitle: 'templates',
                spacer: true,
                accessPermission: 'writeTemplate',
                staticPermission: true,
            },
            librarypanel: true,
            templatebackbutton: true,
        },
        {
            path: '/library/dataset',
            alias: '/data',
            name: 'datasets',
            component: DataSets,
            meta: {
                title: 'vHub CC | ' + 'Data Tables',
                icon: 'table',
                pagetitle: 'datasets',
                accessPermission: 'read',
                staticPermission: true,
            },
            //navbar: true,
            librarypanel: true,
            datasetbackbutton: true,
        },

        {
            path: '/library/datasource',
            name: 'data_sources',
            component: DataSources,
            meta: {
                title: 'vHub CC | ' + 'Data Tables',
                icon: 'database',
                pagetitle: 'data_sources',
                accessPermission: 'read',
                staticPermission: true,
                spacer: true
            },
            librarypanel: true,
            datasourcebackbutton: true,
        },
        {
            path: '/library/texts/add',
            name: 'Add Text',
            component: TextAdd,
            meta: {
                title: 'vhub CC | Add Text',
                icon: 'align-left',
                pagetitle: 'texts',
                accessPermission: 'create',
                staticPermission: true,
            },
        },

        {
            path: '/library/texts/:id/general',
            name: 'text_general',
            props: true,
            component: TextEdit,
            meta: {
                title: 'vhub CC | Edit Text General',
                icon: 'align-left',
                pagetitle: 'texts',
                accessPermission: 'write'
            },
            texttab: true,
        },
        {
            path: '/library/template/:id/general',
            name: 'template_general',
            props: true,
            component: TemplateEdit,
            meta: {
                title: 'vHub CC | ' + 'templates',
                icon: 'copy',
                pagetitle: 'teplates',
                accessPermission: 'writeTemplate',
                staticPermission: true,
            },
            templatetab: true,
        },
        {
            path: '/library/form/:id/general',
            name: 'form_general',
            props: true,
            component: FormEdit,
            meta: {
                title: 'vHub CC | ' + 'Forms',
                icon: 'poll-h',
                pagetitle: 'forms',
                accessPermission: 'write'
            },
            formtab: true,
        },
        {
            path: '/library/forms/add',
            name: 'form_add',
            component: FormAdd,
            meta: {
                title: 'vHub CC | ' + 'Forms',
                icon: 'poll-h',
                pagetitle: 'forms',
                accessPermission: 'write',
                staticPermission: true,
            },
        },
        {
            path: '/library/media/:id/general',
            name: 'media_general',
            props: true,
            component: MediaEdit,
            meta: {
                title: 'vhub CC | Edit Media General',
                icon: 'film',
                pagetitle: 'media',
                accessPermission: 'write'
            },
            mediatab: true,
        },
        {
            path: '/library/font/:id/general',
            name: 'font_general',
            props: true,
            component: FontEdit,
            meta: {
                title: 'vhub CC | Edit Font General',
                icon: 'film',
                pagetitle: 'font',
                accessPermission: 'write'
            },
            fonttab: true,
        },
        {
            path: '/library/texture/:id/general',
            name: 'texture_general',
            props: true,
            component: TextureEdit,
            meta: {
                title: 'vhub CC | Edit Texture General',
                icon: 'game-board-simple',
                pagetitle: 'texture',
                accessPermission: 'write'
            },
            addtexturetab: true,
            texturetab: true
        },
        {
            path: '/app/:id/general',
            name: 'app_general',
            props: true,
            component: AppEdit,
            meta: {
                title: 'vhub CC | Edit App General',
                icon: 'mobile',
                pagetitle: 'apps',
                accessPermission: 'write'
            },
            apptab: true,
            webapptab: true,
        },
        {
            path: '/app/:id/project',
            name: 'app_project',
            props: true,
            component: AppEditProject,
            meta: {
                title: 'vhub CC | Edit App Project',
                icon: 'mobile',
                pagetitle: 'apps',
                accessPermission: 'write'
            },
            apptab: true,
        },
        {
            path: '/app/:id/linked_data',
            name: 'webapp_linked_data',
            props: true,
            component: WebappEditLinkedData,
            meta: {
                title: 'vhub CC | Edit App Project',
                icon: 'mobile',
                pagetitle: 'apps',
                accessPermission: 'write'
            },
            webapptab: true,
        },
        {
            name: 'sfxdata_general',
            props: true,
            path: '/library/3d-data/model/:id/general',
            component: SFXEditDefault,
            meta: {
                title: 'vhub CC | Edit 3D data',
                icon: 'cube',
                pagetitle: 'sfxdata',
                accessPermission: 'write'
            },
            modeltab: true,
        },
        {
            name: 'sfxdata_general',
            props: true,
            path: '/library/3d-data/helper/:id/general',
            component: SFXEditDefault,
            meta: {
                title: 'vhub CC | Edit 3D data',
                icon: 'cube',
                pagetitle: 'sfxdata',
                accessPermission: 'write'
            },
            helpertab: true,
        },
        {
            //be aware that if you change that path you will have to adapt the assembly-Path in vhub config.js as well
            name: 'sfxdata_general_assembly',
            props: true,
            path: '/library/3d-data/assembly/:id/general',
            alias: '/library/3d-data/assembly/:id/',
            component: SFXEditAssembly,
            meta: {
                title: 'vhub CC | Edit 3D data',
                icon: 'cube',
                pagetitle: 'sfxdata',
                accessPermission: 'read',
            },
            assemblytab: true,
        },
        {
            name: 'sfxdata_meta_data',
            props: true,
            path: '/library/3d-data/:id/linked_data',
            component: SFXEditMetaData,
            meta: {
                title: 'vhub CC | Edit 3D data',
                icon: 'cube',
                pagetitle: 'sfxdata',
                accessPermission: 'write'
            },
            assemblytab: true,
        },
        {
            name: 'sfxdata_linked_data',
            props: true,
            path: '/library/3d-data/:id/linked_items',
            component: SFXEditLinkedData,
            meta: {
                title: 'vhub CC | Edit 3D data',
                icon: 'cube',
                pagetitle: 'sfxdata',
                accessPermission: 'write'
            },
            assemblytab: true,
        },
        {
            name: 'sfxdata_export_profiles',
            props: true,
            path: '/library/3d-data/:id/export_profiles',
            component: SFXEditExportProfiles,
            meta: {
                title: 'vhub CC | Edit 3D data',
                icon: 'cube',
                pagetitle: 'sfxdata',
                accessPermission: 'createMetaSet',
                staticPermission: true,
            },
            assemblytab: true,
        },
        {
            name: 'sfxdata_assembly_update',
            props: true,
            path: '/library/3d-data/:id/update',
            component: SFXAssemblyUpdate,
            meta: {
                title: 'vhub CC | Update an assembly',
                icon: 'cube',
                pagetitle: 'sfxdata',
                accessPermission: 'createMetaSet',
                allowSFXUsers: true,
                //accessPermission: 'createMetaSet',
                staticPermission: true,
            },
            assemblytab: true,
        },
        {
            path: '/library/dataset/:id/general',
            name: 'dataset_general',
            props: true,
            component: DatasetEdit,
            meta: {
                title: 'vhub CC | Edit Dataset General',
                icon: 'folder',
                pagetitle: 'datasets',
                accessPermission: 'write'
            },
            datasettab: true,
            firstItem: true,
        },
        {
            path: '/library/datasource/:id/general',
            name: 'data_sources_general',
            component: DatasourceEdit,
            meta: {
                title: 'vHub CC | ' + 'Data Tables',
                icon: 'database',
                pagetitle: 'datasources',
                accessPermission: 'write'
            },
            //navbar: true,
            datasourcetab: true,
            firstItem: true,
        },
        {
            path: '/project/:id/general',
            name: 'project_general',
            props: true,
            component: ProjectEdit,
            meta: {
                title: 'vhub CC | Edit Project General',
                icon: 'folder',
                pagetitle: 'projects',
                accessPermission: 'write'
            },
            projecttab: true,
            firstItem: true,
        },
        {
            path: '/report/:id/general',
            name: 'report_general',
            props: true,
            component: ReportEdit,
            meta: {
                title: 'vHub CC | ' + 'Reports',
                icon: 'chart-bar',
                pagetitle: 'reports',
                accessPermission: 'write'
            },
            reporttab: true,
            firstItem: true,
        },
        {
            path: '/me/:id',
            name: 'user_me',
            props: true,
            component: UserEdit,
            meta: {
                title: 'vhub CC | Edit User General',
                icon: 'user',
                pagetitle: 'users',
            },
            firstItem: true,
        },
        {
            path: '/admin/users/:id/general',
            name: 'user_general',
            props: true,
            component: UserEdit,
            meta: {
                title: 'vhub CC | Edit User General',
                icon: 'user',
                pagetitle: 'users',
                accessPermission: 'writeOrganization'
            },
            usertab: true,
            firstItem: true,
        },
        {
            path: '/project/:id/scene',
            name: 'project_scene',
            props: true,
            component: ProjectEditScene,
            meta: {
                title: 'vhub CC | Edit Project Scene',
                icon: 'folder',
                pagetitle: 'projects',
                accessPermission: 'write'
            },
            projecttab: true,
        },
        {
            path: '/project/:id/linked_data',
            name: 'project_linked_data',
            props: true,
            component: ProjectEditLinkedData,
            meta: {
                title: 'vhub CC | Edit Project Scene',
                icon: 'folder',
                pagetitle: 'projects',
                accessPermission: 'write'
            },
            projecttab: true,
        },
        {
            path: '/project/add',
            name: 'Add Project',
            component: ProjectAdd,
            meta: {
                title: 'vhub CC | Add Project',
                icon: 'folder',
                pagetitle: 'projects',
                accessPermission: 'create',
                staticPermission: true,
            },
        },
        {
            path: '/app/add',
            name: 'Add App',
            component: AppAdd,
            meta: {
                title: 'vhub CC | Add App',
                icon: 'mobile',
                pagetitle: 'apps',
                accessPermission: 'create',
                staticPermission: true,
            },
        },
        {
            path: '/library/3d-data/add',
            name: 'addnew3ddata',
            component: SFXAdd,
            meta: {
                title: 'vhub CC | Add 3D data',
                icon: 'cube',
                pagetitle: 'sfxdata',
                accessPermission: 'create',
                staticPermission: true,
            },
            addsfxtab: true,
        },
        {
            path: '/library/3d-data/:id/update-cad',
            name: 'addnew3ddata',
            component: SFXAdd,
            meta: {
                title: 'vhub CC | Add 3D data',
                icon: 'cube',
                pagetitle: 'sfxdata',
                accessPermission: 'create',
                staticPermission: true,
            },
        },
        {
            path: '/library/3d-data/add-asset-bundle',
            name: 'addnew3ddata',
            component: SFXAddAssetBundle,
            meta: {
                title: 'vhub CC | Add 3D data',
                icon: 'cube',
                pagetitle: 'sfxdata',
                accessPermission: 'createBundle',
                staticPermission: true,
            },
        },
		{
            path: '/library/3d-data/add-asset-bundle/:id',
            name: 'addnew3ddata',
            component: SFXAddAssetBundle,
            meta: {
                title: 'vhub CC | Add 3D data',
                icon: 'cube',
                pagetitle: 'sfxdata',
                accessPermission: 'createBundle',
                staticPermission: true,
            },
        },
        {
            path: '/library/3d-data/add-asset-bundle/:id',
            name: 'addnew3ddata',
            component: SFXAddAssetBundle,
            meta: {
                title: 'vhub CC | Add 3D data',
                icon: 'cube',
                pagetitle: 'sfxdata',
                accessPermission: 'createBundle',
                staticPermission: true,
            },
        },
        {
            path: '/library/media/add',
            name: 'addMedia',
            component: MediaAdd,
            meta: {
                title: 'vhub CC | Add New Media',
                icon: 'film',
                pagetitle: 'media',
                accessPermission: 'create',
                staticPermission: true,
            },
            addmediatab: true,
        },
        {
            path: '/library/texture/add',
            name: 'add_texture',
            component: TextureAdd,
            meta: {
                title: 'vhub CC | Add New Texture',
                icon: 'game-board-simple',
                pagetitle: 'texture',
                accessPermission: 'nonExistingPermission',
                staticPermission: true,
                allowSFXUsers: true,
            },
            addtexturetab: true,
        },
        {
            path: '/library/dataset/add',
            name: 'Add Dataset',
            component: DatasetAdd,
            meta: {
                title: 'vhub CC | Add New Dataset',
                icon: 'table',
                pagetitle: 'dataset',
                accessPermission: 'create',
                staticPermission: true,
            },
        },
        {
            path: '/library/datasource/add',
            name: 'Add Datasource',
            component: DataSourceAdd,
            meta: {
                title: 'vhub CC | Add New Datasource',
                icon: 'database',
                pagetitle: 'data_sources',
                accessPermission: 'create',
                staticPermission: true,
            },
        },
        {
            path: '/library/3d-data/instance/:id/general',
            name: 'instance_general',
            props: true,
            component: SFXEditInstance,
            meta: {
                title: 'vhub CC | Edit Instance General',
                icon: 'image',
                pagetitle: 'instance',
                accessPermission: 'writeInstance',
                allowSFXUsers: true,
                staticPermission:true,
            },
            instancetab: true,
        },
        {
            path: '/library/3d-data/:type/:id/files',
            name: 'library_3d_files',
            component: FileBrowserView,
            meta: {
                title: 'vhub CC',
                icon: 'file',
                pagetitle: 'files',
                //accessPermission: 'accessFileBrowser',
                staticPermission: true,
                allowSFXUsers: true,
            },
            assemblytab: true,
            modeltab: true,
            helpertab: true,
            sfxtab: true,
            qrcodetab: true,
        },
        {
            path: '/library/:type/:id/files',
            name: 'files',
            component: FileBrowserView,
            meta: {
                title: 'vhub CC',
                icon: 'file',
                pagetitle: 'files',
                //accessPermission: 'accessFileBrowser',
                staticPermission: true,
                allowSFXUsers: true,
            },
            templatetab: true,
        },
        {
            path: '/:type/:id/files',
            name: 'files',
            component: FileBrowserView,
            meta: {
                title: 'vhub CC',
                icon: 'file',
                pagetitle: 'files',
                //accessPermission: 'accessFileBrowser',
                staticPermission: true,
                allowSFXUsers: true,
            },
            apptab: true,
            webapptab: true,
            instancetab: true,
            projecttab: true
        },
        {
            path: '/:type/:id/versions',
            name: 'versions',
            component: VersionView,
            meta: {
                title: 'vhub CC',
                icon: 'code-branch',
                pagetitle: 'versions',
                staticPermission: true,
                allowSFXUsers: true,
            },
            // reactivate those when the cc frontend stuff is done (like blocking the edit function while doing a version)
            //apptab: true,
            //webapptab: true,
            //projecttab: true,
            //instancetab: true,
            //mediatab: true
        },
        {
            path: '/library/3d-data/:type/:id/versions',
            name: 'versions',
            component: VersionView,
            meta: {
                title: 'vhub CC',
                icon: 'code-branch',
                pagetitle: 'versions',
                staticPermission: true,
                allowSFXUsers: true,
            },
            // reactivate those when the cc frontend stuff is done (like blocking the edit function while doing a version)
            //modeltab: true,
            //helpertab: true,
            sfxtab: true,
        },
        {
            path: '/library/3d-data/:type/:id/versions',
            name: 'versions',
            component: VersionView,
            meta: {
                title: 'vhub CC',
                icon: 'code-branch',
                pagetitle: 'versions',
                staticPermission: true,
                allowSFXUsers: true,
            },
            assemblytab: true,
        },
        {
            path: '/library/scenes/:id/general',
            name: 'scene_general',
            props: true,
            component: SceneEdit,
            meta: {
                title: 'vhub CC | Edit Scene General',
                icon: 'image',
                pagetitle: 'scene',
                accessPermission: 'writeScene',
                allowSFXUsers: true,
            },
            scenetab: true,
        },
        {
            path: '/library/scenes/add',
            name: 'scene_general',
            props: true,
            component: SceneAdd,
            meta: {
                title: 'vhub CC | Edit Scene General',
                icon: 'image',
                pagetitle: 'scene',
                accessPermission: 'createScene',
                allowSFXUsers: true,
            },
        },
        {
            path: '/library/:type/:id/files',
            name: 'library_files',
            component: FileBrowserView,
            meta: {
                title: 'vhub CC',
                icon: 'file',
                pagetitle: 'files',
                allowSFXUsers: true,
            },
            mediatab: true,
            fonttab: true,
            scenetab: true,
            formtab: true,
            texturetab: true
        },
        {
            path: '/library/materials',
            name: 'materials',
            component: Materials,
            meta: {
                title: 'vhub CC | Materials',
                icon: 'block-brick',
                pagetitle: 'materials',
            },
            //navbar: true,
            librarypanel: true,
            materialbackbutton: true,
        },
        {
            path: '/library/texture',
            name: 'texture',
            component: Texture,
            meta: {
                title: 'vhub CC | Media',
                icon: 'game-board-simple',
                pagetitle: 'texture',
                accessPermission: 'read',
                staticPermission: true,
            },
            //navbar: true,
            librarypanel: true,
            texturebackbutton: true,
        },
        {
            path: '/library/materialmappers',
            alias: '/materialmappers',
            name: 'materialmappers',
            component: MaterialMappers,
            meta: {
                title: 'vHub CC | ' + 'Material Mappers',
                icon: 'table',
                pagetitle: 'materialmappers',
                spacer: true,
            },
            //navbar: true,
            librarypanel: true,
            materialmapperbackbutton: true,
        },
        {
            path: '/library/materials/add',
            name: 'materials_add',
            component: MaterialAdd,
            meta: {
                title: 'vhub CC | Materials',
                icon: 'box-open',
                pagetitle: 'materials',
            },
        },
        {
            path: '/library/materials/:id/general',
            name: 'material_general',
            component: MaterialEdit,
            meta: {
                title: 'vhub CC | Materials',
                icon: 'box-open',
                pagetitle: 'materials',
                accessPermission: 'accessMaterials'
            },
            materialtab: true,
        },
        {
            path: '/library/qrcodes',
            name: 'qrcodes',
            component: QrCodes,
            meta: {
                title: 'vhub CC | QrCodes',
                icon: 'qrcode',
                pagetitle: 'qrcodes',
            },
            //navbar: true,
            librarypanel: true,
            qrcodebackbutton: true,
        },
        {
            path: '/library/qrcodes/add',
            name: 'Add QR Code',
            component: QrCodeAdd,
            meta: {
                title: 'vhub CC | Add QR Code',
                icon: 'align-left',
                pagetitle: 'qrcodes',
            },
        },
        {
            path: '/library/qrcodes/:id/general',
            name: 'qrcode_general',
            props: true,
            component: QrCodeEdit,
            meta: {
                title: 'vhub CC | Edit QR Code General',
                icon: 'align-left',
                pagetitle: 'qrcodes',
            },
            qrcodetab: true,
        },
        {
            path: '/library/materialmappers/add',
            name: 'Add Material Mapper',
            component: MaterialMapperAdd,
            meta: {
                title: 'vhub CC | Add Material Mapper',
                icon: 'align-left',
                pagetitle: 'materialmappers',
            },
        },
        {
            path: '/library/materialmappers/:id/general',
            name: 'materialmapper_general',
            props: true,
            component: MaterialMapperEdit,
            meta: {
                title: 'vhub CC | Edit Material Mapper',
                icon: 'align-left',
                pagetitle: 'materialmappers',
            },
            materialmappertab: true,
        },
        {
            path: '/library/exportprofiles',
            name: 'exportprofiles',
            component: ExportProfiles,
            meta: {
                title: 'vhub CC | Profiles',
                icon: 'filter',
                pagetitle: 'profiles',
                accessPermission: 'nonExistingPermission',
                staticPermission: true,
                allowSFXUsers: true,
            },
            librarypanel: true,
            filterbackbutton: true,
        },
        {
            path: '/library/exportprofiles/:id/general',
            name: 'exportprofiles_general',
            props: true,
            component: ExportProfilesEdit,
            meta: {
                title: 'vhub CC | Edit Profiles General',
                icon: 'filter',
                pagetitle: 'profiles',
                accessPermission: 'nonExistingPermission',
                allowSFXUsers: true,
            },
            filtertab: true,
        },
        {
            path: '/webapp-check',
            name: 'webapp_check',
            props: true,
            component: () => {return import ('./views/LinkedWebapps')},
            meta: {
                title: 'vhub CC | Edit Profiles General',
                icon: 'filter',
                pagetitle: 'profiles',
                accessPermission: 'nonExistingPermission',
                allowSFXUsers: true,
            },
            filtertab: true,
        },
    ];
}

// This callback adds editors-infos, page-titles etc. for the specific page
// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {

    store.dispatch('addToPageHistory', {
        routeName: to.name,
        status: 'visited',
        itemId: to.params && to.params.id ? to.params.id : null,
        timestamp: new Date().toISOString(),
        fullUrl: to.fullPath,
    })
    //item.editors.roles.filter(role => {return role === $store.getters.getUserRole})
    //if(to.meta.accessPermission && !to.meta.staticPermission) {
        //next('/access-denied');
    //}

    // This goes through the matched routes from last to first, finding the closest route with a title.
    // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

    // Find the nearest route element with editors tags.
    const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
    // eslint-disable-next-line no-unused-vars
    //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.editors && r.editors.metaTags);

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

    // Remove any stale editors tags from the document using the key attribute we set below.
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

    // Skip rendering editors tags if there are none.

    if (!nearestWithMeta) return next();

    // Turn the editors tag definitions into actual elements in the head.
    nearestWithMeta.meta.metaTags.map(tagDef => {
        const tag = document.createElement('meta');

        Object.keys(tagDef).forEach(key => {
            tag.setAttribute(key, tagDef[key]);
        });

        // We use this to track which editors tags we create, so we don't interfere with other ones.
        tag.setAttribute('data-vue-router-controlled', '');

        return tag;
    })
        // Add the editors tags to the document head.
        .forEach(tag => document.head.appendChild(tag));

    next();
});
