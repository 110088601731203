<template>
  <div class="info-helper" @mouseenter="showTooltip" @click="showTooltip">
    <icon size="0.9" type="question-circle"/>
    <div class="tooltip border-radius"
         v-html="'<p>' + $t(text) + '</p>' + (image ? '<img class=\'img\' src=\'' + image + '\' />' : '')"
         @mouseenter="showTooltip"
         @mouseleave="hideTooltip"
    />
  </div>
</template>

<script>
import Icon from "./Icon";

export default {
  name: "InfoHelper",
  components: {
    Icon,
  },
  props: {
    text: {type: String, required: true},
    imageName: {type: String, default: ""}
  },
  data() {
    return {
      tooltipVisible: false
    };
  },
  computed: {
    image() {
      return this.imageName ? require('@/assets/images/' + this.imageName) : '';
    }
  },
 methods: {
   showTooltip() {
     this.tooltipVisible = true;
   },
   hideTooltip() {
     this.tooltipVisible = false;
   }
 }
}
</script>

<style lang="scss">
.info-helper {
  display: inline-block;
  padding-top: 5px;
  position: relative;
  z-index: 0;
  cursor: pointer;

  .tooltip {
    min-width: 370px;
    position: absolute;
    background-color: $info-helper-background-color;
    pointer-events: auto;
    overflow: auto;
    padding: 10px;
    opacity: 0;
    display:none;
    transition: opacity 0.3s;
    .img {
      max-width: 100%;
    }
  }

  &:hover {
    z-index: 500;
    .tooltip {
      opacity: 1;
      display: block;
    }
  }

  .tooltip-visible {
    opacity: 1;
  }
}
</style>