<template>
  <div>
    <h2>BluePrint Editor</h2>
    <div class="form-group">
      <label for="blueprintName">Blueprint Name:</label>
      <input type="text" v-model="bluePrint.name" class="form-control form-control-sm" id="blueprintName" placeholder="Enter blueprint name">
    </div>
    <button @click="addConstraint" class="btn btn-primary btn-sm mb-2">Add Position</button>

    <div class="constraint-list">
      <div class="constraint" v-for="(constraint, index) in bluePrint.constraints" :key="constraint.id + forceReRenderKey">
        <div class="constraint-header">
          <span>Position {{ index + 1 }}</span>
          <button @click="removeConstraint(index)" class="btn btn-danger btn-sm">Remove</button>
        </div>
        <div class="form-group">
          <label :for="'constraintName' + index">Name:</label>
          <input type="text" v-model="constraint.displayName" class="form-control form-control-sm" :id="'constraintName' + index" @input="debounceUpdateConstraintName(index)" placeholder="Enter constraint name">
        </div>
        <div class="metaValues-list">
          <template v-if="constraint && constraint.metaValues && constraint.metaValues.value">
            <div class="metaValues" v-for="(metaValues, metaValuesIndex) in constraint.metaValues.value" :key="metaValuesIndex">
              <div @click="removeMetaValues(index, metaValuesIndex)" class="settings-button d-inline-block mr-2 switched-colors"><icon type="trash" /></div>
              <preview class="mr-3 border-radius" :removable="false" :cover="true" :preview-uri="getAssembly(metaValues).previewUri" :preview-id="metaValues + 'preview'" />
              <span>{{ getAssemblyName(metaValues) }}</span>
            </div>
          </template>
          <button @click="showObjectSelector(index)" class="btn btn-secondary btn-sm">Add Assemblies</button>
        </div>
      </div>
    </div>

    <!-- Assembly Selector Modal -->
    <div v-if="isObjectSelectorVisible" class="modal">
      <div class="modal-content">
        <span class="close" @click="isObjectSelectorVisible = false">&times;</span>
        <h3 class="mt-3">Select Assemblies</h3>
        <assembly-selector
            :organization-id="bluePrintObject.organizationId"
            :pre-selected-objects="selectedObjects"
            @selectObjects="(obj) => {
              confirmObjectSelection(obj);
              isObjectSelectorVisible = false;
            }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import Preview from "@/components/preview/Preview.vue";
import Icon from "@/components/Icon.vue";
import AssemblySelector from "@/components/bluePrint/AssemblySelector.vue";

export default {
  name: 'BluePrintEditor',
  components: { Preview, Icon, AssemblySelector },
  props: {
    bluePrintObject: { type: Object, required: true }
  },
  data() {
    return {
      forceReRenderKey: 0,
      bluePrint: {
        id: uuidv4(),
        name: '',
        constraints: []
      },
      assemblies: [],
      selectedObjects: [],
      isObjectSelectorVisible: false,
      currentConstraintIndex: null,
      updateConstraintNameTimeout: null
    };
  },
  watch: {
    bluePrintObject() {
      this.setBluePrint();
    }
  },
  mounted() {
    this.setBluePrint();
    const { organizationId } = this.bluePrintObject;
    this.loadAssemblies(organizationId);
  },
  methods: {
    getAssemblyName(id) {
      const ass = this.getAssembly(id);
      return ass && ass.name ? ass.name : '';
    },
    getAssembly(id) {
      const ass = this.assemblies.find(item => {
        return item.id === id;
      })
      return ass ? ass : {};
    },
    async setBluePrint() {
      console.log('setting blueprint...')
      if (this.bluePrintObject) {
        this.bluePrint = {};
        Object.keys(this.bluePrintObject).map(key => {
          this.bluePrint[key] = this.bluePrintObject[key];
        });
        const {assets} = this.bluePrintObject;
        this.bluePrint.constraints = assets;
        let assemblyIds = [];
        this.bluePrint.constraints.map(item => {
          const {metaValues} = item;
          const ids = metaValues && metaValues.value ? metaValues.value : [];
          for(let i = 0; i < ids.length; i++) {
            if(!assemblyIds.includes(ids[i])) {
              assemblyIds.push(ids[i])
            }
          }
        });
        console.log('assemblyIds', assemblyIds)
        await this.loadAssemblies(this.bluePrintObject.organizationId, assemblyIds);
        this.forceReRenderKey++;
      }
    },
    async loadAssemblies(organizationId, ids = []) {
      let filter = `type eq assembly,organizationId eq ${organizationId}`;
      if(ids && ids.length) {
        if(ids.length > 1) {
          filter += ",id in '" + ids.join("' '") + "'";
        } else {
          filter += ",id eq " + ids[0]
        }
      }
      return await this.$store.dispatch('clientLoadAssets', {
        filter,
        sorting: '-createdAt',
      }).then(data => {
        this.assemblies = [...data];
      });
    },
    async addConstraint() {
      await this.$store.dispatch('clientCreateAsset', {
        type: 'blueprintconstraint',
        name: 'constraint-' + uuidv4(),
        displayName: 'new'
      }).then(asset => {
        return this.$store.dispatch('clientAddProjectAsset', {
          id: this.bluePrintObject.id,
          args: [asset.id]
        });
      }).then(() => {
        this.$emit('reload');
        /*const instance = instances[0];
        this.bluePrint.constraints.push({
          id: instance.assetId,
          name: 'new',
          displayName: 'new',
          metaValues: []
        });*/
      });
    },
    async removeConstraint(index) {
      await this.$store.dispatch('clientDeleteAssets', [this.bluePrint.constraints[index].id]);
      this.bluePrint.constraints.splice(index, 1);
    },
    getAssemblyIdsFromMetaVals(metaValues) {
      return metaValues && metaValues.value ? metaValues.value.map(item => {return {id: item}}) : []
    },
    showObjectSelector(constraintIndex) {
      this.currentConstraintIndex = constraintIndex;
      const {metaValues} = this.bluePrint.constraints[constraintIndex];

      this.selectedObjects = this.getAssemblyIdsFromMetaVals(metaValues);
      console.log('selectedObjects', this.selectedObjects)
      this.isObjectSelectorVisible = true;
    },
    async confirmObjectSelection(objects) {
      console.log('received objects', objects)
      if (this.currentConstraintIndex !== null) {
        this.bluePrint.constraints[this.currentConstraintIndex].metaValues = [...objects];
        const {metaValues} = this.bluePrint.constraints[this.currentConstraintIndex];
        console.log('metaValues', metaValues);
        if(metaValues && metaValues.length) {
          const ids = metaValues.map(item => {
            return item.id;
          });
          await this.saveMetaValues(ids, this.currentConstraintIndex)
        }
      }
      this.selectedObjects = [];
      this.isObjectSelectorVisible = false;
    },
    async saveMetaValues(ids, constraintIndex) {
      await this.$store.dispatch('clientSaveInstanceMetaSetValues', {
        id: this.bluePrintObject.id,
        cid: this.bluePrint.constraints[constraintIndex].instance.id,
        values: [{
          metaFieldId: 'be366594-a89f-4c71-b3a3-781dcbb62a1b',
          value: JSON.stringify(ids)
        }]
      }).then(() => {
        this.$emit('reload')
      });
    },
    async removeMetaValues(constraintIndex, metaValuesIndex) {
      console.log('metaVals before', this.bluePrint.constraints[constraintIndex].metaValues)
      console.log('removing metavalue at index ', metaValuesIndex)
      this.bluePrint.constraints[constraintIndex].metaValues.value.splice(metaValuesIndex, 1);
      const {metaValues} = this.bluePrint.constraints[constraintIndex];
      console.log('emtaVals after', metaValues)
      const ids = metaValues.value;
      await this.saveMetaValues(ids, constraintIndex).then(() => {
        this.$emit('reload')
      });
    },
    debounceUpdateConstraintName(index) {
      clearTimeout(this.updateConstraintNameTimeout);
      this.updateConstraintNameTimeout = setTimeout(() => {
        this.updateConstraintName(index);
      }, 400);
    },
    async updateConstraintName(index) {
      const constraint = this.bluePrint.constraints[index];
      await this.$store.dispatch('clientSaveAsset', {
        id: constraint.id,
        displayName: constraint.displayName,
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 0.75rem;
}

.btn {
  margin-top: 0.25rem;
}

.constraint-list {
  margin-top: 0.75rem;
}

.constraint {
  border: 1px solid #ccc;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 4px;
}

.constraint-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.metaValues-list {
  margin-top: 0.5rem;
  min-width: 240px;
}

.metaValues {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  .preview {
    width: 80px;
  }
}

.metaValues img.metaValues-preview {
  width: 40px;
  height: 40px;
  margin-right: 0.5rem;
}

.metaValues button {
  flex-shrink: 0;
}

.modal {
  display: block;
  position: fixed;
  z-index: 105;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  color: #000;
}

.modal-content {
  background-color: var(--vform-editor-ui-secondary-color);
  color: var(--vform-editor-ui-primary-color);
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 70%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 24px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>