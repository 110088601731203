<template>
  <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
    <div
        :class="['form-block', getBlockDraggableClasses(elementType)]"
        :element-type="elementType"
    >
      <h2 class="vform-editor-label">
        {{ label }}
      </h2>
      <slot />
    </div>
  </div>
</template>

<script>
import {hotspotPopupAllowedElementTypes} from "@/enum";
export default {
  name: "Block.vue",
  props: {
    elementType: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    state: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      hotspotPopupAllowedElementTypes: hotspotPopupAllowedElementTypes,
    };
  },
  methods: {
    getBlockDraggableClasses(elementType){
      if (this.state.hotspotPopupVisible) {
        if (this.hotspotPopupAllowedElementTypes.find((x) => {
          return x === elementType
        })) {
          return "draggable";
        }
        else{
          return "disabled"
        }
      }
      else{
        return "draggable";
      }
    }
  }
}
</script>


<style scoped lang="scss">
.form-block .checkmark {
  background: var(--vform-editor-ui-tertiary-color);
  border: solid 1px var(--vform-editor-ui-secondary-color);
}
/* When the checkbox is checked, add a blue background */
.form-block .container input:checked ~ .checkmark {
  background-color: gray;
}
.form-block .radio {
  background: var(--vform-editor-ui-tertiary-color);
  //border: solid 1px var(--vform-editor-ui-quaternary-color);
}
/* When the checkbox is checked, add a blue background */
.form-block .container input:checked ~ .radio {
  background-color: gray;
}
.form-block {
  background-color: var(--vform-editor-ui-secondary-color);
  color: var(--vform-editor-ui-primary-color);
  //border: solid 4px var(--vform-editor-panel-active-background-color;
  border-radius: 8px;
  min-height: 50px;
  height: 100%;
  padding: 12px;
  -webkit-box-shadow: 0 8px 6px -5px black;
  -moz-box-shadow: 0 8px 6px -5px black;
  box-shadow: 0 8px 6px -5px black;
  //box-sizing: border-box;
  //margin: 3px;

  &.disabled {
    opacity: 0.2;
  }
}
.form-block-image {
  display: flex;
  justify-content: center;
  line-height: 30px;
  color: var(--vform-editor-ui-tertiary-color);
  button {
    color: var(--vform-editor-ui-quaternary-color);
    background-color: var(--vform-editor-ui-tertiary-color);
    border-radius: 4px;
    border: solid 1px var(--vform-editor-ui-secondary-color);
    font-size: 0.95rem;
    min-width: 100px;
    line-height: 25px;
  }
}
.form-block a {
  text-decoration: underline;
  font-size: 10px;
}
.form-block input,
textarea {
  width: 100%;
  cursor: pointer;
}
.form-block {
  &:not(.disabled):hover {
    // border: solid 2px #da5e06;
    cursor: move;
    background-color: var(--vform-editor-ui-quinary-color);
    opacity: 0.99;
    //padding: 14px;
  }
  &.disabled {
    cursor: not-allowed;

    > textarea, span, label{
      cursor: not-allowed;
    }
  }
}
.form-block h2 {
  //font-weight: 700;
  //font-size: 13px;
}
.form-block {
  h3,
  label {
    color: var(--vform-editor-ui-tertiary-color);
    font-weight: 400;
    //font-size: 0.8rem;
  }
}
.form-block input,
textarea {
  border-width: 1px !important;
  color: var(--vform-editor-ui-quaternary-color) !important;
  background-color: var(--vform-editor-ui-tertiary-color) !important;
}
.form-block label.container {
  font-size: 10px;
}
.draggable {
  z-index: 10000;
}
.separatorBlock {
  background-color: var(--vform-editor-ui-tertiary-color);
  height: 3px;
}

.verticalSpaceBlock {
  border-top: solid 1px var(--vform-editor-ui-tertiary-color);
  border-bottom: solid 1px var(--vform-editor-ui-tertiary-color);
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2px;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.block-container {
  .form-block input,
  textarea,
  span,
  label {
    cursor: inherit;
    font-size: 10px;
  }
}
.drop-not-allowed {
  cursor: not-allowed !important;
}
</style>