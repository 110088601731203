<template>
  <div>
    <checkbox-widget
        v-for="metaSet in metaSets"
        :key="metaSet.id"
        :initial-value="metaSet.selected"
        class="vform-checkbox-label-text"
        :label-for="'chk' + metaSet.id"
        :label-text="metaSet.name"
        :disabled="false"
        @change="(value) => {
            onChange(metaSet, value);
          }"
    >
      <checkbox-widget
          v-for="metaField in metaSet.metaFields"
          v-if="!metaSet.collapsed"
          :key="metaField.id"
          :initial-value="localSelectedMetaFields[metaField.id]"
          class="vform-checkbox-label-text ml-2 mt-2"
          :label-for="'chk' + metaField.id"
          :label-text="metaField.description"
          :disabled="false"
          @change="(value) => {
            $set(localSelectedMetaFields, metaField.id, value);
          }"
      />
    </checkbox-widget>
  </div>
</template>

<script>
import CheckboxWidget from "@/components/widgets/editors/Checkbox.vue";
import {values} from "lodash";

export default {
  name: "MetaFieldsSelector",
  components: {CheckboxWidget},
  props: {
    organizationId: {
      type: String,
      required: true
    },
    selectedMetaFields: {
      type: Array,
      default: () => []
    },
    includeSuperOrgMetasets: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
      metaSets: [],
      forceReRenderKey: 0,
      localSelectedMetaFields: {}
    }
  },
  watch: {
    /*selectedMetaFields: {
      handler() {
        this.setLocalSelectedMetaFields(false);
        this.forceReRenderKey++;
      },
      deep: true
    },*/
    localSelectedMetaFields: {
      handler() {
        this.emitMetaFieldsSelection();
      },
      deep: true
    }
  },
  beforeMount() {
    this.setLocalSelectedMetaFields(false);
    this.loadMetaSets();
  },
  methods: {
    values,
    setLocalSelectedMetaFields(sendEmitted = true) {
      for(let i = 0; i < this.selectedMetaFields.length; i++) {
        this.$set(this.localSelectedMetaFields, this.selectedMetaFields[i], true);
      }
      if(sendEmitted) {
        this.emitMetaFieldsSelection()
      }
    },
    async loadMetaSets() {
      await this.$store.dispatch('clientLoadMetaSets', {
        include: 'metaFields',
        filter: "organizationId in '" + this.organizationId + "' '" + this.$store.getters.getSuperOrg + "'"
        //filter: 'organizationId eq ' + this.organizationId
      }).then(metaSets => {
        metaSets = metaSets.filter((ms) => {
          return ms.organizationId === this.organizationId || (this.includeSuperOrgMetasets.find((x) => x === ms.name))
        });

        if (this.localSelectedMetaFields.length > 0) {
          //load selection from vform
          metaSets.forEach((metaSet) => {
            metaSet.collapsed = true;
            metaSet.metaFields.forEach((field) => {
              if (this.localSelectedMetaFields.find((x) => {
                return x === field.id;
              })) {
                // at least 1 field is selected
                metaSet.collapsed = false;
              } else {
                metaSet.selected = false;
              }
            });
          });
        }

        metaSets.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
        this.$set(this, 'metaSets', metaSets);
      })
    },
    emitMetaFieldsSelection() {
      const selected = [];
      const keys = Object.keys(this.localSelectedMetaFields);
      for(let i = 0; i < keys.length; i++) {
        if(this.localSelectedMetaFields[keys[i]]) {
          selected.push(keys[i]);
        }
      }
      this.$emit('updateMetaFieldSelection', selected);
    },
    onChange(metaset, value) {
      metaset.metaFields.forEach((field) => {
        this.localSelectedMetaFields[field.id] = value;
      });
      this.emitMetaFieldsSelection();
    },
  }
}
</script>