<template>
<div />
</template>

<script>
import {vFormButtonActionModes, vformButtonActions, vformTargetTypes, /*vFormControls*/} from "@/enum";
/**
 * This mixin extends the element mixin with methods, it is split so it can also be used separately
 * ***/
export default {
  name: "ElementMethodMixin",
  methods: {
    getOptions(config) {
      return config.options ? config.options : [];
    },
    checkConditions(rules) {
      const fulFilled = [];

      for (let i = 0; i < rules.length; i++) {
        const {conditions} = rules[i];

        if (conditions && conditions.selectedOptions) {
          for (let j = 0; j < conditions.selectedOptions.length; j++) {
            const {elementId, optionId} = conditions.selectedOptions[j];
            const isSelected = this.getFormDataElementOption(elementId, optionId);
            if (!isSelected) {
              break;
            }
            if (j === (conditions.selectedOptions.length - 1)) {
              fulFilled.push(rules[i]);
            }
          }
        }
      }
      return fulFilled;
    },
    getProjectId(config, projectId) {
      if (config.isCurrentProject) {
        return projectId;
      } else {
        return config.linkedProjectId;
      }
    },
    getProjectName(config) {
      if (config.isCurrentProject) {
        return this.$t("currentProject");
      } else {
        return config.linkedProjectName;
      }
    },
    getTargetStep(config) {
      return config && config.targetStep ? config.targetStep : null;
    },
    getTargetSlide(config) {
      return config && config.targetSlide && config.targetSlide.uuid ? config.targetSlide.uuid : config && config.targetSlide ? config.targetSlide : null;
    },
    getLabel(config, lang) {
      if (config.label && config.label.dix) {
        return config.label.dix[lang] ? config.label.dix[lang] : config.label.dix.Unknown;
      }

      return '';
    },
    /**
     * This method retrieves all targets of e.g. a button and returns them in an array
     * **/
    getTargetByElement(element, currentSlide, slides, steps) {
      const {action, actionMode, formElementTypeString} = element;
      console.log('action: ' + action)

      // todo: make all return values instead of emitting them...
      const projectId = this.getProjectId(element, this.projectId);
      const projectName = this.getProjectName(element);
      const sameProject = !projectId || (projectId && projectId === this.projectId);
      const targetStep = this.getTargetStep(element);
      const targetSlide = this.getTargetSlide(element);
      const originalLabel = this.getLabel(element, this.$store.getters.getSelectedFormLanguage);
      const label = originalLabel ? originalLabel : formElementTypeString;

      // todo: just get ALL of them (OR check them before)
      // rule based actions depending on filled out data
      if (actionMode === vFormButtonActionModes.RULES_BASED) {
       /* const fulFilled = this.checkConditions(element.rules);
        if (fulFilled && fulFilled.length) {
          element = fulFilled[0];
        }*/
        console.log('rule based!')
        return null;
      }

      let targetObject = {};
      console.log('found label: ' + label)

      switch (action) {
        case vformButtonActions.NO_ACTION:
          return null;
        case vformButtonActions.HISTORY_BACK:
          return null;
        case vformButtonActions.FIRST_SLIDE:
          targetObject = {
            target: vformTargetTypes.SLIDE,
            projectId,
            targetId: this.getFirstSlideUuid(slides),
            label
          };
          break;
        case vformButtonActions.LAST_SLIDE:
          targetObject = {
            target: vformTargetTypes.SLIDE,
            projectId,
            targetId: this.getLastSlideUuid(slides),
            label
          };
          break;
        case vformButtonActions.FIRST_STEP:
          targetObject = {
            target: vformTargetTypes.STEP,
            projectId,
            targetId: this.getFirstStepUuid(steps),
            label
          };
          break;
        case vformButtonActions.LAST_STEP:
          targetObject = {
            target: vformTargetTypes.STEP,
            projectId,
            targetId: this.getLastStepUuid(steps),
            label
          };
          break;
        case vformButtonActions.NEXT_SLIDE:
          targetObject = {
            target: vformTargetTypes.SLIDE,
            projectId,
            targetId: this.getNextSlideUuid(slides, currentSlide),
            label
          };
          break;
        case vformButtonActions.GO_TO_SLIDE:
          targetObject = {
            target: vformTargetTypes.SLIDE,
            projectId,
            targetId: targetSlide,
            label
          };
          break;
        case vformButtonActions.GO_TO_STEP:
          targetObject = {
            target: vformTargetTypes.STEP,
            projectId,
            targetId: targetStep,
            label
          };
          break;
        default:
          console.log('going default')
          break;
      }
      // legacy for items which don't have an action defined
      if(!action) {
        console.log('no action')
        if(!sameProject) {
          if (targetStep) {
            targetObject = {
              target: vformTargetTypes.STEP,
              projectId,
              projectName,
              targetId: targetStep,
              label
            };
            // go to a slide in another project
          } else if (targetSlide) {
            targetObject = {
              target: vformTargetTypes.SLIDE,
              projectId,
              projectName,
              targetId: targetSlide,
              label
            };
            // go to step in the same project
          } else {
            targetObject = {
              target: vformTargetTypes.PROJECT,
              projectId,
              projectName,
              label
            };
            // go to step in the same project
          }
        } else {
          // go to a a step in another project
          if (targetStep) {
            targetObject = {
              target: vformTargetTypes.STEP,
              projectId,
              targetId: targetStep,
              label
            };
            // go to slide in the same project
          } else if (targetSlide) {
            targetObject = {
              target: vformTargetTypes.SLIDE,
              projectId,
              targetId: targetSlide,
              label
            };
            // go to next slide
            // this is a workaround because radiobutton options can have an action, but if they have no action,
            // their default behaviour is NOT next slide, but do nothing
            // options do not have a formElementTypeString
          } else if(formElementTypeString) {
            targetObject = {
              target: vformTargetTypes.SLIDE,
              projectId,
              targetId: this.getNextSlideUuid(slides, currentSlide),
              label
            };
          }
        }
      }
      return targetObject;
    }
  }
}
</script>