<template>
    <div>
      <div class="dflex mt-4">
        <div class="col row">
          <label class="vform-editor-label section mt-0">File</label>
        </div>
        <div
            v-for="(key, index2) in Object.keys(element.label.dix)"
            v-if="$store.getters.getSelectedFormLanguage === key"
            :key="index2 + 1000000000 + forceReRenderKey"
            :class="['dflex col-auto pr-0', element.label.imageDix[$store.getters.getSelectedFormLanguage] ? '' : '']"
        >
          <div>
            <v-form-image-dix-cell
                :v-s-t-a-g-e-context="vSTAGEContext"
                :element="element"
                :image-dix="element.label.imageDix"
                :media-category="6"
                :organization-id="organizationId"
                preview-size="medium"
            />
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { vFormControls } from "@/enum";
import FileTypeMixin from "@/components/files/FileTypeMixin.js.vue";
import vFormImageDixCell from "@/components/vForm/vFormImageDixCell";

export default {
    name: "vFormPDF",
    components: {
      vFormImageDixCell
    },
    computed: {
    },
    props: {
        element: { type: Object, required: true },
        organizationId: {type: String, default: ""},
        vSTAGEContext: {type: Boolean, default: false}
    },
    mixins: [FileTypeMixin],
    data() {
        return {
            vFormControls: vFormControls,
        };
    },
    beforeMount() {
        this.element.formElementType = vFormControls["PDF"];
    },
    methods: {

    },
};
</script>

<style scoped></style>
