<template>
  <!-- Collapsible Panel Lasche -->
    <div
        :class="[
            'collapse-handle',
            'collapse-handle-' + position,
            //!editorMode && collapsedPanels[panel.uuid] ? 'active bg-highlight' : '',
            //getButtonOrientation(panel),
            //'handle-' + getCollapseDirection(panel, true),
          ]"
        :style="{
            backgroundColor: panel.backgroundColor,
            [position]: '0',
            borderColor: panel.backgroundColor && getContrast(panel.backgroundColor, true) === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)',
          }"
        @click="toggleCollapse()"
    >
      <icon
          :style="{
            color: iconColor
          }"
          size="0.9" :type="iconType"/>
    </div>
</template>

<script>
import Icon from '@/components/Icon.vue';
import ColorContrastMixinJs from "@/components/mixins/ColorContrastMixin.js.vue";

export default {
  name: "CollapsiblePanelLasche",
  components: {
    Icon
  },
  mixins: [ColorContrastMixinJs],
  props: {
    panel: {type: Object, required: true},
    editorMode: {type: Boolean, default: true},
    position: {type: String, required: true},
  },
  data() {
    return {
      isCollapsed: this.panel.initiallyCollapsed || false
    };
  },
  computed: {
    iconType() {
      const direction = this.position;
      if (!this.isCollapsed) {
        return `angle-double-${this.getOppositeDirection(direction)}`;
      } else {
        return `angle-double-${this.getDirection(direction)}`;
      }
    },
    iconColor() {
      return this.panel.backgroundColor && this.getContrast(this.panel.backgroundColor, true) === 'dark' ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.7)';
    }
  },
  watch: {
    'panel.initiallyCollapsed': {
      handler: function (val) {
        this.isCollapsed = val;
      },
      immediate: true
    }
  },
  methods: {
    getOppositeDirection(direction) {
      switch (direction) {
        case 'left': return 'right';
        case 'right': return 'left';
        case 'top': return 'down';
        case 'bottom': return 'up';
        default: return '';
      }
    },
    getDirection(direction) {
      switch (direction) {
        case 'top': return 'up';
        case 'bottom': return 'down';
        default: return direction;
      }
    },
    toggleCollapse() {
      console.log('changing collapse....')
      this.isCollapsed = !this.isCollapsed;
      this.$emit('toggle-collapse', this.isCollapsed);
    }
  }
}
</script>


<style scoped lang="scss">
$border-radius: 3px;
$width: 25px;
$height: 80px;
.collapse-handle {
  background-color: var(--vform-editor-ui-primary-color);
  position: absolute;
  box-shadow: #1b1a1c;
  z-index: 5;
  cursor: pointer;
  &.is-transparent {
    background-color: var(--vform-editor-ui-primary-color) !important;
  }
  &.collapse-handle-left {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    border-right: 1px solid;
    width: $width;
    height: $height;
  }
  &.collapse-handle-right {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-left: 1px solid;
    width: $width;
    height: $height;
  }
  &.collapse-handle-top {
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
    border-bottom: 1px solid;
    left: 10px;
    width: $height;
    height: $width;
  }
  &.collapse-handle-bottom {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    border-top: 1px solid;
    left: 10px;
    width: $height;
    height: $width;
  }
  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

</style>