<template>
  <div
      v-if="showContextMenu"
      id="panel-contextmenu"
      :style="{top: contextMenuY + 'px', left: contextMenuX + 'px'}"
      class="context-menu p-2"
  >
    <div class="mt-1 darker border-radius pl-1 cat-name">
      {{ $t('STEP') }}
    </div>

    <div class="item" @click="copyFullStep(); showContextMenu = false;">
      <icon class="mr-1" type="shoe-prints"/>
      Copy step
    </div>
    <div :class="['item', getStepFromLocalStorage() ? 'enabled' : 'disabled']" @click="pasteFullStep(); showContextMenu = false;">
      <icon class="mr-1" type="shoe-prints"/>
      Paste step
    </div>
    <div class="mt-1 darker border-radius pl-1 cat-name">
      {{ $t('PANEL') }}
    </div>

    <div v-if="contextMenuPanelUuid && getPanelType(contextMenuPanelUuid) === 'local'" :class="['item', getPanelSettings() ? 'enabled' : 'disabled']" @click="applyForAllPanels(contextMenuPanelUuid); showContextMenu = false;">
      <icon class="mr-1" type="cog"/>
      Apply for all panels in position {{ getPanelIndex(contextMenuPanelUuid, activeStepObject, globalObject) + 1 }}
    </div>

      <div v-if="contextMenuPanelUuid && getPanelType(contextMenuPanelUuid) === 'global'" class="item" @click="copyGlobalPanel(contextMenuPanelUuid); showContextMenu = false;">
        <icon class="mr-1" type="columns"/>
        Copy panel
      </div>
      <div v-if="contextMenuPanelUuid && getPanelType(contextMenuPanelUuid) === 'local'" class="item" @click="copyLocalPanel(contextMenuPanelUuid); showContextMenu = false;">
        <icon class="mr-1" type="columns"/>
        Copy panel
      </div>
      <div :class="['item', getGlobalPanelFromLocalStorage() ? 'enabled' : 'disabled']" @click="pasteGlobalPanel(); showContextMenu = false;">
        <icon class="mr-1" type="columns"/>
        Paste panel (global)
      </div>
      <div :class="['item', getLocalPanelFromStorage() ? 'enabled' : 'disabled']" @click="pasteLocalPanel(); showContextMenu = false;">
        <icon class="mr-1" type="columns"/>
        Paste panel (local)
      </div>

    <div v-if="contextMenuPanelUuid" class="item" @click="copyPanelSettings(contextMenuPanelUuid); showContextMenu = false;">
      <icon class="mr-1" type="paint-roller"/>
      Copy panel style
    </div>
    <div v-if="contextMenuPanelUuid" :class="['item', panelStylePasting ? 'disable-hover' : '', getPanelSettings() ? 'enabled' : 'disabled']" @click.stop="() => {if(getPanelSettings()) { panelStylePasting = true }}">
      <icon class="mr-1" type="paint-roller"/>
      Paste panel style
      <div v-if="panelStylePasting">
        <div v-if="contextMenuPanelUuid" :class="['item']" @click.stop="pastePanelStyles(contextMenuPanelUuid); showContextMenu = false; panelStylePasting = false;">
          <icon class="mr-1" type="location-dot"/>
          Paste style
        </div>
        <div v-if="contextMenuPanelUuid" :class="['item']" @click.stop="pastePanelPosition(contextMenuPanelUuid); showContextMenu = false; panelStylePasting = false;">
          <icon class="mr-1" type="location-dot"/>
          Paste position/size
        </div>
        <div v-if="contextMenuPanelUuid" class="item" @click.stop="pastePanelSettings(contextMenuPanelUuid); showContextMenu = false; panelStylePasting = false;">
          <icon class="mr-1" type="cog"/>
          Paste all
        </div>
      </div>
    </div>

    <div v-if="contextMenuPanelUuid" class="mt-1 darker border-radius pl-1 cat-name">
      {{ $t('BLOCK') }}
    </div>
    <div v-if="contextMenuPanelUuid" class="item" @click="copyPanelElements(contextMenuPanelUuid, activeStepObject); showContextMenu = false;">
      <icon class="mr-1" type="th"/>
      Copy blocks
    </div>
    <div
        v-if="contextMenuPanelUuid"
        :class="['item', state.clipboard.tempPanelElements ? 'enabled' : 'disabled']"
        @click="pastePanelElements(contextMenuPanelUuid, activeStepObject); showContextMenu = false;">
      <icon class="mr-1" type="th"/>
      Paste blocks
    </div>
    <div v-if="contextMenuPanelUuid" :class="['item', contextMenuElementId ? 'enabled' : 'disabled']" @click="copyElement(); showContextMenu = false;">
      <icon class="mr-1" type="cog"/>
      Copy Block
    </div>
    <div v-if="contextMenuPanelUuid" :class="['item', getElementFromStorage() ? 'enabled' : 'disabled']" @click="pasteElement(); showContextMenu = false;">
      <icon class="mr-1" type="cog"/>
      Paste Block
    </div>
  </div>
</template>

<script>
import Icon from "../Icon";
import listenerMixinJs from "../mixins/listenerMixin.js";
import vFormAndProjectMixin from "@/components/vForm/mixins/vFormAndProjectMixin.js.vue";
import {v4 as uuidv4} from "uuid";

export default {
  name: "PanelContextMenu",
  components: {
    Icon
  },
  mixins: [listenerMixinJs, vFormAndProjectMixin],
  props: {
    activeStepObject: {type: Object, default: null},
    /**
     * Whether or not the vformeditor is in editor mode
     * */
    editorMode: {type: Boolean, default: false},
    state: {type: Object, default: null},
    globalObject: {
      type: Object, default: () => {
        return {}
      }
    },
    config: {type: Object, required: true}
  },
  data() {
    return {
      contextMenuY: 0,
      contextMenuX: 0,
      contextMenuPanelUuid: null,
      contextMenuElementId: null,
      showContextMenu: false,
      panelStylePasting: false,
      canPasteStep: false,
      canPasteStyles: false,
    };
  },
  watch: {
    editorMode() {
      this.addContextMenu();
    },
  },
  mounted() {
    this.addContextMenu();
    this.canPasteStep = this.getStepFromLocalStorage();
  },
  methods: {
    getPanelType(uuid) {
      const index = this.activeStepObject.panels.findIndex(item => {
        return item.uuid === uuid
      });
      return index === -1 ? 'global' : 'local';
    },
    getStepElementById(uuid, stepObject) {
      const allEls = this.globalObject.elements ? [...stepObject.elements, ...this.globalObject.elements] : stepObject.elements;
      return allEls.find(item => {
        return item.uuid === uuid;
      })
    },
    documentClick(e) {
      const el = document.getElementById('panel-contextmenu')
      let target = e.target;
      if (el && (el !== target) && !el.contains(target)) {
        this.showContextMenu = false;
        this.showContextMenu = false;
      }
    },
    copyFullStep() {
      window.localStorage.setItem("step", JSON.stringify(this.activeStepObject))
    },
    copyLocalPanel(uuid) {
      const panels = this.activeStepObject.panels.filter(item => {
        return item.uuid === uuid;
      })
      const elements = this.activeStepObject.elements.filter(item => {
        return item.panelUuid === uuid;
      })
      window.localStorage.setItem("localPanel", JSON.stringify({panels, elements}));
    },
    copyGlobalPanel(globalPanelUuid) {
      // 1 copy all elements belonging to that panel
      // 2 filter so only the selected panel is in the panels array
      const panel = this.globalObject.panels.filter(item => {
        return item.uuid === globalPanelUuid;
      })
      const elements = this.globalObject.elements.filter(item => {
        return item.panelUuid === globalPanelUuid;
      })
      let item = JSON.parse(JSON.stringify(this.globalObject));
      item.panels = panel;
      item.elements = elements;
      window.localStorage.setItem("globalPanel", JSON.stringify(item));
    },
    pasteGlobalPanel() {
      if(this.getGlobalPanelFromLocalStorage()) {
        let {panels, elements} = this.getGlobalPanelFromLocalStorage();
        panels = panels.map(item => {
          item.uuid = uuidv4();
          return item;
        })
        const obj = this.globalObject;
        this.$set(this.config.global, "panels", obj.panels ? [...obj.panels, ...panels] : panels)
        // all
        this.pastePanelElements(panels[0].uuid, null, elements);
        this.$emit('add/AllStepsToGlobalPanel', panels[0]);
      }
    },
    pasteLocalPanel() {
      if(this.getLocalPanelFromStorage()) {
        let {panels, elements} = this.getLocalPanelFromStorage();
        panels = panels.map(item => {
          item.uuid = uuidv4();
          item.identifierColor = "#" + Math.floor(Math.random() * 16777215).toString(16);
          return item;
        })
        const obj = this.activeStepObject;
        this.$set(this.activeStepObject, "panels", obj.panels.concat(panels))
        // all
        const els = this.preprocessPanelElementsPaste(panels[0].uuid, elements)
        this.$set(this.activeStepObject, "elements", this.activeStepObject.elements.concat(els))
        this.$emit('reattach')
        setTimeout(() => {
          this.addContextMenu()
        }, 200)
      }
    },
    getGlobalPanelFromLocalStorage() {
      try {
        const panel = window.localStorage.getItem("globalPanel");
        //console.log(JSON.parse(panel));
        return JSON.parse(panel);
      } catch (e) {
        console.log(e);
        // do nothing
        return null;
      }
    },
    getLocalPanelFromStorage() {
      try {
        const panel = window.localStorage.getItem("localPanel");
        //console.log(JSON.parse(panel));
        return JSON.parse(panel);
      } catch (e) {
        console.log(e);
        // do nothing
        return null;
      }
    },
    getStepFromLocalStorage() {
      try {
        const step = window.localStorage.getItem("step");
        return JSON.parse(step);
      } catch (e) {
        console.log(e);
        // do nothing
        return null;
      }
    },
    /**
     * Pastes one full step into the current object (overwrites most of it)
     * */
    pasteFullStep() {
      let copyingStep = null;
      if(this.getStepFromLocalStorage()) {
        copyingStep = this.getStepFromLocalStorage();
      } else {
        return;
      }
      const {uuid, name, linkedSlide, linkedSlides, step} = JSON.parse(JSON.stringify(this.activeStepObject));
      copyingStep.linkedSlide = linkedSlide;
      copyingStep.linkedSlides = linkedSlides;
      copyingStep.name = name;
      copyingStep.step = step;
      copyingStep.uuid = uuid;
      let elements = [];
      for (let i = 0; i < copyingStep.panels.length; i++) {
        const panel = copyingStep.panels[i];
        const copiedEls = this.copyPanelElements(panel.uuid, copyingStep, i === 0);
        const pn = this.copyPanel(panel);
        const els = this.preprocessPanelElementsPaste(pn.uuid, copiedEls);
        elements = [...elements, ...els];
        copyingStep.panels[i] = pn;
      }
      const index = this.config.steps.findIndex(item => {
        return item.uuid === uuid;
      })
      if (index !== -1) {
        //this.$set(copyingStep, "elements", elements);
        this.$set(this.config.steps, index, JSON.parse(JSON.stringify(copyingStep)));
      } else {
        //this.config.steps.push(step);
        console.log('current step not found, adding new')
      }
      this.$emit('reattach');
      const $this = this;
      setTimeout(() => {
        $this.addContextMenu()
      }, 100)
    },
    copyPanel(panel) {
      panel = JSON.parse(JSON.stringify(panel));
      panel.uuid = uuidv4()
      return panel;
    },
    copyElement() {
      const element = this.getStepElementById(this.contextMenuElementId, this.activeStepObject)
      window.localStorage.setItem("element", JSON.stringify(element));
    },
    getElementFromStorage() {
      try {
        const element = window.localStorage.getItem("element");
        //console.log(JSON.parse(panel));
        return JSON.parse(element);
      } catch (e) {
        console.log(e);
        // do nothing
        return null;
      }
    },
    pasteElement() {
      let el = this.getElementFromStorage();
      const els = this.preprocessPanelElementsPaste(this.contextMenuPanelUuid, [el]);
      const type = this.getPanelType(this.contextMenuPanelUuid);
      if (type === 'global') {
        this.$set(this.globalObject, "elements", this.globalObject.elements.concat(els))
      } else {
        this.$set(this.activeStepObject, "elements", this.activeStepObject.elements.concat(els))
      }
      this.$emit('reattach');
    },
    copyPanelElements(uuid, stepObject, includeNonPanelUuids = false) {
      const elements = this.getElementsByPanel(uuid, this.globalObject, stepObject, includeNonPanelUuids);
      this.$set(this.state.clipboard, "tempPanelElements", JSON.parse(JSON.stringify(elements)))
      return elements;
    },
    preprocessPanelElementsPaste(uuid, elements) {
      for (let i = 0; i < elements.length; i++) {
        this.$set(elements[i], "panelUuid", uuid);
        this.$set(elements[i], "uuid", uuidv4());
      }
      return elements;
    },
    pastePanelElements(uuid, stepObject = null, elements = null) {
      const panelType = this.getPanelType(uuid);

      // eslint-disable-next-line no-undef
      let pastedElements = elements ? elements :  JSON.parse(JSON.stringify(this.state.clipboard.tempPanelElements));
      if (pastedElements) {
        pastedElements = this.preprocessPanelElementsPaste(uuid, pastedElements)
        if (panelType === 'global') {
          this.$set(this.globalObject, "elements", this.globalObject.elements.concat(pastedElements))
        } else if (stepObject) {
          this.$set(stepObject, "elements", stepObject.elements.concat(pastedElements));
        }
        return pastedElements;
      }
      return [];
    },
    copyPanelSettings(uuid) {
      const panel = this.getPanelByUuid(uuid, this.activeStepObject, this.globalObject);
      window.localStorage.setItem("temporaryPanelData", JSON.stringify(panel));
    },
    getPanelSettings() {
      try {
        const style = window.localStorage.getItem("temporaryPanelData");
        //console.log(JSON.parse(panel));
        return JSON.parse(style);
      } catch (e) {
        console.log(e);
        // do nothing
        return null;
      }
    },
    applyForAllPanels(uuid) {
      const panelIndex = this.getPanelIndex(uuid, this.activeStepObject, this.globalObject);
      this.$emit('applyForAllPanels', panelIndex, this.activeStepObject.panels[panelIndex])
    },
    pastePanelPosition(uuid) {
      const panelItem = this.getPanelByUuid(uuid, this.activeStepObject, this.globalObject);
      if (this.getPanelSettings()) {
        let targetData = this.getPanelSettings()
        this.$set(panelItem, "xLeft", targetData.xLeft);
        this.$set(panelItem, "y", targetData.y);
        this.$set(panelItem, "height", targetData.height);
        this.$set(panelItem, "width", targetData.width);
        this.$set(panelItem, "autoFit", targetData.autoFit);
        this.$set(panelItem, "pin", targetData.pin);
        this.$set(panelItem, "collapsible", targetData.collapsible);
        this.$set(panelItem, "collapseDirection", targetData.collapseDirection);
        this.$set(panelItem, "initiallyCollapsed", targetData.initiallyCollapsed);
        if(targetData.version) {
          this.$set(panelItem, "version", targetData.version);
        } else if(panelItem.version) {
          delete panelItem.version;
        }
      }
      this.addContextMenu()
    },
    pastePanelStyles(uuid) {
      const panelItem = this.getPanelByUuid(uuid, this.activeStepObject, this.globalObject);
      // eslint-disable-next-line no-undef
      if (this.getPanelSettings()) {
        // eslint-disable-next-line no-undef
        let targetData = this.getPanelSettings();
        this.$set(panelItem, "backgroundColor", targetData.backgroundColor ? targetData.backgroundColor : null);
        this.$set(panelItem, "fontColor", targetData.fontColor ? targetData.fontColor : null);
        this.$set(panelItem, "borderRadius", targetData.borderRadius ? targetData.borderRadius : 0);
        this.$set(panelItem, "hideTopPadding", targetData.hideTopPadding ? targetData.hideTopPadding : false);
      }
    },
    pastePanelSettings(uuid) {
      const panelItem = this.getPanelByUuid(uuid, this.activeStepObject, this.globalObject);
      // eslint-disable-next-line no-undef
      if (this.getPanelSettings()) {
        // eslint-disable-next-line no-undef
        let targetData = this.getPanelSettings();
        this.$set(panelItem, "xLeft", targetData.xLeft);
        this.$set(panelItem, "y", targetData.y);
        this.$set(panelItem, "height", targetData.height);
        this.$set(panelItem, "backgroundColor", targetData.backgroundColor ? targetData.backgroundColor : null);
        this.$set(panelItem, "fontColor", targetData.fontColor ? targetData.fontColor : null);
        this.$set(panelItem, "width", targetData.width);
        this.$set(panelItem, "borderRadius", targetData.borderRadius);
        this.$set(panelItem, "hideTopPadding", targetData.hideTopPadding);
        this.$set(panelItem, "pin", targetData.pin);
        this.$set(panelItem, "collapsible", targetData.collapsible);
        this.$set(panelItem, "collapseDirection", targetData.collapseDirection);
        this.$set(panelItem, "initiallyCollapsed", targetData.initiallyCollapsed);

        if(targetData.version) {
          this.$set(panelItem, "version", targetData.version);
        } else if(panelItem.version) {
          delete panelItem.version;
        }      }
      this.addContextMenu()
    },
    addContextMenu() {
      this.removeAllListeners();
      const $this = this;
      for (let i = 0; i < this.activeStepObject.panels.length; i++) {
        const panel = this.activeStepObject.panels[i];
        this.addContextMenuToEl($this, panel);
      }
      if (this.globalObject && this.globalObject.panels) {
        for (let i = 0; i < this.globalObject.panels.length; i++) {
          const panel = this.globalObject.panels[i];
          this.addContextMenuToEl($this, panel);
        }
      }
      this.addContextMenuToOverview();
      this.addContextMenuToBlock($this);
      this.addListener(document, "click", this.documentClick, 'document-click')
    },
    addContextMenuToOverview() {
      let $this = this;
      const el = document.getElementById('slideshow-div');
        this.addListener(el, "contextmenu", function (e) {
          if ($this.editorMode) {
            //e.stopPropagation();
            e.preventDefault();
            $this.contextMenuPanelUuid = null;
            $this.contextMenuElementId = null;
            $this.contextMenuX = e.clientX;
            $this.contextMenuY = e.clientY - 150;
            $this.showContextMenu = true;
          }
        }, 'panel-context-overview')
    },
    addContextMenuToBlock($this) {
      const el = document.getElementsByClassName('form-element-context');
      if (el) {
        for(let i = 0; i < el.length; i++) {
          const id = el[i].dataset.elementId;
          this.addListener(el[i], "contextmenu", function (e) {
            if ($this.editorMode) {
              e.stopPropagation();
              e.preventDefault();
              $this.contextMenuElementId = id;
              $this.contextMenuX = e.clientX;
              $this.contextMenuY = e.clientY - 150;
              $this.showContextMenu = true;
            }
          }, 'panel-context-' + id)
        }
      } else {
        console.log('panel with uuid not found for context menu attachment')
      }
    },
    addContextMenuToEl($this, panel) {
      const el = document.getElementById('panel-' + panel.uuid);
      if (el) {
        this.addListener(el, "contextmenu", function (e) {
          if ($this.editorMode) {
            //e.stopPropagation();
            e.preventDefault();
            $this.contextMenuPanelUuid = panel.uuid;
            //$this.contextMenuElementId = null;
            $this.contextMenuX = e.clientX;
            $this.contextMenuY = e.clientY - 150;
            $this.showContextMenu = true;
          }
        }, 'panel-context-' + panel.uuid)
      } else {
        console.log('panel with uuid not found for context menu attachment')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
#panel-contextmenu {
  font-size: 0.8rem;
  position: fixed;
  z-index: 100000;
  background-color: var(--vform-editor-ui-secondary-color);
  color: var(--vform-editor-ui-primary-color);
  border-radius: 3px;
  -webkit-box-shadow: 0 8px 6px -5px black;
  box-shadow: 0 5px 6px -5px black;
  .cat-name {
    font-size: 0.9rem;
  }
  .item {
    cursor: pointer;
    padding: 2px;
    padding-left: 30px;
    position: relative;

    .icon {
      position: absolute;
      left: 5px;
    }

    &:hover {
      background-color: var(--vform-editor-ui-quinary-color);
    }
    &.disable-hover:hover {
      background-color: var(--vform-editor-ui-secondary-color);
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
</style>