<template>
  <div></div>
</template>

<script>
import {v4 as uuidv4} from "uuid";
import {vformModes, vFormEvents} from "@/enum";

/**
 * This script is exclusively to be used by the slideshowdisplay component
 * do NOT use this anywhere else, it is just separated so the code for logging is separated from the rest
 * */
export default {
  name: "loggingMixin",
  data() {
    return {
      /**
       * Logging
       * - logger {Object}: the current logging data
       * - userId {Uuid}: the unique identifier of the user (can also be a name or internal number)
       * - sessionId {Uuid}: unique ID per Session
       * - columns {Object}: columns of dataSet
       * - lastInteractinTime {timestamp}: the last time the user interacted with the form
       * */
      dataSetId: null,
      logEntries: [],
      logger: {},
      userId: null,
      sessionId: null,
      userInput: "",
      visitedSteps: [],
      nonVisitedSteps: [],
      baseRow: {},
      dataRowKey: null,
      columns: null,
      lastInteractionTime: Date.now(),
      loggedStarted: false,
      doUpdate: false,
      currentlyStepPreviouslyVisited: false,
      loggingTarget: null,
      stepName: "",
      firstLogEntryDone: false,
      vformModes: vformModes,
      vFormEvents: vFormEvents
    };
  },
  methods: {
    async saveEntries() {
      if (!this.dataSetId) {
        //console.log('no dataset id given')
        return;
      }
      await this.getColumns();
      let mappedCreate = [];
      const mappedUpdate = [];
      for (let i = 0; i < this.logEntries.length; i++) {
        let logEntry = this.logEntries[i];
        logEntry = {...this.baseRow, ...logEntry};
        const doUpdate = this.doUpdate && logEntry.eventType === this.vFormEvents.AGGREGATION;
        const item = this.mapEntry(this.columns, logEntry, doUpdate);
        if (doUpdate) {
          mappedUpdate.push(item);
        } else {
          mappedCreate.push(item);
        }
      }
      if (mappedCreate.length) {
        mappedCreate = mappedCreate.map(item => {
          return {data: item, key: item.key}
        })
        await this.$store.dispatch('clientCreateDatasetRows', {id: this.dataSetId, values: mappedCreate});
      }
      for (let i = 0; i < mappedUpdate.length; i++) {
        const res = await this.$store.dispatch('clientLoadDatasetRows', {
          id: this.dataSetId,
          filter: 'key eq ' + mappedUpdate[i].key
        });
        let params = {
          id: this.dataSetId,
          data: mappedUpdate[i], //replace by complete datarow
          datarowId: res[0].id,
        };
        delete params.data.key;
        await this.$store.dispatch('clientUpdateDatasetRow', params);
      }
      this.logEntries = [];
    },
    async getRowByStepId(key) {
      const res = await this.$store.dispatch('clientLoadDatasetRows', {
        id: this.dataSetId,
        filter: 'key eq ' + key
      });
      return res && res.length ? res[0] : null;
    },
    /**
     * @params dataSetId {uuid} - the id of the target dataset
     * @params columns {object} - the columns object of the target dataset
     * @params data {object} - the data for one single log entry
     * */
    mapEntry(columns, data, doUpdate = false) {
      let mappedData = {};
      if (!doUpdate) {
        Object.keys(data).map(key => {
          if (this.columns[key.toLowerCase()]) {
            const columnIndex = this.columns[key.toLowerCase()].columnIndex;
            mappedData[columnIndex] = data[key];
          } else {
            //console.log('could not map field ' + key);
          }
        });
      } else {
        Object.keys(data).map(key => {
          if (this.columns[key.toLowerCase()]) {
            mappedData[key] = data[key];
          } /*else {
            console.log('could not map field ' + key);
          }*/
        });
      }
      if (data.dataRowKey) {
        mappedData.key = data.dataRowKey;
      }
      return mappedData;
    },
    /***
     * saves the from slide-with-form collected data into single events
     * @params data {Object} – a data object with:
     *  - logs {Array} - the items collected from all step elements
     *  - slideName {String} - the first text item on the page
     *
     *
     *
     *        * Array comes like this:
     *        * [
     *        *  logs: [{
     *        *    data: [{
     *        *      value: "input-value",
     *        *      type: "userId"
     *        *    }],
     *        *    type: "input"
     *        *  }]
     *        * ]
     *        *
     * */
    resetUserSession() {
      this.setUserId(null);
      this.lastInteractionTime = Date.now();
      this.forceReRenderKey++; // reset the steps so they lose their preset data
      this.loggedStarted = false;
      this.userInput = "";
      this.nonVisitedSteps = [];
      this.visitedSteps = [];
      this.createSession(true);
    },
    /**
     * Array comes like this:
     * [
     *  logs: [{
     *    data: [{
     *      value: {
     *        correctAnswers: [{
     *          message: "answerlabel"
     *        }],
     *        wrongAnswers: [{
     *          message: "answerlabel"
     *        }],
     *        totalPossiblePoints: 2,
     *        receivedPoints: 1
     *      },
     *      type: "default"
     *    }],
     *    type: "input"
     *  }]
     * ]
     * */
    /**
     * Array comes like this:
     * [
     *  logs: [{
     *    data: [{
     *      value: "input-value",
     *      type: "default"
     *    }],
     *    type: "input"
     *  }]
     * ]
     * */
    /**
     * Gets the user inputs:
     *
     *   0: {
     *        rawValue: 'some-string',
     *        value: 'fieldName;Content'
     *      }
     *   }
     *
     * */
    setUserIdByInput(userId) {
      console.log('setting user id: ' + userId)
      let isNewUser = false;
      if (userId && this.$store.getters.getVFormUserId) {
        isNewUser = this.$store.getters.getVFormUserId !== userId;
        if (isNewUser) {
          console.log('user has changed')
          console.log('previous: ' + this.$store.getters.getVFormUserId)
          console.log('now ' + userId)
          // if there was another user id: erase all data from the previous user
          this.eraseAllData();
        }
      }
      if(userId) {
        this.setUserId(userId);
      }
      return isNewUser;
    },
    prepareLogging(data) {
      this.logEntries = [];
      this.loggingTarget = null;
      this.stepName = "";

      let {stepName} = data;
      this.passedSeconds = this.calculatePassedSeconds();
      this.loggingTarget = this.activeStepObject.uuid;
      this.stepName = stepName;
      this.dataSetId = this.vformConfig.logID;

      // prepare data
      this.baseRow = {
        userId: this.$store.getters.getVFormUserId, // if there are multiple fields in the login field, it will be like: firstfield;secondField;thirdField
        sessionId: this.$store.getters.getVFormSessionId,
        stepName: this.activeStepObject.name,
        time: this.lastInteractionTime,
        durationInSeconds: this.passedSeconds,
        projectName: this.name,
        stepNo: this.activeStepIndex + 1,
        visited: true,
        target: this.loggingTarget ? this.loggingTarget : "",
      };
      this.dataRowKey = this.$store.getters.getVFormSessionId + '-' + this.baseRow.stepNo;
    },
    checkAutoPlaySteppedLogged() {
      if (this.isLastSlideOfStep) {
        // this prevents continuous logging during autoplay
        this.autoplayStepLogged = true;
      } else if (!this.activeStepObject.autoPlay) {
        this.autoplayStepLogged = false;
      }
    },
    handleLoginComponent(items) {
      if (items && items.length) {
        const {userId} = items[0];
        this.setUserIdByInput(userId);
        const {textAnswerAggregation} = this.handleTextComponents(items, true);
        this.userInput = textAnswerAggregation.join(';');
        this.baseRow.userId = this.userInput;
        this.baseRow.sessionId = this.$store.getters.getVFormSessionId;
      }
    },
    handleTextComponents(items, useRawValues = false) {
      const textAnswerAggregation = [];
      const textAnswerData = [];
      if(items && items.length) {
        for (let i = 0; i < items.length; i++) {
          const data = items[i].fields;
          if (!data) {
            continue;
          }
          for (let j = 0; j < data.length; j++) {
            const item = data[j];
            const {rawValue, value} = item;
            this.logEntries.push({
              textAnswers: value,
              eventType: this.vFormEvents.NEUTRAL_ANSWER
            });
            textAnswerAggregation.push(useRawValues ? rawValue : value);
            if(item.data) {
              textAnswerData.push(item.data);
            }
          }
        }
      }
      return {textAnswerAggregation, textAnswerData};
    },
    handleSingeMultiChoiceComponents(items, textAggregation, textAnswerData) {
      let totalPossiblePointsTotal = 0;
      let receivedPointsTotal = 0;
      let questions = '';
      const answerData = textAnswerData ? textAnswerData : [];
      for (let i = 0; i < items.length; i++) {
        const answer = items[i];
        const {question} = answer.data;
        if (question) {
          questions += !questions ? question : ';' + question;
        }
        const {receivedPoints, totalPossiblePoints, correctAnswers, wrongAnswers, data} = answer.data.value;
        totalPossiblePointsTotal += totalPossiblePoints;
        receivedPointsTotal += receivedPoints;
        answerData.push(data);
        this.logEntries.push({
          receivedPoints: JSON.stringify(receivedPoints),
          totalPossiblePoints: JSON.stringify(totalPossiblePoints),
          eventType: this.vFormEvents.MULTIPLE_CHOICE_ANSWER,
          data
        });
        for (let j = 0; j < correctAnswers.length; j++) {
          this.logEntries.push({
            totalPossiblePoints: 1,
            ...correctAnswers[j],
            eventType: this.vFormEvents.CORRECT_ANSWER,
          });
        }
        for (let j = 0; j < wrongAnswers.length; j++) {
          this.logEntries.push({
            totalPossiblePoints: 1,
            ...wrongAnswers[j],
            eventType: this.vFormEvents.WRONG_ANSWER,
          });
        }
      }


      this.logEntries.push({
        receivedPoints: totalPossiblePointsTotal !== 0 ? JSON.stringify(receivedPointsTotal) : "",
        totalPossiblePoints: totalPossiblePointsTotal !== 0 ? JSON.stringify(totalPossiblePointsTotal) : "",
        textAnswers: textAggregation.length ? textAggregation.join(',') : '',
        eventType: this.vFormEvents.AGGREGATION,
        dataRowKey: this.dataRowKey,
        message: questions,
        data: answerData
      });
      return answerData;
    },
    async signOut(goToFirstSlide = false) {
      this.resetUserSession();
      // it needs to be erased the first time for the login window to clear
      this.eraseAllData();
      if(goToFirstSlide) {
        await this.goToSlideNo(0, {}, false);
        // it needs to be erased a second time or it will save the data of the last visited slide
        this.eraseAllData();
      }
      this.resetVisitedState();
    },
    handleCheckoutComponent(items) {
      if (items.length) {
        this.logEntries.push({eventType: this.vFormEvents.FINISH});
      }
    },
    /**
     * This is the main function for logging
     *
     * Logging is disabled under those conditions:
     *  - vForm Mode is something other than production
     *  - The item is autoplaying (we do not want to log the same item multiple times)
     *  - The form is in offline mode (no logging possible)
     *  - The form is in edit mode (logging makes no sense here)
     *
     *  @params data {Object} - the data object with:
     *  - logs {Array} - the items collected from all step elements
     *  - eventType {String} - the event type (nextSlide, historyBack, historyForward, Gotoslide, gotostep etc.)
     *  - trigger {String} - the trigger for the logging (checkout)
     *  - doForceLogging {Boolean} - whether to force logging or not
     *  * Array comes like this:
     *  * [
     *  *  logs: [{
     *  *    data: [{
     *  *      value: "input-value",
     *  *      type: "default"
     *  *    }],
     *  *    type: "input"
     *  *  }]
     *  * ]
     *
     * */
    async parseAndSaveLogEntries(data, doForceLogging = false, trigger = null) {
      if (this.$store.getters.getvFormMode !== vformModes.PRODUCTION || (this.activeStepObject.autoPlay && this.autoplayStepLogged) || this.state.offlineMode || this.editorMode) {
        console.log('logging disabled')
        return new Promise(resolve => {
          resolve();
        });
      }
      if (doForceLogging || this.isLastSlideOfStep) {
        this.updateVisitedSteps();
      }
      this.prepareLogging(data);
      this.checkAutoPlaySteppedLogged();

      // prepare logins anyway in case we need them for th event logs
      let {logs} = data;
      if(!logs) {
        logs = [];
      }

      // LOGIN LOGGING
      const logins = logs.filter(item => {
        return item.type === 'login'
      });
      this.handleLoginComponent(logins);

      // No logging if user is not logged in
      if(!this.$store.getters.getVFormUserId) {
        console.log('no user id, no logging')
        return new Promise(resolve => {
          resolve();
        });
      }

      // EVENT LOGGING (nextSlide, historyBack, historyForward, Gotoslide, gotostep etc.)
      this.logEntries.push({
        target: this.loggingTarget ? this.loggingTarget : "",
        eventType: data.eventType
      });

      // if this isn't the last slide of a step, do only log goTo-Events, but not the form data yet
      if (!doForceLogging && !this.isLastSlideOfStep) {
        console.log('Only logging event logs because it is not last step on slide')
        await this.saveEntries();
        return;
      }

      // todo: consider putting this into a separate function
      // FORM DATA LOGGING
      const textInputs = logs.filter(item => {
        return item.type === 'textAnswer'
      })
      const{textAnswerAggregation, textAnswerData } = this.handleTextComponents(textInputs);

      const singleMultiChoice = logs.filter(item => {
        return ['checkboxes', 'radiobuttons'].includes(item.type);
      })
      this.handleSingeMultiChoiceComponents(singleMultiChoice, textAnswerAggregation, textAnswerData);

      if(trigger === "checkout") {
        const checkouts = logs.filter(item => {
          return item.type === 'checkout'
        });
        this.handleCheckoutComponent(checkouts);
      }

      // INITIAL LOGGING only
      if (!this.firstLogEntryDone) {
        this.logAllNonVisitedSteps();
        this.logEntries.push({
          eventType: this.vFormEvents.STARTED
        });
      }

      await this.saveEntries();
      this.firstLogEntryDone = true;
      if(trigger === "checkout") {
        await this.signOut();
      }
    },
    /**
     * Sets the userId if there is a field isID in the form
     * @params id {string} - a unique string identifying the user (is user input)
     * */
    setUserId(id) {
      console.log('setting user id to ' + id)
      if(this.$store.getters.getVFormUserId !== id) {
        const forceCreateNewSession = this.$store.getters.getVFormUserId !== null
        this.createSession(forceCreateNewSession);
        this.$store.dispatch('updateVFormUserId', id);
      }
    },
    /**
     * Generates a new sessionId for a user
     * */
    createSession(forceCreate = false) {
      if (!this.$store.getters.getVFormSessionId || forceCreate) {
        console.log('creating new session ' + forceCreate)
        this.$store.dispatch('updateVFormSessionId', uuidv4())
      }
    },
    calculatePassedSeconds() {
      let passedSeconds = null;
      const previousInteractionTime = this.lastInteractionTime;
      this.lastInteractionTime = Date.now();
      if (previousInteractionTime && this.lastInteractionTime) {
        passedSeconds = (this.lastInteractionTime - previousInteractionTime) / 1000;
      }
      return passedSeconds;
    },
    resetVisitedState() {
      this.nonVisitedSteps = [];
      this.visitedSteps = [];
    },
    logAllNonVisitedSteps() {
      const steps = this.vformConfig.steps;
      this.nonVisitedSteps = [];
      for (let i = 0; i < steps.length; i++) {
        const step = steps[i];
        if (!this.visitedSteps.includes(step.uuid)) {
          this.nonVisitedSteps.push(step.uuid);
          this.logEntries.push({
            stepName: step.name,
            target: step.uuid,
            visited: "false",
            stepNo: step.step,
            dataRowKey: this.$store.getters.getVFormSessionId + '-' + step.step,
            eventType: this.vFormEvents.CANCELLED,
            durationInSeconds: null,
          });
        }
      }
    },
    async getColumns() {
      if (!this.columns) {
        await this.$store.dispatch('clientLoadDataset', {id: this.dataSetId})
            .then(dataSet => {
              if (dataSet) {
                this.columns = dataSet.schema.columns;
                // this so upper and lowercase do not matter
                Object.keys(this.columns).map(key => {
                  this.columns[key.toLowerCase()] = this.columns[key];
                })
              }
            })
      }
    },
    /**
     * Checks which steps are visited and pushes the current step to the visited ones
     * @returns {Boolean} - whether the current step is already visited or not
     * */
    updateVisitedSteps() {
      this.doUpdate = false;
      const {uuid} = this.activeStepObject;
      // this needs to be up here because it should log the login-step as visited as well
      if (!this.visitedSteps.includes(uuid)) {
        this.visitedSteps.push(uuid);
      }
      this.currentlyStepPreviouslyVisited = false;
      if (this.nonVisitedSteps.includes(uuid)) {
        this.doUpdate = true;
        this.currentlyStepPreviouslyVisited = true;
        const index = this.nonVisitedSteps.findIndex(item => {
          return item === uuid
        });
        this.nonVisitedSteps.splice(index, 1);
      }
      return this.currentlyStepPreviouslyVisited;
    },
  }
}
</script>