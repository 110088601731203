<template>
  <div class="col-12">
    <div class="form-section">
      <info-helper class="float-right" text="UpdateMetaDataInfo"
      />
      <h3>{{ $t("UpdateMetaData") }}</h3>
      <!-- Collapsible Section -->
      <div>
        <div @click="toggleExamples" class="settings-button d-inline-block">
          {{ showExamples ? $t("HideExamples") : $t("ShowExamples") }}
        </div>
        <div v-if="showExamples" class="collapsible-content pt-1 mt-2">
          <!-- Example payload generic xml -->
          <div class="mt-3 code-example">
            <h5>{{ $t("ExamplePayload") }} GenericXML</h5>
            <b>Version 1:</b><br />
            <pre>&lt;root&gt;
  &lt;record&gt;
    &lt;column1&gt;Value1&lt;/column1&gt;
    &lt;column2&gt;Value2&lt;/column2&gt;
    &lt;column3&gt;Value3&lt;/column3&gt;
  &lt;/record&gt;
  &lt;record&gt;
    &lt;column1&gt;Value4&lt;/column1&gt;
    &lt;column2&gt;Value5&lt;/column2&gt;
    &lt;column3&gt;Value6&lt;/column3&gt;
  &lt;/record&gt;
&lt;/root&gt;
            </pre>
            <b>Version 2 (column names in first row):</b><br />
            <pre>
&lt;root&gt;
  &lt;row&gt;
    &lt;cell&gt;column1&lt;/cell&gt;
    &lt;cell&gt;column2&lt;/cell&gt;
    &lt;cell&gt;column3&lt;/cell&gt;
  &lt;/row&gt;
  &lt;row&gt;
    &lt;cell&gt;Value for column1&lt;/cell&gt;
    &lt;cell&gt;Value for column2&lt;/cell&gt;
    &lt;cell&gt;Value for column3&lt;/cell&gt;
  &lt;/row&gt;
&lt;/root&gt;
</pre>
          </div>
          <!-- Example payload WindchillSearchResult -->
          <div class="mt-3 code-example">
            <h5>{{ $t("ExamplePayload") }} WindchillSearchResult</h5>
            <pre>
&lt;?xml version="1.0" encoding="UTF-8"?&gt;
&lt;SearchResults&gt;
  &lt;TableHeader&gt;
    &lt;ColumnName&gt;Part Name&lt;/ColumnName&gt;
    &lt;ColumnName&gt;Some meta field&lt;/ColumnName&gt;
  &lt;/TableHeader&gt;
  &lt;Object&gt;
    &lt;Attribute&gt;val1&lt;/Attribute&gt;
    &lt;Attribute&gt;val2&lt;/Attribute&gt;
  &lt;/Object&gt;
&lt;/SearchResults&gt;
        </pre>
          </div>
          <!-- Example payload CSV -->
          <div class="mt-3 code-example">
            <h5>{{ $t("ExamplePayload") }} CSV</h5>
            <pre>
PartName;Material
Cube;Iron
Cube;Steel
Cube;Plastic
Cube;Foam
            </pre>
          </div>
        </div>
      </div>
    </div>
    <upload-drop-zone
        ref="metaDataUpload"
        :accepted-file-types="Object.values(metaDataFileFormats).map((item) => {return '.' + item.format;}).join(',')"
        :asset-id="$route.params.id"
        :auto-update-preview="false"
        :auto-upload="false"
        :class="[!metaDataColumns ? 'col-12 col-md-8' : 'col-6 col-md-6', 'mt-3']"
        :downloadable="false"
        :image-editable="true"
        :is-loading="isLoading"
        :removable="false"
        asset-type="assembly"
        form-id="assemblyUpdate"
        target-type="Asset"
        @data="setData"
        @fileReceived="fileReceived"
        @filesInQueue="filesInQueue"
        @queueEmpty="queueEmpty"
    >
      <div class="mt-3">
        {{ $t("uploadMetaFile") }}
      </div>
    </upload-drop-zone>
    <div v-if="fileContent" class="col-12 col-md-8">
      <label class="lighter mt-2">{{ $t("PostProcessingForMeta") }}</label><br/>
      <select
          v-model="vhubPreProcessor"
          class="custom-select dark"
          @change="parseData"
      >
        <option
            v-for="(key, value) in vhubPreProcessorOptions"
            :key="key"
            :value="value"
        >
          {{ $t(key.name) }}
        </option>
      </select>
      <div v-if="error" class="form-error mt-2">
        {{ error }}
      </div>
      <assembly-config
          v-if="metaDataColumns.length"
          ref="AssemblyConfig"
          :meta-data-columns="metaDataColumns"
          :organization-id="$store.getters.getAssemblyOrganization($route.params.id)"
          :teams="$store.getters.getAssemblyTeams($route.params.id)"
          permission="writeMetaSet"
          @setConfig="setMetaConfig"
      />
    </div>
    <div
        v-if="Object.keys(metaConfig).length"
        class="col-12 col-md-6">
      <label>{{ $t("Modus") }} </label>
      <info-helper class="ml-1" text="MetaUpdateMode"/>
      <tile-button
          ref="tiles"
          :initial-active-tiles="[metaSetUpdateModes[0]]"
          :tiles="metaSetUpdateModes"
          :values="metaSetUpdateModes"
          @trigger="selectMode"
      />
    </div>
    <div
        v-if="Object.keys(metaConfig).length"
        class="form-section col-12"
    >
      <label>{{ $t("email") }}</label>
      <input
          v-model="email"
          :class="['form-text w-100', $v.email.$dirty && $v.email.$error ? 'form-group--error' : '',]"
          type="text"
          @input="delayTouch($v.email)"
      />
      <div
          v-if="$v.email.$dirty && !$v.email.email"
          class="form-error mb-2"
      >
        {{ $t("errors.mustBeAValidEmailAdress") }}
      </div>
      <Button
          :deactivated="!this.$v.email.email || partIdentifierColumnMissing"
          class="mt-3 icon-rotate-animation make-longer-animation"
          @click="startMetaSetUpdate"
      >{{ $t("XMLUpdateStart") }}
        <icon type="cube"/>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "../forms/Button";
import Icon from "../Icon";
import AssemblyConfig from "@/components/assemblies/MetaConfig";
import TileButton from "@/components/forms/TileButton";
import InfoHelper from "@/components/InfoHelper";
import UploadDropZone from "@/components/files/UploadDropZone";
import {decodeArrayBuffer} from "@/utils";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import {metaDataFileFormats, metaSetUpdateModes, TaskManagerTaskTypes} from "@/enum";
import {email} from "vuelidate/lib/validators";
import DataProcessor from "../fileProcessors/DataProcessor";
import {sleep} from "@/utils";
export default {
  name: "MetaDataUpdater",
  components: {
    AssemblyConfig,
    UploadDropZone,
    TileButton,
    InfoHelper,
    Button,
    Icon,
  },
  mixins: [VuelidateMixin],
  props: {
    remoteContent: {type: Object, required: true},
    canUpdate: {type: Boolean, default: false}
  },
  data() {
    return {
      partIdentifierColumnMissing: true,
      /**
       * will be set by the meta-config component – is the content.pipeline.meta-Object
       * */
      metaConfig: {},
      /**
       * the columns from the meta file (which is usually a table of some sort)
       * */
      metaDataColumns: [],
      /**
       * Sets the dropzone to loading-state while processing data
       * */
      isLoading: false,
      /**
       * the available file formats = processors for meta-files (currently Windchill Search Res only)
       * */
      metaDataFileFormats: metaDataFileFormats,
      vhubPreProcessorOptions: metaDataFileFormats,

      /**
       * The preprocessor will be set as
       * */
      vhubPreProcessor: "", //Object.keys(metaDataFileFormats)[0],

      /**
       * the mode to use for either overwriting/extending or reset the meta-data
       * */
      mode: "extend",
      metaSetUpdateModes: metaSetUpdateModes,

      email: this.$store.getters.getUserEmail(this.$store.getters.getCurrentUserId),
      sendMails: false,

      content: {},
      dataProcessor: null,
      fileContent: null,
      showExamples: false,
      error: '',
    };
  },
  validations: {
    email: {
      email,
    },
  },
  mounted() {
    this.content = this.remoteContent;
  },
  methods: {
    toggleExamples() {
      this.showExamples = !this.showExamples;
    },
    setMetaConfig(metaConfig) {
      this.metaConfig = metaConfig;
      this.partIdentifierColumnMissing = !this.metaConfig.partIdentifierColumn;
    },
    // 1 resume upload-dropzone upload which triggers 2
    startMetaSetUpdate() {
      this.$refs.metaDataUpload.resumeUpload();
    },
    // 2 when file is uploaded, start update of sfx content and
    // 3 start the pipeline task "dataprocessing"
    async fileReceived(assetId, fileName) {
      this.content.pipeline.files = [`${assetId}/${fileName}`];
      this.$emit('processing')
      await this.updateAssemblyContent(fileName)
          .then(async () => {
            await this.startUpdate();
            this.isLoading = false;
          });
    },
    async startUpdate() {
      return await this.$store.dispatch("clientAssetToPipeline", {
        id: this.$route.params.id,
        type: TaskManagerTaskTypes.DATAPROCESSING,
        config: JSON.stringify({
          sendMails: true,
          email: this.email,
          lang: this.$i18n.locale ? this.$i18n.locale : 'en',
        })
      })
    },
    setData(data) {
      this.fileContent = data;
      this.isLoading = false;
    },
    async parseData() {
      this.error = '';
      this.metaDataColumns = [];
      this.metaConfig = {};
      this.dataProcessor = new DataProcessor(this.vhubPreProcessor);
      await this.dataProcessor.init();
      const file = decodeArrayBuffer(this.fileContent);
      this.metaDataColumns = await this.dataProcessor.getColumnNames(file)
          .catch(() => {
            this.error = this.$t('errors.errorWhileParsingFile');
          });
    },
    filesInQueue(files) {
      this.isLoading = true;
      console.log(files);
      const {name} = files[0];
      this.autoSetParser(name);
    },
    async autoSetParser(fileName) {
      await sleep(300);
      console.log('autoSetParser', fileName);
      if(fileName.includes('.csv')) {
        console.log('csvParser');
        this.vhubPreProcessor = 'csvParser';
      } else {
        console.log('genericXMLParser');
        this.vhubPreProcessor = 'genericXMLParser';
      }
      if(this.vhubPreProcessor) {
        await this.parseData();
        if(this.error) {
          console.log('error', 'xmlWindchillSearchRes');
          this.vhubPreProcessor = 'xmlWindchillSearchRes';
          await this.parseData();
        }
      }
    },
    queueEmpty: function () {
      this.metaConfig = {};
      this.metaDataColumns = [];
    },
    async updateAssemblyContent(clientMetaFile) {
      if (this.$refs.AssemblyConfig) {
        await this.$refs.AssemblyConfig.save();
      }
      if (this.vhubPreProcessor && this.vhubPreProcessor !== "none") {
        this.content.pipeline.dataProcessor = this.vhubPreProcessor;
      }
      this.content.pipeline.sendMails = this.sendMails;
      this.content.pipeline.email = this.email;
      this.content.pipeline.lang = this.$i18n.locale ? this.$i18n.locale : "en";
      this.content.pipeline.meta = {
        updateMode: this.mode,
        clientMetaFile: clientMetaFile,
        ...this.metaConfig,
      };
      return await this.$store.dispatch("loadAssembly", {
        id: this.$route.params.id
      }).then(async () => {
        return await this.$store.dispatch("updateAssembly", {
          id: this.$route.params.id,
          content: JSON.stringify(this.content),
        });
      });
    },
    selectMode(params) {
      this.mode = params.value;
    },
  }
}
</script>

<style scoped>
h5 {
  font-family: "Inter bold", sans-serif;
  color: #000;
}

.collapsible-content {
  padding: 0 18px;
  background-color: #f1f1f1;
}

.collapsible-content pre {
  margin: 0;
  padding: 0;
}
.code-example {
  color: #000;
  b {
    font-family: "Inter bold", serif;
  }
}
</style>