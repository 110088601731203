<template>
  <div></div>
</template>

<script>
import {combineAssetAndInstanceMetaValues_v2} from "@/utils";

export default {
  name: "PartBlockMixin.js.vue",
  data() {
    return {
      partBlockMixin: {
        assetId: null,
        projectId: null,
        instanceMetaSets: null,
        assetMetaSets: null,
        smashedInstanceMetaSets: [],
        instanceName: "",
        instanceMetaValues: null,
      }
    };
  },
  methods: {
    async loadInstance(instanceId) {
      this.partBlockMixin.assetId = null;
      this.partBlockMixin.smashedInstanceMetaSets = [];
      this.partBlockMixin.instanceName = "vform.selectInstanceNotFound";
      // load instance to retrieve assetId and projectId
      await this.$store
        .dispatch("clientGetCrossProjectInstances", {
          filter: `id eq ${instanceId}`,
          include: [],
        })
        .then((data) => {
          if (data[0]) {
            this.partBlockMixin.instanceName = data[0].name;
            this.partBlockMixin.projectId = data[0].projectId;
            this.partBlockMixin.assetId = data[0].assetId;
          }
        });

      if (this.partBlockMixin.assetId) {
        // load asset
        await this.$store
          .dispatch("clientLoadAsset", {
            id: this.partBlockMixin.assetId,
            include: "metaSets",
          })
          .then((asset) => {
            const { metaSets } = asset;
            this.partBlockMixin.assetMetaSets = metaSets;
          });

        this.partBlockMixin.instanceMetaValues = await this.$store.dispatch("getProjectInstanceMetaValues", {
            id: this.partBlockMixin.projectId,
            cid: instanceId,
          });
        this.makeMetaFieldsForInstance();
      }
    },
    makeMetaFieldsForInstance() {
      this.partBlockMixin.instanceMetaSets = combineAssetAndInstanceMetaValues_v2(
        this.partBlockMixin.assetMetaSets,
        this.partBlockMixin.instanceMetaValues
      );

      //filter based on what fields to display (config.metaFields)
      this.partBlockMixin.instanceMetaSets.forEach((metaset) => {
        let fieldsClone = JSON.parse(JSON.stringify(metaset.metaFields));
        fieldsClone.forEach((field) => {
          if (!this.config.metaFields.find((id) => id === field.id)) {
            const index = metaset.metaFields.findIndex((f) => {
              return f.id === field.id;
            });
            metaset.metaFields.splice(index, 1);
          }
        });
      });
      this.partBlockMixin.smashedInstanceMetaSets = this.smashMetaSets(
        this.partBlockMixin.instanceMetaSets,
        true
      );
    },
  },
};
</script>