<template>
  <div :class="['tag-input', (showItemList || showItemListPermanently) ? 'high-z-index' : '']">
    <div :class="['form-item', showItemListPermanently ? 'with-append-icon' : 'with-prepend-icon'] ">
      <text-input
          :id="'tagsinput'"
          ref="textinput"
          :placeholder="selectPlaceholder ? selectPlaceholder : $t('typeToSelect')"
          @blur="getValue"
          @typingAlert="filterAndPropose"
      />
      <div v-if="!showItemListPermanently" class="input-prepend-icon interactive" @click="toggleItemList">
        <icon v-if="!showItemList" type="angle-down"/>
        <icon v-else type="angle-up"/>
      </div>
    </div>
    <div v-if="(showItemList || showItemListPermanently) && (specialGetter ? $store.getters[specialGetter](specialGetterArgs, listName) : items)"
         class="form-dropdown-box">
      <div v-if="message" class="form-small-message">{{ message }}</div>
      <div v-for="(tag, id) in specialGetter ? $store.getters[specialGetter](specialGetterArgs, listName) : items"
           :key="tag.name + id + forceReRenderKey"
           @click="() => {$emit('addItem', tag); showItemList = false }">
        <tag
            v-if="!excludeFromItemList || excludeFromItemList.filter(item => {return item.id === tag.id}).length === 0"
            :icon-left="getIcon(tag)"
            :is-removable="false"
            :name-property="filterAttribute"
            :organization-id="showOrganization ? tag.organizationId : ''"
            :tag="tag"
            class="mb-1"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../Icon";
import Tag from "../Tag";
import TextInput from "../forms/TextInput";

export default {
  name: "UniversalSelector",
  components: {
    Icon,
    Tag,
    TextInput,
  },
  props: {
    /**
     * @listName the name of the vuex store in which the list is stored
     * */
    listName: {type: String, required: true},
    /**
     * @storeName the name of the vuex store to be used for Queries
     * */
    storeName: {type: String, required: true},
    /**
     * @excludeFromItemList {Array} of items which should be excluded (they need to have an id property)
     * */
    excludeFromItemList: {type: Array, default: null},
    /**
     * @showItemListPermanently {Boolean} whether to show the dropdown permanently or not
     * */
    showItemListPermanently: {type: Boolean, default: false},
    /**
     * an array containing selected visibilities, e.g. [0] for all private organizations
     * */
    visibilitySelection: {
      type: Array, default: () => {
        return [0, 1, 2, 3]
      }
    },
    /**
     * in which attribute of the item should be filtered when using the text field
     * */
    filterAttribute: {type: String, default: 'name'},
    /**
     * @specialGetter if you wish to use another getter than the default one
     * */
    // todo: remove specialGettersThings as soon as we got rid of private orgs
    specialGetter: {type: String, default: ''},
    /**
     * @specialGetterArgs if you wish to use another getter than the default one and provide special args
     * */
    specialGetterArgs: {
      type: Array, default: () => {
      }
    },
    /**
     * @tagIcon the icon showing up in the list
     * */
    tagIcon: {type: String, default: 'home'},
    /**
     * @tagCondition if provided, the tagIcon will only be used if condition is not met
     *  {
     *    fieldName: 'id',
     *    '2': 'home',
     *    '5': 'garage'
     *  }
     * */
    tagCondition: {
      type: Object, default: () => {
      }
    },
    /**
     * @organizationId limit entries for a spezific organization
     * */
    limitByOrganizationId: {type: String, default: ''},
    limitByOrganizationIds: {type: Array, default: () => {return []}},
    showOrganization: {type: Boolean, default: false},
    /**
     * a function in the form (item) => {return item.attribute === 'attributename';}
     * which needs to return true in order to keep the item
     * */
    afterQueryFilter: {default: null},

    /**
     * If there need to be some includes
     * **/
    include: {type: String, default: null},
    selectPlaceholder: {type: String, default: ""},
  },
  data() {
    return {
      forceReRenderKey:0,
      showItemList: false,
      message: '',
      value: '',
      items: [],
    }
  },
  beforeMount() {
    this.showItemListPermanently ? this.loadItems() : this.loadItems(false);
  },
  methods: {
    getIcon(tag) {
      const tagConditionFieldName = this.tagCondition && this.tagCondition.fieldName ? this.tagCondition.fieldName : '';
      return tagConditionFieldName && this.tagCondition[tag[tagConditionFieldName]] ? this.tagCondition[tag[tagConditionFieldName]] : this.tagIcon;
    },
    toggleItemList: function () {
      this.showItemList = !this.showItemList;
      if (this.showItemList) {
        this.loadItems();
      }
    },
    loadItems(showing = true) {
      this.filterAndPropose({value: ''}, showing);
      if (this.$refs.textinput) {
        this.$refs.textinput.clearValue();
      }
    },
    getValue: function (id, value) {
      this.value = value;
    },
    filterAndPropose: function (input, showItemList = true) {
      let params = {};
      let filterParamId = this.listName;
      this.showItemList = showItemList;
      this.message = '';
      let filterString = '';
      if(this.limitByOrganizationIds && this.limitByOrganizationIds.length) {
        filterString += this.limitByOrganizationIds.length > 1 ? "organizationId in '" + this.limitByOrganizationIds.join("'") + "'" : 'organizationId eq ' + this.limitByOrganizationIds[0];
      }
      else if (this.limitByOrganizationId) {
        filterString += 'organizationId eq ' + this.limitByOrganizationId;
      }
      if (input.value.length > 2) {
        if (filterString) {
          filterString += ','
        }
        filterString += `${this.filterAttribute} like *${input.value}*`;
      }
      if (filterString) {
        params = {
          add: {
            filter: {
              [filterParamId]: filterString
            }
          }
        };
      } else if (!filterString) {
        params = {
          remove: {
            filter: {filterParamId}
          }
        };
      } else {
        this.message = this.$t('errors.atLeastThreeCharacters');
      }
      if (!params.add) {
        params.add = {}
      }
      params.add.listName = {[filterParamId]: this.listName};
      params.listName = this.listName;
      if (this.include) {
        params.add.include = {[filterParamId]: this.include};
      }
      params.add.limit = {[filterParamId]: 30};
      this.$store.dispatch(`load${this.storeName}s`, params)
          .then(data => {
            this.items = data;
            if (this.afterQueryFilter) {
              this.items = this.items ? this.items.filter(item => {
                return this.afterQueryFilter(item);
              }) : [];
              this.forceReRenderKey++;
            }
          });
    },
  }
}
</script>

<style lang="scss" scoped>
.tag-input {
  width: 100%;
  position: relative;

  .form-text {
    margin-bottom: 0;
  }
}

.color-preview {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid #fff;
  }
}

.color-preview-container {
  min-width: 40px;
  height: 100%;
  position: relative;
  display: inline-block;
}
</style>