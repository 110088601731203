<template>
  <div
      :id="step.uuid"
      ref="wrapper"
      :class="['panel-form-wrapper', 'active',
        state.hotspotPopupVisible ? 'step-panel-in-background' : '']"
      :style="{
          width: width ? width + 'px !important' : '100%',
          height: height ? height + 'px !important' : '100%',
        }"
  >
    <template v-if="vformConfig.logID && !editorMode">
      <div class="state-overlay" v-if="$store.getters.getvFormMode === vformModes.TEST">
        {{ $t('TestMode') }}
      </div>
      <div class="state-overlay" v-else-if="$store.getters.loggingIsDisabled">
        {{ $t('TestMode') }}
      </div>
    </template>
    <portal ref="form-popup" to="form-popup" />
    <panel v-for="(panel, panelIndex) in getStepPanels()"
           v-if="organizationId && projectId"
           :key="'panel-' + panel.uuid"
           :id="'panel-' + panel.uuid"
           :panel-index="panelIndex"
           :ref="panel.uuid"
           :panel="panel"
           :step="step"
           :editable="editable"
           :show-button-container="!!showButtonContainer(panel, panelIndex)"
           :editor-mode="editorMode"
           :state="state"
           :form-settings="formSettings"
           :bottom-elements="getPanelElements(panel, 'bottomDropZone', false)"
           :content-elements="getPanelElements(panel, 'bottomDropZone', true)"
           :next-button-allowed="nextButtonAllowed"
           :project-info="{
             name: projectName,
             projectId,
             organizationId
           }"
           :teams="teams"
           :v-s-t-a-g-e-context="vSTAGEContext"
           :globals="globals"
           :steps="steps"
           :step-form-data="stepFormData"
           :last-slide-active="lastSlideActive"
           :is-current-step="isCurrentStep"
           :active-mode="activeMode"
           @switchToPanelEdit="switchToPanelEdit"
           @storeData="storeData"
           @increasePostLoadingCounter="increasePostLoadingCounter"
           @signOut="signOut"
           @showElementProperties="showElementProperties"
           @editTemplate="(el) => {$emit('editTemplate', el)}"
           @edit="showElementProperties"
           @removeBlock="(el) => {$emit('removeBlock', el)}"
           @goToSlide="goToSlide"
           @goToStep="goToStep"
           @goToProject="goToProject"
           @goToProjectSlide="goToProjectSlide"
           @next="doNext"
           @historyBack="$emit('historyBack')"
           @goToFirstSlide="goToFirstSlide"
           @goToLastSlide="goToLastSlide"
           @goToFirstStep="goToFirstStep"
           @goToLastStep="goToLastStep"
           @checkout="checkout"
    />
  </div>
</template>

<script>
import listenerMixinJs from "../../mixins/listenerMixin.js";
import {vformModes} from "@/enum";
import ColorContrastMixinJs from "@/components/mixins/ColorContrastMixin.js.vue";
import Panel from "@/components/vForm/viewer/Panel.vue";

export default {
  name: "Step",
  components: {
    Panel,
  },
  mixins: [listenerMixinJs, ColorContrastMixinJs],
  props: {
    step: {type: Object, default: () => {return {};}},
    isCurrentStep: {type: Boolean, default: true},
    projectId: {type: String, required: true},
    projectName: {type: String, default: ""},
    showSlide: {type: Boolean, default: true},
    lastSlideActive: {type: Boolean, default: true},
    nextButtonAllowed: {type: Boolean, default: true},
    /**
     * Whether or not the step should be editable
     * **/
    editable: {type: Boolean, default: false},
    /**
     * Show next button when no step is attached to a slide
     * */
    showNextButtonOnEmptySlides: {type: Boolean, default: false},
    /**
     * shared state between different vform components (different from vformConfig as we do not want to store it)
     * */
    state: {type: Object, default: null},
    /**
     * Whether we are in editor mode or not
     * */
    editorMode: {type: Boolean, default: false},
    formSettings: {type: Object, default: () => {return {fontSize: "0.95", resizeFontBasedOnScreenSize: false};},},
    disableLinks: {type: Boolean, default: false},
    vSTAGEContext: {type: Boolean, default: false},
    /**
     * only for vSTAGE context: whether vform is currently displayed or not
     * if not, stop video and audio and other things happening in the background
     * */
    activeMode: {type: Boolean, default: true},
    vformConfig: {type: Object, default: null},
    stepFormData: { type: Object, default: () => {return {}}},
    steps: { type: Array, default: () => { return []}},
    globals: {type: Object, default: () => {return []}},
    teams: {type: Array, required: true},
    organizationId: {type: String, required: true},
    templateAssetId: {type: String, required: true}
  },
  data() {
    return {
      showBottomBar: true,

      // validation
      validationIncorrectByPanel: [],
      missingRequiredByPanel: [],

      // states
      success: false,
      isMounted: false,
      registeredPostLoadingComponents: 0,

      // context menu
      contextMenuForPanel: null,

      // layout
      width: 0,
      height: 0,
      vformModes,
      collapsedPanels: {},
    };
  },
  watch: {
    editorMode() {
      this.fillData();
      this.adjustSize();
    },
    registeredPostLoadingComponents() {
      console.log('change in post loading components...')
      this.emitFullyLoaded();
    }
  },
  beforeMount() {
    this.addListener(window, "resize", this.adjustSize, 'adjust-step-size')
    this.adjustSize();
  },
  mounted() {
    this.isMounted = true;
    this.$emit('stepMounted');
    this.fillData();

    this.$emit('setJumpTargets', this.step.uuid, this.getAllJumpTargets());
    this.emitFullyLoaded();
  },
  methods: {
    removePanelPin(panel) {
      this.$set(panel, 'pin', null);
      this.state.panelsRerenderKey++;
    },
    checkout(args) {
      this.$emit("checkout", args);
      this.$emit("goToSlide", 'first', {}, false, false, null);
    },
    switchToPanelEdit(panel) {
      if(this.state.panelEditing && this.state.currentlyEditingPanel === panel.uuid) {
        this.state.currentlyEditingPanel = null;
        this.state.panelEditing = false;
        this.$emit('setActiveColumn', 'blocks');
      } else {
        this.$set(this.state, "currentlyEditingPanel", panel.uuid)
        this.state.panelEditing = true;
        this.$emit('setActiveColumn', 'step-properties');
      }
    },
    adjustSize() {
      if(!this.vSTAGEContext) {
        const coverImages = document.getElementsByClassName("slide-large-image");
        const coverImage = coverImages[0];
        if (!coverImage) {
          return;
        }
        const mediaRatio = coverImage.naturalWidth / coverImage.naturalHeight;
        const containerRatio = coverImage.clientWidth / coverImage.clientHeight;
        if (mediaRatio <= containerRatio) {
          this.width = coverImage.clientWidth;
          this.height = coverImage.clientHeight;
        } else {
          this.width = coverImage.clientHeight * containerRatio;
          this.height = coverImage.clientHeight;
        }
      } else {
        this.width = null;
        this.height = null;
      }
    },
    /**
     * Checks if there are buttons in the bottom panel which are hidden
     * */
    checkForHiddenButton() {
      const {elements} = this.step;
      let hiddenBottomEls = 0;
      if (elements.length && this.step.panels) {
        const bottom = elements.filter(item => {
          return !!item.bottomDropZone
        })
        for (let i = 0; i < bottom.length; i++) {
          const panelUuid = bottom[i].panelUuid;
          let panel = [this.step.panels[0]];
          if (panelUuid) {
            panel = this.step.panels.filter(item => {
              return item.uuid === bottom[i].panelUuid
            })
          }
          if (panel && panel.length) {
            const hiddenBottomBar = panel[0].hideBottomBar;
            if (hiddenBottomBar) {
              hiddenBottomEls++;
            }
          }
        }
      }
      return hiddenBottomEls;
    },
    getStepPanels() {
      let globalStepPanels = [];
      let globalPanels = this.vformConfig.global && this.vformConfig.global.panels ? this.vformConfig.global.panels : [];
      globalPanels.forEach((panel) => {
        if (panel.globalVisibleOnSteps) {
          if (panel.globalVisibleOnSteps.find((x) => x === this.step.uuid)) {
            globalStepPanels.push(panel);
          }
        }
      });

      return [...this.step.panels, ...globalStepPanels];
    },
    checkItemDropzone(item, dropZoneName, negate = false) {
      if(!dropZoneName) {return true}
      return negate ? !item[dropZoneName] : !!item[dropZoneName];
    },
    /**
     * Gets all elements for a given panel
     * the panel can either be global or local
     * */
    getPanelElements(panel, dropZoneFilter = '', negate = false) {
      const {uuid} = panel;
      const panelIndex = this.step.panels.findIndex((p) => p.uuid === uuid);
      //if the panel is part of the current step
      if (panelIndex !== -1) {
        return this.step.elements.filter(item => {
          return ((item.panelUuid === uuid) || (!item.panelUuid && panelIndex === 0)) && this.checkItemDropzone(item, dropZoneFilter, negate)
        });
      } else if (panel.isMaster) {
        if (this.vformConfig.global && this.vformConfig.global.elements) {
          return this.vformConfig.global.elements.filter((item) => {
            return (item.panelUuid === uuid) && this.checkItemDropzone(item, dropZoneFilter, negate);
          });
        }
      }
      return [];
    },
    removeHiddenBottomBarItems(panel, els) {
      if(panel.hideBottomBar) {
        return els.filter(item => {return this.checkItemDropzone(item, 'bottomDropZone', true)})
      }
      return els;
    },
    emitFullyLoaded() {
      /**
       * We have two issues when setting a step to fully loaded:
       * 1 - Buttons in a hidden bottom bar – they won't be mounted at all so they cannot emit a loaded state
       * 2 - Buttons that are hidden because of autoplay – they are in the dom, but not visible and not clickable
       * */
      const panels = this.getStepPanels();
      let allElementsCount = 0;
      for(let i = 0; i < panels.length; i++) {
        let els = this.getPanelElements(panels[i]);
        els = this.removeHiddenBottomBarItems(panels[i], els);
        allElementsCount += els.length;
      }
      if (allElementsCount === this.registeredPostLoadingComponents) {
        console.log('emitting fully loaded')
        this.$emit('fullyLoaded', this.step.uuid);
      }
    },
    increasePostLoadingCounter() {
      this.registeredPostLoadingComponents = this.registeredPostLoadingComponents + 1;
    },
    getFontSize(fontSizeInRem, panelUuid) {
      if (!this.formSettings.resizeFontBasedOnScreenSize) {
        return fontSizeInRem + 'rem';
      }
      const wrapper = document.getElementById('slideshow-div');
      if (wrapper) {
        const width = window.getComputedStyle(wrapper, null).getPropertyValue("width").replace('px', '');
        const height = window.getComputedStyle(wrapper, null).getPropertyValue("height").replace('px', '');
        const ref = this.$refs[panelUuid];
        const el = ref ? ref[0] : null;

        if (el) {
          const rootFs = window.getComputedStyle(document.body).getPropertyValue("font-size");
          const rawRootFs = rootFs.replace('px', '');
          const targetSize = (rawRootFs / fontSizeInRem * 100) * (width * height / 1000 * 0.0000015);
          return targetSize + 'rem';
        }
      }

      return fontSizeInRem + 'rem';
    },
    /**F
     * For validation (e.g. a questionnaire with checkboxes) the panel shows something is wrong
     * */
    getPanelValidation(panelUuid) {
      if (this.validationIncorrectByPanel && this.validationIncorrectByPanel.length) {
        const incorrect = this.validationIncorrectByPanel.filter((item) => {
          return (item.panelUuid === panelUuid || (!panelUuid && !item.panelUuid));
        });
        return incorrect.length;
      } else if (this.missingRequiredByPanel && this.missingRequiredByPanel.length) {
        const required = this.missingRequiredByPanel.filter((item) => {
          return (item.panelUuid === panelUuid || (!panelUuid && !item.panelUuid));
        });
        return required.length;
      } else {
        return false;
      }
    },
    signOut() {
      if (!this.editorMode) {
        this.$emit("signOut", true);
      }
    },
    editTemplate(config) {
      this.$emit("editTemplate", config);
    },
    showElementProperties(elementUuid) {
      this.state.activeElement = elementUuid;
    },
    showButtonContainer(panel, panelIndex) {
      if(panel.hideBottomBar) {
        return false;
      }
      return (
          this.editorMode ||
          (this.step.elements.length &&
              this.step.elements.filter((item) => {
                return (
                    item.bottomDropZone &&
                    (item.panelUuid === panel.uuid ||
                        (panelIndex === 0 && !item.panelUuid))
                );
              }).length)
          || (this.vformConfig.global && this.vformConfig.global.elements && this.vformConfig.global.elements.length &&
              this.vformConfig.global.elements.filter((item) => {
                return (
                    item.bottomDropZone &&
                    (item.panelUuid === panel.uuid ||
                        (panelIndex === 0 && !item.panelUuid))
                );
              }).length)
      );
    },
    storeData(elementId, data) {
      this.$emit('storeData', elementId, data);
    },
    /**
     * @params {any} - data
     * **/
    fillData() {
      const data = this.stepFormData;
      const panels = this.getStepPanels();
      for(let i = 0; i < panels.length; i++) {
        const panel = panels[i];
        const panelEl = this.$refs[panel.uuid];
        panelEl[0].fillComponentData(data);
      }
    },
    getAllJumpTargets() {
      const targets = [];
      const panels = this.getStepPanels();
      for(let i = 0; i < panels.length; i++) {
        const panel = panels[i];
        const panelEl = this.$refs[panel.uuid];
        const tmpTargets = panelEl[0].getJumpTargets();
        targets.push(...tmpTargets);
      }
      return targets;
    },
    prepareStepTransition() {
      this.validationIncorrectByPanel = [];
      this.missingRequiredByPanel = [];
      const showSuccess = false;
      let data = {};
      // todo:
      /**
       * getStepPanels -> returns all panels on this step
       * get all elements per panel
       * validate all elements of that panel
       * */
      const panels = this.getStepPanels();
      let isValid = true;
      for (let j = 0; j < panels.length; j++) {
        const panel = panels[j];
        const panelEl = this.$refs[panel.uuid];
        if(this.lastSlideActive) {
          const panelValid = panelEl[0].validate();
          if(!panelValid) {
            isValid = false;
          }
        }
      }
      data = this.collectComponentData();
      if (isValid) {
        if (showSuccess) {
          this.success = true;
        }
        return {data, success: true};
      } else {
        this.$emit('wrongAnswer', data)
      }
      return {data, success: false};
    },
    /**
     * Default next button
     * */
    doNext(trigger = null) {
      console.log('doing next...')
      const {data, success} = this.prepareStepTransition();
      if (success) {
        this.$emit("nextSlide", data, false, false, trigger);
      }
    },
    goToLastSlide(args = {}) {
      // args also contains projectId, make this working cross project at some point
      const {trigger} = args;
      const {data, success} = this.prepareStepTransition();
      if (success) {
        this.$emit("goToSlide", 'last', data, false, false, trigger);
      }
    },
    goToFirstSlide(args = {}) {
      const {trigger} = args;
      const {data, success} = this.prepareStepTransition();
      if (success) {
        this.$emit("goToSlide", 'first', data, false, false, trigger);
      }
    },
    goToLastStep(args = {}) {
      const {trigger} = args;
      const {data, success} = this.prepareStepTransition();
      if (success) {
        this.$emit("goToStep", 'last', data, false, trigger);
      }
    },
    goToFirstStep(args = {}) {
      const {trigger} = args;
      const {data, success} = this.prepareStepTransition();
      if (success) {
        this.$emit("goToStep", 'first', data, false, trigger);
      }
    },
    goToSlide(uuid, label, trigger = null) {
      const {data, success} = this.prepareStepTransition();
      if (success) {
        this.$emit("goToSlide", uuid, data, false, false, trigger);
      }
    },
    /**
     * Legacy, only used for old QR codes, which have slide:5 in their metavalues
     * @params no {Integer} - the number of the slide (which of course can change if new slides are inserted)
     * */
    goToSlideNo(no, trigger = null) {
      const {data, success} = this.prepareStepTransition();
      if (success || no === 0) {
        this.$emit("goToSlideNo", no, data, true, trigger);
      }
    },
    goToStep(stepUuid, label, trigger = null) {
      const {data, success} = this.prepareStepTransition();
      if (success) {
        this.$emit("goToStep", stepUuid, data, false, trigger);
      }
    },
    goToProjectSlide(projectUuid, projectName, targetSlide = null, trigger = null) {
      let data = {};
      data = this.collectComponentData();
      this.$emit("goToProjectSlide", projectUuid, projectName, targetSlide, data, trigger);
    },
    goToProject(projectUuid, projectName, targetStep = null, trigger = null) {
      let data = {};
      data = this.collectComponentData();
      this.$emit("goToProject", projectUuid, projectName, targetStep, data, trigger);
    },
    collectComponentData() {
      let slideData = {
        logs: [],
        stepName: this.step.name ? this.step.name : "",
      };
      const panels = this.getStepPanels();
      for(let i = 0; i < panels.length; i++) {
        const panel = panels[i];
        const panelEl = this.$refs[panel.uuid];
        const tmpData = panelEl[0].getPanelData();
        slideData.logs = slideData.logs.concat(tmpData);
      }

      console.log('collected data:', slideData)

      return slideData;
    },
  },
};
</script>

<style lang="scss">
#template-editor-vforms {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 70%;
  height: 90%;
  background-color: var(--vform-editor-ui-quinary-color);
  z-index: 655;
  box-shadow: -10px 5px 10px #1f1f1f;
  color: #fff;
}
.block-drop-not-allowed {
  cursor: not-allowed !important;
}
</style>

<style lang="scss" scoped>

.state-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(80, 188, 255, 0.4);
  border: 1px dashed #444;
  border-radius: 3px;
  padding: 5px;
  z-index: 180;
  pointer-events: none;
}

.collapsible-button {
  cursor: pointer;
  padding-top: 0;
  padding-bottom: 0;
  transition: all 300ms ease;
  position: absolute;
  width: 60px;
  height: 60px;
  z-index: 1;

  .hover-label {
    position: absolute;
    left: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 1);
    padding: 3px;
    border-radius: 3px;
    display: inline-block;
    min-width: 200px;
    opacity: 0;
    pointer-events: none;
    font-size: 0.9rem;
    z-index: 5;
  }

  .icon-container {
    z-index: 4;
    border-radius: 0;
    background-color: var(--ccdefault-normal-button-hover-background);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    position: relative;
    width: 60px;
    height: 60px;

    &:hover {
      opacity: 1;
      background-color: var(--ccdefault-normal-button-hover-background);

      .hover-label {
        animation: forwards 0.5s fadeIn;
        animation-delay: 0.8s;
      }
    }

    &.active {
      background-color: var(--vform-editor-ui-secondary-color);
    }

    &.bg-highlight {
      background-color: $highlight;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.9rem !important;
  }

  .horizontal {
    height: 30px;
    width: 60px;
  }

  .vertical {
    margin-top: 10px;
    height: 60px;
    width: 30px;
  }

  &-top {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    top: 100%;
    width: 100%;
  }

  &-bottom {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    bottom: 100%;
    width: 100%;
  }

  &-left {
    left: 100%;
    right: unset;
  }

  &-right {
    width: 30px;
    right: 100%;
    left: unset;
  }

}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.collapse-top {
  transform: translateY(-100%);
}

.slideToTop {
  animation: slideTop 0.3s ease;
}

.collapse-bottom {
  transform: translateY(100%);
}

.collapse-panel-fadeout {
  opacity: 0;
  animation: fadeOut 0.3s ease;
}

.collapse-left {
 transform: translateX(-100%);
}

.collapse-right {
  transform: translateX(100%);
}

.slide {
  &-upwards {
    animation: forwards slideTop 0.3s ease;
  }
  &-upwards-reverse {
    animation: forwards slideTopReverse 0.3s ease;
  }
  &-downwards {
    animation: forwards slideBottom 0.3s ease;
  }
  &-downwards-reverse {
    animation: forwards slideBottomReverse 0.3s ease;
  }
  &-leftwards {
    animation: forwards slideLeft 0.3s ease;
  }
  &-leftwards-reverse {
    animation: forwards slideLeftReverse 0.3s ease;
  }
  &-rightwards {
    animation: forwards slideRight 0.3s ease;
  }
  &-rightwards-reverse {
    animation: forwards slideRightReverse 0.3s ease;
  }
}

@keyframes slideTop {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes slideTopReverse {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slideBottom {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

@keyframes slideBottomReverse {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}

@keyframes slideRightReverse {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes slideLeftReverse {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

.element-display {
  border: solid 1px transparent;
  cursor: pointer;
}

@keyframes slideIn {
  from {
    left: 0;
    width: 0;
  }
  to {
    width: 2vw;
    left: -3vw;
  }
}

.slide-header {
  width: 100%;
  z-index: 15;
  margin-top: 15px;
  color: var(--vform-on-bright-color);
}
.panel-form-wrapper {
  font-size: 16px;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 20;
  cursor: auto;
  display: flex;

  &.inactive {
    display: none;
  }
}

/***
---------------
Media Queries
--------------
*/
// X-Small devices (portrait phones, less than 576px)
@media (min-width: 576px) {
  .panel-form-wrapper {
    font-size: 12px;
    // background-color: red;
    .form {
      min-width: 200px;
    }
  }
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 768px) {
  .panel-form-wrapper {
    font-size: 13px;
    // background-color: yellow;
  }
}
// Large devices (desktops, less than 1200px)
@media (min-width: 992px) {
  .panel-form-wrapper {
    font-size: 14px;
    // background-color: blue;
  }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 1200px) {
  .panel-form-wrapper {
    font-size: 14px;
    // background-color: brown;
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1400px) {
  .panel-form-wrapper {
    font-size: 16px;
    // background-color: violet; // done
  }
}
// XXX-Large devices (larger desktops, 1400px and up)
@media (min-width: 2400px) {
  .panel-form-wrapper {
    font-size: 18px;
    // background-color: black;
  }
}
</style>
