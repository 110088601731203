<template>
    <span/>
</template>

<script>
    export default {
        name: "ColorContrastMixinJsVue",
        methods: {
            /**
             * @params col
             * */
            lightenDarkenColor(col,amt) {
              col = col.substring(1);
              //var num = parseInt(col,16);

              let c = col.match(/.{1,2}/g);
              //console.log(c);

              const r = parseInt(c[0], 16);
              const g = parseInt(c[1], 16);
              const b = parseInt(c[2], 16);

              let r1 = Math.ceil(Math.min(r * amt, 255));
              let g1 = Math.ceil(Math.min(g * amt, 255));
              let b1 = Math.ceil(Math.min(b * amt, 255));

              return '#' + this.componentToHex(r1) + this.componentToHex(g1) + this.componentToHex(b1);
            },
            componentToHex(c) {
              const hex = c.toString(16);
              return hex.length == 1 ? "0" + hex : hex;
            },
            getContrast: function (hexcolor, brightDarkOnly = false){
                if(!hexcolor) {
                    return '';
                }
                // If a leading # is provided, remove it
                if (hexcolor.slice(0, 1) === '#') {
                    hexcolor = hexcolor.slice(1);
                }

                // If a three-character hexcode, make six-character
                if (hexcolor.length === 3) {
                    hexcolor = hexcolor.split('').map(function (hex) {
                        return hex + hex;
                    }).join('');
                }

                // Convert to RGB value
                var r = parseInt(hexcolor.substr(0,2),16);
                var g = parseInt(hexcolor.substr(2,2),16);
                var b = parseInt(hexcolor.substr(4,2),16);

                // Get YIQ ratio
                var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

                if(brightDarkOnly) {
                  return (yiq >= 128) ? 'bright' : 'dark';
                }

                // Check contrast
                return (yiq >= 128) ? '#2b2a2a' : '#fff';
            },
            hexToRGBA(hex) {
              const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])([a-f\d]?)$/i;
              hex = hex.replace(shorthandRegex, function (m, r, g, b, a) {
                return r + r + g + g + b + b + (a ? a + a : '');
              });

              const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);
              if (result) {
                const alpha = result[4] !== undefined ? parseInt(result[4], 16) / 255 : 1;
                return `rgba(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})`;
              }

              return null;
            },
            /**
             * @param threshold {Int} Threshold in percentage as a float value (0.5 for 50%)
             * */
            isTransparent(color, threshold) {
              // Check if color is in hex format
              if (color.startsWith("#")) {
                color = this.hexToRGBA(color);
              }

              if(!color) {
                return;
              }

              const rgbaMatch = color.match(/rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d?\.?\d+)\)/);
              if (!rgbaMatch) {
                throw new Error('Invalid format for RGBA color');
              }

              const alphaValue = parseFloat(rgbaMatch[4]);
              if (isNaN(alphaValue)) {
                throw new Error('Invalid alpha value');
              }

              return alphaValue < threshold;
            },
            isBrightColor: function (hexcolor){
              if(!hexcolor) {
                return '';
              }
              // If a leading # is provided, remove it
              if (hexcolor.slice(0, 1) === '#') {
                hexcolor = hexcolor.slice(1);
              }

              // If a three-character hexcode, make six-character
              if (hexcolor.length === 3) {
                hexcolor = hexcolor.split('').map(function (hex) {
                  return hex + hex;
                }).join('');
              }

              // Convert to RGB value
              var r = parseInt(hexcolor.substr(0,2),16);
              var g = parseInt(hexcolor.substr(2,2),16);
              var b = parseInt(hexcolor.substr(4,2),16);

              // Get YIQ ratio
              var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

              return yiq >= 128;
            }
        },
    }
</script>

<style scoped>

</style>