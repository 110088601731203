var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"wrapper",class:['panel-form-wrapper', 'active',
      _vm.state.hotspotPopupVisible ? 'step-panel-in-background' : ''],style:({
        width: _vm.width ? _vm.width + 'px !important' : '100%',
        height: _vm.height ? _vm.height + 'px !important' : '100%',
      }),attrs:{"id":_vm.step.uuid}},[(_vm.vformConfig.logID && !_vm.editorMode)?[(_vm.$store.getters.getvFormMode === _vm.vformModes.TEST)?_c('div',{staticClass:"state-overlay"},[_vm._v(" "+_vm._s(_vm.$t('TestMode'))+" ")]):(_vm.$store.getters.loggingIsDisabled)?_c('div',{staticClass:"state-overlay"},[_vm._v(" "+_vm._s(_vm.$t('TestMode'))+" ")]):_vm._e()]:_vm._e(),_c('portal',{ref:"form-popup",attrs:{"to":"form-popup"}}),_vm._l((_vm.getStepPanels()),function(panel,panelIndex){return (_vm.organizationId && _vm.projectId)?_c('panel',{key:'panel-' + panel.uuid,ref:panel.uuid,refInFor:true,attrs:{"id":'panel-' + panel.uuid,"panel-index":panelIndex,"panel":panel,"step":_vm.step,"editable":_vm.editable,"show-button-container":!!_vm.showButtonContainer(panel, panelIndex),"editor-mode":_vm.editorMode,"state":_vm.state,"form-settings":_vm.formSettings,"bottom-elements":_vm.getPanelElements(panel, 'bottomDropZone', false),"content-elements":_vm.getPanelElements(panel, 'bottomDropZone', true),"next-button-allowed":_vm.nextButtonAllowed,"project-info":{
           name: _vm.projectName,
           projectId: _vm.projectId,
           organizationId: _vm.organizationId
         },"teams":_vm.teams,"v-s-t-a-g-e-context":_vm.vSTAGEContext,"globals":_vm.globals,"steps":_vm.steps,"step-form-data":_vm.stepFormData,"last-slide-active":_vm.lastSlideActive,"is-current-step":_vm.isCurrentStep,"active-mode":_vm.activeMode},on:{"switchToPanelEdit":_vm.switchToPanelEdit,"storeData":_vm.storeData,"increasePostLoadingCounter":_vm.increasePostLoadingCounter,"signOut":_vm.signOut,"showElementProperties":_vm.showElementProperties,"editTemplate":function (el) {_vm.$emit('editTemplate', el)},"edit":_vm.showElementProperties,"removeBlock":function (el) {_vm.$emit('removeBlock', el)},"goToSlide":_vm.goToSlide,"goToStep":_vm.goToStep,"goToProject":_vm.goToProject,"goToProjectSlide":_vm.goToProjectSlide,"next":_vm.doNext,"historyBack":function($event){return _vm.$emit('historyBack')},"goToFirstSlide":_vm.goToFirstSlide,"goToLastSlide":_vm.goToLastSlide,"goToFirstStep":_vm.goToFirstStep,"goToLastStep":_vm.goToLastStep,"checkout":_vm.checkout}}):_vm._e()})],2)}
var staticRenderFns = []

export { render, staticRenderFns }