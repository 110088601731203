//import Vue from 'vue'
//import i18n from "../translation";
const state = {
    teamPermissions: ['writeTeam', 'deleteTeam', 'readTeam', 'createTeam']
};
const getters = {
    isSuperAdmin: (state, rootGetters) => {
        return rootGetters.getCurrentUserId === '8a26c307-0b45-4420-b3cc-16db98bbfe7d';
    },
    isSFXBilling: (state, rootGetters) => {
        const members = [
            'a3325944-801c-4879-bec8-6d4269b8793c',
            '6fb4fa48-f8a7-4873-bbc8-c8fcb11358d8',
            '01c8252f-affd-4c1b-a41b-1542d10fa660',
            'ad80afe1-681e-4675-a26d-bace0ff91a10',
            '15455d3e-dfa6-4605-aa2a-22e1aa78c3ff',
            //'dff9c40f-0106-41b1-a6cb-48083b924e9f',
            '46a8e55e-aeed-4816-95e0-42ea3869ad76',
            'b64031c8-baa3-4461-99e1-ce263c0692f0',
            'b4570924-2290-4d8f-94fa-a177401e754b',
            '7eb60669-0729-4d48-9f15-51b93cfd1f99',
            'dff9c40f-0106-41b1-a6cb-48083b924e9f'
        ];
        return members.includes(rootGetters.getCurrentUserId);
    },
    isSFXMember: (state, rootGetters) => {
        const members = [
            'a3325944-801c-4879-bec8-6d4269b8793c',
            '6fb4fa48-f8a7-4873-bbc8-c8fcb11358d8',
            '01c8252f-affd-4c1b-a41b-1542d10fa660',
            'ad80afe1-681e-4675-a26d-bace0ff91a10',
            '15455d3e-dfa6-4605-aa2a-22e1aa78c3ff',
            //'dff9c40f-0106-41b1-a6cb-48083b924e9f',
            '46a8e55e-aeed-4816-95e0-42ea3869ad76',
            'b64031c8-baa3-4461-99e1-ce263c0692f0',
            '6e58b591-4507-47d7-93b7-5ea32b09f968',
            '2aa0062f-c98f-43ea-993a-9294417d6f19',
            '8edbb421-b816-4bb9-914f-6161a3b1f2ad'
        ];
        return members.includes(rootGetters.getCurrentUserId);
    },
    idIsSFXMember: () => (id) => {
        const members = [
            'a3325944-801c-4879-bec8-6d4269b8793c',
            '6fb4fa48-f8a7-4873-bbc8-c8fcb11358d8',
            '01c8252f-affd-4c1b-a41b-1542d10fa660',
            'ad80afe1-681e-4675-a26d-bace0ff91a10',
            '15455d3e-dfa6-4605-aa2a-22e1aa78c3ff',
            'dff9c40f-0106-41b1-a6cb-48083b924e9f',
        ];
        return members.includes(id);
    },
    isSuperOrg: () => (id) => {
        return id === '7bc33721-622f-4b87-b9a4-333170f9fa93';
    },
    getSuperOrg: () => {
        return '7bc33721-622f-4b87-b9a4-333170f9fa93';
    },
    checkIsOwnUser: (state, rootGetters) => (resourceId) => {
        return rootGetters.getCurrentUserId === resourceId;
    },
    checkIsTeamPermission: (state) => (op) => {
        return state.teamPermissions.includes(op);
    }
};
const mutations = {};
const actions = {
    /**
     * Gets roles with selected permisson
     * @param op – an operation to be permitted (equals the permission-name)
     * @param getters - standard vuex
     * @param dispatch - standard vuex
     * @return array of role-names
     * */
    getRolesWithPermission: async ({getters, dispatch}, op) => {
        let rolePermissions = [];
        if(!Object.keys(getters.getRolesByListName('roleDefault')).length) {
            await dispatch('loadRoles', {
                ignoreIndex: true,
                add: {
                    include: {
                        roleDefault: ['permissions'],
                    }
                },
                listName: 'roleDefault'
            }).then(roles => {
                rolePermissions = roles;
            });
        } else {
            rolePermissions = getters.getRolesByListName('roleDefault');
            rolePermissions = Object.keys(rolePermissions).map(id => {
                return rolePermissions[id];
            });
        }
        const filtered = rolePermissions.filter(item => {
            const res = item.permissions.filter(permission => {
                return permission.name === op;
            });
            return res.length;
        });
        return filtered.map(item => {return item.name});
    },
    /**
     * Gets the teams with specific roles for the currently logged in user
     * @param roles array of names (NOT displayName) of the role
     * */
    getUserTeamsWithRoles: async ({getters}, roles) => {
        return new Promise(resolve => {
            const userTeams = getters.getUserTeams();
            if(!userTeams) {
                return resolve([]);
            }
            return resolve(userTeams.filter(item => {
                return roles.includes(item.members.role);
            }));
        });
    },

    /**
     * Gets the organizations with specific roles for the currently logged in user
     * @param roles array of names (NOT displayName) of the role
     * */
    getUserOrganizationsWithRoles: async ({getters}, roles) => {
        return new Promise((resolve) => {
            const userOrgs = getters.getUserOrganizations();
            if(!userOrgs) { return resolve(false);}
            return resolve(userOrgs.filter(item => {
                return roles.includes(item.members.role) && item.visibility > 0;
              }));
        });
    },
    /**
     * Gets the user organizations for the currently logged in user with the selected op
     * @param dispatch - standard vuex
     * @param args: op - permissionstring to compare (e.g. write)
     * */
    getUserOrganizationsWithPermission: async ({dispatch}, args) => {
        const op = args.op;
        const roles = await dispatch('getRolesWithPermission', op);
        if(roles) {
          return await dispatch('getUserOrganizationsWithRoles', roles);
        }
        return [];
    },
    userIsOrganizationMember: async ({getters}, args) => {
        if(getters.isSuperAdmin) {
            return new Promise(resolve => {
                resolve(true);
            });
        }
        const { organizationId } = args;
       const userOrgs = getters.getUserOrganizations();
       if(userOrgs) {
           return userOrgs.filter(item => {return item.id === organizationId}).length > 0;
       }
       return false;
    },

    /**
     * Gets the teams for the currently logged in user with the selected op
     * @param dispatch - standard vuex
     * @param args: op - permissionstring to compare (e.g. write)
     * */
    getUserTeamsWithPermission: async ({dispatch}, args) => {
        const op = args.op;
        const roles = await dispatch('getRolesWithPermission', op);
        if(roles) {
          return await dispatch('getUserTeamsWithRoles', roles);
        }
        return [];
    },

    /***
     * checks if user has permission for an operation on a certain organization
     * @param args: op - the operation (e.g. write)
     * @param args: organizationId - the organization in which to check for (if none is given it returns true if there is at least 1 org with that op)
     * @param dispatch - standard vuex
     * */
    checkOrgAccess: async ({dispatch}, args) => {
        const {op, organizationId, opOnly} = args;
        const roles = await dispatch('getRolesWithPermission', op);
        const userOrgs = await dispatch('getUserOrganizationsWithRoles', roles);
        return new Promise(resolve => {
            // check organization role / access
            if (!userOrgs) {
                return resolve(false);
            }

            if(organizationId) {
                const hasUserOrg = userOrgs.filter(item => {return item.id === organizationId});
                resolve(hasUserOrg.length > 0);
            }
            else if(opOnly) {
                resolve(userOrgs.length > 0);
            } else { resolve(false); }
        });
    },
    /**
     * checks if user has permission for a specific op on a given set of teams
     * @param args: op* - the operation (e.g. write)
     * @param args: teams* - an array of teams (if none are given it returns true if there is at least 1 team with the selected op)
     * @param dispatch – standard vuex
     * */
    checkTeamAccess: async ({dispatch}, args) => {
        const {op, teams, opOnly} = args;
        const roles = await dispatch('getRolesWithPermission', op);
        const userTeams = await dispatch('getUserTeamsWithRoles', roles);
        return new Promise(resolve => {
            // check organization role / access
            if (!userTeams) {
                return resolve(false);
            }
            const targetTeamIds = teams.map(item => {return item.id});
            if(!opOnly) {
                const hasUserOrg = userTeams.filter(item=> {return targetTeamIds.includes(item.id)});
                resolve(hasUserOrg.length > 0);
            }
            else {
                resolve(userTeams.length > 0);
            }
        });
    },
    /**
     * Checks whether a user has a permission for an op on a given organization and/or teams
     * @param args: op* read/write/delete/create
     * @param args: teams an array of id's
     * @param args: organizationId
     * @param args: opOnly - Boolean, if set true, it will just check if user can make the op anywhere at all
     * @param dispatch - standard vuex
     * @param getters - standard vuex
     * */
    checkTeamOrgPermission: async ({dispatch, getters}, args) => {
        const { op, teams, organizationId, opOnly, orgPermissionOnly } = args;
        
        if(getters.isSuperAdmin) {
            return new Promise(resolve => {
                resolve(true);
            });
        }
        // temporary hack to allow special perms for sfx-users
        if(args.allowSFXUsers) {
            if(getters.isSFXMember) {
                 return new Promise(resolve => {
                    resolve(true);
                });
            }
        }
        if(args.allowSFXBilling) {
            if(getters.isSFXBilling) {
                return new Promise(resolve => {
                    resolve(true);
                });
            }
        }
        if(await dispatch('checkOrgAccess', {op: op, organizationId: organizationId, opOnly: opOnly})) {
            return true;
        }
        if(orgPermissionOnly) {return false;}
        return await dispatch('checkTeamAccess', {op: op, teams: teams ? teams : [], opOnly: opOnly})
    },

    /**
     * Checks if the organization has use permission on exportProfiles service
     * */
    checkIfExportProfileFeature: async ({dispatch}, args) => {
        let {organizationId} = args;
        const searchName = 'exportProfiles';
        return dispatch('clientGetAccessibleServices', {id: organizationId}).then(async orgServices => {
            return orgServices && orgServices.filter(item => {return item.name === searchName && item.permission[0].relation.state.toString() === "1"}).length;
        })
    },
    checkIfService: async ({dispatch}, args) => {
        let {organizationIds, serviceName} = args;
        let hasAccess = false;
        for(let i = 0; i < organizationIds.length; i++) {
            const organizationId = organizationIds[i];
            await dispatch('clientGetAccessibleServices', {id: organizationId}).then(async orgServices => {
                hasAccess =  orgServices && orgServices.filter(item => {
                    return item.name === serviceName && item.permission[0].relation.state.toString() === "1"}
                ).length;
            })
            if(hasAccess) {
                return true;
            }
        }
        return false;
    },
};
export default {
    state, getters, mutations, actions
}
