<template>
  <div>
    <label v-if="!templateEditing" class="vform-editor-label section">Question</label>
    <!--ADD DIX TRANSLATION-->
    <div
        v-for="(key, index2) in Object.keys(element.label.dix)"
        v-if="!templateEditing && $store.getters.getSelectedFormLanguage === key"
        :key="index2 + 1000000000 + forceReRenderKey"
        class="dflex"
    >
      <div class="col p-0">
                <textarea
                    v-model="element.label.dix[key]"
                    :placeholder="
                        key === 'Unknown'
                            ? placeholders.LONG
                            : $t('vform.translationMessage', {
                                  key: key,
                                  content: element.label.dix['Unknown'],
                              })
                    "
                    :title="element.label.dix['Unknown']"
                    class="form-text v-form-label form-text-dark"
                    rows="300"
                    type="text"
                />
        <div class="col row mt-2">
          <div class="col-12 pl-0 dflex">
            <label :for="'chkNoQuestion' + $vnode.key" class="container vform-editor-body"
            >{{ $t("noQuestion") }}
              <input
                  :id="'chkNoQuestion' + $vnode.key"
                  v-model="element.noQuestion"
                  type="checkbox"
              >
              <span class="checkmark"/>
            </label>
          </div>
        </div>

        <label class="vform-editor-label section">Answers</label>
        <label class="vform-editor-label section" style="float: right">Correct</label>
        <!--ANSWERS-->
        <div
            v-for="(item, index4) in element.options"
            :key="index4 + 98239283 + forceReRenderKey"
            class="dflex mb-1"
        >
          <div class="col pl-0 pr-0 dflex">
            <label class="vform-editor-label section">{{ $t("Option") }} {{ index4 + 1 }}</label>
            <div class="w-100" />
            <div class="col pl-0">
              <textarea
                  v-model="item.text.dix[key]"
                  :placeholder="key === 'Unknown'? placeholders.SHORT: $t('vform.translationMessage', {key: key,content: item.text.dix['Unknown']})"
                  :title="item.text.dix['Unknown']"
                  class="form-text v-form-label form-text-dark mb-2"
                  rows="300"
                  type="text"
              />
              <div class="icon-delete-option" style="position: absolute; top: 0px; right: 16px">
                <span @click="removeOption(index4)">
                  <icon :size="'1.8'" type="fat-square-minus"/>
                </span>
              </div>
            </div>
            <div class="col-auto pl-0 pr-0">
              <div v-if="true" class="col-12 dflex row">
                <label
                    :for="'chkCorrect' + $vnode.key + index4"
                    class="container"
                    style="padding-left: 5px !important"
                >
                  <input
                      :id="'chkCorrect' + $vnode.key + index4"
                      v-model="item.isCorrectAnswer"
                      type="checkbox"
                      @change="correctAnswerUpdated(item)"
                  >
                  <span class="radio"/>
                </label>
              </div>
            </div>
            <div class="w-100" />
            <div class="w-100 pl-0 pr-0">
              <div v-if="true" class="col-12 dflex row">
                <label
                    :for="'chkPreSelected' + $vnode.key + index4"
                    class="container"
                    style="padding-left: 5px !important"
                ><div class="ml-4">{{ $t('isPreSelected') }}</div>
                  <input
                      :id="'chkPreSelected' + $vnode.key + index4"
                      v-model="item.isPreSelected"
                      type="checkbox"
                  >
                  <span class="checkmark"/>
                </label>
              </div>
            </div>
            <v-form-go-to-component
                class="w-100 mb-5"
                v-if="$store.getters.getSelectedFormLanguage"
                :organization-id="organizationId"
                :slides="slides"
                :project-id="projectId"
                :element="item"
                :steps="steps"
                default-action="NoAction"
                dix-selector="text"
            />
          </div>
        </div>
        <div class="w-100 mt-1 text-right col" style="padding-right: 34px">
                    <span @click="addOption(key)">
                        <icon :size="'2.0'" type="fad-square-plus"/>
                    </span>
        </div>
      </div>
    </div>
    <template v-if="!element.emphasized">
      <label class="vform-editor-label section">Background Color</label>
      <set-by-template v-if="!templateEditing && !isObjectEmpty(selectedTemplate)" />
      <div
          v-else
          :style="{
                width: '20px',
                height: '20px',
                backgroundColor: element.backgroundColor
                    ? element.backgroundColor
                    : '',
                border: element.backgroundColor ? '' : '1px dashed rgba(255,255,255,0.5)'
            }"
          class="clickable"
          @click="(event) => {
            setColorPickrId(event, 'backgroundColor');
          }"
      />
      <label class="vform-editor-label section">Font Color</label>
      <set-by-template v-if="!templateEditing && !isObjectEmpty(selectedTemplate)" />
      <div
          v-else
          :style="{
                width: '20px',
                height: '20px',
                backgroundColor: element.fontColor
                    ? element.fontColor
                    : '',
                border: element.fontColor ? '' : '1px dashed rgba(255,255,255,0.5)'
            }"
          class="clickable"
          @click="(event) => {
            setColorPickrId(event, 'fontColor');
          }"
      />
      <color-picker
        v-if="!templateEditing"
        v-show="colorPickrId"
        :key="colorPickrId"
        ref="colorpickr"
        :popup-style="true"
        :show-save-button="true"
        :value="element[colorPickrId] ? element[colorPickrId] : '#000'"
        class="step-settings-color-pickr"
        :template-editing="templateEditing"
        @changeColor="(color) => { setItemColor(colorPickrId, color, false); }"
        @close="() => { colorPickrId = null;}"
        @save="(color) => { setItemColor(colorPickrId, color); }"
      />
      <portal to="colorPickerOverlay" :key="index + colorPickrId" v-else>
          <color-picker
          v-show="colorPickrId"
          ref="colorpickr"
          :key="colorPickrId"
          :popup-style="true"
          :show-save-button="true"
          :value="element[colorPickrId] ? element[colorPickrId] : '#fff'"
          class="step-settings-color-pickr"
          @changeColor="(color) => { setItemColor(colorPickrId, color, false);}"
          @close="() => { colorPickrId = null;}"
          @save="(color) => { setItemColor(colorPickrId, color); }"
          />
      </portal>
      <div
          v-if="(!selectedTemplate.fontColor && !selectedTemplate.backgroundColor) && (element.backgroundColor || element.fontColor)"
          class="settings-button d-inline-block mt-2"
          @click="element.backgroundColor = null; element.fontColor = null;">
        {{ $t('resetColor') }}
      </div>
      <br>
    </template>

    <label v-if="!templateEditing" class="vform-editor-label section">Options</label>
    <div v-if="!templateEditing" class="col row mt-2">
      <div class="col-12 pl-0 dflex">
        <label
            :for="'chkEmphasized' + $vnode.key"
            class="container vform-editor-body"
        >{{ $t("emphasized") }}
          <input
              :id="'chkEmphasized' + $vnode.key"
              v-model="element.emphasized"
              type="checkbox"
          >
          <span class="checkmark"/>
        </label>
      </div>
      <div class="col-12 pl-0 dflex">
        <label
            :for="'chkRequired' + $vnode.key"
            class="container vform-editor-body"
        >{{ $t("isRequired") }}
          <input
              :id="'chkRequired' + $vnode.key"
              v-model="element.isRequired"
              type="checkbox"
          >
          <span class="checkmark"/>
        </label>
      </div>
      <div class="col-12 pl-0 dflex">
        <label :for="'chkCorrect' + $vnode.key" class="container"
        >{{ $t("validateCorrectness") }}
          <input
              :id="'chkCorrect' + $vnode.key"
              v-model="element.validateCorrectness"
              type="checkbox"
          >
          <span class="checkmark"/>
        </label>
      </div>
    </div>
    <!--DIX-->
    <!--FOR EACH TRANSLATION ONE FIELD-->
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import {vFormControls, placeholders} from "@/enum";
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";
import ColorPicker from "../widgets/editors/ColorPicker";
import vFormGoToComponent from "@/components/vForm/vFormGoToComponent";
import {v4 as uuidv4} from "uuid";
import SetByTemplate from "@/components/vForm/SetByTemplate.vue";
export default {
  name: "vFormCHECKBOXES",
  components: {
    SetByTemplate,
    Icon,
    ColorPicker,
    vFormGoToComponent
  },
  mixins: [vFormElementMixin],
  props: {
    stepNo: {type: Number, required: true},
    organizationId: {type: String, default: ""},
    slides: {type: Array, default: null},
    steps: {type: Array, default: null},
    projectId: {type: String, required: true},
  },
  data() {
    return {
      forceReRenderKey: 0,
      editImage: "",
      vFormControls: vFormControls,
      placeholders: placeholders,
    };
  },
  beforeMount() {
    if (!this.element.options) {
      this.addOption("Unknown");
    }
    if(!this.element.noQuestion) {
      this.$set(this.element, "noQuestion", false)
    }
  },
  methods: {
    removeOption(index) {
      this.element.options.splice(index, 1);
    },
    addOption(lang) {
      if (!this.element.options) {
        this.$set(this.element, "options", []);
      }
      this.element.options.push({
        uuid: uuidv4(),
        isCorrectAnswer: false,
        isPreSelected: false,
        isChecked: false,
        text: {
          dix: {
            [lang]: "",
          },
          imageDix: {
            [lang]: "",
          },
        },
      });
      this.forceReRenderKey++;
    },
    correctAnswerUpdated(item) {
      if (item.isCorrectAnswer) {
        this.element.options.forEach((option) => {
          if (option !== item) {
            option.isCorrectAnswer = false;
          }
        });
      }

      this.forceReRenderKey++;
    },
  },
};
</script>
