<template>
  <div class="dataset">
    <popup
        v-if="showDeletePrompt"
        @close="() => {showDeletePrompt = false;}"
    >
      <delete-prompt
          slot="popupMainContent"
          @abort="() => {showDeletePrompt = false; indexToDeleteConfirm = null;}"
          @confirm="() => { showDeletePrompt = false; deleteDataRowFinally();}"
      />
    </popup>
    <popup
        v-if="showLeavePagePrompt"
        @close="() => {showLeavePagePrompt = false;}"
    >
      <delete-prompt
          slot="popupMainContent"
          custom-message="reloadMappingsWarningLoseChanges"
          @abort="() => {showLeavePagePrompt = false;}"
          @confirm="() => {showLeavePagePrompt = false; loadMappings();}"
      />
    </popup>
    <div class="row">
      <div class="cell col-3 pl-0">
        <h4 class="source-title">{{ $t("matMapperLoadFromServer") }}</h4>
        <span>{{ $t("type.MetaSet") }}</span>
        <select
            v-model="selectedMetaSetId"
            class="custom-select col-12 mb-2"
            @change="loadMetaFields()"
        >
          <option
              v-for="(item, index) in $store.getters.getMetaSets"
              :key="index"
              :value="item.id"
          >
            {{ item.description ? item.description : item.name }}
          </option>
        </select>

        <span>{{ $t("metaSetMaterialField") }}</span>
        <select v-model="selectedMetaFieldId" class="custom-select col-12">
          <option
              v-for="(item, index) in $store.getters.getMetaFieldsByListName(selectedMetaSetId)"
              :key="index"
              :value="item.id"
          >
            {{ item.description ? item.description : item.name }}
          </option>
        </select>
        <div
            slot="buttonsRight"
            :deactivated="disableLoadFromMetaSets"
            class="settings-button d-inline-block mb-3 mt-3"
            @click="loadMaterialNamesFromMetaSets"
        >
          {{ $t("loadMaterials") }}
          <icon type=""/>
        </div>
      </div>

      <div class="cell col-5 explanation">
        <h4 class="source-title mt-2">{{ $t("How to use") }}</h4>
        <ol>
          <li>Import your material meta data via XML or CSV import into your 3D item<br />(your model->Update->update meta data)</li>
          <li>Then come here to the material mapper, load the metaset you created and the field containing the material info</li>
          <li>Now for each of your materials select which material on vHUB should be mapped on one of your materials</li>
          <li>When you're done, don't forget to save and then go to<br />your-model->Update->apply material mapping</li>
        </ol>
        <!--display a box explaining the process-->
      </div>
    </div>
    <div class="col-9">
      <div class="row">
        <Button class="icon-button" icon="redo" type="reload" @click="reload"/>
        <!-- <Button
                slot="buttonsRight"
                class="mb-3 mt-3 mr-3"
                @click="cancelChanges"
                :deactivated="!hasUnsavedItems"
            > -->
        <pagination
            id="MaterialList"
            ref="pagination"
            slot="mainContentButtons"
            :limit="limit"
            :local-pagination="true"
            :total="mappings.length"
            @setActive="showPageItems"
        />
      </div>

      <div class="row table-header">
        <div class="cell col-5 center-vertical pl-3">
          {{ $t("materialName") }}
        </div>
        <div class="cell col-5 center-vertical pl-1">
          {{ $t("materialRemapWith") }}
        </div>
        <div class="cell col-2 center-vertical"></div>
      </div>
      <div
          v-for="(item, index) in mappings"
          v-if="item.visible"
          :key="index"
          class="row pl-3 table-row"
      >
        <div class="cell col-5 pr-2">
          <div
              v-if="!item.editMode"
              class="edit-trigger mr-3 center-vertical"
              @click="editRow(index)"
          >
            <icon type="edit"/>
          </div>

          <span v-if="!item.editMode" class="center-vertical">{{ item.data[0] }}</span>
          <div
              v-if="item.editMode"
              class="edit-trigger mr-3 center-vertical"
              @click="cancelEdit(index)"
          >
            <icon type="close"/>
          </div>
          <input
              v-if="item.editMode"
              :id="item.id"
              :ref="'entry'"
              v-model="item.data[0]"
              class="form-text"
              type="text"
              @change="markRowAsDirty(index)"
          />
        </div>
        <div class="cell col-5">
          <select
              v-model="item.data[1]"
              :class="{ dirty: item.dirty ? 'dirty' : '' }"
              class="custom-select"
              @change="markRowAsDirty(index)"
          >
            <option
                v-for="(material, matIndex) in materials"
                :key="matIndex"
                :value="material.id"
                class="form-text mb-2"
            >
              {{ material.displayName }}
            </option>
          </select>
        </div>
        <div class="col-2">
          <div
               class="settings-button d-inline-block row-delete-button"
               @click="deleteRow(index)">
            {{ $t("Delete") }}
            <icon class="ml-1" type="trash"/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <div
              slot="buttonsLeft"
              class="settings-button d-inline-block mb-3 mt-3 mr-3"
              @click="addMappingRow"
          >
            {{ $t("addrow") }}
            <icon size="0.9" type="plus"/>
          </div>
        </div>
        <div class="col-7 pr-0 flex-right">
          <div
              slot="buttonsRight"
              :deactivated="!hasUnsavedItems"
              class="settings-button d-inline-block mb-3 mt-3 mr-3"
              @click="cancelChanges"
          >
            {{ $t("abort") }}
            <icon class="ml-1" type="times"/>
          </div>
          <div
              slot="buttonsRight"
              :deactivated="!hasUnsavedItems"
              class="settings-button d-inline-block mb-3 mt-3"
              @click="saveAllRows"
          >
            {{ $t("save") }}
            <icon class="ml-1" type="save"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "../../forms/Button";
import Popup from "../../Popup";
import Icon from "../../Icon";
import DeletePrompt from "@/components/forms/DeletePrompt";
import {getColumnNames} from "@/components/fileProcessors/xmlWindchillSearchRes";
import {getData} from "@/components/fileProcessors/xmlWindchillSearchRes";
import {metaDataFileFormats} from "@/enum";
import Pagination from "../../Pagination";
import ArrayMixin from "@/components/mixins/ArrayMixin.js";

export default {
  name: "MaterialMapper",
  components: {
    Button,
    Popup,
    Icon,
    DeletePrompt,
    Pagination,
  },
  mixins: [ArrayMixin],
  props: {
    id: {type: String, required: true},
    limit: {type: Number, required: false, default: 10},
  },
  data() {
    return {
      mappings: [],
      materials: [],
      forceReRenderKey: 1,
      materialListName: "MaterialListForMapping",
      selectedMetaSetId: "",
      selectedMetaFieldId: "",
      showDeletePrompt: false,
      indexToDeleteConfirm: -1,
      organizationId: "",
      xmlColumns: [],
      xmlMaterialColumnIndex: null,
      xmlRows: [],
      xmlFormat: "none",
      xmlFormatOptions: {
        // none: {
        //     name: "none",
        // },
        ...metaDataFileFormats,
      },
      validationError: "",
      showLeavePagePrompt: false,
    };
  },
  computed: {
    disableLoadFromXml: function () {
      return this.validationError || !this.xmlMaterialColumnIndex;
    },
    disableLoadFromMetaSets: function () {
      return !this.selectedMetaSetId || !this.selectedMetaFieldId;
    },
    hasUnsavedItems: function () {
      return this.mappings.some((x) => x.dirty === true);
    },
    filteredMappings: function () {
      return this.mappings;
    },
  },
  watch: {},

  beforeMount() {
    this.xmlFormat = Object.keys(this.xmlFormatOptions)[0];
    this.organizationId = this.$store.getters.getMaterialmapperOrganization(this.id);
    this.loadMetaSets();
    this.loadMaterials();
    this.loadMappings();
  },
  methods: {
    loadMetaSets() {
      let args = {add: {}};
      args.add.filter = {default: "organizationId eq " + this.organizationId};
      this.$store.dispatch("loadMetaSets", args);
    },
    loadMetaFields() {
      this.$store.dispatch("loadMetaFields", {
        keep: {
          id: this.selectedMetaSetId,
        },
        listName: this.selectedMetaSetId,
      });
    },
    loadMaterialNamesFromMetaSets() {
      this.$store
          .dispatch("getMetaValues", {
            id: this.selectedMetaSetId,
            cid: this.selectedMetaFieldId,
          })
          .then((data) => {
            let rowsToAdd = [];
            for (let index = 0; index < data.length; index++) {
              const element = data[index];
              if (
                  !this.mappings.find((m) => m.data[0].toLowerCase() === element.value.toLowerCase()) &&
                  !rowsToAdd.find((m) => m.data[0].toLowerCase() === element.value.toLowerCase())
              ) {
                var row = {
                  id: 0,
                  data: {0: element.value, 1: ""},
                  editMode: false,
                  initialClientMaterialName: element.value,
                  dirty: true,
                };
                rowsToAdd.push(row);
              }
            }

            rowsToAdd.sort(this.objectComparer("initialClientMaterialName"));
            rowsToAdd.forEach((element) => {
              this.mappings.push(element);
            });

            this.$refs.pagination.goToLastPage();
          });
    },
    loadMaterials() {
      let args = {
        listName: this.materialListName,
      };
      args.add = {filter: {matWidget: "organizationId in '" + this.organizationId + "' '" + this.$store.getters.getSuperOrg + "'"}};
      this.$store.dispatch("loadMaterials", args).then((data) => {
        data.sort(this.objectComparer("name"));
        for (let index = 0; index < data.length; index++) {
          const element = data[index];
          this.materials.push({
            id: element.id,
            displayName: element.name,
          });
        }
      });
    },
    showPageItems(offset) {
      for (let index = 0; index < this.mappings.length; index++) {
        this.mappings[index].visible = false;
      }

      for (let index = offset; index < offset + this.limit; index++) {
        this.mappings[index].visible = true;
      }
    },
    reload() {
      if (this.hasUnsavedItems) {
        this.showLeavePagePrompt = true;
      } else {
        this.loadMappings();
      }
    },
    cancelChanges() {
      if (this.hasUnsavedItems) {
        this.showLeavePagePrompt = true;
      }
    },
    loadMappings() {
      let args = {
        listName: this.id,
        keep: {
          id: this.id,
          datasetId: this.id,
          limit: 100,
        },
        add: {
          sort: {
            [this.id]: "createdAt",
          },
        },
      };

      this.$store.dispatch("loadDatasetRows", args).then((data) => {
        var rows = [];

        data.forEach(function (item) {
          var row = {
            id: 0,
            data: {},
            editMode: false,
            initialClientMaterialName: item.data[0],
            visible: false,
            dirty: false,
          };
          row.id = item.id;
          Object.assign(row.data, item.data);
          rows.push(row);
        });

        rows.sort(this.objectComparer("initialClientMaterialName"));
        this.mappings = rows;
        this.showPageItems(0);
        this.$refs.pagination.setActive(0);
      });
    },
    markRowAsDirty(index) {
      this.mappings[index].dirty = true;
    },
    editRow(index) {
      this.mappings[index].editMode = true;
    },
    deleteRow(index) {
      this.showDeletePrompt = true;
      this.indexToDeleteConfirm = index;
    },
    deleteDataRowFinally() {
      if (this.indexToDeleteConfirm > -1) {
        var dataRow = this.mappings[this.indexToDeleteConfirm];
        this.$store
            .dispatch("deleteDatasetRow", {
              id: this.id,
              args: [dataRow.id],
              listName: this.id,
            })
            .then(() => {
              this.mappings.splice(this.indexToDeleteConfirm, 1);
              this.indexToDeleteConfirm = -1;
            });
      }
    },
    async saveAllRows() {
      for (let index = 0; index < this.mappings.length; index++) {
        const row = this.mappings[index];
        if (row.dirty || !row.id) {
          //save dirty or new
          await this.saveRow(index);
        }
      }

      this.loadMappings();
    },
    async saveRow(index) {
      var row = this.mappings[index];
      if (row.id) {
        //update
        let params = {
          id: this.id,
          data: {materialName: row.data[0], mappedMaterial: row.data[1]}, //replace by complete datarow
          datarowId: row.id,
          listName: this.id,
        };
        return this.$store.dispatch("updateDatasetRow", params).then(() => {
        });
      } else {
        //create
        return this.$store
            .dispatch("createDatasetRow", {
              id: this.id,
              data: row.data,
            })
            .then(() => {
            });
      }
    },
    cancelEdit(index) {
      this.mappings[index].data[0] = this.mappings[index].initialClientMaterialName;
      this.mappings[index].editMode = false;
    },
    addMappingRow() {
      let el = {
        data: {
          0: "",
          1: "",
        },
        editMode: true,
        initialClientMaterialName: "",
        visible: false,
        dirty: true,
      };
      this.mappings.push(el);
      this.$refs.pagination.goToLastPage();
    },

    async parseXmlBytes(xmlBytes) {
      const text = new TextDecoder("utf-8").decode(xmlBytes);
      this.validationError = null;
      try {
        const columns = await getColumnNames(text);
        if (columns) {
          this.xmlColumns = columns;
        }

        const objects = await getData(text);
        if (objects) {
          this.xmlRows = objects;
        }
      } catch (e) {
        this.xmlColumns = [];
        this.validationError = "The xml is not in a valid format";
      }
    },
    loadMaterialNamesFromXml() {
      let rowsToAdd = [];
      for (let index = 0; index < this.xmlRows.length; index++) {
        const element = this.xmlRows[index];
        var materialName = element.Attribute[this.xmlMaterialColumnIndex];
        if (materialName) {
          if (
              !this.mappings.find(
                  (m) => m.data[0].toLowerCase() === materialName.toLowerCase()
              ) &&
              !rowsToAdd.find(
                  (m) => m.data[0].toLowerCase() === materialName.toLowerCase()
              )
          ) {
            let row = {
              data: {0: materialName, 1: ""},
              editMode: false,
              initialClientMaterialName: materialName,
              dirty: true,
              visible: false,
            };
            rowsToAdd.push(row);
          }
        }
      }

      rowsToAdd.sort(this.objectComparer("initialClientMaterialName"));
      rowsToAdd.forEach((element) => {
        this.mappings.push(element);
      });

      this.$refs.pagination.goToLastPage();
    },
  },
};
</script>

<style lang="scss">
.tiny-dropzone {
  .square-image {
    padding-top: 150px !important;
  }

  .dropzone-wrapper {
    height: 150px;
  }

  .dropzone-outer {
    height: 150px;
  }
}
</style>

<style lang="scss" scoped>
.row-delete-button {
  margin-top: 3px;
}
.center-vertical {
  margin-top: auto;
  margin-bottom: auto;
}

label {
  display: block;
}

.cell {
  width: 400px;
  display: block;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.table-row .cell {
  display: flex;
}

.row {
  display: flex;
  margin: 10px 0 0 0;
  width: 100%;
}

.select {
  height: 40px;
  width: 100%;
  margin-bottom: 8px;
}

div.content {
  background-color: red !important;
}

.dirty {
  background-color: #ddd;
}

.col-5 {
  padding: 0px;
}
.explanation {
  background-color: var(--vform-editor-ui-secondary-color);
  border-radius: 2px;
}
.source-title {
  background-color: var(--vform-editor-ui-secondary-color);
  padding: 5px;
  border-radius: 2px;
}

.error {
  max-width: 100%;
}

.table-header {
  background-color: var(--vform-editor-ui-secondary-color);
  padding: 5px;
}

.table-row {
  display: flex;
  background: #222;
  padding: 5px;
}

.flex-right {
  justify-content: right;
  display: flex;
}
</style>
