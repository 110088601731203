<template>
  <div class="w-100">
    <info-panel
        :collapsible="true"
        class="vform-editor-body-bold"
        info-panel-header="Text"
        :initially-collapsed="false"
        preview-id="textElements"
    >
    <div class="block-container" slot="info">
      <!--todo: replace all divs through the block component-->
      <block
          :element-type="vFormControls.HEADLINE"
          :label="$t('vform.headline')"
          :state="state">
        <h3 class="mt-3 mb-0">
          {{ $t("vform.helloworld") }}
        </h3>
      </block>

      <block
        :element-type="vFormControls.WEBSITE"
        :label="$t('vform.website')"
        :state="state">
        <a
            target="_blank"
            class="mt-1 mb-0 dark-ui-primary"
        >http://example.com</a
        >
      </block>

      <block
          :element-type="vFormControls.TEXT"
          :label="$t('vform.textfield')"
          :state="state">
        <label>{{ placeholders.LONG }}</label>
      </block>
    </div>
    </info-panel>
    <info-panel
        :collapsible="true"
        class="vform-editor-body-bold"
        info-panel-header="Form"
        :initially-collapsed="false"
        preview-id="textElements"
    >
      <div class="block-container" slot="info">
        <block
            :element-type="vFormControls.RADIOBUTTONS"
            :label="$t('vform.singlechoice')"
            :state="state">
          <div class="pl-0 mt-2">
            <label class="container"
            >Option 1
              <input type="checkbox" disabled />
              <span class="radio" />
            </label>
          </div>
          <div class="pl-0">
            <label class="container"
            >Option 2
              <input
                  type="checkbox"
                  disabled
                  checked="checked"
              />
              <span class="radio" />
            </label>
          </div>
          <div class="pl-0">
            <label class="container mb-0"
            >Option 3
              <input type="checkbox" disabled />
              <span class="radio" />
            </label>
          </div>
        </block>

        <block
            :element-type="vFormControls.CHECKBOXES"
            :label="$t('vform.multichoice')"
            :state="state">
          <div class="pl-0 mt-2">
            <label class="container"
            >Option 1
              <input type="checkbox" disabled />
              <span class="checkmark" />
            </label>
          </div>
          <div class="pl-0">
            <label class="container"
            >Option 2
              <input
                  type="checkbox"
                  checked="checked"
                  disabled
              />
              <span class="checkmark" />
            </label>
          </div>
          <div class="pl-0">
            <label class="container mb-0"
            >Option 3
              <input
                  type="checkbox"
                  checked="checked"
                  disabled
              />
              <span class="checkmark" />
            </label>
          </div>
        </block>

        <block
            :element-type="vFormControls.SHORT_ANSWER"
            :label="$t('vform.shortanswer')"
            :state="state">
          <textarea rows="3" cols="" class="form-text-dark mt-2" disabled>Lorem ipsum dolor sit amet</textarea>
        </block>


        <block
            :element-type="vFormControls.FORM_SUMMARY"
            :label="$t('vform.formSummary')"
            :state="state">
          <a class="mt-1 mb-0 dark-ui-primary">Summary</a>
        </block>

        <!--Those are basu blocks-->
        <!--<div v-if="false" class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1" >
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.LIST_MATCH)]"
              :element-type="vFormControls.LIST_MATCH"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.listMatch") }}
            </h2>
            <div class="form-block-image">
              <icon type="list-ul" size="3.3" />
            </div>
          </div>
        </div>
        <div v-if="false" class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1" >
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.LIST_MATCH_TEXT_INPUT)]"
              :element-type="vFormControls.LIST_MATCH_TEXT_INPUT"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.listMatchTextInput") }}
            </h2>
            <div class="form-block-image">
              <icon type="list-dropdown" size="3.3" />
            </div>
          </div>
        </div>-->

        <block
            :element-type="vFormControls.BUTTON"
            :label="$t('vform.button')"
            :state="state">
          <div class="form-block-image">
            <button>NEXT ></button>
          </div>
        </block>

        <block
            :element-type="vFormControls.LOGIN"
            :label="$t('vform.login')"
            :state="state">
        </block>

        <block
            :element-type="vFormControls.LOGOUT"
            :label="$t('vform.logout')"
            :state="state">
        </block>
      </div>
    </info-panel>
    <info-panel
        :collapsible="true"
        class="vform-editor-body-bold"
        info-panel-header="Media"
        :initially-collapsed="false"
        preview-id="mediaElements"
    >
      <div class="block-container" slot="info">
        <block
            :element-type="vFormControls.IMAGE"
            :label="$t('vform.image')"
            :state="state">
          <div class="form-block-image">
            <icon type="image" size="3.3" />
          </div>
        </block>

        <block
            :element-type="vFormControls.VIDEO"
            :label="$t('video')"
            :state="state">
          <div class="form-block-image">
            <icon type="video" size="3.3" />
          </div>
        </block>

        <block
            :element-type="vFormControls.AUDIO"
            :label="$t('audio')"
            :state="state">
          <div class="form-block-image">
            <icon type="audio" size="3.3" />
          </div>
        </block>

        <block
            :element-type="vFormControls.QR_CODE"
            :label="$t('vform.qrcode')"
            :state="state">
          <div class="form-block-image">
            <icon type="qrcode" size="3.3" />
          </div>
        </block>
        <block
          :element-type="vFormControls.SENSOR"
          :label="$t('vform.sensor')"
          :state="state">
        </block>

        <block
            v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin"
            :element-type="vFormControls.CODE_SNIPPET"
            :label="$t('vform.codeSnippet')"
            :state="state">
          <div class="form-block-image">
            <icon type="code" size="3.3" />
          </div>
        </block>
      </div>
    </info-panel>
    <info-panel
        :collapsible="true"
        class="vform-editor-body-bold"
        info-panel-header="Layout"
        :initially-collapsed="false"
        preview-id="layoutElements"
    >
      <div class="block-container dark-ui" slot="info">

        <block
            :element-type="vFormControls.SEPARATOR"
            :label="$t('vform.divider')"
            :state="state">
          <div class="mt-3 separatorBlock" />
        </block>

       <block
            :element-type="vFormControls.VERTICAL_SPACE"
            :label="$t('vform.verticalSpace')"
            :state="state">
          <div class="mt-3 verticalSpaceBlock">
            <icon
                :size="'1.55'"
                class=""
                type="arrows-up-down"
            />
          </div>
        </block>
      </div>
    </info-panel>
    <info-panel
        :collapsible="true"
        class="vform-editor-body-bold"
        info-panel-header="Live Data"
        :initially-collapsed="false"
        preview-id="layoutElements"
    >
      <div class="block-container dark-ui" slot="info">

        <block
            :element-type="vFormControls.PART_INFO"
            :label="$t('vform.partInfo')"
            :state="state" />

        <block
            v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin"
            :element-type="vFormControls.PROGRESS_TRACKER"
            :label="$t('vform.progressTracker')"
            :state="state" />

        <block
            v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin"
            :element-type="vFormControls.PART_LEGEND"
            :label="$t('vform.partLegend')"
            :state="state" />
      </div>
    </info-panel>
  </div>
</template>

<script>
import { vFormControls, placeholders } from "@/enum";
import Icon from "../Icon";
import InfoPanel from "../InfoPanel";
import Block from "./Block.vue"
export default {
    name: "BlockPanel",
    components: {
      InfoPanel,
      Icon,
      Block
    },
    props: {
      state: {type: Object, required: true},
    },
    data() {
        return {
          vFormControls: vFormControls,
          placeholders: placeholders,
        };
    },
};
</script>

<style lang="scss" scoped>
.block-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
.info-panel {
  background-color: var(--vform-editor-panel-active-background-color);
}
</style>