<template>
  <div>
    <!--info-popup-->
    <div>
      <!--Hotspot popups--->
      <div></div>
      <div v-for="(hotspot, hotspotIndex) in metaHotspots"
           v-if="state.selectedHotspot.uuid === hotspot.uuid && hotspot.hotspotAction === 'popup' && isPopupVisible()"
           :key="'infopopup' + hotspotIndex"
           :class="['info-popup p-3 pt-5', state.dragging ? 'drop-zone-highlight' : '', editorMode ? 'popup-play-mode' : 'popup-play-mode']"
           :hotspotForSlide="activeSlideUuid"
           :hotspotIndex="hotspotIndex">
        <div class="info-close mb-5" @click="closePopup()">
          <icon class="" size="1.8" type="close"/>
        </div>
        <template>
          <div class="fancy-scrollbar vform-tile-container pr-2 pt-3"
               style="overflow-y: auto; overflow-x: hidden; max-height: 100%">
            <template v-for="(element, elemIndex) in hotspot.elements"
                      v-if="hotspot.elements"
                      :class="['element-display']">
              <component
                  :is="getComponentName(element.formElementTypeString)"
                  :key="element.uuid"
                  :ref="'element' + element.uuid"
                  :active-mode="true"
                  :class="['element-draggable-popup', getComponentName(element.formElementTypeString), defaultDropzoneHoverable ? 'hover': 'no-hover',]"
                  :config="element"
                  :editable="editorMode && !state.dragging && state.activeColumn !== 'step-properties'"
                  :editor-mode="editorMode"
                  :editor-selected="state.activeElement === element.uuid"
                  :element-uuid="element.uuid"
                  :elementIndex="elemIndex"
                  :is-active="true"
                  :lang="lang"
                  :offline-mode="state.offlineMode"
                  panel-uuid="hotspot"
                  :project-id="projectId"
                  :v-s-t-a-g-e-context="vSTAGEContext"
                  @editProperty="showElementProperties(element.uuid)"
                  @goToProject="goToProject"
                  @goToProjectSlide="goToProjectSlide"
                  @goToSlide="goToSlide"
                  @goToSlideNo="goToSlideNo"
                  @goToStep="goToStep"
                  @next="nextSlide"
                  @removeBlock="$emit('removeBlock', element.uuid)"
              >
                <div
                    v-if="editorMode"
                    :key="elemIndex"
                    slot="dropzone"
                    :class="['interactjs-drop-zone drop-zone w-100',state.dragging ? 'drop-zone-highlight': '',]"
                    :previous-element="element.uuid"
                    :hotspotForSlide="activeSlideUuid"
                    :hotspotIndex="hotspotIndex"
                />
              </component>
            </template>
            <div
                v-if="editorMode"
                :class="['interactjs-drop-zone large-drop-zone w-100',state.dragging ? 'drop-zone-highlight' : 'd-none',]"
                :is-last="true"
                dropZoneId="Large"
                :hotspotForSlide="activeSlideUuid"
                :hotspotIndex="hotspotIndex"
            />
          </div>
        </template>

      </div>
    </div>
  </div>
</template>

<script>
import {vFormControls, placeholders} from "@/enum";
import {getComponentName} from "@/components/vForm/utils";
import Icon from "@/components/Icon";
import TEXTDISPLAY from "@/components/vForm/viewer/TEXTDISPLAY";
import RADIOBUTTONS from "@/components/vForm/viewer/RADIOBUTTONS";
import QR_CODE from "@/components/vForm/viewer/QR_CODE";
import SHORT_ANSWER from "@/components/vForm/viewer/SHORT_ANSWER";
import SEPARATOR from "@/components/vForm/viewer/SEPARATOR";
import CHECKBOXES from "@/components/vForm/viewer/CHECKBOXES";
import HEADLINE from "@/components/vForm/viewer/HEADLINE";
import WEBSITE from "@/components/vForm/viewer/WEBSITE";
import IMAGEDISPLAY from "@/components/vForm/viewer/IMAGEDISPLAY";
import VIDEODISPLAY from "@/components/vForm/viewer/VIDEODISPLAY";
import AUDIODISPLAY from "@/components/vForm/viewer/AUDIODISPLAY";
import META_LIST_FILTER from "@/components/vForm/viewer/META_LIST_FILTER";
import MEDIA_LOADER from "@/components/vForm/viewer/MEDIA_LOADER";
import SENSOR from "@/components/vForm/viewer/SENSOR";
import LOGOUT from "@/components/vForm/viewer/LOGOUT";
import PDFDISPLAY from "@/components/vForm/viewer/PDFDISPLAY";
import VERTICAL_SPACE from "./VERTICAL_SPACE";
import BUTTON_ITEM from "@/components/vForm/viewer/BUTTON_ITEM";
import PART_INFO from "@/components/vForm/viewer/PART_INFO";
import LOGIN from "@/components/vForm/viewer/LOGIN";
import loadingSpinner from "@/components/LoadingSpinner";


export default {
  name: "SlideHotspotsPopups",
  components: {
    Icon,
    TEXTDISPLAY,
    RADIOBUTTONS,
    QR_CODE,
    SHORT_ANSWER,
    SEPARATOR,
    CHECKBOXES,
    HEADLINE,
    META_LIST_FILTER,
    MEDIA_LOADER,
    IMAGEDISPLAY,
    VIDEODISPLAY,
    AUDIODISPLAY,
    WEBSITE,
    LOGOUT,
    SENSOR,
    BUTTON_ITEM,
    LOGIN,
    VERTICAL_SPACE,
    PDFDISPLAY,
    PART_INFO,
    loadingSpinner
  },
  props: {
    slideMeta: {type: Object, required: true},
    activeStepObject: {type: Object, default: null},
    activeSlideUuid: {type: String, default: null},
    state: {type: Object, required: true},
    autoplaying: {type: Boolean, required: true},
    editorMode: {type: Boolean, default: false},
    projectId: {type: String, required: true},
    vSTAGEContext: {type: Boolean, default: false},
  },
  data() {
    return {
      placeholders: placeholders,
      vFormControls: vFormControls,
      defaultDropzoneHoverable: true,
      getComponentName: getComponentName,
    };
  },
  computed: {
    metaHotspots() {
      return this.slideMeta.hotspots ? this.slideMeta.hotspots : [];
    }
  },
  beforeMount() {
  },
  methods: {
    isPopupVisible() {
      return this.state.hotspotPopupVisible;
    },
    closePopup() {
      this.state.hotspotPopupVisible = false;
      this.state.hotspotTooltip = null;
      this.state.selectedHotspot = {};
    },
    showElementProperties(element_uuid) {
      this.state.activeElement = element_uuid;
      this.$emit("showElementProperties", element_uuid);
    },
    //emit goto events
    nextSlide() {
      this.$emit("nextSlide", {});
    },
    goToSlide(uuid) {
      this.$emit("goToSlide", uuid, {});
    },
    goToSlideNo(no) {
      this.$emit("goToSlideNo", no, {});
    },
    goToStep(stepUuid) {
      this.$emit("goToStep", stepUuid, {});
    },
    goToProjectSlide(projectUuid, projectName, targetSlide = null) {
      this.$emit("goToProjectSlide", projectUuid, projectName, targetSlide, {});
    },
    goToProject(projectUuid, projectName, targetStep = null) {
      this.$emit("goToProject", projectUuid, projectName, targetStep, {});
    },
  }
};
</script>
<style lang="scss" scoped>

.info-close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  z-index: 40;
  color: var(--vform-editor-ui-secondary-color);
}

.popup-play-mode {
  z-index: 35;
}

.info-popup {
  background-color: white;
  position: absolute;
  top: 10%;
  left: 20%;
  height: 80%;
  width: 60%;

  &.with-step-panel {
    left: 10%;
    width: 55%;
  }
}

@keyframes stretch {
  0% {
    transform: scale(0.9);
  }

  50% {
  }

  100% {
    transform: scale(1.3);
  }
}

.fancy-scrollbar {
  /* Track */
  &::-webkit-scrollbar-track {
    background-color: white;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #5b5b5b;
    border: solid 1px #5b5b5b;
    border-radius: 5px;
    // background-color: $highlight;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #5b5b5b;
    border: solid 1px #5b5b5b;
    border-radius: 5px;
  }
}

.drop-zone {
  height: 23px;
  position: absolute;
  top: -16px;
  z-index: -1;
  width: 100%;
  left: 0px;
}

.drop-active {
  background-color: var(--vform-editor-accent-color);
  background-clip: content-box;
  opacity: 0.7;
  padding: 6px 0px 8px 0px;
}

.large-drop-zone {
  height: 100px !important;
  position: relative;
  top: 5px;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  z-index: -1;
  width: 100%;
  //background: gray;
}

.drop-active {
  background-color: var(--vform-editor-accent-color) !important;
  background-clip: content-box;
  opacity: 0.7;
  padding: 6px 0px 8px 0px;
}
</style>
