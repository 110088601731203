<template>
    <one-column-sidebar-left
        sub-menu-selector="librarypanel"
        back-button-tab-selector="materialmapperbackbutton"
        tab-selector="materialmappertab"
    >
        <info-panel
            slot="sidebarLeft"
            :collapsible="true"
            :initially-collapsed="false"
            class="main-content-info-panel"
            :created="$store.getters.getMaterialmapperCreationDate(this.$route.params.id)"
            :last-change="
                $store.getters.getMaterialmapperChangeDate(this.$route.params.id)
            "
            :organization="$store.getters.getMaterialmapperOrganization($route.params.id)"
            preview-id="datasetEdit"
            :limit-tags-to-organization="
                $store.getters.getMaterialmapperOrganization($route.params.id)
            "
            :type="$store.getters.getMaterialmapperType(this.$route.params.id)"
        >
            <div slot="info">
                <lifetime-setter
                    class="mt-3"
                    @setValue="updateLifetime"
                    :value="
                        $store.getters.getMaterialmapperRowLifetime(this.$route.params.id)
                    "
                />
            </div>
        </info-panel>
        <team-attacher
            slot="sidebarLeft"
            v-if="$store.getters.getMaterialmapperOrganization($route.params.id)"
            :id="$route.params.id"
            :teams="teams"
            store-name="DataSet"
            :limit-by-organization-id="
                $store.getters.getMaterialmapperOrganization($route.params.id)
            "
            @added="loadDataSetTeams"
            @removed="loadDataSetTeams"
        />
        <div slot="mainContent">
            <div class="row">
                <div class="col-12">
                    <content-title-block
                        v-if="$store.getters.getMaterialmapperName(this.$route.params.id)"
                        :value="
                            $store.getters.getMaterialmapperName(this.$route.params.id)
                        "
                        :id="$route.params.id"
                        store-name="Materialmapper"
                        :organization-id="
                            $store.getters.getMaterialmapperOrganization($route.params.id)
                        "
                        icon="table"
                    />
                    <div class="w-100" />
                    <content-description-block
                        :value="
                            $store.getters.getMaterialmapperDescription(
                                this.$route.params.id
                            )
                        "
                        :id="$route.params.id"
                        store-name="Materialmapper"
                    />
                </div>

                <div class="col-12 col-md-6"></div>
                <div class="col-12">
                    <material-mapper
                        v-if="$store.getters.getMaterialmapperOrganization($route.params.id)"
                        :id="$route.params.id"
                        :limit="4000"
                    />
                </div>
            </div>
        </div>
    </one-column-sidebar-left>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
//import MainTableLayout from "../layouts/MainTableLayout";
//import NavigationPanelsContainer from "../components/navigation/NavigationPanelsContainer";
import OneColumnSidebarLeft from "@/layouts/OneColumnSidebarLeft";
import ContentTitleBlock from "../components/ContentTitleBlock";
import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
//import Dataset from "@/components/widgets/dataset/DatasetWidget";
import MaterialMapper from "@/components/widgets/dataset/MaterialMapperWidget";
import TeamAttacher from "@/components/organizations/TeamAttacher";
import LifetimeSetter from "@/components/LifetimeSetter";
export default {
    name: "MaterialmapperEdit",
    components: {
        InfoPanel,
        //MainTableLayout,
        //Dataset,
        //NavigationPanelsContainer,
        ContentTitleBlock,
        ContentDescriptionBlock,
        TeamAttacher,
        LifetimeSetter,
        MaterialMapper,
        OneColumnSidebarLeft,
    },
    data() {
        return {
            id: "",
            teams: [],
            forceReRenderKey: 0,
        };
    },
    beforeMount() {
        this.loadMaterialMapper();
        this.loadDataSetTeams();
        //TODO: load material mappers from store. cehck if then laod
    },
    methods: {
        loadMaterialMapper() {
            this.$store
                .dispatch("loadMaterialmapper", {
                    id: this.$route.params.id,
                })
                .catch((e) => {
                    if (e.status === 401) {
                        this.$router.push("/access-denied");
                    } else if(e.status === 404) {
                      this.$router.push('/not-found')
                    }
                });
        },
        async loadDataSetTeams() {
            this.$store
                .dispatch("clientLoadDataSetTeams", {
                    id: this.$route.params.id,
                })
                .then((data) => {
                    this.teams = data;
                });
        },
        async updateLifetime(lifetime) {
            this.$store
                .dispatch("clientSaveDataset", {
                    id: this.$route.params.id,
                    rowLifetime: lifetime,
                })
                .then(() => {
                    this.forceReRenderKey++;
                });
        },
    },
};
</script>
