<template>
    <div>
        <div class="dflex mt-4">
            <div class="col row">
                <label class="vform-editor-label section mt-0">File</label>
            </div>
            <div
                v-if="$store.getters.getSelectedFormLanguage === key"
                class="dflex col-auto pr-0"
                :key="index2 + 1000000000 + forceReRenderKey"
                v-for="(key, index2) in Object.keys(element.label.dix)"
            >
                <div>
                    <v-form-image-dix-cell
                        :element="element"
                        :image-dix="element.label.imageDix"
                        preview-size="medium"
                        :organization-id="organizationId"
                        :media-category="3"
                        :v-s-t-a-g-e-context="vSTAGEContext"
                    />
                </div>
            </div>
        </div>
        <div class="dflex mt-3">
            <div class="col row">
                <label class="vform-editor-label section mt-0">Alignment</label>
            </div>
            <div
                v-for="(align, index) in alignments"
                :class="[
                    'vform-alignment-button',
                    align === element.alignment ? 'active' : '',
                ]"
                :key="index"
                @click="setAlignment(align)"
            >
                <icon :type="'align-' + align" size="1" />
            </div>
        </div>
        <label class="vform-editor-label section">Scale</label>
        <label class="section ml-2 vform-editor-body slider-label"
            >{{  this.size ? `${this.size}%` : 'AUTO' }}</label
        >
        <slider :default-value="null" :initial-value="size"
              :interval="10" :max="100" :min="0"
              @change="(val) => { this.$set(this, 'size', val) }"></slider>
      <div class="col pl-0 pr-0 dflex">
        <div class="col pl-0">
          <label class="mt-3 vform-editor-label section">{{ $t("showAsTile") }}</label>
        </div>
        <div class="col-auto pl-0 pr-0" style="margin: auto">
          <div v-if="true" class="col-12 dflex row">
            <label class="container" style="padding-left: 5px !important" :for="'chkshowastiles' + $vnode.key">
              <input
                  type="checkbox"
                  :id="'chkshowastiles' + $vnode.key"
                  v-model="element.showastiles"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import vFormImageDixCell from "@/components/vForm/vFormImageDixCell";
import { vFormControls } from "@/enum";
import Icon from "../Icon";
import Slider from "@/components/widgets/editors/Slider";

export default {
    name: "vFormIMAGE",
    components: {
        vFormImageDixCell,
        Slider,
        Icon,
    },
    props: {
        element: { type: Object, required: true },
        stepNo: { type: Number, required: true },
        organizationId: { type: String, default: "" },
        vSTAGEContext: {type: Boolean, default: false}
    },
    data() {
        return {
            forceReRenderKey: 0,
            editImage: "",
            vFormControls: vFormControls,
            size: 100,
            alignments: ["left", "center", "right"],
        };
    },
    watch: {
        size() {
            this.$set(this.element, "size", this.size);
        },
    },
    beforeMount() {
        this.element.formElementType = vFormControls["IMAGE"];
        this.size = this.element.size ? this.element.size : this.size;
    },
    methods: {
        save() {
            this.$emit("save", this.element);
        },
        setAlignment(val) {
            this.$set(this.element, "alignment", val);
        },
    },
};
</script>
