<template>
  <div
      :key="slide.id + 'container'"
      :class="['d-flex', 'slide', slideImageMode]">
    <div
         :class="['slide-wrapper',
                slideImageMode,
                isStepPanelVisible() ? 'panel-visible' : '',
                isActiveSlide() && images[slide.id] && !setAllInactive ? 'active' : (jumpTargets.includes(slide.id) && !setAllInactive ? 'inactive' : 'reallyinactive'),
                slide.id,
                getSlideTransition(),
                autoplaying ? 'autoplay' : '' ]">
      <div v-if="!images[slide.id] && !slide.ghostSlide" class="vform-viewer-loading-ellipsis border-radius">
        <loading-spinner class="white" size="default"/>
      </div>
      <img
          v-if="!images[slide.id]"
          :class="['slide-mini-image slide-large-image', slideImageMode, showStaticImageOnTopOfScene() ? '' : 'opacity-0']"
          :src="miniImages[slide.id]">
      <img
          v-if="images[slide.id]"
          :class="['slide-large-image slide-full-image', slideImageMode, showStaticImageOnTopOfScene() ? '' : 'opacity-0']"
          :src="images[slide.id]">
    </div>
  </div>
</template>

<script>
import {vFormControls, placeholders} from "@/enum";
import {getComponentName} from "@/components/vForm/utils";
import loadingSpinner from "@/components/LoadingSpinner";

export default {
  name: "SlideImageDisplay",
  components: {
    loadingSpinner,
  },
  props: {
    formSettings: {type: Object, required: true},
    activeStepObject: {type: Object, default: null},
    slide: {type: Object, required: true},
    miniImages: {type: Object, required: true},
    images: {type: Object, required: true},
    activeSlideUuid: {type: String, default: null},
    state: {type: Object, required: true},
    autoplaying: {type: Boolean, required: true},
    editorMode: {type: Boolean, default: false},
    projectId: {type: String, required: true},
    vSTAGEContext: {type: Boolean, default: false},
    jumpTargets: {type: Array, default: () => {return []}},
    setAllInactive: {type: Boolean, default: false},
    slideImageMode: {type: String, required: true},
    activeSlideMeta: {type: Object, default: null},
  },
  data() {
    return {
      placeholders: placeholders,
      vFormControls: vFormControls,
      defaultDropzoneHoverable: true,
      getComponentName: getComponentName,
    };
  },
  watch: {
    images() {
      this.setLoaded();
    }
  },
  mounted() {
    this.setLoaded();
  },
  methods: {
    setLoaded() {
      if(this.images[this.slide.id]) {
        const $this = this;
        setTimeout(() => {
          $this.$emit('loaded')
        }, 2000);
      }
    },
    isActiveSlide() {
      return this.activeSlideUuid === this.slide.id;
    },
    showStaticImageOnTopOfScene() {
      if (!this.vSTAGEContext) {
        return true;
      }

      if (this.vSTAGEContext && this.editorMode) {
        return true;
      }

      if (!this.editorMode) {
        if (!this.activeSlideMeta) {
          return false;
        }

        return this.activeSlideMeta && !this.activeSlideMeta.vStageScene;
      }
    },
    getSlideTransition() {
      // remove transitions in vSTAGE to save some performance
      if (this.vSTAGEContext) {
        return '';
      }
      let result = 'transition-zoom';

      if (this.autoplaying && this.activeStepObject && this.activeStepObject.transition) {
        result = 'transition-' + this.activeStepObject.transition;
      } else {
        result = 'transition-' + this.formSettings.slideTransition;
      }

      return result;
    },
    isStepPanelVisible() {
      //TOOD@Marius: duplicate function with one from SlideShowDisplay
      if (!this.activeSlideMeta) {
        return true;
      }

      if (this.activeSlideMeta && this.activeSlideMeta.learningDots && this.activeSlideMeta.learningDots.length > 0) {
        return true;
      } else {
        return !this.activeSlideMeta || !this.activeSlideMeta.hideStepPanel;
      }
    },
  }
};
</script>
<style lang="scss" scoped>
.slide-wrapper {
  position: relative;
  z-index: 2;
  height: 100%;

  &.cover {
    width: 100%;
  }
}

.slide-mini-image {
  filter: blur(1.5rem);
}

.slide-large-image.cover {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.slide-large-image.contain {
  height: 100%;
}

.fancy-scrollbar {
  /* Track */
  &::-webkit-scrollbar-track {
    background-color: white;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: #5b5b5b;
    border: solid 1px #5b5b5b;
    border-radius: 5px;
    // background-color: $highlight;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: #5b5b5b;
    border: solid 1px #5b5b5b;
    border-radius: 5px;
  }
}

.vform-viewer-loading-ellipsis {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: var(--vform-editor-layout-tertiary-color);
  opacity: 0.8;
  padding: 15px;
  border-radius: 3px;
}

.slide {
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  overflow: hidden;

  &.contain {
    position: absolute;
    left: auto;
    align-items: center;
    height: 100%;
    justify-content: center;
    pointer-events: all;
  }

  &.cover {
    width: 100%;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.slide-wrapper {
  &.active {
    opacity: 1;
    z-index: 1;
    // do not change this or you will have to test if autoplay is still working!!!
    animation-duration: 2.5s;
    animation-direction: forwards;

    &.transition-zoom {
      animation-name: fadeInWithZoom;
    }

    &.transition-fade {
      animation-name: fadeIn;
    }

    &.autoplay {
      animation-duration: 1s;
    }
  }

  &.inactive {
    opacity: 0;
    z-index: 0;
    animation-duration: 1.5s;
  }

  // the previous active must fade out slowly
  &.previous-active {
    opacity: 0;
    z-index: 1;
    animation-name: fadeOut;
    // do not change this or you will have to test if autoplay is still working!!!
    animation-duration: 2.5s;
    animation-direction: forwards;

    &.autoplay {
      animation-duration: 1.5s;
    }
  }

  &.reallyinactive {
    opacity: 0;
    z-index: 0;
  }
}

.learning-dot-select {
  width: 30px;
}

.selector {
  width: 100%;
  position: relative;
  top: 30px;
  height: 20px;
  align-items: flex-start;
  justify-content: center;
  z-index: 50;
}

.selector-item {
  line-height: 10px;
  background-color: #1e7e34;
  margin-right: 5px;
  height: 25px;
  border-radius: 3px;
  min-width: 30px;
  width: fit-content;
  justify-content: center;
  display: flex;
  z-index: 50;
  white-space: nowrap;

  &:hover {
    background-color: darkgreen;
  }
}

.high-z-index {
  z-index: 3;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInWithZoom {
  from {
    opacity: 0;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1.0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    z-index: 0;
  }
  to {
    opacity: 0;
    z-index: 0;
  }
}

.opacity-0 {
  opacity: 0;
}
</style>
