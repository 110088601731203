<template>
  <div
      :class="[
          'interactjs-drop-zone drop-zone',
           state.dragging ? 'drop-zone-highlight' : '',
           size + '-drop-zone',
           additionalClasses
      ]"
      :is-first="isFirst"
      :is-bottom="isBottom"
      :dropZoneId="dropZoneId"
      :global-panel="panel.isMaster"
      :panel-uuid="panel.uuid"
      :previous-element="previousElement"
  >
    <div class="drop-zone-content">
    </div>
  </div>
</template>

<script>
export default {
  name: "BlockDropZone",
  props: {
    panel: {type: Object, required: true},
    state: {type: Object, required: true},
    dropZoneId: {type: String, required: true},
    isLast: {type: Boolean, default: false},
    isBottom: {type: Boolean, default: false},
    isFirst: {type: Boolean, default: false},
    previousElement: {type: String, default: null},
    size: {type: String, default: 'large'},
    additionalClasses: {type: String, default: ''}
  }
}
</script>

<style lang="scss" scoped>
  .drop-zone {
    height: 23px;
    width: 100%;
    left: 0;
    //z-index: 0;
    z-index: 500 !important;
    transition: all 300ms ease;
    position: relative;
    //background-color: rgba(255,0,0,0.5);
    .drop-zone-content {
      border-radius: 4px;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
    }
    &.drop-not-allowed {
      z-index: 500 !important;
      cursor: not-allowed !important;
      background-clip: content-box;
      opacity: 0.2;
      padding: 6px 0 8px 0;
      .drop-zone-content {
        cursor: not-allowed;
      }
    }
  }

  .drop-zone-highlight {
    z-index: 500 !important;
    transition: all 300ms ease;
    opacity: 0.5;
    .drop-zone-content {
      background: repeating-linear-gradient(-45deg, #cbcbcc, #969697, #cccedf 6px, transparent 2px);
    }
    &:hover {
      cursor: grab;
    }
  }

  .small-drop-zone {
    height: 50px;
    width: 100%;
    .drop-zone-content {
      height: 10px;
    }
    &.can-drop {
    //  height: 60px;
    }
  }
  .medium-drop-zone {
    height: 50px;
    width: 100%;
  }
  .large-drop-zone {
    height: 100px;
    width: 100%;
  }
  .drop-active {
    background-clip: content-box;
    opacity: 1;
    .drop-zone-content {
      background: #ff7e35;
    }
  }
</style>