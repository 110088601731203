<template>
    <div>
        <div id="form-export">
          <div :class="['pt-3 pb-4 pl-3', 'form-export-' + (darkMode ? 'dark' : 'bright')]">
            <h1 v-if="showHeading" class="white">Export</h1>
            <div class="lighter mb-1">{{ $t('Please select a format for your export.') }}</div>
            <!-- Word -->
            <div
              :class="['settings-button float-left mr-1 d-inline-block with-distinct-border',
              showExportPrompt === exportPossibilites.WORD ? 'orange no-hover' : 'switched-colors']"
              @click="
                showExportPrompt = exportPossibilites.WORD;
                autoGenerateLegend = false;
              "
            >
              <icon class="mr-2" type="file-word" />
              <loading-spinner  v-if="wordGenerating" class="white ml-2 float-right" />
              {{ $t("exportPrompt.word") }}
              <div class="d-inline-block ml-1" v-if="wordGenerating">
                ({{ currentWordPage }} / {{ totalWordPages }})
              </div>
            </div>
  
            <!-- PDF -->
            <div
                :class="['settings-button mr-1 d-inline-block with-distinct-border',
                showExportPrompt === exportPossibilites.PDF ? 'orange no-hover' : 'switched-colors']"
              @click="
                showExportPrompt = exportPossibilites.PDF;
                showSessionSelection = false;
              "
            >
              <icon
                v-if="!pdfGenerating"
                class="mr-2"
                type="pdf"
              />
              <loading-spinner v-if="pdfGenerating" class="white ml-2 float-right" />
              {{ $t("exportPrompt.pdf") }}
              <div class="d-inline-block ml-1" v-if="pdfGenerating">
                ({{ currentPDFPage }} / {{ totalPDFPages }})
              </div>
            </div>
          </div>
          <div class="w-100" />
          <div v-if="finishedMessage" class="form-error p-1 w-100">
            {{ finishedMessage}}
          </div>

          <div :class="['col-12 border-radius form-export-details', darkMode ? 'darker' : 'brighter',!showExportPrompt ? 'collapsed' : '']">
            <!-- Word Prompt popup -->
            <template v-if="showExportPrompt === exportPossibilites.WORD">
              <h2 class="mb-3">
                {{ $t("exportPrompt.wordOptions") }}
              </h2>
              <div class="d-flex flex-row mb-3">
                <h4 class="left-side">{{ $t("exportPrompt.template") }}</h4>
                <drop-down
                    :initial-active-value="template[0]"
                    :labels="template"
                    :values="template"
                    class="w-75"
                />
              </div>

              <div class="generate-legend-box">
                <div class="mb-2 lighter">{{ $t('AdditionalOptions') }}</div>
                <label for="generateLegend" class="container vform-editor-body ml-0">
                  {{ $t("exportPrompt.autoGenerateLegend") }}
                  <input
                      id="generateLegend"
                      type="checkbox"
                      v-model="autoGenerateLegend"
                  >
                  <span class="checkmark" />
                </label>
              </div>
              <template v-if="autoGenerateLegend">
                <!--DO NOT REMOVE THE FOLLOWING in case we want to use it later in life-->
                <div class="lighter mb-2 mt-4">
                  {{ $t("exportPrompt.pleaseSelectAlternativeNames") }}
                </div>
                <div class="d-flex flex-row mb-3">
                  <h4 class="left-side">{{ $t("exportPrompt.metaSet") }}</h4>
                  <drop-down
                      v-if="metaSetIds.length"
                      :labels="metaSetNames"
                      :values="metaSetIds"
                      @select="(selected) => (selectedMetaSet = selected)"
                      class="w-75"
                  />
                </div>

                <div v-if="selectedMetaSet" class="d-flex flex-row mb-3">
                  <h4 class="left-side">{{ $t("exportPrompt.metaField") }}</h4>
                  <drop-down
                      :labels="metaFieldDescriptions"
                      :values="metaFieldIds"
                      @select="(selected) => (selectedMetaField = selected)"
                      class="w-75"
                  />
                </div>
              </template>

              <label v-if="autoGenerateLegend" for="generatePartList" class="container vform-editor-body ml-0">
                {{ $t("exportPrompt.generatePartList") }}
                <input
                    id="generatePartList"
                    type="checkbox"
                    v-model="generatePartList"
                >
                <span class="checkmark" />
              </label>

              <div
                  class="settings-button float-right d-inline orange"
                  @click="exportToWord()"
              >
                {{ $t("exportPrompt.download") }}
              </div>
            </template>

            <!-- PDF Prompt -->
            <template v-else-if="showExportPrompt === exportPossibilites.PDF">
              <h2 class="mb-3">
                {{ $t("exportPrompt.pdfOptions") }}
              </h2>
              <div class="mb-3">
                <div v-if="logId" @click="showSessionSelection = true" class="settings-button d-inline-block mr-1">Report <icon type="angle-right" /></div>
                <div @click="exportToPDF" class="settings-button d-inline-block mr-1 mixed-case">vFORM <icon type="angle-right" /></div><info-helper class="ml-1" text="Report: Filled out form, vForm: Empty form" />
                <session-user-selector
                    v-if="showSessionSelection"
                    class="pb-2 mt-3"
                    :log-id="logId"
                    @setSessionData="(data) => {startPDFReport(data)}"
                />
              </div>
            </template>
          </div>
        </div>
    </div>
  </template>
  
  <script>
  import Icon from "@/components/Icon";
  import DropDown from "@/components/forms/DropDown.vue";
  import LoadingSpinner from "@/components/LoadingSpinner";
  import SessionUserSelector from "@/components/vForm/logs/SessionUserSelector.vue";
  import InfoHelper from "@/components/InfoHelper.vue";
  import toPDFMixinJs from "@/components/vForm/mixins/toPDFMixin.js.vue";
  import toWordMixinJs from "@/components/vForm/mixins/toWordMixin.js.vue";
  import { mapState, mapActions } from "vuex";
  import {projectLoadingModes, vformLoadingModes} from "@/enum";
  import vFormAndProjectMixin from "@/components/vForm/mixins/vFormAndProjectMixin.js.vue";
  import ElementMethodMixinJs from "@/components/vForm/mixins/ElementMethodMixin.js.vue";
  export default {
    name: "VFormExport",
    components: {
      SessionUserSelector,
      Icon,
      DropDown,
      LoadingSpinner,
      InfoHelper
    },
    mixins: [ElementMethodMixinJs, toPDFMixinJs, toWordMixinJs, vFormAndProjectMixin],
    props: {
      logId: { type: String, default: "" },
      organizationId: {type: String, required: true},
      projectId: {type: String, required: true},
      formId: {type: String, required: true},
      projectName: {type: String, required: true},
      showCloseButton: {type: Boolean, default: true},
      showHeading: {type: Boolean, default: true},
      darkMode: {type: Boolean, default: true},
    },
    data() {
      return {
        finishedMessage: "",
        formLoading: false,
        showExportSettings: false,
        showSessionSelection: false,
        showExportPrompt: "",
        exportPossibilites: Object.freeze({
          WORD: 'Word',
          PDF: 'PDF'
        }),
  
        // Word Export settings
        selectedMetaSet: "",
        metaFields: [],
        selectedMetaField: "",
        template: ["Tables"],
        autoGenerateLegend: false,
        generatePartList: false,

        // PDF Export Settings
        metaSets: [],

        config: {},
        slides: [],
      };
    },
    computed: {
      ...mapState({
        totalPDFPages: (state) => state.formpage.totalPDFPages,
        currentPDFPage: (state) => state.formpage.currentPDFPage,
        pdfGenerating: (state) => state.formpage.isPDFGenerating,
        pdfReportGenerating: (state) => state.formpage.pdfReportGenerating,
        currentWordPage: (state) => state.formpage.currentWordPage,
        totalWordPages: (state) => state.formpage.totalWordPages,
        wordGenerating: (state) => state.formpage.isWORDGenerating,
      }),
      metaSetNames() {
        if (this.metaSets.length > 0) {
          return Object.values(this.metaSets).map((metaSet) => metaSet.name);
        }
        return [];
      },
      metaSetIds() {
        if (this.metaSets.length > 0) {
          return Object.values(this.metaSets).map((metaSet) => metaSet.id);
        }
        return [];
      },
      metaFieldDescriptions() {
        if (this.metaFields.length > 0) {
          return Object.values(this.metaFields).map(
            (metaField) => metaField.description
          );
        }
        return [];
      },
      metaFieldIds() {
        if (this.metaFields.length > 0) {
          return Object.values(this.metaFields).map((metaField) => metaField.id);
        }
        return [];
      },
    },
    watch: {
      autoGenerateLegend() {
        if (!this.autoGenerateLegend) {
          this.selectedMetaSet = "";
          this.selectedMetaField = "";
          this.metaFields = [];
        }
      },
      selectedMetaSet(val) {
        if (val) {
          const item = this.metaSets.find((metaSet) => {
            return metaSet.id === val;
          });
          if (item) {
            this.metaFields = item.metaFields;
            this.selectedMetaField = this.metaFields.length
              ? this.metaFields[0].id
              : null;
          } else {
            this.metaFields = [];
          }
        }
      },
    },
    beforeMount() {
      this.loadMetaSets();
      // todo: reset all values
      this.updateTotalPDFPages(0);
      this.updateCurrentPDFPage(0);
      this.updatePDFGenerating(false);
      this.updateTotalWordPages(0);
      this.updateCurrentWordPage(0);
      this.updateWORDGenerating(false);
      // todo: load vForm here instead of relying on the parent component
      this.loadFormAndProjectConfig();
    },
    methods: {
      ...mapActions([
          "updateCurrentPDFPage",
          "updateTotalPDFPages",
          "updatePDFGenerating",
          "updateCurrentWordPage",
          "updateTotalWordPages",
          "updateWORDGenerating",
      ]),
      async loadFormAndProjectConfig() {
        this.formLoading = true;
        console.log('loading vForm')
        this.projectLoadingMode = this.offlineMode ? projectLoadingModes.OFFLINE : await this.getProjectLoadingMode(this.projectId);
        this.config = await this.loadFormNew({
          loadingMode: this.offlineMode ? vformLoadingModes.OFFLINE : vformLoadingModes.CONTENT, // todo: when vform moves to project storage, change here to dynamic
          formId: this.formId,
          projectId: this.projectId
        })
        this.projectConfig = await this.loadProjectConfigFileNew(this.projectId, {loadingMode: this.projectLoadingMode})
        this.slides = this.projectConfig && this.projectConfig.slides ? this.projectConfig.slides : [{id: "none"}];
        this.formLoading = false;
      },
      async exportToPDF() {
        this.finishedMessage = "";
        await this.updatePDFGenerating(true)
        await this.toPDF(this.organizationId, this.projectId, this.formId, this.slides, this.config, this.projectLoadingMode);
        await this.updatePDFGenerating(false);
        this.finishedMessage = "The PDF has been saved into your downloads folder.";
      },
      async exportToWord() {
        const exportConfig = {
          autoGenerateLegend: this.autoGenerateLegend,
          generatePartList: this.generatePartList,
          metaSetId: this.selectedMetaSet,
          metaFieldId: this.selectedMetaField,
        }
        await this.updateWORDGenerating(true)
        await this.toWord(this.organizationId, this.projectId, this.slides, this.config, exportConfig);
        await this.updateWORDGenerating(false)
      },
      async startPDFReport(config) {
        await this.updatePDFGenerating(true)
        await this.toPDF(this.organizationId, this.projectId, this.formId, this.slides, this.config, this.projectLoadingMode, config);
        await this.updatePDFGenerating(false)
      },
      async loadMetaSets() {
        await this.$store.dispatch('clientLoadMetaSets', {
          include: 'metaFields',
          filter: 'organizationId eq ' + this.organizationId
        }).then(metaSets => {
          this.metaSets = metaSets;
        });
      },
      setPDFTotalPages(pageNo) {
        this.updateTotalPDFPages(pageNo);
      },
      setPDFAddingPage(pageNo) {
        this.updateCurrentPDFPage(pageNo);
      },
      setPDFGenerating(bool) {
        this.pdfGenerating = bool;
      },
      setPdfReportGenerating(bool) {
        this.pdfReportGenerating = bool;
      },
      setWordTotalPages(pageNo) {
        this.totalWordPages = pageNo;
      },
      setWordAddingPage(pageNo) {
        this.currentWordPage = pageNo;
      },
      setWordGenerating(bool) {
        this.wordGenerating = bool;
      },
    }
  };
  </script>


  <style lang="scss">
  $left-side: 160px;
  .newStyle #form-export {
    h1.white {
      color: rgba(255,255,255,0.8);
    }
    h2 {
      font-family: 'Inter bold', sans-serif;
      font-weight: bold;
      text-transform: none;
      color: rgba(255,255,255,0.7);
      font-size: 1.2rem;
    }
  }
  .left-side {
    width: $left-side;
  }
  .generate-legend-box {
    border-top: 1px solid rgba(255,255,255,0.4);
    padding-top: 15px;
    margin-top: 15px;
    // margin-left: $left-side - 10px;
  }
  </style>

<style lang="scss" scoped>
.form-export-details {
  transition: all 300ms ease;
  max-height: 1000px;
  padding: 20px;
  background-color: rgba(0,0,0,0.5);
  min-height: 230px;
  &.collapsed {
    height: 0;
    padding: 0;
    overflow: hidden;
    min-height: 0;
  }
  &.brighter {
    background-color: #f5f5f5;
    h2 {
      color: #333 !important;
    }
  }
}
.form-export-bright {
  background-color: #f5f5f5;
  h1 {
    color: #333 !important;
  }
}
</style>
