<template>
<div class="condition-editor p-3 bg-dark border-radius">
  <div @click="forceReRenderKey++; setSelectionElements()"><icon type="redo" /></div>
  <div class="settings-button d-inline-block mb-1" @click="addRule">Add rule <icon type="square-plus" /></div>
  <div class="w-100" />
  <div
      v-if="element && element.rules"
      :key="rule.uuid + forceReRenderKey"
      v-for="rule in element.rules"
      class="mb-1 p-2 brighter border-radius"
  >
    <div class="vform-label white">Rule Name</div>
    <input v-model="rule.name" class="form-text form-text-dark mb-1" />
      <!---->
      <div class="vform-label white mb-1">Current Form Element</div>
      <select v-model="rule.conditions.selectedElement" class="custom-select">
        <option :key="el.uuid" :value="el.uuid" v-for="el in selectionElements">{{ getLabel(el, 'Undefined') }}</option>
      </select>
      <!--Element-->
      <div v-if="rule.conditions.selectedElement && getSelectionElementOptionsByUuid(rule.conditions.selectedElement)">
        <!--Option-->
        <div
            :class="[optionSelected(rule.conditions, option.uuid) ? 'lighter' : '']"
            :key="option.uuid"
            v-for="(option) in getSelectionElementOptionsByUuid(rule.conditions.selectedElement)"
        >
          <div class="d-inline-block mr-1" v-if="!optionSelected(rule.conditions, option.uuid)"
               @click="addOption(rule.conditions, rule.conditions.selectedElement, option.uuid)">
            <icon type="square-plus" />
          </div>
          <span><span class="lighter">Option:</span> {{ getText(option, 'Unknown') }}</span>
        </div><br />
        <div class="p-1 darker border-radius">
          <div class="vform-label white">
            Selected
          </div>
          <div :key="index2" v-for="(opt, index2) in rule.conditions.selectedOptions">
            <div class="d-inline-block mr-1" @click="removeOption(rule.conditions, opt)"><icon type="square-minus" /></div>
            <span class="lighter">{{ getQuestionLabel(opt) }}</span> {{ getOptionLabel(opt) }}
          </div>
        </div>
      </div>
    <v-form-go-to-component
        class="w-100 mb-5"
        :organization-id="organizationId"
        :slides="slides"
        :project-id="projectId"
        :element="rule"
        :steps="steps"
        default-action="NoAction"
        dix-selector="actionDix"
    />
    <div class="d-inline-block" @click="removeRule(rule)"><icon type="square-minus" /></div>
  </div>
  <!--<div @click="save" class="settings-button d-inline-block mt-2">{{ $t('save') }}</div>-->
</div>
</template>

<script>
import vFormAndProjectMixin from "../mixins/vFormAndProjectMixin.js.vue";
import vFormGoToComponent from "@/components/vForm/vFormGoToComponent";
import {v4 as uuidv4} from "uuid";
import Icon from "../../Icon";
export default {
  name: "ConditionEditor",
  components: {vFormGoToComponent, Icon},
  mixins: [vFormAndProjectMixin],
  props: {
    slides: {type: Array, required: true},
    projectId: {type: String, required: true},
    organizationId: {type: String, required: true},
    steps: {type: Array, default: () => {return []}},
    globals: {type: Object, default: () => {return {}}},
    element: {type: Object, required: true},
  },
  data() {
    return {
      forceReRenderKey: 0,
      selectionElements: [],
      selectedElementsByCondition: {}
    };
  },
  beforeMount() {
    this.setSelectionElements();
    if(!this.element.rules) {
      this.$set(this.element, "rules", []);
    }
  },
  methods: {
    getSelectedElementByCondition(condition) {
      return condition.selectedElement;
    },
    optionSelected(condition, optionId) {
      const index = condition.selectedOptions.findIndex(item => {
        return item.optionId === optionId;
      })
      return index !== -1;
    },
    async save() {
      console.log(this.element.rules);
      /*await this.$store.dispatch('createAssetTextFile', {
        id: this.formId,
        fileName: vFormFiles['CONDITION_CONF'],
        fileContent: JSON.stringify(this.conditions)
      }).then(() => {
        this.$emit('reloadTransConfig');
        this.$emit('abortEditing');
      })*/
    },
    getQuestionLabel(opt) {
      const {elementId, } = opt;
      const el = this.getSelectionElementByUuid(elementId);
      return this.getLabel(el, 'Unknown')
    },
    getOptionLabel(opt) {
      const {elementId, optionId} = opt;
      const options = this.getSelectionElementOptionsByUuid(elementId);
      const index = options.findIndex(item => {
        return item.uuid === optionId;
      })
      if(index !== -1) {
        return this.getText(options[index]);
      }
      return "missing label";
    },
    getSelectionElementByUuid(uuid) {
      const item = this.selectionElements.filter(item => {
        return item.uuid === uuid;
      })
      if(item && item[0]) {
        return item[0];
      }
      return null;
    },
    getSelectionElementOptionsByUuid(uuid) {
      const item = this.getSelectionElementByUuid(uuid);
      return item && item.options ? item.options : [];
    },
    setSelectionElements() {
      let mixed = [];
      for(let i = 0; i < this.steps.length; i++) {
        const {elements} = this.steps[i];
        if(elements.length) {
          mixed = mixed.length ? [...mixed, ...elements] : elements;
        }
      }
      const globals = this.globals && this.globals.elements ? this.globals.elements : [];
      mixed = globals ? [...mixed, ...globals] : mixed;
      this.selectionElements = this.getSelectionElementsFromElementList(mixed);
    },
    addRule() {
      const uuid = uuidv4();
      this.element.rules.push({
        uuid,
        name: 'new',
        conditions: {
          selectedElement: "",
          selectedOptions: [],
        },
        actionDix: {
          dix: {
            Unknown: {}
          },
        }
      });
      this.selectedElementsByCondition[uuid] = "";
    },
    removeRule(rule) {
      console.log('rule removal: ' + rule.uuid)
      const index = this.element.rules.findIndex(item => {
        return item.uuid === rule.uuid;
      });
      if(index !== -1) {
        console.log('rule found...')
        this.element.rules.splice(index, 1);
        this.forceReRenderKey++;
      }
    },
    findOption(rule, elementId, optionId) {
      const index = rule.selectedOptions.findIndex(item => {
        return item.elementId === elementId && item.optionId === optionId;
      })
      return index;
    },
    addOption(condition, elementId, optionId, selected = true) {
      const existing = this.findOption(condition, elementId, optionId);
      if(existing === -1) {
        condition.selectedOptions.push({
          elementId,
          optionId,
          selected
        });
      }
    },
    removeOption(condition, opt) {
      const existing = this.findOption(condition, opt.elementId, opt.optionId);
      if(existing !== -1) {
        condition.selectedOptions.splice(existing, 1);
      }
    },
    getLabel(config, lang) {
      if (config.label && config.label.dix) {
        return config.label.dix[lang] ? config.label.dix[lang] : config.label.dix.Unknown;
      }

      return '';
    },
  }
}
</script>

<style scoped>
.condition-editor {
  /*position:fixed;
  bottom: 185px;
  left:115px;
  z-index:5000;
  min-width: 400px;*/
}
</style>