<template>
  <div class="col-12">
    <div class="form-error p-2">
      This function is currently disabled and an updated version will be released soon.
    </div>
    <div v-if="false">
    <upload-drop-zone
        ref="hierarchyUpdate"
        :accepted-file-types="Object.values(metaDataFileFormats).map((item) => {return '.' + item.format;}).join(',')"
        :asset-id="$route.params.id"
        :auto-update-preview="false"
        :auto-upload="false"
        :class="[!metaDataColumns ? 'col-12 col-md-8' : 'col-6 col-md-6', 'mt-3']"
        :downloadable="false"
        :image-editable="true"
        :is-loading="isLoading"
        :removable="false"
        asset-type="assembly"
        form-id="hierarchyUpdate"
        target-type="Asset"
        @data="setData"
        @fileReceived="fileReceived"
        @filesInQueue="filesInQueue"
        @queueEmpty="queueEmpty"
    >
      <div class="mt-3">
        {{ $t("uploadMetaFile") }}
      </div>
    </upload-drop-zone>
    <div v-if="fileContent" class="col-12">
      <div class="row">
        <div class="col-12 col-md-12">
          <label class="lighter mt-2">1 {{ $t("PostProcessingForInstance") }}</label><br/>
          <select
              v-model="vhubPreProcessor"
              class="custom-select dark"
              @change="parseData"
          >
            <option
                v-for="(key, value) in vhubPreProcessorOptions"
                :key="key"
                :value="value"
            >
              {{ $t(key.name) }}
            </option>
          </select>
        </div>
        <div class="col-12 col-md-12">
          <!--Data mapping...--->
          <div class="mt-4" v-if="metaDataColumns.length">
            <label class="lighter">2 {{ $t("mapData") }}</label> <info-helper class="ml-1" text="instanceMapInfo"/><br/>
            <item-mapper
                v-if="metaDataColumns.length"
                :items1="Object.values(updatableInstanceFields).map(item => {return item.key})"
                :items1-labels="Object.values(updatableInstanceFields).map(item => {return item.name})"
                :items2="metaDataColumns"
                :allow-multi-items-right="true"
                @update="(val) => {instanceMap = val}"
            />
          </div>
        </div>
      </div>
        <div class="mt-4" v-if="instanceMap.length">
          <label class="lighter mt-2">3 {{ $t("selectIdentifier") }}</label><br/>
          <drop-down
              :labels="metaDataColumns"
              :values="metaDataColumns"
              class="w-100"
              @select="(value) => {partIdentifierColumn = value}"
          />
        </div>
    </div>

    <div
        class="form-section col-12"
        v-if="instanceMap && partIdentifierColumn"
    >
      <label class="lighter">4 {{ $t("Regex") }}</label><br/>
      <div class="form-item">
        Select a lovely regex for your purposes
        <universal-selector
            class="mt-1"
            store-name="Regex"
            list-name="importRegex"
            @addItem="addRegex"
            :exclude-from-item-list="regexes"
        />
        <div v-if="regexes"
             v-for="regex in regexes"
             :key="regex.name"
        >
          <tag
              :is-removable="true"
              :tag="regex"
              @removeTag="removeRegex"
          />
        </div>
      </div>
    </div>

    <div
        class="form-section col-12"
        v-if="instanceMap && partIdentifierColumn"
    >
      <label class="lighter">5 {{ $t("email") }}</label><br/>
      <div class="form-item">
        <input
            id="sendMails"
            v-model="sendMails"
            name="sendMails"
            type="checkbox"
        />
        <label class="ml-1" for="sendMails">{{ $t("GetEmailNotification") }}</label>
      </div>
      <input
          v-model="email"
          :class="['form-text form-text-dark w-100', $v.email.$dirty && $v.email.$error ? 'form-group--error' : '',]"
          type="text"
          @input="delayTouch($v.email)"
      />
      <div
          v-if="$v.email.$dirty && !$v.email.email"
          class="form-error mb-2"
      >
        {{ $t("errors.mustBeAValidEmailAdress") }}
      </div>
      <div
          class="mt-4 settings-button d-inline-block"
          @click="startMetaSetUpdate"
      >{{ $t("start") }}
        <icon class="ml-1" type="cube"/>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import Icon from "../Icon";
import InfoHelper from "@/components/InfoHelper";
import UploadDropZone from "@/components/files/UploadDropZone";
import {decodeArrayBuffer} from "@/utils";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import {metaDataFileFormats, TaskManagerTaskTypes, updatableInstanceFields} from "@/enum";
import {email} from "vuelidate/lib/validators";
import DataProcessor from "../fileProcessors/DataProcessor";
import ItemMapper from "../ItemMapper";
import DropDown from "../forms/DropDown";
import UniversalSelector from "@/components/organizations/UniversalSelector.vue";
import Tag from "@/components/Tag.vue";
export default {
  name: "InstanceUpdater",
  components: {
    Tag,
    UniversalSelector,
    UploadDropZone,
    InfoHelper,
    Icon,
    ItemMapper,
    DropDown
  },
  mixins: [VuelidateMixin],
  props: {
    remoteContent: {type: Object, required: true},
    canUpdate: {type: Boolean, default: false}
  },
  data() {
    return {
      partIdentifierColumn: null,
      /**
       * will be set by the meta-config component – is the content.pipeline.meta-Object
       * */
      metaConfig: {},
      /**
       * the columns from the meta file (which is usually a table of some sort)
       * */
      metaDataColumns: [],
      /**
       * Sets the dropzone to loading-state while processing data
       * */
      isLoading: false,
      /**
       * the available file formats = processors for meta-files (currently Windchill Search Res only)
       * */
      metaDataFileFormats: metaDataFileFormats,
      vhubPreProcessorOptions: metaDataFileFormats,
      updatableInstanceFields: updatableInstanceFields,

      /**
       * The preprocessor will be set as
       * */
      vhubPreProcessor: "", //Object.keys(metaDataFileFormats)[0],

      /**
       * the mode to use for either overwriting/extending or reset the meta-data
       * */
      mode: "extend",

      email: this.$store.getters.getUserEmail(this.$store.getters.getCurrentUserId),
      sendMails: false,

      content: {},
      dataProcessor: null,
      fileContent: null,

      instanceMap: [],
      regexes: []
    };
  },
  validations: {
    email: {
      email,
    },
  },
  mounted() {
    this.content = this.remoteContent;
  },
  methods: {
    removeRegex(item) {
      const {id} = item;
      const index = this.regexes.findIndex(el => {
        return el.id === id;
      })
      if(index !== -1) {
        this.regexes.splice(index, 1);
      }
    },
    addRegex(regex) {
      this.regexes.push(regex);
    },
    setMetaConfig(metaConfig) {
      this.metaConfig = metaConfig;
      this.partIdentifierColumnMissing = !this.metaConfig.partIdentifierColumn;
    },
    // 1 resume upload-dropzone upload which triggers 2
    startMetaSetUpdate() {
      this.$refs.hierarchyUpdate.resumeUpload();
    },
    // 2 when file is uploaded, start update of sfx content and
    // 3 start the pipeline task "dataprocessing"
    async fileReceived(assetId, fileName) {
      console.log('received file, starting update...')
      this.content.pipeline.files = [`${assetId}/${fileName}`];
      this.$emit('processing')
      await this.updateAssemblyContent(fileName)
          .then(async () => {
            console.log('starting update...')
            await this.startUpdate();
            this.isLoading = false;
          });
    },
    async startUpdate() {
      console.log('starting!')
      return await this.$store.dispatch("clientAssetToPipeline", {
        id: this.$route.params.id,
        type: TaskManagerTaskTypes.INSTANCE_UPDATING,
        config: this.content.pipeline
      })
    },
    setData(data) {
      this.fileContent = data;
      this.isLoading = false;
    },
    async parseData() {
      this.dataProcessor = new DataProcessor(this.vhubPreProcessor);
      await this.dataProcessor.init();
      const file = decodeArrayBuffer(this.fileContent);
      this.metaDataColumns = await this.dataProcessor.getColumnNames(file);
    },
    // todo: needed?
    filesInQueue: function () {
      this.isLoading = true;
    },
    queueEmpty: function () {
      this.metaConfig = {};
      this.metaDataColumns = [];
    },
    async updateAssemblyContent(clientMetaFile) {
      if (this.$refs.AssemblyConfig) {
        await this.$refs.AssemblyConfig.save();
      }
      if (this.vhubPreProcessor && this.vhubPreProcessor !== "none") {
        this.content.pipeline.dataProcessor = this.vhubPreProcessor;
      }
      const obj = {
        sendMails: this.sendMails,
        email: this.email,
        lang: this.$i18n.locale ? this.$i18n.locale : "en",
        meta: {
          clientMetaFile,
          regexes: this.regexes,
          dataProcessor: this.vhubPreProcessor,
          instanceMap: this.instanceMap,
          partIdentifierColumn: this.partIdentifierColumn,
          ...this.metaConfig,
        },
      };
      this.content.pipeline = {...this.content.pipeline, ...obj};
      return await this.$store.dispatch("loadAssembly", {
        id: this.$route.params.id
      }).then(async () => {
        return await this.$store.dispatch("updateAssembly", {
          id: this.$route.params.id,
          content: JSON.stringify(this.content),
        });
      });
    },
    selectMode(params) {
      this.mode = params.value;
    },
  }
}
</script>