<template>
  <div :key="forceReRenderTransitionEditor"
       class="bg-dark p-3 border-radius transition-editor">
    <div class="clickable float-right border-radius pl-2 pr-2" @click="$emit('abortEditing')"><icon class="mb-1 lighter" size="0.8" type="times" /></div>
    <div v-if="editMode">Editing</div><div v-else>Creating</div>
    <span class="lighter">start slide:</span> {{ getSlideNo(editingTransition.inSlide) }}<br />
    <span class="lighter">end slide:</span> {{ getSlideNo(editingTransition.outSlide) }}<br />
    <span class="lighter">startTime</span>: <input class="form-text form-text-dark" v-model="editingTransition.startTime" /><br />
    <span class="lighter">endTime</span>: <input class="form-text form-text-dark" v-model="editingTransition.endTime" /><br />
    <span class="lighter">videoFile</span>:<br />
    <select :key="forceReRenderKey" class="custom-select form-select-dark" v-model="editingTransition.videoFile">
      <option :key="file.key" :value="file.key" v-for="file in videos">{{ file.key }}</option>
    </select>
    <input class="form-text form-text-dark" v-model="editingTransition.videoFile" />
    <div class="settings-button d-inline-block mb-1 mt-1" @click="loadVideoCandidates">reload</div>
    <div class="settings-button d-inline-block ml-1" @click="setVideoDuration">Auto set end time</div>
    <div v-if="editingTransition.videoFile" class="settings-button d-inline-block mb-1 mt-1 ml-1" @click="reverseVideoFile">reverse video file</div>
    <br />
    <div @click="saveTransition" class="settings-button d-inline-block">save</div><div @click="$emit('abortEditing')" class="settings-button d-inline-block ml-3">Close</div>
    <div @click="deleteTransition" class="settings-button d-inline-block float-right">delete</div><br /><br />
    <div v-if="editingTransition.videoFile" @click="autoSetTransitionTimes" class="settings-button d-inline-block float-right">Auto set all transition times</div>
    <div v-if="editingTransition.videoFile" @click="autoSetTransitionTimes(true, manualTime ? parseFloat(manualTime) : null)" class="settings-button d-inline-block float-right">Auto set all transition times with corr</div><br />
    <br />
    <div v-if="editingTransition.videoFile" @click="convertToWebm(editingTransition.videoFile)"
         class="settings-button d-inline-block mb-2">
      <loading-spinner class="d-inline-block white" v-if="isConverting" /> Convert to webm</div><br />
    <span class="lighter">correction</span>: <input class="form-text form-text-dark" v-model="manualTime" /><br />
    <span v-if="message">{{ message }}</span>
    <div class="settings-button d-inline-block" @click="autoConvertToWebm">
      <loading-spinner v-if="allConverting" class="d-inline-block" />
      Auto convert all to webm <span v-if="allConverting">{{ currentConverting }} / {{ totalConverting }}</span>
    </div>
  </div>
</template>

<script>
import {vFormFiles} from "@/enum";
import Icon from "../../Icon";
import vFormAndProjectMixin from "../mixins/vFormAndProjectMixin.js.vue";
import LoadingSpinner from "../../LoadingSpinner.vue";

export default {
  name: "TransitionEditor",
  components: {Icon, LoadingSpinner},
  mixins: [vFormAndProjectMixin],
  props: {
    videoConfig: {type: Array, required: true},
    slides: {type: Array, required: true},
    formId: {type: String, required: true},
    projectId: {type: String, required: true}
  },
  data() {
    return {
      allConverting: false,
      totalConverting: 0,
      currentConverting: 0,
      isConverting: false,
      forceReRenderKey: 0,
      forceReRenderTransitionEditor: 0,
      editingTransition: {},
      localVideoConfig: {},
      editMode: false,
      videos: [],
      manualTime: 0,
      message: ""
    };
  },
  watch: {
    videoConfig: {
      deep: true,
      handler() {
        this.setVideoConfig();
      }
    },
  },
  beforeMount() {
    this.loadVideoCandidates();
    this.setVideoConfig();
  },
  methods: {
    /**
     * This sets all transition times automatically (experimental)
     * IF a video file is selected!!
     * IF the video file is covering the whole presentation and that one is linear
     * This function will be removed as soon as vSTAGE has the rendering function adapted
     * */
    async autoSetTransitionTimes(autoCorrect = false, manualSingleDuration = null) {
      this.message = '';
      const videoFile = this.editingTransition.videoFile;
      if(!videoFile) {
        throw new Error('cannot auto set transition times without a selected video file')
      }
      let singleDuration = null;
      if(manualSingleDuration) {
        singleDuration = manualSingleDuration;
      }
      else if(autoCorrect) {
        const totalDuration = await this.getVideoDuration();
        const totalTransitions = this.slides.length - 1;
        singleDuration = totalDuration / totalTransitions;
        this.message = 'The duration for a single transition is: ' + singleDuration + ' s';
      }
      let startTime = 0;
      for(let i = 0; i < this.slides.length; i++) {
        const nextIndex = i + 1;
        const slide = this.slides[i];
        const nextSlide = this.slides[nextIndex];
        if(nextSlide) {
          const totalTransitionTime = singleDuration ? singleDuration : this.getSlideTransitionTime(slide);
          const endTime = startTime + totalTransitionTime;
          this.addOrCreateTransition(slide.id, nextSlide.id, startTime, endTime, videoFile);
          startTime = endTime; // set the new startTime for the next slide
        }
      }
      const reversedVideo = this.getReversedFileName(videoFile);
      for(let i = 0; i < this.slides.length; i++) {
        const nextIndex = i + 1;
        const slide = this.slides[i];
        const nextSlide = this.slides[nextIndex];
        if(nextSlide) {
          const totalTransitionTime = this.getSlideTransitionTime(slide);
          const endTime = startTime - totalTransitionTime;
          this.addOrCreateTransition(nextSlide.id, slide.id, endTime, startTime, reversedVideo);
          startTime = endTime; // set the new startTime for the next slide
        }
      }
      this.saveTransition(false, true);
    },
    async autoConvertToWebm() {
      this.allConverting = true;
      this.message = '';
      this.totalConverting = (this.slides.length-1) * 2;
      const transitions = this.videoConfig;
      for(let i = 0; i < transitions.length; i++) {
        console.log('converting transition ' + (i+1) + ' of ' + transitions.length)
        const {inSlide, outSlide} = transitions[i];
        await this.convertToWebmGlobal(inSlide, outSlide);
      }
      this.allConverting = false;
    },
    async convertToWebm() {
      this.isConverting = true;
      const {videoFile} = this.editingTransition;
      if(!videoFile) {
        return;
      }
      return await this.$store.dispatch('clientProjectConvertVideoFile', {
        id: this.projectId,
        key: videoFile,
        format: 'webm'
      }).then(response => {
        console.log(response);
        this.editingTransition.videoFile = videoFile.replace('mp4', 'webm');
        this.loadVideoCandidates();
        this.isConverting = false;
      })
    },
    async convertToWebmGlobal(inSlide, outSlide) {
      const index = this.findTransition(inSlide, outSlide);
      if(index === -1) {
        return;
      } else {
        const {videoFile} = this.localVideoConfig[index];
        if(!videoFile || videoFile.endsWith('webm')) {
          return;
        }
        const webmVideoFile = videoFile.replace('.mp4', '.webm');
        if(!videoFile) {
          return;
        }
        await this.$store.dispatch('clientProjectConvertVideoFile', {
          id: this.projectId,
          key: videoFile,
          format: 'webm'
        })
        this.localVideoConfig[index].videoFile = webmVideoFile;
      }
      await this.saveTransition(false, false);
    },
    async getVideoDuration() {
      const {videoFile} =  this.editingTransition;
      if(!videoFile) {
        return;
      }
      return await this.$store.dispatch('clientProjectGetVideoDuration', {
        id: this.projectId,
        key: videoFile,
      }).then(response => {
        if(response.text) {
          return parseFloat(response.text);
        }
        return 0;
      })
    },
    async setVideoDuration() {
      this.editingTransition.endTime = await this.getVideoDuration();
    },
    getReversedFileName(videoFile) {
      const arr = videoFile.split('.');
      return arr[0] + '_reversed.' + arr[1]
    },
    async reverseVideoFile() {
      const {videoFile} =  this.editingTransition;
      if(!videoFile) {
        return;
      }
      const targetKey = this.getReversedFileName(videoFile);
      await this.$store.dispatch('clientProjectVideoOperation', {
        id: this.projectId,
        key: videoFile,
        targetKey: targetKey,
        videoCommand: 'reverse'
      }).then(async () => {
        const $this = this;
        setTimeout(async () => {
          await $this.loadVideoCandidates();
          $this.videoFile = targetKey;
        }, 4000)
      })
    },
    setVideoConfig() {
      this.localVideoConfig = this.videoConfig;
    },
    loadVideoCandidates() {
      this.$store.dispatch('clientListProjectParts', {
        id: this.projectId
      }).then(files => {
        this.videos = files && files.length ? files.filter(item => {
          return item.key.endsWith('.mp4') || item.key.endsWith('.webm')
        }) : [];
        this.forceReRenderKey++;
      })
    },
    getSlideNo(slideId) {
      const index = this.slides && this.slides.length ? this.slides.findIndex(item => {return item.id === slideId}) : -1;
      return index >= 0 ? index + 1 : 'N/A';
    },
    async deleteTransition() {
      const {inSlide, outSlide} = this.editingTransition;
      const transitionIndex = this.findTransition(inSlide, outSlide);
      if(transitionIndex === -1) {
        return;
      } else {
        this.localVideoConfig.splice(transitionIndex, 1);
      }
      await this.$store.dispatch('createAssetTextFile', {
        id: this.formId,
        fileName: vFormFiles['VIDEO_CONF'],
        fileContent: JSON.stringify(this.localVideoConfig)
      }).then(() => {
        this.$emit('reloadVideoConfig');
        this.$emit('abortEditing');
      })
    },
    async saveTransition(addCurrentTransition = true, doNotCloseWindow = false) {
      if(addCurrentTransition) {
        const {inSlide, outSlide, startTime, endTime, videoFile} = this.editingTransition;
        this.addOrCreateTransition(inSlide, outSlide, startTime, endTime, videoFile)
      }
      await this.$store.dispatch('createAssetTextFile', {
        id: this.formId,
        fileName: vFormFiles['VIDEO_CONF'],
        fileContent: JSON.stringify(this.localVideoConfig)
      }).then(() => {
        this.$emit('reloadVideoConfig');
        if(!doNotCloseWindow) {
          this.$emit('abortEditing');
        }
      })
    },
    findTransition(inSlide, outSlide) {
      const foundTransition = this.videoConfig.findIndex(item => {
        return item.inSlide === inSlide && item.outSlide === outSlide;
      });
      return foundTransition;
    },
    async updateVideoFileToWebm(inSlide, outSlide) {
      const index = this.findTransition(inSlide, outSlide);
      if(index !== -1) {
        let obj = this.localVideoConfig[index];
        const {videoFile} = obj;
        await this.convertToWebm(videoFile)
        obj.videoFile = videoFile.replace('.mp4', '.webm');
        this.localVideoConfig[index] = obj;
      }
      else {
        console.log('transition for update video not found')
      }
    },
    addOrCreateTransition(inSlide, outSlide, startTime, endTime, videoFile) {
      const targetObject = {
        inSlide: inSlide,
        outSlide: outSlide,
        startTime: startTime,
        endTime: endTime,
        videoFile: videoFile
      };

      const index = this.findTransition(inSlide, outSlide);
      if(index !== -1) {
        this.localVideoConfig[index] = targetObject;
      }
      else {
        this.localVideoConfig.push(targetObject);
      }
    },
    addTransition(slideId) {
      if(!this.editingTransition.inSlide || this.editingTransition.outSlide) {
        this.editingTransition = {
          inSlide: slideId,
          outSlide: null,
          startTime: 0,
          endTime: 0,
          videoFile: "noVideo.mp4"
        };
      } else {
        this.editingTransition.outSlide = slideId;
          const transitionIndex = this.findTransition(this.editingTransition.inSlide, this.editingTransition.outSlide);
          if(transitionIndex !== -1) {
            this.editMode = true;
            this.editingTransition = this.videoConfig[transitionIndex];
          } else {
            this.editMode = false;
          }
      }
      this.forceReRenderTransitionEditor++;
    },
  }
}
</script>

<style scoped>
.transition-editor {
  position:absolute;
  bottom: 185px;
  left:115px;
  z-index:5000;
  min-width: 400px;
}
</style>