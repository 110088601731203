<template>
    <div :class="[
      'vform-part-legend',
      'position-relative',
      'w-100',
      'multi-line', 
      config.emphasized ? 'vform-emphasized' : ''
    ]">
      <slot name="dropzone"/>
      <div class="w-100"/>
      <editor-overlay
          v-if="editable"
          :active="editorSelected"
          @removeBlock="$emit('removeBlock')"
          @editProperty="editProperty"
      />
      <div class="text"
           contenteditable="false"
           @input="onInput"
           @keydown.enter="leaveEditing(uniqueId)"
           :id="uniqueId"
           @click.stop="doNothing"
      >
        <div v-if="editable && !legend">
          <h3>{{ $t('vform.legendPlaceholder') }}</h3>
        </div>
        <div v-else-if="legend">
          <div v-if="loadingLegend">
            Loading...
          </div>
          <div v-else-if="!Object.values(legend).length">
            {{ $t('vform.noCaptionsDetected') }}
          </div>
          <div :class="[
            'vform-legend',
            'text-' + config.textAlignment,
          ]">
            <div
              v-for="(item, index) in legend" 
              :key="index" 
              class="legend-item"
              :style="borderStyle"
              >
              <span v-if="item.letter" class="letter-container">{{ item.letter }}</span>
              <div class="info-container mb-2">
                 <div class="info-container-title">{{ item.name }}</div>
                 <template v-if="$store.getters.getInstanceSquashedMetaValById(item.instanceId)">
                   <div class="mt-2">
                     <div v-for="(val, index2) in $store.getters.getInstanceSquashedMetaValById(item.instanceId)" :key="index2"
                          :class="['info-container-row row', index2 % 2 === 0 ? 'zebra-stripe' : '']"
                          :style="{
                            backgroundColor: config.backgroundColor ? config.backgroundColor : '',
                            color: config.fontColor ? config.fontColor : (fontColor ? fontColor : (contrastColor ? contrastColor : '')),
                            borderColor: config.borderColor ? config.borderColor : (fontColor ? fontColor : (contrastColor ? contrastColor : '')),
                           }">
                       <span class="col-4 info-container-name">{{ getMetaValueKey(val) }}</span>
                       <span v-if="getFinalMetaValue(val)" class="col-8 info-container-meta">{{ getFinalMetaValue(val) }}</span>
                       <span v-else class="col-8 info-container-meta"> - </span>
                     </div>
                   </div>
                  </template>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          {{ $t('vform.partLegendLoading') }}
        </div>
    </div>
    </div>
  </template>
  
  <script>
  import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
  import ToWordMixinJs from "@/components/vForm/mixins/toWordMixin.js.vue";
  import MetaSetsMixin from "@/components/mixins/MetaSetsMixin.js";
  import PartBlockMixin from "@/components/vForm/mixins/PartBlockMixin.js.vue";
  import ColorContrastMixin from "@/components/mixins/ColorContrastMixin.js";
  import EditorOverlay from "./EditorOverlay";

  export default {
    name: "PART_LEGEND",
    components: {
      EditorOverlay
    },
    mixins: [ToWordMixinJs, ElementMixin, MetaSetsMixin, ColorContrastMixin, PartBlockMixin],
    props: {
      /**
       * The slides array coming from the config.json of a vSTAGE package
       * */
      slides: {type: Array, default: null},
      state: {type: Object, required: true},
    },
    data() {
      return {
        localString: "",
        legend: null,
        lastKnownSlide: "",
        loadingLegend: false,
      };
    },
    computed: {
      borderStyle() {
        const borderColor = this.config.borderColor;
        return borderColor ? {border: `3px solid ${borderColor}`} : {};
      }
    },
    watch: {
      state: {
        deep: true,
        handler(val) {
          if(val.activeSlideUuid !== this.lastKnownSlide) {
            this.lastKnownSlide = val.activeSlideUuid;
            this.setLegend();
          }
        }
      },
      'config.metaFields': {
        handler() {
          this.setLegend();
        }
      }
    },
    beforeMount() {
      this.setUniqueId();
      this.setLocalString();
    },
    mounted() {
      this.increaseCounter();
      this.setLegend();
    },
    methods: {
      getFinalMetaValue(val) {
        if(!val) {
          return '';
        }
        else {
          const {squashedMetaValues} = val;
          const {finalMetaValue} = squashedMetaValues;
          return finalMetaValue;
        }
      },
      /*getInstanceMetaValue(val) {
        if(!val) {
          return '';
        }
        else {
          const {squashedMetaValues} = val;
          const {instanceMetaValue} = squashedMetaValues;
          return instanceMetaValue;
        }
      },*/
      getMetaValueKey(val) {
        if(!val) {
          return '';
        }
        else {
          const {squashedMetaValues} = val;
          const {metaFieldKey} = squashedMetaValues;
          return metaFieldKey;
        }
      },
      /**
       * Generates the legend for the slides
       * @param projectConfig
       * @param slideUuids
       * @returns {Promise<[]>}
       */
      async setLegend() {
        this.loadingLegend = true;
        try {
          this.legend = [];
          const legendSlides = await this.generateLegendForSlides(this.state.projectConfig, [this.state.activeSlideUuid])
          this.$set(this, 'legend', legendSlides);
        } catch (e) {
          this.$set(this, 'legend', []);
        }
        this.loadingLegend = false;
      },
      setLocalString() {
        this.localString = this.getLabel(this.config, this.lang);
      },
      onInput(e) {
        this.setLabel(this.config, this.lang, e.target.innerText);
      },
      save() {
        this.editing = false;
      },
      setEditing() {
        if(this.editable) {
          this.editing = true;
          this.setCursor(this.uniqueId);
        }
      },
      validate() {
        return true;
      },
      checkRequired() {
        return true;
      },
      getData() {
        return null;
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .vform-legend {
    word-break: break-all;
    display: flex;
    flex-direction: column;

    .legend-item {
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;

      .letter-container {
        flex: 0 0 auto;
        width: 50px;
        text-align: center;
        padding: 1rem 1rem 0 1rem;
        font-weight: bold;
        font-size: 1.25rem;
      }

      .info-container {
        flex: 1 1 200px; // grow to fill available space, minimum width of 200px
        min-width: 200px;
      }
      .info-container-name {
        flex: 1;
        min-width: 100px;
        max-width: 100%;
        overflow-wrap: break-word; // allows breaking long words
        word-break: break-word; // ensures long words are broken onto the next line
        padding-right: 0.5rem;
        font-weight: bold;
        font-family: "Inter bold", serif;
      }

      .info-container-meta {
        flex: 1;
      }
    }
    .info-container-row {
      padding: 1px auto;
      border-bottom: 1px solid rgba(0,0,0,0.6);
      &.zebra-stripe {
        background-color: rgba(0,0,0,0.3);
      }
    }
  }

</style>