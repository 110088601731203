<template>
  <div></div>
</template>

<script>
import {placeholders, vFormControls, vFormButtonActionModes, vformButtonActions} from "@/enum";
import listenerMixinJs from "../../mixins/listenerMixin.js.vue";
import vFormAndProjectMixin from "./vFormAndProjectMixin.js.vue";
import ElementMethodMixinJs from "@/components/vForm/mixins/ElementMethodMixin.js.vue";
import ColorContrastMixinJs from "@/components/mixins/ColorContrastMixin.js.vue";
import {
  getAllElements,
  getElementInfo,
  getElementQuestion,
  getElementInfoForCheckout,
  getElementListForm
} from "./checkoutMethods";
import {mapState} from 'vuex';

/**
 * This is a template for the elements in the viewer
 * all elements inherit their props and functions from this
 * */
export default {
  name: "ElementMixin.js.vue",
  mixins: [ColorContrastMixinJs, listenerMixinJs, vFormAndProjectMixin, ElementMethodMixinJs],
  props: {
    /**
     * If the item is editable or not (e.g. used for drag and drop etc)
     * */
    editable: {type: Boolean, default: false},
    /**
     * The element configuration (is called "element" in the editor components, we need to resolve this inconsistency at some point)
     * */
    config: {type: Object, required: true},
    /**
     * Whether or not an element is disabled (mostly used for interactive elements like buttons)
     * */
    disabled: {type: Boolean, default: false},
    /**
     * TBD
     * */
    isActive: {type: Boolean, default: true},
    /**
     * Whether or not the slide show is displayed with the editor
     * */
    editorMode: {type: Boolean, default: false},
    /**
     * Whether or not the element is selected for editing (only one can be selected at a time)
     * */
    editorSelected: {type: Boolean, default: false},
    /**
     * The id of the project the vform belongs to
     * */
    projectId: {type: String, default: ""},
    /**
     * The name of the project the vform belongs to
     * */
    projectName: {type: String, default: ""},
    /**
     * The general font color for the panel (can be overwritten locally)
     * */
    fontColor: {type: String, default: ""},
    /**
     * the font (if a customized font is given)
     * */
    font: {
      type: Object, default: () => {
        return {}
      }
    },
    /**
     * Whether the app is running in vSTAGE or in a regular browser
     * */
    vSTAGEContext: {type: Boolean, default: false},
    /**
     * Whether the app is running without access to the api (all static assets) or not
     * */
    offlineMode: {type: Boolean, default: false},
    /**
     * only for vSTAGE context: whether vform is currently displayed or not
     * if not, stop video and audio and other things happening in the background
     * */
    activeMode: {type: Boolean, default: true},
    /**
     * The filled out form data for a step
     * (when switching a step, the other steps get unmounted, so we need to refill the data when coming back to a certain step)
     * **/
    stepFormData: {
      type: Object, default: () => {
        return {}
      }
    },
    /**
     * Template mode is required for the template editor to know if it should show a preview mode of a component or not
     */
    templateMode: {type: Boolean, default: false},
    state: {type: Object, required: true},
    panel: {type: Object, default: () => {}},
  },
  computed: {
    ...mapState({
      /**
       * this is a bridge gap measure so we don't have to change the lang prop on all elements to getters
       * */
      lang: state => state.formpage.selectedFormLanguage,
      currentLang: state => state.formpage.selectedFormLanguage,
    }),
    contrastColor() {
      const {backgroundColor} = this.panel;
      if(backgroundColor) {
        return this.getContrast(backgroundColor);
      }
      return '#000';
    },
  },
  data() {
    return {
      selected: {},
      forceReRenderKey: 0,
      validationIsCorrect: true,
      placeholders: placeholders,
      uniqueId: null,
      editing: false,
      counterIncreased: false,

      /**
       * Those serve to set a hover color while remembering the original one
       * (the user only sets the color, so the hover colors and background colors get calculated dynamically)
       * */
      originalBackground: '',
      originalBorderColor: '',
      hoverBackground: '',
      originalColor: '',
      hoverColor: '',

      // this is for hover-functions so they are not called multiple times
      isHover: false,
      getElementListForm,
      getAllElements,
      getElementQuestion,
      getElementInfoForCheckout,
      getElementInfo,
    };
  },
  methods: {
    // this will be locally overwritten if necessary
    validate() {
      return true;
    },
    // this will be locally overwritten if necessary
    checkRequired() {
      return true;
    },
    /***
     * Returns the correct font name depending whether it is:
     * 'Inter' – the default font coming with the cc
     * systemFont = true – those are websafe fonts
     * systemFont = false – those are custom fonts coming from the customer and loaded into vHUB
     * */
    getFontName() {
      const isHeading = [vFormControls.HEADLINE].includes(this.config.formElementType);

      if (!this.font || !this.font.name) {
        return isHeading ? 'Inter medium' : 'Inter';
      }
      const {name, systemFont} = this.font;
      if (name === 'Inter') {
        return isHeading ? 'Inter medium' : 'Inter';
      } else if (systemFont) {
        return name + ', sans-serif';
      } else {
        return isHeading ? (name + ' bold, sans-serif') : (name + ' normal, sans-serif');
      }

    },
    increaseCounter() {
      if (!this.counterIncreased) {
        this.$emit('increasePostLoadingCounter')
        this.counterIncreased = true;
      }
    },
    setInitialColors(id) {
      if (!this.config.backgroundColor) {
        return;
      }
      let el = id.includes('subButton') ? document.getElementById(id) : document.getElementById(id).querySelector('.inner');
      this.originalBackground = this.config.backgroundColor;
      this.originalBorderColor = this.config.borderColor ? this.config.borderColor : '#000';
      this.originalColor = el.style.color;
      let hoverBG;
      if (this.getContrast(this.config.backgroundColor, true) === 'dark') {
        hoverBG = this.lightenDarkenColor(this.config.backgroundColor, 1.4);
      } else {
        hoverBG = this.lightenDarkenColor(this.config.backgroundColor, 0.7);
      }
      this.hoverBackground = hoverBG;
      this.hoverColor = this.getContrast(hoverBG);
      el = null;
    },
    setColors(id) {
      if (!this.config.backgroundColor || this.isHover) {
        return;
      }
      let el = id.includes('subButton') ? document.getElementById(id) : document.getElementById(id).querySelector('.inner');
      el.style.backgroundColor = this.hoverBackground;
      el.style.borderColor = this.hoverBackground;
      el.style.fontColor = this.hoverColor;
      el = null;
      this.isHover = true;
    },
    unsetColors(id) {
      if (!this.config.backgroundColor || !this.isHover) {
        return;
      }
      const el = id.includes('subButton') ? document.getElementById(id) : document.getElementById(id).querySelector('.inner');
      el.style.backgroundColor = this.originalBackground;
      el.style.borderColor = this.originalBorderColor ? this.originalBorderColor : this.originalBackground;
      el.style.color = this.originalColor;
      this.isHover = false;
    },
    getInlineIconSize(size) {
      return size / 2;
    },
    editTemplate() {
      this.$emit('editTemplate', this.config)
    },
    editProperty() {
      this.$emit("editProperty");
    },
    doNothing() {
      // this prevents the editing field jumping back into the no-edit state
    },
    documentClick() {
      // needs to be implemented individually
    },
    setCursor(id) {
      const el = document.getElementById(id);
      const range = document.createRange();
      const sel = window.getSelection();
      sel.removeAllRanges();
      range.selectNodeContents(el);
      range.collapse(false);
      sel.addRange(range);
      el.focus();
    },
    leaveEditing(id) {
      this.editing = false;
      const el = document.getElementById(id);
      if (el) el.blur();
    },
    setUniqueId() {
      this.uniqueId = Math.random().toString(36).substring(2, 7);
    },
    isTiles(config) {
      return config.showastiles;
    },
    isID(config) {
      return config.isID ? config.isID : false;
    },
    getSlideList(form, slides) {
      console.log('form steps:' + form.steps.length);
      console.log('slides:' + slides.length);
    },
    setLabel(config, lang, value) {
      this.$set(config.label.dix, lang, value);
    },
    getImage(config, lang) {
      if (config.label && config.label.imageDix) {
        const defaultImg = config.label.imageDix.Unknown;
        const langImg = config.label.imageDix[lang];
        return langImg ? langImg : defaultImg;
      }
    },
    getVideo(config, lang) {
      if (config.videoDix) {
        const defaultImg = config.videoDix.Unknown;
        const langImg = config.videoDix[lang];
        return langImg ? langImg : defaultImg;
      }
    },
    getOptionImage(option, lang) {
      if (option.text.imageDix) {
        const defaultImg = option.text.imageDix.Unknown;
        const langImg = option.text.imageDix[lang];
        return langImg ? langImg : defaultImg;
      }
    },
    getTargetSlides(config) {
      return config && config.targetSlides && config.targetSlides.length ? config.targetSlides : null;
    },
    getRequired(config) {
      return config.isRequired;
    },
    setOptionPreSelected(config) {
      let options = this.getOptions(config);
      let hasPreSelected = false;
      for (let i = 0; i < options.length; i++) {
        let option = options[i];
        if (option.isPreSelected) {
          option.isSelected = true;
          // this.checkOption(option);
          hasPreSelected = true;
        } else {
          option.isSelected = false;
        }
      }

      config.options = options;
      if(hasPreSelected) {
        this.$emit('storeData', config.uuid, this.retrieveData());
      }
    },
    getChecked(option) {
      return !!option.isChecked;
    },
    isCheckForCorrectness(config) {
      return !!config.validateCorrectness;
    },
    getOptionName(option, lang) {
      return this.getText(option, lang);
    },
    setOptions(config, options) {
      this.$set(config, "options", options);
      //config.options = options;
      return config;
    },
    validateQRCode(config, compareStrings) {
      if (config.codes && config.codes.length) {
        // new version with multiple codes
        const index = config.codes.findIndex(item => {
          return compareStrings.includes(item.compareString)
        });
        return config.codes[index] ? config.codes[index].compareString : null;
      } else {
        // legacy version
        return compareStrings.includes(config.label.dix.Unknown);
      }
    },
    validateOptions() {
      this.validationIsCorrect = true;
      if (this.isCheckForCorrectness(this.config)) {
        console.log('really validating opts')
        const options = this.getOptions(this.config);
        for (let i = 0; i < options.length; i++) {
          const option = options[i];
          if ((option.isCorrectAnswer && !option.isSelected) || (!option.isCorrectAnswer && option.isSelected)) {
            this.validationIsCorrect = false;
            break;
          }
        }
      }
      return this.validationIsCorrect;
    },
    /**
     * Retrieves the data from a checkbox or multiple choice or radio button field
     * Creates an object ready for processing for slideShowDisplay
     * */
    getOptionData(config, lang) {
      const options = this.getOptions(config);
      let data = {
        receivedPoints: 0,
        totalPossiblePoints: options.length,
        correctAnswers: [],
        wrongAnswers: [],
        data: {
          question: this.getLabel(config, lang),
          elementId: this.config.uuid,
          answers: []
        }
      };
      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        const optionText = this.getText(option, lang);
        if ((option.isCorrectAnswer && option.isSelected) || (!option.isCorrectAnswer && !option.isSelected)) {
          data.correctAnswers.push({optionText});
          data.receivedPoints++;
        } else {
          data.wrongAnswers.push({optionText});
        }
        data.data.answers.push({
          answer: optionText,
          selected: option.isSelected,
          answerIsMarkedAsCorrect: option.isCorrectAnswer,
          optionId: option.uuid
        })
      }
      return data;
    },
    /**
     * Checks if any option of checkboxes or radiobuttons has been selected
     * */
    getAnyOptionSelected(config) {
      if (this.getRequired(config)) {
        const options = this.getOptions(config);
        for (let i = 0; i < options.length; i++) {
          const option = options[i];
          if (option.isSelected) {
            return true;
          }
        }
        return false;
      }

      return true;
    },
    getJumpTargets(config = null) {
      if (!config) {
        config = this.config;
      }
      const projectId = this.getProjectId(config, this.projectId);
      const sameProject = !projectId || (projectId && projectId === this.projectId);
      const targetStep = this.getTargetStep(config);
      const targetSlide = this.getTargetSlide(config);
      if (!sameProject) {
        return {};
      }
      if (targetStep) {
        return {stepId: targetStep};
      } else if (targetSlide) {
        return {slideId: targetSlide};
      }

    },
    getFormDataElement(uuid) {
      return this.stepFormData[uuid];
    },
    getFormDataElementOption(elementId, uuid) {
      const element = this.getFormDataElement(elementId);
      if (element && element.length) {
        const index = element.length ? element.findIndex(item => {
          return item.uuid === uuid;
        }) : -1;
        if (index !== -1) {
          return element[index].isSelected;
        }
      }
      return false;
    },
    checkOption(option) {
      if(option.targetSlide || option.targetStep) {
        this.handleGoTo(option);
      }
    },
    handleGoTo(config = null, trigger = "default") {
      if (!config) {
        config = this.config;
      }
      if (this.editorMode) {
        return;
      }

      const {action, actionMode} = config;

      // rule based actions depending on filled out data
      if (actionMode === vFormButtonActionModes.RULES_BASED) {
        const fulFilled = this.checkConditions(config.rules);
        if (fulFilled && fulFilled.length) {
          config = fulFilled[0];
        }
      }

      const projectId = this.getProjectId(config, this.projectId);
      const projectName = this.getProjectName(config);
      const sameProject = !projectId || (projectId && projectId === this.projectId);
      const targetStep = this.getTargetStep(config);
      const targetSlide = this.getTargetSlide(config);
      const label = this.getLabel(config, this.$store.getters.getSelectedFormLanguage);
      let args = {
        trigger,
        sameProject,
        projectId,
        targetStep,
        targetSlide,
        label,
      }

      switch (action) {
        case vformButtonActions.NO_ACTION:
          return;
        case vformButtonActions.HISTORY_BACK:
          this.$emit('historyBack');
          return;
        case vformButtonActions.FIRST_SLIDE:
          this.$emit('goToFirstSlide', args);
          return;
        case vformButtonActions.LAST_SLIDE:
          this.$emit('goToLastSlide', args);
          return;
        case vformButtonActions.FIRST_STEP:
          this.$emit('goToFirstStep', args);
          return;
        case vformButtonActions.LAST_STEP:
          this.$emit('goToLastStep', args);
          return;
        case vformButtonActions.CHECKOUT:
          this.$emit("checkout", config);
          return;
      }

      if(!sameProject) {
        // go to a step in another project
        if (targetStep) {
          this.$emit('goToProject', projectId, projectName, targetStep, trigger)
          // go to a slide in another project
        } else if (targetSlide) {
          this.$emit('goToProjectSlide', projectId, projectName, targetSlide, trigger)
          // go to step in the same project
        } else {
          this.$emit('goToProject', projectId, projectName, null, trigger) // no target step
        }
      } else {
        // go to step in the same project
        if (targetStep) {
          this.$emit('goToStep', targetStep, label, trigger)
          // go to slide in the same project
        } else if (targetSlide) {
          this.$emit('goToSlide', targetSlide, label, trigger)
          // go to next slide
        } else {
          this.$emit('next', trigger);
        }
      }
    }
  }
}
</script>