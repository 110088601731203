<template>
  <div class="mb-3">
    <!--FOR EACH TRANSLATION ONE FIELD-->
    <div
        v-for="(key, index2) in getDix()"
        v-if="getDix() && [$store.getters.getSelectedFormLanguage].includes(key)"
        :key="index2 + 1000000000 + forceReRenderKey"
        class="col-12 p-0 dflex"
    >
      <div class="col p-0">
        <label class="vform-editor-label">{{ $t("Project") }}</label>
        <universal-selector-without-tag
            :after-query-filter="(item) => {return item.state === 'a'}"
            :limit-by-organization-id="organizationId"
            :selected-items="[element.linkedProjectId]"
            :set-name-for="[{ id: projectId, name: $t('currentProject') }]"
            :show-organization="false"
            class="vform-editor-universal-select"
            filter-attribute="name"
            list-name="formProjectList"
            store-name="Project"
            tag-icon="folder"
            @addItem="addProject"
        />
        <tag
            v-if="element.linkedProjectName"
            :icon-left="null"
            :is-removable="false"
            :name="element.linkedProjectId === projectId ? $t('currentProject') : element.linkedProjectName"
            :tag="{ name: element.linkedProjectName }"
            class="mt-2"
        ></tag>
        <div v-if="selectedAction === 'GoToStep' && element.linkedProjectId && !isOldVformProject">
          <label v-if="remotevForm && remotevForm.steps" class="vform-editor-label mt-2">
            {{ $t("Step") }}
          </label>
          <drop-down
              class="vform-dropdown dark pt-1 pb-1"
              open-direction="top"
              v-if="remotevForm && remotevForm.steps && remotevForm.steps.length"
              :labels="remotevForm.steps.map(item => {return item.step + ' - ' + item.name})"
              :values="remotevForm.steps.map(item => {return item.uuid})"
              :initial-active-value="targetStep ? targetStep : null"
              @select="(val) => {targetStep = val}"
          />
        </div>
        <div v-else-if="selectedAction === 'GoToSlide' && element.linkedProjectId && !isOldVformProject">
          <label
              v-if="true"
              class="vform-editor-label mt-2">
            {{ $t("Slide") }}
          </label>
          <div v-if="presentationNotAvailable">Error loading project</div>
          <drop-down
              class="vform-dropdown dark pt-1 pb-1"
              v-if="slides && slides.length"
              open-direction="top"
              :labels="getSlidesLabels(slides)"
              :values="slides.map(item => {return item.id})"
              @select="(val) => {targetSlide = val}"
              :initial-active-value="targetSlide ? targetSlide : null"
          />
        </div>
        <div v-if="isOldVformProject" class="form-error">{{ $t('vform.cannotSelectStepsFromOldVform') }}</div>
      </div>
    </div>

    <div class="w-100"/>
    <div class="col-12">
      <slot/>
    </div>
  </div>
</template>

<script>
import UniversalSelectorWithoutTag from "../organizations/UniversalSelectorWithoutTag";
import Tag from "@/components/Tag";
import {vFormControls} from "@/enum";
import vFormAndProjectMixin from "./mixins/vFormAndProjectMixin.js.vue";
import DropDown from "../forms/DropDown";

export default {
  name: "vFormPROJECT_SWITCH",
  components: {
    UniversalSelectorWithoutTag,
    Tag,
    DropDown
  },
  mixins: [vFormAndProjectMixin],
  props: {
    element: {type: Object, required: true},
    organizationId: {type: String, default: ""},
    projectId: {type: String, required: true},
    linkedProjectId: {type: String, required: false, default: ""},
    initialTargetStep: {type: String, required: false, default: ""},
    steps: {type: Array, default: null},
    selectedAction: {type: String, required: false, default: ""},
    slidesCurrentProject: {type: Array, default: null},
    dixSelector: {type: String, default: "label"},
  },
  data() {
    return {
      forceReRenderKey: 0,
      editImage: "",
      vFormControls: vFormControls,
      remotevForm: {},
      targetStep: "",
      targetSlide: "",
      isOldVformProject: false,
      presentationNotAvailable: false,
      slides: []
    };
  },
  watch: {
    dixSelector() {
      this.forceReRenderKey++;
    },
    targetStep(val) {
      if (val) {
        this.element.targetStep = val;
        this.element.targetSlide = null;
        this.$emit("stepSelected", this.element.targetStep);
      }
    },
    targetSlide(val) {
      if (val) {
        this.element.targetSlide = val;
        this.element.targetStep = null;
        let slideName = "";
        if (this.slides.length > 0) {
          let index = this.slides.findIndex((s) => s.uuid === this.element.targetSlide);
          slideName = "Slide " + (index + 1);
          this.$emit("slideSelected", val, slideName);
        }
      }
    },
  },
  beforeMount() {
    if (this.element.isCurrentProject) {
      this.element.linkedProjectId = this.projectId;
      this.linkedProjectId = this.projectId;
    }
    if (this.linkedProjectId) {
      this.element.linkedProjectId = this.linkedProjectId;
    }

    this.loadProject();
    if (this.initialTargetStep) {
      this.element.targetStep = this.initialTargetStep;
      this.targetStep = this.element.targetStep;
    }

    if (this.selectedAction === 'GoToSlide' && this.element.targetSlide) {
      this.targetSlide = this.element.targetSlide;
    }

    this.element.formElementType = vFormControls["PROJECT_SWITCH"];
    if (this.element.targetStep) {
      this.targetStep = this.element.targetStep;
    }
  },
  methods: {
    getSlidesLabels(slides) {
      const arr = [];
      for(let i = 0; i < slides.length; i++) {
        arr.push('Slide ' + (i+1));
      }
      return arr;
    },
    getDix() {
      const item = this.element[this.dixSelector];
      if (!item) {
        console.log('Problem with item, dixSelector not working')
        return [];
      }
      if (item && item.dix) {
        return Object.keys(item.dix);
      }
      console.log('item:')
      console.log(item);
      console.log('projectswitch could not load dix...')
      return [];
    },
    loadProject() {
      console.log('load project' + this.projectId);
      if (!this.element.linkedProjectId) {
        console.log('has no linked project id – linking...')
        this.$set(this.element, "linkedProjectId", this.projectId);
        this.$set(this.element, "isCurrentProject", true);
      }
      if (this.element.linkedProjectId === this.projectId) {
        this.remotevForm.steps = this.steps;
        this.slides = this.slidesCurrentProject;
        if (this.slides) {
          return;
        }
      }

      if (!this.element.linkedProjectId) {
        return;
      }
      this.$store.dispatch("loadProject", {id: this.element.linkedProjectId})
          .then((data) => {
            this.element.linkedProjectName = data.name;
            if (this.element.linkedProjectId) {
              this.addProject(data);
            }
          });
    },
    async addProject(project) {
      this.$set(this.element, "isCurrentProject", this.projectId === project.id);
      this.$set(this.element, "linkedProjectId", project.id);
      this.$emit("projectSelected", project.id);
      this.$set(this.element, "linkedProjectName", project.name);
      this.forceReRenderKey++;

      if (this.selectedAction === 'GoToStep') {
        //load first from file storage
        let args = {id: project.id, key: `vformConfig.json`};

        await this.$store.dispatch("clientDownloadProject", args).then(async (file) => {
          try {
            console.log(file.text);
            this.remotevForm = JSON.parse(file.text);
            console.log(this.remotevForm);
            if (!this.remotevForm.version) {
              this.isOldVformProject = true;
            }
          } catch (e) {
            console.log(e);
            this.remotevForm = null;
          }
        }).catch(async () => {
          //fallback to loading from database

          await this.$store
              .dispatch("clientLoadProjectInstances", {
                id: project.id,
                filter: "type eq form",
                include: "asset",
              })
              .then((res) => {
                this.isOldVformProject = false;
                console.log(res);
                if (res && res.length) {
                  const asset = res[0].asset; //there is never more than 1 vform attached to a project
                  const content = asset.content;
                  try {
                    this.remotevForm = JSON.parse(content);
                    console.log(this.remotevForm);
                    if (!this.remotevForm.version) {
                      this.isOldVformProject = true;
                    }
                  } catch (e) {
                    console.log(e);
                  }
                } else {
                  this.remotevForm = null;
                }
              });
        });
      } else if (this.selectedAction === 'GoToSlide') {
        if (project.id === this.projectId && this.slidesCurrentProject) {
          this.slides = this.slidesCurrentProject;
        } else {
          this.presentationNotAvailable = false;
          const projectLoadingMode = await this.getProjectLoadingMode(project.id);
          const projectConfig = await this.loadProjectConfigFileNew(project.id, {
            loadingMode: projectLoadingMode
          }).catch(() => {
            this.presentationNotAvailable = true;
          })
          this.slides = projectConfig ? projectConfig.slides : [];
        }
      }
    },
    save() {
      this.$emit("save", this.element);
    },
  },
};
</script>

<style lang="scss" scoped>
.vform-dropdown {
  font-size: 0.8rem;
}
</style>