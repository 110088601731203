import UserAuthStrategy from './UserAuthStrategy';

class B2CAuthStrategy extends UserAuthStrategy {
    constructor() {
        super();
    }
    authenticate() {
        // todo: implement!
    }
}

export default B2CAuthStrategy;