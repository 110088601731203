var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
          'collapse-handle',
          'collapse-handle-' + _vm.position ],style:(( _obj = {
          backgroundColor: _vm.panel.backgroundColor
        }, _obj[_vm.position] = '0', _obj.borderColor = _vm.panel.backgroundColor && _vm.getContrast(_vm.panel.backgroundColor, true) === 'dark' ? 'rgba(255,255,255,0.3)' : 'rgba(0,0,0,0.3)', _obj )),on:{"click":function($event){return _vm.toggleCollapse()}}},[_c('icon',{style:({
          color: _vm.iconColor
        }),attrs:{"size":"0.9","type":_vm.iconType}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }