// todo: SCRUM-44 full file
import UserAuthStrategy from './UserAuthStrategy';
import store from '@/store/store';
import {authStrategies} from '@/enum';
class LegacyAuthStrategy extends UserAuthStrategy {
    constructor() {
        super();
        this.strategyName = 'legacy';
    }
    async authenticate(credentials) {
        // todo: implement!
        const res = await store.dispatch('clientAuthLegacy', credentials);
        console.log(res);
        await store.dispatch('checkLogin', {authToken: res.authToken}).catch(e => {
            store.dispatch('changeAuthStrategy', authStrategies.VHUB);
            throw new Error(e);
        })
        // if successful:
        await store.dispatch('changeAuthStrategy', authStrategies.LEGACY)
        return res;
    }
    async getNewAuthToken() {
        console.log('this is legacy')
    }
}

export default LegacyAuthStrategy;