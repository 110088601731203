<template>
  <one-column-sidebar-left>
    <div slot="buttonsRight">
      <Button
          type="button"
          class="mb-3"
          :deactivated="!enableSaving"
          @click="save"
      >
        <icon v-if="enableSaving" type="save"/><icon v-else type="ban" /> {{ $t('SAVE') }}
      </Button>
    </div>
    <tabs slot="tabs"
          :classes="'col-12 col-md-2 col-lg-2 offset-md-1 backbutton'"
          :id="$route.params.id"
          :tab-selector="'blueprintsbackbutton'"
          custom-tab-name="blueprintsbackbutton"
          :first-inactive="true"
    />
    <tabs slot="tabs"
          :tab-selector="'blueprinttab'"
          :disabled="true"
          :arrow-style="true"
          :id="'555'"
          :first-active="true"
    />
    <div slot="mainContent">
      <h1><icon class="mr-2" type="folder" size="0.9" /> {{ $t('createNewType', {type: 'BluePrint'}) }}</h1> {{ id }}
      <organization-switcher
          @setOrganization="id => {organizationId = id}"
          @setTeam="id => {teamId = id}"
          @unsetTeam="teamId = ''"
          class="mb-2" />
      <input
          type="text"
          :class="['form-text mt-3 mb-3', $v.name.$dirty && $v.name.$error ? 'form-group--error' : '']"
          :id="'name'"
          v-model="name"
          :placeholder="$t('enternamehere')"
          @input="delayTouch($v.name)"
      >

      <div class="form-error" v-if="$v.name.$dirty && !$v.name.required && $v.name.$error">{{ $t('errors.required') }}</div>
      <div class="form-error" v-if="$v.name.$dirty && !$v.name.alphaNumSpace">{{ $t('errors.alphaNumSpaceOnly') }}</div>

      <div class="form-error" v-if="$v.name.$dirty && !$v.name.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>

      <div class="form-error" v-if="$v.name.$dirty && !$v.name.minLength">{{ $t('errors.atLeastCharacters', {num: 3}) }}</div>

      <div class="inline-edit-label">
        <b>{{ $t('description') }}</b>
      </div>
      <div>
                <textarea
                    :class="['form-text mb-3', $v.description.$dirty && $v.description.$error ? 'form-group--error' : '']"
                    :id="'description'"
                    v-model="description"
                    :placeholder="$t('enterdeschere')"
                    @input="delayTouch($v.description)"
                />
        <div class="form-error" v-if="$v.description.$dirty && !$v.description.maxLength">{{ $t('errors.atMostCharacters', {num: 128}) }}</div>
      </div>
    </div>
  </one-column-sidebar-left>
</template>

<script>
import Button from "../components/forms/Button";
import Tabs from "../components/navigation/Tabs";
import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
import Icon from "../components/Icon";
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import {alphaNumSpace} from '../customValidators';
import OrganizationSwitcher from "../components/organizations/OrganizationSwitcher";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";

export default {
  name: "BluePrintAdd",
  components: {
    Button,
    Tabs,
    OneColumnSidebarLeft,
    Icon,
    OrganizationSwitcher
  },
  mixins: [VuelidateMixin],
  data() {
    return {
      id: '',
      enableSaving: false,
      name: '',
      description: '',
      organizationId: '',
      teamId: ''
    };
  },
  watch: {
    '$v.$invalid': function() {
      this.enableSaving = !this.$v.$invalid;
      this.$emit('enableSaving', !this.$v.$invalid);
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(127),
      alphaNumSpace,
    },
    description: {
      maxLength: maxLength(1024),
    }
  },
  methods: {
    save: async function() {
      if(this.enableSaving) {
        let args = {
          name: this.name,
          slug: this.name,
          description: this.description,
          type: 'blueprint',
        };
        args.organizationId = this.organizationId;
        if(this.teamId) {
          args.teams = [this.teamId];
        }
        await this.$store.dispatch('createBluePrint', args).then(bluePrint => {
          this.$router.push('/library/blueprint/'+bluePrint.id+'/general');
        });
      }
    },
  },
}
</script>