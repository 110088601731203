import { render, staticRenderFns } from "./VIDEODISPLAY.vue?vue&type=template&id=399df370&"
import script from "./VIDEODISPLAY.vue?vue&type=script&lang=js&"
export * from "./VIDEODISPLAY.vue?vue&type=script&lang=js&"
import style0 from "./VIDEODISPLAY.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports