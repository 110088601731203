<template>
  <div style="width: 100%; height: 100%">
    <!--hotspot dots-->
    <div
        id="hotspot-container"
        :class="['hotspot-container', state.hotspotPopupVisible ? 'fade' : '']"
        :style="{
          width: width ? width + 'px' : '100%',
          height: height ? height + 'px' : '100%'
        }"
    >
      <div v-for="(hotspot, index) in slideMeta.hotspots"
           v-if="!state.hotspotPopupVisible"
           :key="activeSlideUuid + '_' + index"
           :class="['hotspot-dot-container', state.selectedHotspot.uuid === hotspot.uuid ? 'selected' : '', ]"
           :hotspotIndex="index"
           :slideUuid="activeSlideUuid"
           :style="{
                        left: hotspot.x || hotspot.x === 0 ? hotspot.x + '%' : 'unset',
                        top: hotspot.y || hotspot.y === 0 ? hotspot.y + '%' : 'unset'
                      }">
        <div class="d-flex">
          <div
              :class="['hotspot-icon', !editorMode ? 'pulsate' : '', state.activeColumn === 'hotspots' ? 'can-drag' : '']"
              @click="selectHotspot(hotspot, index, activeSlideUuid)"
              @mouseout="hideHotspotTooltip(hotspot)"
              @mouseover="showHotspotTooltip(hotspot)"
          />
          <div v-if="canShowHotspotTooltip(hotspot)"
               :class="['custom-tooltip-1', hotspot.x <= 50 ? 'tooltip-right' : 'tooltip-left']"
               @click="selectHotspot(hotspot, index, activeSlideUuid)"
               @mouseout="hoveringHotspotLabel(false)"
               @mouseover="hoveringHotspotLabel(true)">
            {{ hotspot.label.dix[lang] }}
          </div>
          <BUTTON_ITEM :ref="'button' + index"
                       :config="hotspot"
                       :lang="lang"
                       style="display: none"
                       @goToProject="goToProject"
                       @goToProjectSlide="goToProjectSlide"
                       @goToSlide="goToSlide"
                       @goToStep="goToStep"
                       @nextSlide="nextSlide"/>
        </div>
      </div>
    </div>

    <!--learning dots-->
    <!--<div
        v-if="showLearningDots()"
        id="hotspot-container"
        :class="['learn hotspot-container', state.hotspotPopupVisible ? 'fade' : '']">
      <div v-for="(learningDot, index) in learningDots"
           :key="activeSlideUuid + '_' + index"
           :class="['hotspot-dot-container learn', state.selectedLearningDot === learningDot ? 'selected' : '',
                    learningDotSelectAnswer && learningDotSelectAnswer === learningDot ? 'high-z-index' : '']"
           :learningDotIndex="index"
           :slideUuid="activeSlideUuid"
           :style="{
                      left: learningDot.x || learningDot.x === 0 ? learningDot.x + '%' : 'unset',
                      top: learningDot.y || learningDot.y === 0 ? learningDot.y + '%' : 'unset'
                    }">
        <div class="d-flex">
          <div
              :class="['hotspot-icon learn', getLearningDotMode() === 'image' ? 'case-2' : '', state.activeColumn === 'learning-dots' ? 'can-drag' : '',
                        learningDotSelectAnswer && learningDotSelectAnswer === learningDot ? 'select-active' : '']"
              @click="selectLearningDot(learningDot, index, activeSlideUuid)">
            <div v-if="getLearningDotMode() === 'image'" @click="showAnswerOptions(learningDot)">
              <div
                  v-if="!learningDotSelectAnswer || (learningDotSelectAnswer && learningDotSelectAnswer !== learningDot)"
                  class="d-flex">
                <div v-if="!editorMode && getSelectedAnswer(learningDot)" class="p-2">
                  {{ getSelectedAnswer(learningDot) }}
                </div>
                <div v-if="!editorMode && !getSelectedAnswer(learningDot)" class="d-inline-flex no-selection">
                  [
                  <div class="p-2"/>
                  ]
                </div>
                <div v-if="editorMode" class="d-inline-flex">
                  <div class="p-2">{{ learningDot.label.dix[lang] ? learningDot.label.dix[lang] : index + 1 }}</div>
                </div>
              </div>
              <div v-if="learningDotSelectAnswer && learningDotSelectAnswer === learningDot" class="d-flex p-3"/>
              <div v-if="learningDotSelectAnswer && learningDotSelectAnswer === learningDot" class="d-flex selector">
                <div v-for="(item) in getAnswersDataSource(slideMeta, lang)"
                     :key="`item${item}`"
                     class="p-2 selector-item"
                     @click="selectAnswer(learningDot, item)">
                  {{ item }}
                </div>
              </div>
            </div>
            <div v-if="getLearningDotMode() === 'panel'">
              {{ learningDot.label.dix[lang] ? learningDot.label.dix[lang] : index + 1 }}
            </div>
          </div>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script>
import listenerMixinJs from "../../mixins/listenerMixin.js";
import BUTTON_ITEM from "./BUTTON_ITEM";
//import LearningDotsMixin from "@/components/vForm/viewer/LearningDotsMixin.js";
export default {
  name: "Hotspots",
  components: {
    BUTTON_ITEM
  },
  mixins: [/*LearningDotsMixin*/ listenerMixinJs],
  props: {
    activeSlideUuid: {type: String, default: null},
    slideMeta: {type: Object, required: true},
    learningDots: {type: Array, default: () => {return []}},
    state: {type: Object, required: true},
    editorMode: {type: Boolean, default: false},
  },
  data() {
    return {
      mouseIsOverHotspotLabel: false,
      learningDotSelectAnswer: null,
      hotspotTooltip: null,
      width: 0,
      height: 0,
    };
  },
  watch: {
    editorMode() {
      this.adjustHotspotCoverPosition();
    }
  },
  mounted() {
    //if (!this.editorMode) {
      this.addListener(window, "resize", this.adjustHotspotCoverPosition, 'hotspot-' + this.activeSlideUuid);
      //this.addListener(window, "resize", this.adjustLearningDotsCoverPosition, 'learning-dots-' + this.activeSlideUuid)
    //}
  },
  methods: {
    //learning dots
    selectLearningDot(learningDot) {
      this.state.selectedLearningDot = learningDot;
    },
    getLearningDotMode() {
      if (this.activeStepObject && this.activeStepObject.elements) {
        let listMatchElement = this.activeStepObject.elements.find((e) => e.formElementTypeString === "List Match");
        if (listMatchElement) {
          return listMatchElement.choiceLocation;
        }
      }

      return 'panel';
    },
    getLearningElement() {
      if (this.activeStepObject && this.activeStepObject.elements) {
        let listMatchElement = this.activeStepObject.elements.find((e) => e.formElementTypeString === "List Match");
        return listMatchElement;
      }
    },
    selectHotspot(hotspot, index) {
      //allow to select hotspot only if popup is closed
      if (!this.state.hotspotPopupVisible) {
        const canShowPopup = this.state.activeColumn !== "hotspots";
        this.state.selectedHotspot = hotspot;
        if (this.editorMode) {
          if (hotspot.hotspotAction === 'popup') {
            this.state.hotspotPopupVisible = canShowPopup;
          } else {
            //this.state.activeColumn = 'hotspots';
          }
        } else {
          if (hotspot.hotspotAction === 'popup') {
            this.state.hotspotPopupVisible = canShowPopup;
          } else {
            this.$refs[`button${index}`][0].handleGoTo();
          }
        }
      }
    },
    canShowHotspotTooltip(hotspot) {
      return !this.state.dragging && ((this.state.hotspotTooltip === hotspot && hotspot.label.dix[this.$store.getters.getSelectedFormLanguage]) || (this.state.selectedHotspot.uuid === hotspot.uuid && this.editorMode))
    },
    hideHotspotTooltip() {
      setTimeout(() => {
        if (!this.mouseIsOverHotspotLabel) {
          this.state.hotspotTooltip = '';
        }
      }, 20);
    },
    showAnswerOptions(learningDot) {
      if (!this.editorMode) {
        if (this.learningDotSelectAnswer === learningDot) {
          this.learningDotSelectAnswer = null;
        } else {
          this.learningDotSelectAnswer = learningDot;
        }
      }
    },
    selectAnswer(learningDot, answer) {
      this.$set(learningDot, "selectedAnswer", answer);
      let element = this.getLearningElement();
      if (element && element.options) {
        let option = element.options.find((o) => o.correctAnswer === learningDot.label.dix[this.$store.getters.getSelectedFormLanguage]);
        if (option) {
          option.selectedAnswer = answer;
        }
      }
    },
    getSelectedAnswer(learningDot) {
      let element = this.getLearningElement();
      if (element && element.options) {
        let option = element.options.find((o) => o.correctAnswer === learningDot.label.dix[this.$store.getters.getSelectedFormLanguage]);
        if (option) {
          return option.selectedAnswer;
        }

        return null;
      }
    },
    showLearningDots() {
      if (this.editorMode) {
        return this.slideMeta.learningDots && this.slideMeta.learningDots.length > 0 && this.state.activeColumn !== "hotspots";
      } else {
        return this.slideMeta.learningDots && this.slideMeta.learningDots.length > 0;
      }
    },
    showHotspots() {
      if (this.editorMode) {
        return this.slideMeta.hotspots && this.slideMeta.hotspots.length > 0 && this.state.activeColumn !== "learning-dots";
      } else {
        return this.slideMeta.hotspots && this.slideMeta.hotspots.length > 0;
      }
    },
    showHotspotTooltip(hotspot) {
      this.state.hotspotTooltip = hotspot;
    },
    hoveringHotspotLabel(hover) {
      this.mouseIsOverHotspotLabel = hover;
      if (!hover) {
        this.state.hotspotTooltip = '';
      }
    },
    adjustHotspotCoverPosition() {
      console.log('adjusting...')
      /**
       * Adjusts the size of the video container based on the first loaded image
       * */
       /* const coverImages = document.getElementsByClassName("slide-full-image");
        const coverImage = coverImages[0];
        const mediaRatio = coverImage.naturalWidth / coverImage.naturalHeight;
        const containerRatio = coverImage.clientWidth / coverImage.clientHeight;
        if (mediaRatio <= containerRatio) {
          this.width = coverImage.clientWidth;
          this.height = coverImage.clientWidth * mediaRatio;
        } else {
          this.width = coverImage.clientHeight * mediaRatio;
          this.height = coverImage.clientHeight;
        }*/
        const coverImages = document.getElementsByClassName("slide-large-image");
        const coverImage = coverImages[0];
        const renderedHeight = coverImage.clientHeight;
        const renderedWidth = coverImage.clientWidth;
        const originalRatio = coverImage.naturalWidth / coverImage.naturalHeight;
        const containerRatio = coverImage.clientWidth / coverImage.clientHeight;
        const hotspots = this.slideMeta.hotspots ? this.slideMeta.hotspots : [];
        for (let i = 0; i < hotspots.length; i++) {
          let hotspot = hotspots[i];
          const hotspotContainers = document.querySelectorAll(`div[hotspotindex="${i}"][class*="hotspot-dot-container"][slideuuid="${this.activeSlideUuid}"]`);
          hotspotContainers.forEach((hc) => {
            const topPercentage = hotspot.y / 100;
            const leftPercentage = hotspot.x / 100;
            if (containerRatio >= originalRatio) {
              //top and bottom portion of the image will be hidden
              let containerHeightToNotCropImage = coverImage.clientWidth / originalRatio;
              let adjustedTopPercentage = (topPercentage * containerHeightToNotCropImage - ((containerHeightToNotCropImage - renderedHeight) / 2)) / renderedHeight;
              hc.style.top = `${(adjustedTopPercentage * 100)}%`;
              hc.style.left = `${(leftPercentage * 100)}%`;
            } else {
              //left and right portion of the image will be hidden
              let containerWidthToNotCropImage = coverImage.clientWidth * originalRatio / containerRatio;
              let adjustedLeftPercentage = (leftPercentage * containerWidthToNotCropImage - ((containerWidthToNotCropImage - renderedWidth) / 2)) / renderedWidth;
              hc.style.top = `${(topPercentage * 100)}%`;
              hc.style.left = `${(adjustedLeftPercentage * 100)}%`;
            }
          });
        }
    },
    adjustLearningDotsCoverPosition() {
        const coverImages = document.getElementsByClassName("slide-large-image");
        const coverImage = coverImages[0];
        const renderedHeight = coverImage.clientHeight;
        const renderedWidth = coverImage.clientWidth;
        const originalRatio = coverImage.naturalWidth / coverImage.naturalHeight;
        const containerRatio = coverImage.clientWidth / coverImage.clientHeight;
        const learningDots = this.slideMeta && this.slideMeta.learningDots ? this.slideMeta.learningDots : [];
        for (let i = 0; i < learningDots.length; i++) {
          let hotspot = learningDots[i];
          const hotspotContainers = document.querySelectorAll(`div[learningDotIndex="${i}"][class*="hotspot-dot-container"][slideuuid="${this.activeSlideUuid}"]`);
          hotspotContainers.forEach((hc) => {
            const topPercentage = hotspot.y / 100;
            const leftPercentage = hotspot.x / 100;
            if (containerRatio >= originalRatio) {
              //top and bottom portion of the image will be hidden
              let containerHeightToNotCropImage = coverImage.clientWidth / originalRatio;
              let adjustedTopPercentage = (topPercentage * containerHeightToNotCropImage - ((containerHeightToNotCropImage - renderedHeight) / 2)) / renderedHeight;
              hc.style.top = `${(adjustedTopPercentage * 100)}%`;
              hc.style.left = `${(leftPercentage * 100)}%`;
            } else {
              //left and right portion of the image will be hidden
              let containerWidthToNotCropImage = coverImage.clientWidth * originalRatio / containerRatio;
              let adjustedLeftPercentage = (leftPercentage * containerWidthToNotCropImage - ((containerWidthToNotCropImage - renderedWidth) / 2)) / renderedWidth;
              hc.style.top = `${(topPercentage * 100)}%`;
              hc.style.left = `${(adjustedLeftPercentage * 100)}%`;
            }
          });
        }
    },
    //emit goto events
    nextSlide() {
      this.$emit("nextSlide", {});
    },
    goToSlide(uuid) {
      this.$emit("goToSlide", uuid, {});
    },
    goToSlideNo(no) {
      this.$emit("goToSlideNo", no, {});
    },
    goToStep(stepUuid) {
      this.$emit("goToStep", stepUuid, {});
    },
    goToProjectSlide(projectUuid, projectName, targetSlide = null) {
      this.$emit("goToProjectSlide", projectUuid, projectName, targetSlide, {});
    },
    goToProject(projectUuid, projectName, targetStep = null) {
      this.$emit("goToProject", projectUuid, projectName, targetStep, {});
    },
  },
}
</script>

<style lang="scss" scoped>
.hotspot-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);

  z-index: 30;

  &.fade {
    background-color: var(--vform-editor-ui-secondary-color);
    opacity: 0.7;
    transition: all 600ms ease-in-out;
  }
}

.hotspot-dot-container {
  position: absolute;
  cursor: pointer;
  z-index: 2;

  &.learn.selected {
    box-shadow: 0 0 9px 6px white;
  }
}
.hotspots-active {
  z-index: 30;

  &.panel-visible {
    opacity: 0.8;
  }
}

.hotspot-icon {
  width: 20px;
  height: 20px;
  background-color: orangered;
  border-radius: 50%;
  box-shadow: 0 0 5px 3px white;
  z-index: 2;

  &.pulsate {
    animation-name: stretch;
    animation-duration: 1.0s;
    animation-timing-function: ease-out;
    animation-direction: alternate;
    animation-iteration-count: infinite;
    animation-play-state: running;

    &:hover {
      //animation: none;
    }
  }

  &.can-drag {
    background-size: 5px 5px;
    background-image: linear-gradient(to right, darkred 1px, transparent 1px),
    linear-gradient(to bottom, darkred 1px, transparent 1px);
    border-color: darkred;
  }

  &.learn {
    &.can-drag {
      background-size: 5px 5px;
      background-image: none;
    }

    background-color: #6a6ade;
    border-radius: 5%;
    width: fit-content;
    min-width: 35px;
    padding: 0 8px 0 8px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;

    &.case-2 {
      background-color: #1e7e34;
    }

    .no-selection {
      padding-bottom: 2px;
    }

    &.select-active {
      width: 42px;
    }
  }
}

@keyframes stretch {
  0% {
    transform: scale(0.9);
  }

  50% {
  }

  100% {
    transform: scale(1.3);
  }
}

.custom-tooltip-1 {
  white-space: nowrap;
  position: absolute;
  background-color: white;
  border-radius: 25px;
  z-index: 0;
  align-items: center;
  top: -3px;
  bottom: -3px;
  padding: 0 0.5em;
  display: flex;
  font-size: 1.05em;
  transition: all 300ms ease;
}

.tooltip-left {
  right: -4px;
  padding-right: calc(30px + 0.5em);
}

.tooltip-right {
  left: -4px;
  padding-left: calc(30px + 0.5em);
}
</style>