<template>
    <div>
        <label class="vform-editor-label section">Html Code</label>
        <div
            v-if="$store.getters.getSelectedFormLanguage === key"
            class="dflex"
            :key="index2 + 1000000000 + forceReRenderKey"
            v-for="(key, index2) in Object.keys(element.label.dix)"
        >
            <div class="col p-0">
                <textarea
                    :title="element.label.dix['Unknown']"
                    :placeholder="
                        key === 'Unknown'
                            ? placeholders.EMBED
                            : $t('vform.translationMessage', {
                                  key: key,
                                  content: element.label.dix['Unknown'],
                              })
                    "
                    rows="500"
                    class="mb-1 form-text v-form-label form-text-dark"
                    type="text"
                    v-model="element.label.dix[key]"
                />
            </div>
        </div>
    </div>
</template>

<script>
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";
import { vFormControls, placeholders } from "@/enum";

export default {
    name: "vFormCODE_SNIPPET",
    components: {
    },
    props: {
        element: { type: Object, required: true },
        stepNo: { type: Number, required: true },
        organizationId: { type: String, default: "" },
    },
    mixins: [vFormElementMixin],
    data() {
        return {
            forceReRenderKey: 0,
            editImage: null,
            vFormControls: vFormControls,
            placeholders: placeholders,
        };
    },
    beforeMount() {
        this.element.formElementType = vFormControls["CODE_SNIPPET"];
    },
    methods: {},
};
</script>
<style lang="scss" scoped>
textarea.form-text{
  min-height: 200px;
}
</style>
