<template>
  <div>
    <label class="vform-editor-label section">Question</label>
    <!--todo: remove this completely unnecessary for loop, only need to display the current lang dix-->
    <div
        v-if="$store.getters.getSelectedFormLanguage === key"
        class="dflex"
        :key="index2 + 1000000000 + forceReRenderKey"
        v-for="(key, index2) in Object.keys(element.label.dix)"
    >
      <div class="col p-0">
                <textarea
                    :title="element.label.dix['Unknown']"
                    :placeholder="
                        key === 'Unknown'
                            ? placeholders.LONG
                            : $t('vform.translationMessage', {
                                  key: key,
                                  content: element.label.dix['Unknown'],
                              })
                    "
                    rows="300"
                    class="form-text v-form-label form-text-dark"
                    type="text"
                    v-model="element.label.dix[$store.getters.getSelectedFormLanguage]"
                />

        <!--ANSWERS-->
        <div
            v-if="false"
            class="dflex mt-1 row pl-1"
            :key="index4 + 98239283"
            v-for="(item, index4) in element.options"
        >
          <div class="col-12 col-xxl-6 dflex">
            <div class="col pl-1 pr-0">
                            <textarea
                                :placeholder="
                                    (key === 'Unknown' ? '' : key) +
                                    ' ' +
                                    $t('answer') +
                                    ' ' +
                                    (index4 + 1)
                                "
                                rows="300"
                                class="form-text v-form-label form-text-dark"
                                type="text"
                                v-model="item.text.dix[key]"
                            />
            </div>
          </div>
          <div class="col-12 col-xxl-6 dflex mt-1">
            <div class="col-10 pl-1 pr-0">
              <div v-if="index2 === 0" class="col-12 dflex row">
                <input
                    :id="'chkCorrect' + $vnode.key + index4"
                    type="checkbox"
                    v-model="item.isCorrectAnswer"
                />
                <label :for="'chkCorrect' + $vnode.key + index4">{{
                    $t("isCorrectAnswer")
                  }}</label>
              </div>
            </div>
            <div class="col-2 pl-0 pr-0 text-right">
              <Button class="icon-button" @click="removeOption(index4)">
                <icon type="trash-alt"/>
              </Button>
            </div>
          </div>
          <div
              class="w-100 mt-1 text-right col"
              v-if="index4 === element.options.length - 1"
          >
            <Button class="icon-button" @click="addOption(key)">
              <icon type="plus"/>
            </Button>
          </div>
        </div>
      </div>
    </div>
    <label class="vform-editor-label section">Options</label>
    <div class="col row dflex">
      <label class="container vform-editor-body" :for="'chkEmphasized' + $vnode.key"
      >{{ $t("emphasized") }}
        <input
            type="checkbox"
            :id="'chkEmphasized' + $vnode.key"
            v-model="element.emphasized"
        />
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="col row dflex">
      <label class="container vform-editor-body" :for="'chkRequired' + $vnode.key"
      >{{ $t("isRequired") }}
        <input
            type="checkbox"
            :id="'chkRequired' + $vnode.key"
            v-model="element.isRequired"

        />
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="col row dflex">
      <label class="container vform-editor-body" :for="'chkTextArea' + $vnode.key"
      >{{ $t("vform.textArea") }}
        <input
            type="checkbox"
            :id="'chkTextArea' + $vnode.key"
            v-model="element.textArea"
            @change="(val) => { if (val && !element.textAreaRows) { this.$set(this.element, 'textAreaRows', 3); }}"
        />
        <span class="checkmark"></span>
      </label>
    </div>
    <div v-if="element.textArea">
      <label class="vform-editor-label section mt-1">Rows</label>
      <label class="section ml-2 vform-editor-body slider-label">{{ element.textAreaRows }}</label>
      <slider :default-value="3" :initial-value="element.textAreaRows"
              :interval="1" :max="10" :min="2"
              @change="(val) => { this.$set(this.element, 'textAreaRows', val) }"></slider>
    </div>
    <component-logger
        :element="element"
        :organization-id="organizationId"
        :teams="teams"
    />
    <!--DIX-->
    <!--FOR EACH TRANSLATION ONE FIELD-->
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import Button from "@/components/forms/Button";
import {vFormControls, placeholders} from "@/enum";
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";
import Slider from "@/components/widgets/editors/Slider";
import "vue-slider-component/theme/default.css";
import ComponentLogger from "./ComponentLogger.vue"

export default {
  name: "vFormTEXT",
  components: {
    Icon,
    Button,
    Slider,
    ComponentLogger
  },
  props: {
    element: {type: Object, required: true},
    stepNo: {type: Number, required: true},
    organizationId: {type: String, default: ""},
    teams: {type: Array, required: true},
  },
  mixins: [vFormElementMixin],
  data() {
    return {
      forceReRenderKey: 0,
      editImage: "",
      vFormControls: vFormControls,
      placeholders: placeholders,
    };
  },
  watch: {
    element: {
      deep: true,
      handler() {
        if (this.element.validateCorrectness && !this.element.options.length) {
          this.addOption("Unknown");
        }
      },
    },
  },
  beforeMount() {
    if (!this.element.options) {
      this.element.options = [];
    }
    this.element.formElementType = vFormControls["SHORT_ANSWER"];
  },
  methods: {
    removeOption(index) {
      this.element.options.splice(index, 1);
    },
    addOption(lang) {
      if (!this.element.options) {
        this.$set(this.element, "options", []);
      }
      this.element.options.push({
        isCorrectAnswer: false,
        isChecked: false,
        text: {
          dix: {
            [lang]: "",
          },
          imageDix: {
            [lang]: "",
          },
        },
      });
    },
    addTranslation(lang) {
      this.element.label.dix[lang] = {
        isRequired: false,
        emphasized: false,
        showastiles: false,
      };
      this.addOption(lang);

      this.forceReRenderKey++;
    },
  },
};
</script>
