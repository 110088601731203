<template>
  <div>
    <div class="dflex mt-4">
      <div class="d-flex mb-3">
        <div
            v-for="(displayOption, index) in displayOptions"
            :key="index"
            :class="[
                    'vform-text-option',
                    displayOption === element.displayOption ? 'active' : '',
                    index === 0 ? 'left' : '',
                    index === displayOptions.length - 1 ? 'right' : '',
                    index !== 0 && index !== displayOptions.length - 1 ? 'middle' : ''
                ]"
            @click="setDisplayOption(displayOption)"
        >
          <p>{{ $t("vform." + displayOption) }}</p>
        </div>
      </div>
    </div>

    <div class="d-flex" v-if="element.displayOption === 'upload'">
      <div class="col row">
        <label class="vform-editor-label section mt-0">File</label>
        <div class="smaller lighter">{{ $t('VideoSizeHint') }}</div>
      </div>
      <div
          v-for="(key, index2) in Object.keys(element.label.dix)"
          v-if="$store.getters.getSelectedFormLanguage === key"
          :key="index2 + forceReRenderKey"
          class="dflex col-auto pr-0"
      >
        <div>
          <div :key="forceReRenderKeyDisplay" class="d-flex">

            <v-form-image-dix-cell
                :element="element"
                :image-dix="element.label.imageDix"
                :media-category="8"
                :organization-id="organizationId"
                :v-s-t-a-g-e-context="vSTAGEContext"
                preview-size="medium"
            />
          </div>
        </div>
      </div>
    </div>

    <template v-else>
      <div class="d-flex mb-3">
        <div
            v-for="(embedOption, index) in embedOptions"
            :key="index"
            :class="[
                    'vform-text-option',
                    embedOption === element.embedOption ? 'active' : '',
                    index === 0 ? 'left' : '',
                    index === embedOptions.length - 1 ? 'right' : '',
                    index !== 0 && index !== embedOptions.length - 1 ? 'middle' : ''
                ]"
            @click="setEmbedOption(embedOption)"
        >
          <p>{{ $t("vform." + embedOption) }}</p>
        </div>
      </div>
        <div
            v-for="(key, index2) in Object.keys(element.label.dix)"
            v-if="$store.getters.getSelectedFormLanguage === key"
            :key="index2 + forceReRenderKey"
            class="dflex col-auto pr-0 pl-0"
        >
        <template v-if="['vimeo', 'youtube'].includes(element.embedOption)">
          <div class="vform-label white col-11 pl-0">{{ $t('VideoId') }}</div>
          <info-helper
              v-if="element.embedOption === 'vimeo'"
              class="ml-2 justify-content-end"
              :text="$t('VideoIdHint') + ': https://vimeo.com/[' + $t('YourIdHere') + ']'"
          />
          <info-helper
              v-else
              class="ml-2 justify-content-end"
              :text="$t('VideoIdHint') + ': https://www.youtube.com/watch?v=[' + $t('YourIdHere') + ']'"
          />

          <input
              type="text"
              :value="element.videoDix && element.videoDix[key] && element.videoDix[key].videoId ? element.videoDix[key].videoId : ''"
              @change="(event) => {setVideoId(element.videoDix, key, event.target.value)}"
              class="mb-1 form-text v-form-label form-text-dark"
          />
        </template>
        <template v-else>
          <div class="vform-label white">{{ $t('EmbedCode') }}</div>
          <textarea
              :value="element.videoDix && element.videoDix[key] && element.videoDix[key].embedCode ? element.videoDix[key].embedCode : ''"
              @change="(event) => {setVideoEmbed(element.videoDix, key, event.target.value)}"
              class="mb-1 form-text v-form-label form-text-dark full-width"
              type="text" />
        </template>
      </div>

    </template>
    <div class="dflex mt-3">
      <div class="col row">
        <label class="vform-editor-label section mt-0">Alignment</label>
      </div>
      <div
          v-for="(align, index) in alignments"
          :key="index"
          :class="[
                    'vform-alignment-button',
                    align === element.alignment ? 'active' : '',
                ]"
          @click="setAlignment(align)"
      >
        <icon :type="'align-' + align" size="1"/>
      </div>
    </div>
    <label class="vform-editor-label section">Width</label>
    <label class="section ml-2 vform-editor-body slider-label"
    >{{  this.size ? `${this.size}%` : 'AUTO' }}</label
    >
    <slider :default-value="null" :initial-value="size"
            :interval="10" :max="100" :min="0"
            @change="(val) => { this.$set(this, 'size', val) }"></slider>
    <div class="col pl-0 pr-0 dflex">
      <div class="col pl-0">
        <label class="mt-3 vform-editor-label section">{{ $t("showAsTile") }}</label>
      </div>
      <div class="col-auto pl-0 pr-0" style="margin: auto">
        <div v-if="true" class="col-12 dflex row">
          <label :for="'chkshowastiles' + $vnode.key" class="container" style="padding-left: 5px !important">
            <input
                :id="'chkshowastiles' + $vnode.key"
                v-model="element.showastiles"
                type="checkbox"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
    <div v-if="!element.embedOption || element.embedOption !== 'other'" class="col pl-0 pr-0 dflex">
      <div class="col pl-0">
        <label class="mt-3 vform-editor-label section">{{ $t("AutoPlay") }}</label>
      </div>
      <div class="col-auto pl-0 pr-0" style="margin: auto">
        <div v-if="true" class="col-12 dflex row">
          <label :for="'chkautoPlay' + $vnode.key" class="container" style="padding-left: 5px !important">
            <input
                :id="'chkautoPlay' + $vnode.key"
                v-model="element.autoPlay"
                @change="checkAutoPlay()"
                type="checkbox"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
    <div v-if="!element.embedOption || element.embedOption !== 'other'" class="col pl-0 pr-0 dflex">
      <div class="col pl-0">
        <label class="mt-3 vform-editor-label section">{{ $t("Muted") }}</label>
      </div>
      <div class="col-auto pl-0 pr-0" style="margin: auto">
        <div v-if="true" class="col-12 dflex row">
          <label :for="'chkMuted' + $vnode.key" class="container" style="padding-left: 5px !important">
            <input
                :id="'chkMuted' + $vnode.key"
                v-model="element.muted"
                type="checkbox"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vFormImageDixCell from "@/components/vForm/vFormImageDixCell";
import {vFormControls} from "@/enum";
import Icon from "../Icon";
import InfoHelper from "../InfoHelper";
import Slider from "@/components/widgets/editors/Slider";

export default {
  name: "vFormVIDEO",
  components: {
    vFormImageDixCell,
    Slider,
    Icon,
    InfoHelper
  },
  props: {
    element: {type: Object, required: true},
    stepNo: {type: Number, required: true},
    organizationId: {type: String, default: ""},
    vSTAGEContext: {type: Boolean, default: false}
  },
  data() {
    return {
      forceReRenderKey: 0,
      forceReRenderKeyDisplay: 0,
      editImage: "",
      vFormControls: vFormControls,
      size: 100,
      alignments: ["left", "center", "right"],
      displayOptions: ["upload", "embed"],
      embedOptions: ["vimeo", "youtube", "other"]
    };
  },
  watch: {
    size() {
      this.$set(this.element, "size", this.size);
    },
  },
  beforeMount() {
    this.element.formElementType = vFormControls["VIDEO"];
    if(!this.element.videoDix) {
      this.$set(this.element, "videoDix", {});
    }
    if(!this.element.imageDix) {
      this.$set(this.element, "imageDix", {});
    }
    if (!this.element.displayOption) {
      this.$set(this.element, "displayOption", "upload");
    }
    if (!this.element.embedOption) {
      this.$set(this.element, "embedOption", "");
    }
    this.size = this.element.size ? this.element.size : this.size;
  },
  methods: {
    checkAutoPlay() {
      if(this.element.autoPlay) {
        this.$set(this.element, "muted", true);
      }
    },
    setVideoId(el, lang, value) {
      if(!el[lang]) {
        this.$set(el, lang, {});
      }
      this.$set(el[lang], "videoId", value)
    },
    setVideoEmbed(el, lang, value) {
      if(!el[lang]) {
        this.$set(el, lang, {});
      }
      this.$set(el[lang], "embedCode", value)
    },
    setEmbedOption(option) {
      this.$set(this.element, "embedOption", option);
      this.forceReRenderKey++;
    },
    setDisplayOption(option) {
      this.$set(this.element, "displayOption", option);
      this.forceReRenderKey++;
    },
    save() {
      // todo: cleanup unused properties
      this.$emit("save", this.element);
    },
    setAlignment(val) {
      this.$set(this.element, "alignment", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.smaller {
  font-size: 0.6rem;
}
</style>
