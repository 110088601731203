<template>
  <div>
    <label class="label-prop-tab-separator mt-4"> {{ $t("onclick") }}</label>
    <div class="prop-tab-separator"/>
    <label class="vform-editor-label section">{{ $t("Action") }}</label>
    <select v-model="selectedAction" class="custom-select form-text-dark">
      <option v-for="(action, index) in actions" :key="index" :value="action">
        {{ $t("vform." + action) }}
      </option>
    </select>
    <v-form-project-switch
        v-if="[vformButtonActions.GO_TO_STEP, vformButtonActions.GO_TO_SLIDE].includes(selectedAction)"
        :key="forceReRenderKey + 600"
        :element="element"
        :organization-id="organizationId"
        :project-id="projectId"
        :selected-action="selectedAction"
        :slides-current-project="slides"
        :steps="steps"
        :dix-selector="dixSelector"
        class="mt-2"
    />
    <v-form-checkout
        v-else-if="[vformButtonActions.CHECKOUT].includes(selectedAction)"
        :element="element"
        class="mt-2"
    />
  </div>
</template>

<script>
import vFormProjectSwitch from "./vFormPROJECT_SWITCH";
import {vformButtonActions} from "../../enum";
import vFormCheckout from "./vFormCheckout.vue";

export default {
  name: "vFormGoToComponent",
  components: {vFormProjectSwitch, vFormCheckout},
  props: {
    organizationId: {type: String, default: ""},
    slides: {type: Array, default: null},
    projectId: {type: String, required: true},
    element: {type: Object, required: true},
    steps: {type: Array, default: null},
    dixSelector: {type: String, default: "label"},
    defaultAction: {type: String, default: "NextSlide"}
  },
  data() {
    return {
      forceReRenderKey: 0,
      actions: Object.values(vformButtonActions),
      vformButtonActions: vformButtonActions,
      selectedAction: "NoAction"
    };
  },
  watch: {
    selectedAction() {
      this.$emit('selectedAction', this.selectedAction);
      // this is necessary because otherwise the project switch doesn't update the steps/slides on action switch
      this.forceReRenderKey++;
      this.$set(this.element, "action", this.selectedAction)
    },
    element() {
      this.forceReRenderKey++;
      this.setSelectedAction();
    }
  },
  beforeMount() {
    this.setSelectedAction()
  },
  methods: {
    setSelectedAction() {
      if(this.element.action) {
        this.selectedAction = this.element.action;
      } else {
        // todo: remove legacy at some point – we add the action explicitely
        this.selectedAction = this.element.targetSlide ? "GoToSlide" : (this.element.targetStep ? "GoToStep" : this.defaultAction);
      }
    }
  }
}
</script>
