<template>
  <div id="slideshow-div"
       :class="['slideshow full-height',
       editorMode ? 'with-slider' : '',
       showImages ? 'with-background' : 'without-background',
       ]">
    <!--<div style="position:fixed;top:0;left:0;z-index:1000;color:#fff;background-color:#000">{{ killSwitchActive }}</div>-->
      <portal-target name="slideShowPopupSection" slim />
    <div v-if="vformConfig" id="slideshow-inner">
      <div v-if="showVersionMessage"
           class="version-error p-2"
           key="versionMessage"
      >
        This form was created with an old version of the form editor.<br>
        Some functionalities might be broken. Go to vhub.ch to the edit page
        of the form and update it there.
      </div>
      <div v-if="error" class="no-slide-error p-3">
        {{ error }}
      </div>
      <portal-target name="form-popup" />
      <popup
          v-if="clearingFormData"
          key="clearingFormData"
          @close="() => {clearingFormData = false;}"
      >
        <delete-prompt
            slot="popupMainContent"
            custom-message="vform.sureClearingFormData"
            @abort="() => {clearingFormData = false;}"
            @confirm="() => {clearFormData();}"
        />
      </popup>
      <popup
          v-if="leaving"
          key="leaving"
          @close="() => {leaving = false;}"
      >
        <delete-prompt
            slot="popupMainContent"
            custom-message="sureLeavePresentation"
            @abort="() => {leaving = false;}"
            @confirm="() => {leave();}"
        />
      </popup>
      <!-- SLIDEOUT -->
      <div v-if="!editorMode" :class="['slide-out p-3', showSlideOut ? 'open' : 'closed']">
        <!--<div style="z-index: 1000; position: fixed;top:0;left:0; background-color: rgba(0,0,0,0.8); color: white;padding:15px; max-height: 100vh; overflow: scroll;">
          <pre style="color: white">{{ stepFormData }}</pre>
        </div>-->
        <label
            :class="['container mb-3 mt-2', !kioskMode ? '' : 'd-none']"
            for="chkEmphasizedstp"
        >{{ $t("setKioskMode") }}
          <input
              id="chkEmphasizedstp"
              v-model="kioskMode"
              type="checkbox"
          >
          <span class="checkmark"/>
        </label>
        <div v-if="kioskModeNotification && kioskMode" class="p-3 mb-2 cclight-notification">
          {{ $t('kioskLeaveHint', {title: name}) }}
        </div>
        <div v-if="kioskMode">
          {{ $t('Kiosk Mode') }}
        </div>
        <div v-if="!kioskMode"
             class="settings-button d-inline-block clickable mt-2 mb-2"
             @click="$emit('reload'); reloadNotification = true;">
          {{ $t('RefreshForm') }}
        </div>
        <br>
        <div v-if="!kioskMode"
             class="settings-button d-inline-block clickable mt-2 mb-2"
             @click="clearFormData()">
          {{ $t('vform.clearFormData') }}
        </div>
        <br>
        <!--<div class="settings-button d-flex clickable mt-2 mb-2 fit-content"
             @click="() => { this.$emit('exportToPdf', this.projectId); }">
          <icon v-if="!state.pdfExportProcessing" class="mr-2" type="pdf"/>
          <loading-spinner v-if="state.pdfExportProcessing" class="white mr-2"/>
          {{ $t("PDF") }}
        </div>-->
        <div v-if="reloadNotification" class="p-3 mb-2 cclight-notification">
          {{ $t('reloadMessage') }}
          <loading-spinner class="d-inline-block ml-3"/>
        </div>

        <label
            :class="['container mb-3 mt-2', !kioskMode ? '' : 'd-none']"
            for="loggingDisabledLocally"
        >{{ $t("disableLoggingForThisSession") }}
          <input
              id="loggingDisabledLocally"
              :checked="$store.getters.getvFormMode === vformModes.TEST"
              @click="setLogging"
              type="checkbox"
          >
          <span class="checkmark"/>
        </label>
        <label
            :class="['container mb-3 mt-2', !kioskMode ? '' : 'd-none']"
            for="keepLoggingDisabled"
        >{{ $t("keepLoggingDisabled") }}
          <input
              id="keepLoggingDisabled"
              :checked="getPermanentlyDisabledLogging()"
              @click="setLogging(getPermanentlyDisabledLogging())"
              type="checkbox"
          >
          <span class="checkmark"/>
        </label>
        <!--<div class="refresh-message">vForm is refreshing now</div>-->
        <div v-if="!$route.query.hideLeaveButton && !kioskMode && !vSTAGEContext"
             class="settings-button leave-button clickable mb-2 d-inline-block mb-2"
             @click="leave">
          {{ $t('leavePresentation') }}
        </div>
        <span style="position: absolute; bottom: 15px; right:30px;" class="lighter">{{ version }}</span>
      </div>
      <div v-if="goingToProject" class="go-to-project p-3 border-radius">
        Going to project
        <loading-spinner class="white loading-display"/>
      </div>
        <step
            v-if="activeStepObject && slides && slides.length && vformConfig && $store.getters.getSelectedFormLanguage && showStepPanel(activeSlideUuid)"
            :key="'_step_' + activeStepObject.uuid + forceReRenderKey"
            ref="activeStep"
            :active-mode="activeMode"
            :disable-links="disableLinks"
            :editable="editorMode"
            :editor-mode="editorMode"
            :form-settings="vformConfig.formSettings"
            :globals="vformConfig.global"
            :has-next-button="!isLastSlide()"
            :is-current-step="true"
            :last-slide-active="true"
            :next-button-allowed="nextButtonAllowed || isLastSlideOfStep || editorMode"
            :project-id="projectId"
            :state="state ? state : {activeElement: activeSlideUuid}"
            :step="activeStepObject"
            :step-form-data="stepFormData"
            :steps="vformConfig && vformConfig.steps ? vformConfig.steps : []"
            :v-s-t-a-g-e-context="vSTAGEContext"
            :vform-config="vformConfig"
            :teams="teams"
            :organization-id="organizationId"
            :project-name="name"
            :template-asset-id="templateAssetId"
            @fullyLoaded="fullyLoaded"
            @goToProject="goToProject"
            @goToProjectSlide="goToProjectSlide"
            @goToSlide="goToSlide"
            @goToStep="goToStep"
            @log="parseAndSaveLogEntries"
            @nextSlide="nextSlide"
            @removeBlock="(uuid) => {$emit('removeBlock', uuid)}"
            @setActiveColumn="(col) => {$emit('setActiveColumn', col)}"
            @setJumpTargets="setJumpTargets"
            @setLanguage="(newlang) => {
              $store.dispatch('updateSelectedFormLanguage', newlang)
            }"
            @setUserId="setUserId"
            @signOut="signOut"
            @stepMounted="$emit('stepMounted')"
            @storeData="storeData"
            @wrongAnswer="wrongAnswer"
            @historyBack="historyBack"
            @checkout="checkout"
            @templateFileChanged="$emit('templateFileChanged')"
            @editTemplate="editTemplate"
        />

      <!--  SLIDE HOTSPOTS -->
      <template
          v-if="activeSlideUuid && activeSlideMeta && activeSlideMeta.hotspots"
      >
        <hotspots
            style="z-index: 10"
            :slide-meta="activeSlideMeta"
            :state="state"
            :active-slide-uuid="activeSlideUuid"
            :editor-mode="editorMode"
            :lang="$store.getters.getSelectedFormLanguage"
            @goToProject="goToProject"
            @goToProjectSlide="goToProjectSlide"
            @goToSlide="goToSlide"
            @goToStep="goToStep"
            @nextSlide="nextSlide"
        />
        <slide-hotspots-popups
            :slide-meta="activeSlideMeta"
            :key="'slideHotspotsPopups' + activeSlideUuid"
            :active-slide-uuid="activeSlideUuid"
            :active-step-object="activeStepObject"
            :autoplaying="autoplaying"
            :editor-mode="editorMode"
            :lang="$store.getters.getSelectedFormLanguage"
            :project-id="projectId"
            :state="state"
            :v-s-t-a-g-e-context="vSTAGEContext"
            :vform-config="vformConfig"
            @goToProject="goToProject"
            @goToProjectSlide="goToProjectSlide"
            @goToSlide="goToSlide"
            @goToStep="goToStep"
            @nextSlide="nextSlide"
            @removeBlock="(uuid) => {$emit('removeBlock', uuid)}"
        />
      </template>

      <!--do NOT add activeslideuuid to v-if or the first transition won't be done-->
      <background-video
          v-if="videoConfig && videoConfig.length && showImages && projectId && loaded && homeSlide"
          ref="videobg"
          :active-step-object="activeStepObject"
          :editor-mode="editorMode"
          :home-slide="homeSlide"
          :offline-mode="state.offlineMode"
          :project-id="projectId"
          :source-slide-uuid="oldActiveSlideUuid"
          :target-slide-uuid="activeSlideUuid"
          :video-config="videoConfig"
          :images="images"
          :jump-targets="necessarySlides"
          class="video-background"
          @showImage="imageMode = true;"
          @showVideo="imageMode = false;"
      />

      <!--  SLIDE IMAGES -->
      <template
          v-for="(slide) in slides"
          v-if="slideImageMode !== 'background' && showImages && necessarySlides.includes(slide.id)"
      >
        <slide-image-display
            :key="'slideImageDisplay' + forceReRenderSlideImageKey + slide.id"
            :active-slide-uuid="activeSlideUuid"
            :active-step-object="activeStepObject"
            :autoplaying="autoplaying"
            :editor-mode="editorMode"
            :images="images"
            :jump-targets="necessarySlides"
            :lang="$store.getters.getSelectedFormLanguage"
            :mini-images="miniImages"
            :old-active-slide-uuid="oldActiveSlideUuid"
            :project-id="projectId"
            :set-all-inactive="!imageMode"
            :slide="slide"
            :state="state"
            :v-s-t-a-g-e-context="vSTAGEContext"
            :vform-config="vformConfig"
            :slide-image-mode="slideImageMode"
            :active-slide-meta="activeSlideMeta"
            :form-settings="vformConfig.formSettings"
            @goToProject="goToProject"
            @goToProjectSlide="goToProjectSlide"
            @goToSlide="goToSlide"
            @goToStep="goToStep"
            @loaded="loaded = true"
            @nextSlide="nextSlide"
        />
      </template>


      <!--  BOTTOM BAR -->
      <div v-if="showBottomBar" :class="['vform-bottom-bar d-flex', editorMode ? 'pointer-events-none' : '']">
        <preview
            v-if="(logoAssetId || localAssetName) && !editorMode"
            :local-asset-name="localAssetName"
            :asset-id="logoAssetId"
            :background-mode="true"
            :class="['bottom-bar-logo preview bg-white']"
            :may-load-now="true"
            :offline-mode="state.offlineMode"
            :removable="false"
            preview-id="vformlogo"
            source="asset"
        />
        <div
            v-if="!editorMode"
            :class="['form-name justify-content-start align-items-center']"
            @mousedown="startTouchTimer"
            @mouseup="resetTouchTimer"
            @touchend="resetTouchTimer"
            @touchstart="startTouchTimer">
          <div class="text">{{ getFormName() }}</div>
        </div>
        <div v-if="!editorMode" class="col vform-view-bottom-buttons justify-content-end align-items-center d-flex">
          <div class="mr-3">
            <div class="userdisplay">
              <icon v-if="$store.getters.getVFormUserId && !editorMode" class="mr-1" type="user"/>
              {{ $store.getters.getVFormUserId }}
            </div>
          </div>
          <div v-if="vSTAGEContext"
               class="mr-3 vform-bottom-icon" @click="repeatGoToSlide">
            <icon size="1" type="redo"/>
          </div>
          <language-selector
              :exclude="getLanguagesNotTranslated(vformConfig.languages)"
              :use-plain-language="true"
              :value="$store.getters.getSelectedFormLanguage"
              class="mr-3 language-selector-bottom-bar align-self-center no-shadow"
              open-direction="top"
              @update="(newlang) => {$store.dispatch('updateSelectedFormLanguage', newlang)}"
          />
          <div v-if="showEditButton" class="mr-3 vform-bottom-icon clickable">
            <icon type="edit"/>
          </div>
          <div v-if="!state || !state.offlineMode" class="mr-3 vform-bottom-icon clickable"
               @click="showHideSlideout(!showSlideOut)">
            <icon size="0.95" type="sfx-icon-horizontal-more"/>
          </div>
          <div class="mr-3 vform-bottom-icon clickable" @click="goHome">
            <icon size="1.15" type="sfx-icon-home"/>
          </div>
          <div
              :class="['mr-3 vform-bottom-icon clickable', history.length === 0 || historyPointer === 0 ? 'inactive' : 'active']"
              @click="historyBack">
            <icon size="0.7" type="sfx-icon-arrow-left"/>
          </div>
          <div
              :class="['mr-3 vform-bottom-icon clickable', history.length === 0 || historyPointer === history.length - 1 ? 'inactive' : 'active']"
              @click="historyForward">
            <icon size="0.7" type="sfx-icon-arrow-right"/>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="!editorMode" class="loading-screen">
      slideshow loading 2
      <loading-spinner/>
    </div>
    <popup
        v-if="showHomeNavigatePrompt"
        :elevated-z-index="10005"
        @close="showHomeNavigatePrompt = false"
    >
      <div slot="popupMainContent">
        <delete-prompt
            :custom-message="$t('RestartOrGoHome')"
            button-abort-text="GoTofirstslide"
            button-confirm-text="resetSession"
            @abort="() => {navigateHome(); showHomeNavigatePrompt = false;}"
            @confirm="() => {signOut(); navigateHome(); showHomeNavigatePrompt = false;}"
        />
      </div>
    </popup>
    <portal
        to="vformEditorPopup"
    >
      <popup
            @close="() => {templateEditing = null;}"
            v-if="templateEditing"
      >
        <div slot="popupMainContent">
          <template-editor
              :object="templateEditing"
              :template-asset-id="templateAssetId"
              @templateSaved="templateEditing = null"
              @templateFileChanged="$emit('templateFileChanged')"
          />
        </div>
      </popup>
    </portal>

  </div>
</template>

<script>/**
 * Readme:
 * The state is mostly dependent on activeSlideUuid – if this one is set, everything else
 * falls into place
 * */


import SlideImageDisplay from "@/components/vForm/viewer/SlideImageDisplay";
import SlideHotspotsPopups from "@/components/vForm/viewer/SlideHotspotsPopups";
import LoadingSpinner from "@/components/LoadingSpinner";
import BackgroundVideo from "./BackgroundVideo";
import Step from "@/components/vForm/viewer/Step";
import Icon from "@/components/Icon";
import loadingSpinner from "@/components/LoadingSpinner";
import Preview from "@/components/vForm/viewer/Preview";
import LanguageSelector from "@/components/forms/LanguageSelector";
import Popup from "../../Popup";
import DeletePrompt from "../../forms/DeletePrompt";
import {screenSizes, PlainLanguages, vformModes} from "@/enum";
import vFormAndProjectMixin from "../mixins/vFormAndProjectMixin.js.vue";
import loggingMixin from "./loggingMixin";
import Hotspots from "./Hotspots";
import TemplateEditor from "@/components/vForm/TemplateEditor.vue";
import {vFormEvents} from "@/enum";
import {
  getElementQuestion,
  getElementInfoForCheckout,
  getElementListForm
} from "../mixins/checkoutMethods";
export default {
  name: "SlideShowDisplay",
  components: {
    SlideImageDisplay,
    SlideHotspotsPopups,
    Step,
    Icon,
    loadingSpinner,
    Preview,
    LanguageSelector,
    Popup,
    DeletePrompt,
    LoadingSpinner,
    BackgroundVideo,
    Hotspots,
    TemplateEditor,
  },
  mixins: [vFormAndProjectMixin, loggingMixin],
  props: {
    projectId: {type: String, required: true},
    formId: {type: String, required: true},
    activeStepIndex: {type: Number, default: 0},
    /**
     * only for vSTAGE context: whether vform is currently displayed or not
     * if not, stop video and audio and other things happening in the background
     * */
    activeMode: {type: Boolean, default: true},
    showImages: {type: Boolean, default: true},
    showEditButton: {type: Boolean, default: false},
    /**
     * If this is given, the slide show display won't be loading the vform content
     * but use this one
     * **/
    vformConfig: {type: Object, default: null},
    /**
     * The slides array coming from the config.json of a vSTAGE package
     * */
    slides: {type: Array, default: null},
    /**
     * shared state between different vform components (different from vformConfig as we do not want to store it)
     * */
    state: {type: Object, default: () => {return {}}},
    /**
     * Whether or not the slide show is displayed with the editor
     * */
    editorMode: {type: Boolean, default: false},

    /**
     * this is the config.json file if the cclight is deployed as its own webapp
     * */
    appConfig: {
      type: Object, required: false, default: () => {
        return {}
      }
    },
    initialSlideId: {type: String, default: null},
    initialStepId: {type: String, default: null},
    /**
     * Links won't be clickable if set to true
     * */
    disableLinks: {type: Boolean, default: false},
    /***/
    activeSlideUuid: {type: String, default: null},
    activeStepObject: {type: Object, default: null},
    vSTAGEContext: {type: Boolean, default: false},
    scaling: {type: String, default: 'NONE'},
    customScreenSize: {
      type: Object, default: () => {
        return {width: 0, height: 0};
      }
    },
    screenOrientation: {type: String, default: 'landscape'},
    killSwitchActive: {type: Boolean, default: false},

    /**
     * The config file for video playing instead of images
     * */
    videoConfig: {type: Array, default: null},

    /**
     * The current project loading mode:
     * - offline
     * - online legacy structure
     * - online current structure
     * */
    projectLoadingMode: {type: String, default: ""},
    activeSlideMeta: {type: Object, default: null},
    slideImageMode: {type: String, default: 'cover'},
    teams: {type: Array, required: true},
    organizationId: {type: String, required: true},
    templateAssetId: {type: String, required: true}
  },
  data() {
    return {
      showBottomBar: true,
      version: process.env.VUE_APP_CCLIGHTVERSION,
      imageLoadMax: 3,
      loaded: false,
      imageMode: true,
      kllSwitchActiveData: this.killSwitchActive,
      goingToProject: false,
      leaving: false,
      clearingFormData: false,
      forceReRenderKey: 0,
      forceReRenderSlideImageKey: 0,
      miniImages: {},
      images: {},
      /**
       * Touch timer is used for leaving the kiosk mode
       * */
      touchTimer: null,
      /**
       * kioskMode hides the exit button
       * **/
      kioskMode: false,
      kioskModeNotification: false,
      reloadNotification: false,
      showSlideOut: false,
      /**
       * The base64 encoded image data, uuid as the key
       * */

      /**
       * The vForm config
       * **/
      showVersionMessage: false,
      isLastSlideOfStep: false,
      isFirstSlideOfStep: true,
      logoAssetId: null,
      localAssetName: "",

      name: "",

      /**
       * History variables used for history forward and history back functionality
       * */
      history: [],
      historyPointer: 0,

      carouselThumnailsLoading: false,

      error: "",

      autoPlayTimer: null,

      screenSizes: screenSizes,

      contextMenuStep: null,
      contextMenuPanel: null,
      nextButtonAllowed: true,
      autoplaying: false,
      requestTimeoutRunning: false,

      stepFormData: {},
      jumpTargets: [],
      oldActiveSlideUuid: "",

      // while
      autoplayStepLogged: false,
      showHomeNavigatePrompt: false,

      fullyLoadedSteps: [],

      /**
       * Home slide is slide zero
       * */
      homeSlide: null,
      vFormEvents: vFormEvents,
      templateEditing: null,
    };
  },
  computed: {
    necessarySlides() {
      const slidesWithoutGhosts = this.getSlidesWithoutGhosts(this.slides);
      const index = slidesWithoutGhosts.findIndex(item => {
        return item.id === this.activeSlideUuid
      });
      const necessarySlides = [this.activeSlideUuid];
      if (this.oldActiveSlideUuid) {
        necessarySlides.push(this.oldActiveSlideUuid);
      }
      if (slidesWithoutGhosts[index - 1]) {
        necessarySlides.push(slidesWithoutGhosts[index - 1].id);
      }
      if (slidesWithoutGhosts[index + 1]) {
        necessarySlides.push(slidesWithoutGhosts[index + 1].id);
      }
      for (let i = 0; i < this.jumpTargets.length; i++) {
        necessarySlides.push(this.jumpTargets[i]);
      }
      return necessarySlides;
    },
  },
  watch: {
    '$route.query.userId'() {
      this.setUserIdByUrl();
    },
    imageMode(val) {
      if(!val) {
        this.imageLoadMax = 1;
      } else {
        this.imageLoadMax = 3;
      }
    },
    activeStepObject: {
      deep: true,
      handler() {
        if (this.activeStepObject.autoPlay) {
          this.nextButtonAllowed = false;
        } else {
          this.killSwitchActiveData = true;
        }
      }
    },
    projectId() {
      // todo: remove this??
      this.loadProjectName();
    },
    /**
     * Enters and leaves the kiosk mode (hiding the exit buttons)
     * */
    kioskMode(val) {
      // eslint-disable-next-line no-undef
      this.$cookies.set('vformKioskMode', val ? val : false);
      this.$emit("kioskMode", val);
      window.vFormKioskMode = val;
      if (val) {
        this.kioskModeNotification = true;
      }
    },
    /*performanceMode(val) {
      const value = val ? "low" : "normal";
      // eslint-disable-next-line no-undef
      this.$cookies.set('performanceMode', value);
      this.$emit("performanceMode", value);
      if (val) {
        this.performanceMode = true;
      }
    },*/
    /**
     * The uuid of the current slide,
     * sets the step belonging to this slide and sets the history marker
     * so – all you need to do is set the activeSlideUuid and everything else with magically happen
     * - activeStepIndex
     * - setStepActive -> activeStep
     * */
    activeSlideUuid(oldval, newval) {
      this.activeSlideUuidThing(oldval, newval)
    },
    editorMode(val) {
      this.loadThumbnailsNew(this.projectId, {loadingMode: this.projectLoadingMode}, this.slides, this.activeSlideUuid, this.imageLoadMax);

      this.startStopAutoPlay("editorMode");
      this.forceReRenderSlideImageKey++;
      if (!val) {
        this.setPlayModeState();
        this.doResizeOrDoNot(true);
      } else {
        this.doResizeOrDoNot()
        this.eraseAllData();
        this.error = "";
      }
    },
    scaling() {
      this.doResizeOrDoNot();
    },
    customScreenSize() {
      this.doResizeOrDoNot();
    },
    screenOrientation() {
      this.doResizeOrDoNot();
    },
  },
  beforeDestroy() {
    console.log('slide show being destroyed!! help!!')
  },
  beforeMount() {
    this.goingToProject = false;
    // eslint-disable-next-line no-undef
    const lang = $cookies.get('vFormLanguage');
    if (lang) {
      // eslint-disable-next-line no-undef
      this.$store.dispatch('updateSelectedFormLanguage', lang)
    }
    this.setUpWhenReady();
    this.setKioskMode();
    this.setHomeSlide();
    this.activeSlideUuidThing();
    this.setUserIdByUrl();
  },
  mounted() {
    if (this.scaling !== 'NONE') {
      this.resizeSlideShow();
    }
    if(this.getPermanentlyDisabledLogging()) {
      this.$store.dispatch('setvFormMode', vformModes.TEST)
    }
  },
  methods: {
    editTemplate(config) {
      this.templateEditing = config;
    },
    setUserIdByUrl() {
      if(this.$route.query && this.$route.query.userId) {
        const urlUserid = this.$route.query.userId;
        if (urlUserid) {
          console.log('userid is currently: ', urlUserid);
          this.setUserIdByInput(urlUserid);
          this.userInput = urlUserid; // todo: make this better, is a hotfix
        }
      }
    },
    showHideBottomBar() {
      this.showBottomBar = !this.showBottomBar;
    },
    getPermanentlyDisabledLogging() {
      return window.localStorage.getItem('keepLoggingDisabled') === 'true';
    },
    setLogging(keepPermanent = false) {
      if(!keepPermanent) {
        this.$store.dispatch('setvFormMode', vformModes.TEST);
        window.localStorage.setItem("keepLoggingDisabled", true)
      } else {
        this.$store.dispatch('setvFormMode', this.$store.getters.getvFormMode === vformModes.TEST ? vformModes.PRODUCTION : vformModes.TEST);
        window.localStorage.setItem("keepLoggingDisabled", false)
      }
    },
    setHomeSlide() {
      const slides = this.getSlidesWithoutGhosts(this.slides);
      this.homeSlide = slides[0].id;
    },
    getLanguagesNotTranslated(translatedLanguages) {
      let languagesNotTranslated = [];
      Object.keys(PlainLanguages).forEach((key) => {
        if (translatedLanguages && !translatedLanguages.find((l) => {
          return l === key;
        })) {
          languagesNotTranslated.push(key);
        }
      });

      return languagesNotTranslated;
    },
    /**
     * Loads thumbnails based on the current active slide uuid
     * */
    async loadThumbnailsNew(projectId, config, slides, activeSlideUuid, limit) {
      // if we are in vstage playmode, do not load the thumbnails!
      if(this.vSTAGEContext && !this.editorMode) {
        return;
      }
      if (!projectId) {
        throw new Error('loadThumbnailsNew: no projectId given')
      }
      if (!slides) {
        throw new Error('loadThumbnailsNew: no slides given')
      }
      let index = 0;
      if (activeSlideUuid) {
        index = slides.findIndex(slide => {
          return slide.id === activeSlideUuid;
        })
      }
      for (let i = index; i < slides.length && i < index + limit; i++) {
        const slide = slides[i];
        if (slide.ghostSlide) {
          continue;
        }
        if(this.images[slide.id]) {
          continue;
        }
        const miniImage = await this.loadThumbnailNew(projectId, slide, i, {...config, isThumbnail: true, mini: true, format: 'url'});
        this.$set(this.miniImages, slide.id, miniImage);
        const imageData = await this.loadThumbnailNew(projectId, slide, i, {...config, mini: false, format: 'url'});
        this.$set(this.images, slide.id, imageData);
      }
    },
    fullyLoaded(stepUuid) {
      this.$emit('fullyLoaded');
      if (!this.fullyLoadedSteps.includes(stepUuid)) {
        this.fullyLoadedSteps.push(stepUuid);
      }
      this.initializeAutoPlay();
    },
    /**
     * Autoplay will be started when:
     * 1 step gets changed
     * 2 fullyLoaded event triggered from the step itself
     * 3
     * */
    initializeAutoPlay() {
      //leave a bit of delay, so that the requestAnimationFrame has time to pickup the change, then re-call startStopAutoplay
      this.stopAutoPlay();
      setTimeout(() => {
        this.startStopAutoPlay("fullyLoaded");
      }, 100);
    },
    async goHome() {
      if (this.userId) {
        this.showHomeNavigatePrompt = true;
      } else {
        await this.navigateHome();
      }
    },
    /**
     * Gets all targetSlides for buttons etc and sets them as jump targets
     * @params {uuid} - the uuid of a step
     * */
    setJumpTargets(stepUuid, targets) {
      this.jumpTargets = [];
      for (let i = 0; i < targets.length; i++) {
        const {slideId, stepId} = targets[i];
        if (slideId) {
          this.jumpTargets.push(slideId);
        } else if (stepId) {
          const stp = this.vformConfig.steps.filter(item => {
            return item.uuid === stepId
          });
          if (stp && stp[0]) {
            stp[0].linkedSlides.map(slide => {
              this.jumpTargets.push(slide);
            })
          }
        }
      }
    },
    /**
     * Stores the data for a specific step
     * @params {uuid} - stepId
     * @params {object} - data
     *  example for data:
     *    {
     *      elementUuid1: {
     *        value: "myval"
     *      },
     *      elementUuid2: {
     *        value: "something"
     *      }
     *    }
     * */
    storeData(elementId, data) {
      if (!data) {
        return;
      }
      this.$set(this.stepFormData, elementId, data);
    },
    clearFormData(showWarning = true) {
      if (this.clearingFormData || !showWarning) {
        this.eraseAllData();
        if(this.showSlideOut) {
          this.showHideSlideout(!this.showSlideOut)
        }
        this.clearingFormData = false;
        console.log('clearing form data...')
        this.forceReRenderKey++;
        this.$store.dispatch("createNotification", {
          text: this.$t("events.formDataCleared"),
        });
      } else {
        this.clearingFormData = true;
      }
    },
    eraseAllData() {
      console.log('erasing all data')
      this.setUserId(null);
      this.stepFormData = {};
      this.loggedStarted = false;
    },
    async activeSlideUuidThing(newval, oldval = "") {
      this.oldActiveSlideUuid = oldval;
      if (!oldval) {
        this.oldActiveSlideUuid = this.activeSlideUuid;
      }
      if (this.slides && this.activeSlideUuid && !this.images[this.activeSlideUuid]) {
        await this.loadThumbnailsNew(this.projectId, {loadingMode: this.projectLoadingMode}, this.slides, this.activeSlideUuid, this.imageLoadMax);
      }
      this.checkLastSlideOfStep();
      this.makeHistory(this.activeSlideUuid);
      this.$emit('selectSlide', this.activeSlideUuid);
    },
    setKioskMode() {
      // eslint-disable-next-line no-undef
      const hideLeaveButton = $cookies.get('vformKioskMode');
      if (this.vformConfig.alwaysKioskMode || (!!this.vformConfig.formSettings && !!this.vformConfig.formSettings.alwaysKioskMode)) {
        this.kioskMode = true;
      } else if (hideLeaveButton === 'true') {
        this.kioskMode = true;
      } else {
        this.kioskMode = false;
      }
    },
    doResizeOrDoNot(forceReset = false) {
      if (this.scaling !== 'NONE' && !forceReset) {
        this.resizeSlideShow();
      } else {
        this.resetSlideShowResize();
      }
      console.log('do resize or not')
      this.forceReRenderKey++;
    },
    setPlayModeState() {
      this.state.hotspotPopupVisible = false;
      this.state.selectedHotspot = {};
    },
    checkLastSlideOfStep() {
      this.isLastSlideOfStep = false;
      this.isFirstSlideOfStep = false;
      if (this.activeStepObject && this.activeSlideUuid === this.activeStepObject.linkedSlide) {
        this.isFirstSlideOfStep = true;
      }
      const slides = this.getSlidesWithoutGhosts(this.slides);
      if (slides) {
        const startSlideIndex = slides.findIndex(item => {
          return item.id === this.activeSlideUuid
        });
        if (this.slides[startSlideIndex + 1]) {
          const hasStep = this.vformConfig.steps.filter(item => {
            return item.linkedSlide === this.slides[startSlideIndex + 1].id
          });
          if (hasStep && hasStep.length) {
            this.isLastSlideOfStep = true;
          }
        } else {
          this.isLastSlideOfStep = true; // if there is no startSlideIndex + 1, then we are on the last slide
        }
      }
    },
    noop() {
      this.requestTimeoutRunning = false;
    },
    registerCancel() {
      return this.killSwitchActiveData || this.editorMode || (this.isLastSlideOfStep && !this.activeStepObject.loop) || !this.activeStepObject.autoPlay;
    },
    requestTimeout(fn, delay) {
      const start = new Date().getTime();
      const loop = () => {

        const delta = new Date().getTime() - start;
        if (delta >= delay * 1.2) {
          fn();
          if (this.registerCancel()) {
            this.noop();
          }
          return;
        }

        const raf = requestAnimationFrame(loop);
        if (this.registerCancel()) {
          cancelAnimationFrame(raf)
        }
      };
      const raf = requestAnimationFrame(loop);
      if (this.registerCancel()) {
        cancelAnimationFrame(raf)
      }
    },
    stopAutoPlay(/*caller*/) {
      this.killSwitchActiveData = true;
      this.nextButtonAllowed = true;
      this.autoplaying = false;
      this.requestTimeoutRunning = false;
    },
    /***
     * starts or stops the autoplay based on the conditions
     * @params starterItem {string} - a string saying who started the autoplay
     * */
    startStopAutoPlay() {

      // autoplay gets started by fullyLoaded event if step is initially loaded
      // if step gets called the second time, autoplay will be triggered from here

      if (!this.fullyLoadedSteps.includes(this.activeStepObject.uuid)) {
        console.log('is not fully loaded, not starting autoplay')
        return;
      }
      this.autoplayStepLogged = false;
      // leave this here or it will mark the lastSlide too late and short autoplay with no loop will fail
      this.checkLastSlideOfStep();
      if (this.activeStepObject && this.activeStepObject.autoPlay && !this.editorMode) {
        if (!this.autoplaying) {
          //this.stopAutoPlay();
          this.killSwitchActiveData = false;
          this.autoplaying = true;
          this.nextButtonAllowed = false;
          this.autoPlay('from start stop autoplay');
        }
      } else {
        this.nextButtonAllowed = true;
        this.stopAutoPlay('from startStopAutoPlay');
      }
    },
    autoPlay(/*from*/) {
      if (this.killSwitchActiveData) {
        return;
      }
      const $this = this;

      if (this.requestTimeoutRunning || this.registerCancel()) {
        return;
      }

      this.requestTimeoutRunning = true;
      this.requestTimeout(async () => {

        if ($this.editorMode) {
          $this.stopAutoPlay('from auto-play going to editor mode ' + this.requestTimeoutRunning);
          return;
        }
        if (!$this.isLastSlideOfStep) {
          await $this.nextSlide({}, true, true);
          $this.requestTimeoutRunning = false;
          $this.autoPlay();
        } else if ($this.isLastSlideOfStep) {
          await $this.goToSlide(this.activeStepObject.linkedSlide, {}, true, true);
          $this.nextButtonAllowed = true;
          $this.requestTimeoutRunning = false;
          $this.autoPlay();
        } else {
          $this.requestTimeoutRunning = false;
          $this.stopAutoPlay();
        } // do not make the delay shorter than 1100, it will just hang in between
      }, this.getAutoPlayDelay());
    },
    getAutoPlayDelay() {
      return this.activeStepObject.autoPlayInterval ? this.activeStepObject.autoPlayInterval * 1000 : 2500;
    },
    resetSlideShowResize() {
      const id = 'slideshow-inner';
      const outerDiv = 'slideshow-div';
      document.getElementById(id).style.removeProperty('position');
      document.getElementById(outerDiv).style.removeProperty('position');
      document.getElementById(id).style.removeProperty('transform');
      document.getElementById(id).style.removeProperty('overflow');
      document.getElementById(id).style.removeProperty('width');
      document.getElementById(id).style.removeProperty('height');
    },
    /**
     * This will resize the thingy so it looks like full screen, only smaller
     * */
    resizeSlideShow() {
      const id = 'slideshow-inner';
      const outerDiv = 'slideshow-div';
      let width = '100vw';
      let height = '100vh';
      if (this.screenOrientation === 'portrait') {
        width = '100vh';
        height = '100vw';
      }
      if (this.scaling === 'NONE' || !this.scaling) {
        this.resetSlideShowResize();
        return;
      } else if (this.scaling !== "DEFAULT") {
        let obj = this.customScreenSize;
        if (this.scaling !== "CUSTOM") {
          obj = this.screenSizes[this.scaling];
        }

        width = obj.width + 'px';
        height = obj.height + 'px';
        if (this.screenOrientation === 'portrait') {
          width = obj.height + 'px';
          height = obj.width + 'px';
        }
      }

      document.getElementById(id).style.position = 'absolute';
      document.getElementById(outerDiv).style.position = 'relative';
      document.getElementById(id).style.width = width;
      document.getElementById(id).style.height = height;
      document.getElementById(id).style.overflow = 'hidden';
      let source = document.getElementById(id);
      const sWidth = window.getComputedStyle(source).width.replace('px', '');
      const sHeight = window.getComputedStyle(source).height.replace('px', '');

      let target = document.getElementById(outerDiv);
      const tWidth = window.getComputedStyle(target).width.replace('px', '');
      const tHeight = window.getComputedStyle(target).height.replace('px', '');

      const scalingFactor = (sWidth / sHeight) > (tWidth / tHeight) ? tWidth / sWidth : tHeight / sHeight;
      document.getElementById(id).style.transform = 'scale(' + scalingFactor + ') translate(-50%,-50%)';

      source = null; // garbage collection
      target = null; // garbage collection
    },
    repeatGoToSlide() {
      this.$emit('repeatSlide', this.activeSlideUuid);
    },
    leave() {
      if (this.leaving) {
        this.$emit('leave');
        this.showHideSlideout(false);
        this.leaving = false;
      } else {
        this.leaving = true;
      }
    },
    getGlobalElements() {
      let res = [];
      this.vformConfig.steps.map(item => {
        const tmp = item.elements.filter(el => {
          return el.upperDropZone;
        });
        if (tmp && tmp.length) {
          res = [...res, ...tmp];
        }
      })
      return res;
    },
    setUpWhenReady() {
      this.loadProjectName();
      this.createSession();
      this.setLogo();
    },
    checkout(args) {
      const checkoutObject = {
        id: this.formId,
        args: {
          formData: this.getFormData(),
          config: {
            sendEmailTo: args.emailAddress,
            userId: this.userId,
            sessionId: this.$store.getters.getVFormSessionId,
          }
        }
      }
      this.$store.dispatch('commitCheckout', checkoutObject);
      this.resetUserSession();
      this.clearFormData(false);
    },
    getFormData() {
      const allElements = {};
      const globals = this.vformConfig.global;
      const steps = this.vformConfig && this.vformConfig.steps ? this.vformConfig.steps : [];
      for (let elementId of getElementListForm(this.stepFormData)) {
        let el = {};
        el.question = getElementQuestion(elementId, globals, steps, this.$store.getters.getSelectedFormLanguage);
        el.answer = getElementInfoForCheckout(elementId, this.stepFormData, this.$store.getters.getSelectedFormLanguage);
        allElements[elementId] = el;
      }
      return allElements;
    },
    getFormName() {
      if (this.state.offlineMode) {
        return this.vformConfig.name;
      } else {
        return this.name;
      }
    },
    loadProjectName() {
      if (this.projectId) {
        this.$store.dispatch('clientLoadProject', {id: this.projectId})
            .then(proj => {
              if (proj) {
                this.name = proj.name;
                this.$emit('setProjectName', proj.name)
              }
            })
      }
    },
    showHideSlideout(val) {
      this.showSlideOut = val;
      this.kioskModeNotification = false;
      this.$emit("slideOut", val);
    },
    /**
     * Used for exiting the kiosk mode via klicking for 8 seconds on the vForm title in the bottom bar
     * starts the timer
     * */
    startTouchTimer() {
      this.touchTimer = Date.now();
      this.doTouchTimer();
    },
    /**
     * see method above
     * endless loop until either the user aborts or the timer reaches 8 seconds and the kiosk mode is exited
     * **/
    doTouchTimer() {
      const endTime = Date.now();
      const difference = endTime - this.touchTimer;
      if (difference > 8000) {
        this.kioskMode = false;
        return;
      }
      const $this = this;
      setTimeout(() => {
        if ($this.touchTimer) {
          $this.doTouchTimer();
        }
      }, 500);
    },
    resetTouchTimer() {
      this.touchTimer = null;
    },
    /**
     * Helper method
     * checks if a given step is the current step
     * @params step {Object} - a step object
     * */
    isCurrentStep(step) {
      return this.activeStepObject && step.uuid === this.activeStepObject.uuid;
    },
    /**
     * Helper method
     * checks if a given slide is the current slide
     * @params slide {Object} - a slide object
     * */
    showStepPanel(slideUuid) {
      if (!this.vformConfig.slidesMeta) {
        return true;
      }

      let slideMeta = this.vformConfig.slidesMeta.find((s) => s.uuid === slideUuid);
      if (slideMeta && slideMeta.learningDots && slideMeta.learningDots.length > 0) {
        return true;
      }

      return !slideMeta || (slideMeta && !slideMeta.hideStepPanel);
    },
    hasHotspots(slideUuid) {
      if (!this.vformConfig.slidesMeta) {
        return false;
      }

      let slideMeta = this.vformConfig.slidesMeta.find((s) => s.uuid === slideUuid);
      if (!slideMeta) {
        return false;
      }

      return slideMeta && ((slideMeta.hotspots && slideMeta.hotspots.length > 0) || (slideMeta.learningDots && slideMeta.learningDots.length > 0));
    },
    /**
     * Whenever the active slide changes, we write history
     * @params uuid {uuid} - the uuid of the current slide
     * */
    makeHistory(uuid) {
      // when autoplaying
      if ((this.autoplaying && !this.isFirstSlideOfStep) || (this.autoplaying && this.isFirstSlideOfStep && this.history[this.historyPointer] === this.activeSlideUuid)) {
        return;
      }
      const historyPrevLastElement = this.history.length ? this.history[this.historyPointer - 1] : null;
      const historyNextElement = this.history.length && this.history[this.historyPointer + 1] ? this.history[this.historyPointer + 1] : null;
      // case 1: history back
      if (historyPrevLastElement && historyPrevLastElement === uuid) {
        this.historyPointer = this.historyPointer - 1;
        // case 2:history forward
      } else if (historyNextElement && historyNextElement === uuid) {
        this.historyPointer = this.historyPointer + 1;
      } else {
        const pointerOnLastElement = (this.history.length && this.historyPointer === this.history.length - 1) || !this.history.length;
        // case 3: pointer is on last element or there is no history yet – just push
        if (pointerOnLastElement) {
          this.history.push(uuid);
          this.historyPointer = this.history.length - 1;
        } else {
          // case 4: we went back into history and now erasing forward history because we chose a new point
          this.history = this.history.slice(0, this.historyPointer + 1);
          this.history.push(uuid);
          this.historyPointer = this.history.length - 1;
        }
      }
      // is it a history back?
      // set new pointer
      // is it new history?
      // if pointer on last element: push
      // if pointer not on last element: truncate history forward from pointer and then push
    },
    historyBack() {
      if (this.history[this.historyPointer - 1]) {
        this.goToSlide(this.history[this.historyPointer - 1], {
          target: this.activeStepObject.uuid,
          eventType: this.vFormEvents.HISTORY_BACK,
          logs: []
        });
      }
    },
    historyForward() {
      if (this.history[this.historyPointer + 1]) {
        let data = this.getCollectedDataForCurrentStep();
        data.eventType = [this.vFormEvents.HISTORY_FORWARD];
        this.goToSlide(this.history[this.historyPointer + 1], data);
      }
    },
    getCollectedDataForCurrentStep() {
      let data = {};
      if (this.$refs.activeStep) {
        data = this.$refs.activeStep.prepareStepTransition().data;
        console.log(data);
      }
      return data;
    },
    setLogo() {
      if(this.vformConfig && this.vformConfig.formSettings && this.vformConfig.formSettings.logoAssetId) {
        const {logoAssetId} = this.vformConfig.formSettings
        this.logoAssetId = logoAssetId;
      } else if(this.appConfig && this.appConfig.sfx) {
        const {theme} = this.appConfig.sfx.project.webapp;
        const {logoName, logo} = theme;
        if(this.state.offlineMode && logoName) {
          this.localAssetName = logoName;
        } else {
          this.logoAssetId = logo;
        }
      }
    },
    getFormLogo() {
      if (this.state.offlineMode) {
        return this.vformConfig && this.vformConfig.formSettings && this.vformConfig.formSettings.logoAssetId ? this.vformConfig.formSettings.logoAssetId : this.appConfig && this.appConfig.sfx ? this.appConfig.sfx.project.webapp.theme.logo : null;
      }

      return this.$store.getters.getLogo;
    },
    /**
     * Goes to a specific slide
     * @params uuid {Uuid} - the uuid of the slide
     * @params data {Object} - the logging data if available (for more info see this.log())
     * @returns {Promise}
     * */
    async goToSlide(uuid, data = {}, doNotLog = false, autoplay = false, trigger = null) {
      if(uuid === 'last') {
        uuid = this.getLastSlideUuid(this.slides);
      } else if(uuid === 'first') {
        uuid = this.getFirstSlideUuid(this.slides);
      }
      this.onSlideChange(uuid);
      if (!autoplay) {
        //this.stopAutoPlay('from goto slide');
        this.initializeAutoPlay();
      } else if (this.killSwitchActiveData) {
        return;
      }
      this.error = "";
      data.eventType = this.vFormEvents.GO_TO_SLIDE;
      if (!doNotLog) {
        await this.parseAndSaveLogEntries(data, false, trigger);
      }

      const index = this.slides.findIndex(item => {
        return item.id === uuid
      });
      if (index !== -1) {
        this.$emit('selectSlide', uuid);
        await this.loadThumbnailsNew(this.projectId, {loadingMode: this.projectLoadingMode}, this.slides, this.activeSlideUuid, this.imageLoadMax);
      } else {
        // case: if slide uuid is not found: do nothing
        console.log("go to slide: slide not found with uuid " + uuid)
      }
    },
    /**
     * Legacy, only used for old QR codes, which have slide:5 in their metavalues
     * @params no {Integer} - the number of the slide (which of course can change if new slides are inserted)
     * @params data {Object} - the logging data if available (for more info see this.log())
     * */
    async goToSlideNo(no, data = {}, includeGhosts = true, trigger = null) {
      this.error = "";
      data.eventType = this.vFormEvents.GO_TO_SLIDE;
      await this.parseAndSaveLogEntries(data, false, trigger);
      no = parseInt(no);
      if (no >= 0 && no < this.slides.length) {
        const slides = includeGhosts ? this.slides : this.slides.filter(item => {
          return !item.ghostSlide
        });
        const uuid = slides[no].id;
        this.$emit('selectSlide', uuid);
        await this.loadThumbnailsNew(this.projectId, {loadingMode: this.projectLoadingMode}, this.slides, this.activeSlideUuid, this.imageLoadMax);
      } else {
        console.log("slide no is invalid");
      }
    },
    goNextExternal() {
      this.$refs.activeStep.doNext();
    },
    /**
     * Goes to the next slide (this is NOT history forward, but going a new path)
     * @params data {Object} - the logging data if available (for more info see this.log())
     * @returns {Promise}
     * */
    async nextSlide(data = {}, doNotLog = false, isAutoPlay = false, trigger = null) {
      // if it is autoplay, we want the click on "nextSlide" to go to the next step instead
      if (this.activeStepObject.autoPlay && !isAutoPlay) {
        await this.nextStep(data, false, trigger);
        return;
      }
      if (!isAutoPlay) {
        this.stopAutoPlay('from next slide');
      } else if (this.killSwitchActiveData) {
        return;
      }
      data.eventType = this.vFormEvents.NEXT_SLIDE;
      const uuid = this.getNextSlideUuid(this.slides, this.activeSlideUuid);
      this.onSlideChange(uuid);

      if (!doNotLog) {
        await this.parseAndSaveLogEntries(data, false, trigger); // isStepChange is false here because it doesn't matter if it is when going linear through the presentation
      }

      this.$emit('selectSlide', uuid);
      if(this.imageMode) {
        await this.loadThumbnailsNew(this.projectId, {loadingMode: this.projectLoadingMode}, this.slides, this.activeSlideUuid, this.imageLoadMax);
      }
    },
    onSlideChange(slideUuid) {
      this.state.progress = this.getSlideIndex(this.slides, slideUuid);

      let nextStep = this.getStepForSlide(
          this.slides,
          this.vformConfig.steps,
          slideUuid,
          this.state.activeSlideUuid,
          this.state.activeStep
      );

      if (nextStep.uuid === this.state.activeStep) {
        this.$emit('fullyLoaded') // if the step did not change, it is already fully loaded
      }

      this.state.hotspotPopupVisible = false;

      nextStep = null; // garbage collector
    },
    async wrongAnswer(data = {}, trigger = null) {
      data.eventType = this.vFormEvents.NEXT_SLIDE;
      await this.parseAndSaveLogEntries(data, true, trigger); // forceLogging when wrong answer
    },

    /***
     * Goes to a specific step
     * @params uuid {Uuid} - the uuid for the given step
     * @params name {String} - the name of the step
     * @params data {Object} - the logging data if available (for more info see this.log())
     * @returns {Promise}
     * */
    async goToStep(uuid, data = {}, suppressWarnings = false, trigger = null) {
      const {steps} = this.vformConfig;
      if(uuid === 'last') {
        uuid = this.getLastStepUuid(steps);
      } else if(uuid === 'first') {
        uuid = this.getFirstStepUuid(steps);
      }
      this.error = "";
      data.eventType = this.vFormEvents.GO_TO_STEP;
      await this.parseAndSaveLogEntries(data, uuid !== this.state.nextStep.uuid, trigger);
      const conf = this.getStepConfig(uuid);
      if (conf.linkedSlides && conf.linkedSlides.length) {
        await this.goToSlide(conf.linkedSlides[0], {}, true, false, trigger);
      } else {
        if (!suppressWarnings) {
          this.error = this.$t("no linked slide for this step, cannot go");
          console.log("no linked slide for this step, cannot go!");
        }
      }
    },
    /**
     * Helper method, returns either the linked slides or linked slide, if linked slides is empty
     * @params stepIndex {Integer} - the index in the steps array
     * */
    getStepSlideArray(stepIndex) {
      const step = this.vformConfig.steps[stepIndex];
      return step.linkedSlides && step.linkedSlides.length ? step.linkedSlides : [step.linkedSlide];
    },
    /**
     * History forward function
     * @params data {Object} - data for logging (see more on this.log() function)
     * **/
    async nextStep(data, doNotLog = false, trigger = null) {
      data.nextStep = true;
      if (!doNotLog) {
        await this.parseAndSaveLogEntries(data, false, trigger); // isStepChange is false here because it doesn't matter if it is when going linear through the presentation
      }

      const nextStepObject = this.state.nextStep;
      const nextSlideUuid = nextStepObject.linkedSlide;
      await this.goToSlide(nextSlideUuid, data, false, false, trigger)
    },
    /***
     * Returns the config for a certain step
     * @params uuid {Uuid} - the uuid of the step
     * */
    getStepConfig(uuid) {
      const res = this.vformConfig.steps.filter((item) => {
        return item.uuid === uuid;
      });
      if (res && res.length) {
        return res[0];
      }
      return {};
    },
    /**
     * Triggers the go to project
     * @params uuid {Uuid} - the id of the project
     * @params name {String} - the name of the project
     * @params stepUuid {Uuid} - the id of the step
     * @params data {Object} - an object containing the data, for more info see this.log() function
     * **/
    async goToProject(uuid, name, stepUuid = null, data = {}, trigger) {
      this.goingToProject = true;
      if (uuid) {
        let forceLogging = false;
        const step = this.getStepConfig(stepUuid);
        let targetSlide = step && step.linkedSlide ? step.linkedSlide : null;
        if (uuid !== this.projectId) {
          targetSlide = await this.getForeignSlideBystep(uuid, stepUuid);
          forceLogging = true;
        }
        data.eventType = this.vFormEvents.GO_TO_PROJECT;
        await this.parseAndSaveLogEntries(data, forceLogging ? forceLogging : stepUuid !== this.state.nextStep.uuid, trigger);
        this.$emit('goToProject', uuid, stepUuid, targetSlide);
      } else {
        console.log('cannot go to project because uuid is empty');
      }
    },
    async goToProjectSlide(uuid, name, targetSlide, data = {}, trigger = null) {
      if (uuid) {
        let forceLogging = false;
        if (uuid !== this.projectId) {
          forceLogging = true;
        }
        data.eventType = this.vFormEvents.GO_TO_PROJECT;
        // todo check force logging condition here
        await this.parseAndSaveLogEntries(data, forceLogging, trigger);
        this.$emit('goToProjectSlide', uuid, targetSlide);
      } else {
        console.log('cannot go to project because uuid is empty');
      }
    },
    async getForeignSlideBystep(projectUuid, stepUuid) {
      return await this.$store.dispatch('clientLoadProjectInstances', {
        id: projectUuid,
        filter: 'type eq form'
      }).then(async formInstances => {
        if (formInstances && formInstances.length) {
          return await this.$store.dispatch('clientLoadAsset', {
            id: formInstances[0].assetId
          });
        }
      }).then(formAsset => {
        const content = formAsset.content;
        try {
          const parsedContent = JSON.parse(content);
          const res = parsedContent.steps ? parsedContent.steps.filter((item) => {
            return item.uuid === stepUuid;
          }) : [];
          if (res && res.length) {
            return res[0].linkedSlide;
          }
          return null;
        } catch (e) {
          console.log('target form could not be parsed')
          console.log(e);
          return null;
        }
      }).catch(e => {
        // todo: static vforms will throw an error here because the user is not authenticated
        console.log(e);
        return null;
      })
    },
    async navigateHome() {
      this.initializeAutoPlay();
      let data = this.getCollectedDataForCurrentStep();
      await this.goToSlideNo(0, data, false);
    },
    /***
     * Checks if the current slide is the first slide of a step
     * */
    isFirstSlide() {
      let firstSlide;
      const firstStep = this.vformConfig && this.vformConfig.steps && this.vformConfig.steps.length ? this.vformConfig.steps[0] : null;
      if (firstStep) {
        firstSlide = firstStep.linkedSlides && firstStep.linkedSlides.length ? firstStep.linkedSlides[0] : firstStep.linkedSlide;
      } else {
        firstSlide = this.activeSlideUuid;
      }
      return this.activeSlideUuid === firstSlide;
    },
    /***
     * Checks if the current slide is the last slide of a step
     * */
    isLastSlide() {
      let lastSlide;
      const lastStep = this.vformConfig && this.vformConfig.steps && this.vformConfig.steps.length ? this.vformConfig.steps[this.vformConfig.steps.length - 1] : null;
      if (lastStep) {
        lastSlide = lastStep.linkedSlides && lastStep.linkedSlides.length ? lastStep.linkedSlides[lastStep.linkedSlides.length - 1] : lastStep.linkedSlide;
      } else {
        lastSlide = this.activeSlideUuid;
      }
      return this.activeSlideUuid === lastSlide;
    },
    selectHotspot(hotspot) {
      if (this.editorMode) {
        this.state.selectedHotspot = hotspot;
      } else {
        if (hotspot.selectedAction === "GoToSlide") {
          this.goToSlide(hotspot.targetSlide);
        } else if (hotspot.selectedAction === "GoToStep") {
          this.goToStep(hotspot.targetStep);
        }
      }
    },
    hideHotspotTooltip() {
      this.state.hotspotTooltip = '';
    },
    showHotspotTooltip(hotspot) {
      this.state.hotspotTooltip = hotspot;
    }
  }
}
</script>

<style lang="scss">
.language-selector-bottom-bar {
  height: 40px;
  padding-top: 10px !important;
  background-color: var(--vform-editor-ui-bottom-bar-button-color) !important;

  .language-selector {
    .options {
      &.top {
        bottom: 0;
      }
    }
  }
}

</style>

<style lang="scss" scoped>
.video-background {
  z-index: 15;
}

.go-to-project {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: var(--vform-editor-ui-secondary-color);
  z-index: 105;
  color: #fff;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: bold;

  .loading-display {
    margin: auto;
    margin-top: 8px;
  }
}

.slide-out {
  position: absolute;
  top: 0;
  bottom: 15vh; //var(--vform-default-vform-margin);
  right: -30vw; //var(--vform-default-vform-margin);
  background-color: var(--vform-editor-ui-quinary-color);
  color: var(--vform-bottom-bar-text-color);
  width: 30vw;
  height: 90vh;
  z-index: 5555;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;

  .leave-button {
    position: absolute;
    bottom: 15px;
    left: 15px;
  }

  &.open {
    right: 0;
    z-index: 5555;
  }
}

.vform-bottom-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-box-shadow: 2px 2px 10px 2px #666;
  box-shadow: 2px 2px 10px 2px #666;
  background-color: var(--vform-editor-ui-bottom-bar-color);
  color: var(--vform-bottom-bar-text-color);
  height: var(--vform-bottom-bar-height);

  .form-name {
    height: var(--vform-bottom-bar-height);
    padding: 15px;
    z-index: 55;
    min-width: 150px;
    font-size: 1.1rem;
    display: flex;

    .text {
      font-weight: bold;
    }
  }

  .vform-view-bottom-buttons {
    font-size: 1.1rem;
  }

  .vform-bottom-icon {
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    cursor: pointer;
    border-radius: 3px;
    min-width: 40px;
    height: 40px;
    text-align: center;
    background-color: var(--vform-editor-ui-bottom-bar-button-color);
    display: flex;
    justify-content: center;
    align-items: center;

    &.inactive {
      color: var(--vform-bottom-bar-inactive-color);
      cursor: default;
    }
  }

  z-index: 55;
}

@media (hover: hover) {
  .vform-bottom-bar {
    .vform-bottom-icon {
      &:not(.inactive) {
        &:hover {
          background-color: var(--vform-bottom-bar-hover-background-color);
          color: var(--vform-bottom-bar-hover-color);
        }
      }
    }
  }
}

.slideshow {
  width: 100%;
  height: 100vh;
  margin: 0;
  background-color: transparent;
  position: relative;
  overflow: hidden;
  color: #000;

  &.with-slider {
    height: calc(var(--vform-editor-vform-editor-height) - var(--vform-editor-carousel-height));
  }

  &.with-background {
    background-color: var(--vform-editor-ui-secondary-color);
  }
}

#slideshow-inner {
  top: 50%;
  left: 50%;
  transform-origin: top left;
  height: 100%;
  width: 100%;
  position: static;
  display: flex;
  justify-content: center;
}

.version-error {
  width: 100%;
  background-color: #f87606;
  color: #fff;
  z-index: 55;
  position: absolute;
  top: 0;
  left: 0;
}

.vform-viewer-loading-ellipsis {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: var(--vform-editor-layout-tertiary-color);
  opacity: 0.8;
  padding: 15px;
  border-radius: 3px;
}

.no-slide-error {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background-color: red;
  color: var(--vform-on-dark-color);
  z-index: 15;
}

.cclight-notification {
  background-color: var(--vform-editor-layout-tertiary-color);
}

.step-fade-enter-active {
  transition: all 1.2s ease-in;
}

.step-fade-leave-active {
  transition: all 0.4s cubic-bezier(1, 0.5, 0.8, 1);
}

.step-fade-enter {
  opacity: 0;
}

.step-fade-enter-to {
  opacity: 1;
}

.step-fade-enter-from,
.step-fade-leave-to {
  opacity: 0;
}

.bottom-bar-logo {
  width: 180px;
  display: flex;
  justify-content: center;
  padding: 5px;
}
</style>
