<template>
    <one-column-sidebar-left
        sub-menu-selector="librarypanel"
        back-button-tab-selector="sfxbackbutton"
        tab-selector="instancetab"
        :id="$route.params.id"
    >
    <info-panel slot="sidebarLeft"
                :id="$route.params.id"
                preview-id="sfxedit"
                upload-type="Instance"
                :created="$store.getters.getInstanceCreationDate($route.params.id)"
                :last-change="$store.getters.getInstanceChangeDate($route.params.id)"
                :organization="$store.getters.getInstanceOrganization($route.params.id)"
                :image-editable="true"
                :preview-uri="$store.getters.getInstancePreviewUri($route.params.id)"

    />
        <info-panel
                slot="sidebarLeft"
                :id="$route.params.id"
                :tags="$store.getters.getInstanceTags($route.params.id)"
                :taggable="true"
                :key="'infopanel2'"
                preview-id="sfxedit"
                :limit-tags-to-organization="$store.getters.getInstanceOrganization($route.params.id)"
        />

        <div slot="mainContent">
            <content-title-block
                    v-if="$store.getters.getInstanceName(id)"
                    :value="$store.getters.getInstanceName(id)"
                    :id="$route.params.id"
                    store-name="Sfxdata"
                    :organization-id="$store.getters.getInstanceOrganization($route.params.id)"
                    :icon="$store.getters.getInstanceType($route.params.id)"
            />
            <content-description-block
                    :value="$store.getters.getInstanceDescription(id)"
                    :id="$route.params.id"
                    store-name="Sfxdata"
            />
           <div v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin" class="row">
            <state-setter
                v-if="$store.getters.getInstanceState($route.params.id)"
              class="col-12 col-md-4 mb-2 mt-2"
              :target-id="$route.params.id"
              store-name="Sfxdata"
              :given-state="$store.getters.getInstanceState($route.params.id)"
            />
          </div>

            <div class="row">
              <div class="col-12 col-md-6 no-gutters">
                <info-panel
                  :collapsible="true"
                  info-panel-header="files"
                  preview-id="fileview"
                >
                  <div slot="info">
                    <file-list
                  :target-id="$route.params.id"
                  store-name="Instance"
                  :file-types="['fbx', 'vab']"
                />
                  </div>
                </info-panel>
              </div>
              <div class="w-100"/>
            <div class="col-12 col-md-6 no-gutters">
              <info-panel
                  preview-id="metasetattacher"
                  :collapsible="true"
                  :initially-collapsed="true"
                  info-panel-header="sfx edit"
                  v-if="$store.getters.isSuperAdmin"
              >
                <meta-set-attacher
                    slot="info"
                    :target-id="$route.params.id"
                    store-name="Instance"
                    @added="loadInstance"
                    @removed="loadInstance"
                    :given-meta-sets="$store.getters.getInstanceMetaSets($route.params.id)"
                />
              </info-panel>
            </div>
            <meta-panel
                class="col-12 col-md-6"
                type="sfxdata" :key="item.id" v-for="(item) in $store.getters.getInstanceMetaSets($route.params.id)"
                        :title="item.description" :fields="item.metaFields" icon-left="folder"
                        store-name="Sfxdata"
                        :organization-ids="[$store.getters.getInstanceOrganization($route.params.id)]"
                 @updated="loadInstance"
            />
          </div>
    </div>
  </one-column-sidebar-left>
</template>

<script>
    import InfoPanel from "../components/InfoPanel";
    import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
    import ContentTitleBlock from "../components/ContentTitleBlock";
    import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
    import MetaPanel from "../components/widgets/meta/MetaPanel";
    import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
    import StateSetter from "@/components/StateSetter";
    import FileList from "@/components/files/FileList";

    export default {
        name: 'SFXEditInstance',
        components: {
          InfoPanel,
          MetaPanel,
          OneColumnSidebarLeft,
          ContentTitleBlock,
          ContentDescriptionBlock,
          MetaSetAttacher,
          StateSetter,
          FileList
        },
        data() {
            return {
                id: '',
                teams: [],
            };
        },
        beforeMount() {
           this.loadInstance();
            this.id = this.$route.params.id;
        },
        methods: {
          loadInstance() {
              this.$store.dispatch('loadInstanceById', {
                id: this.$route.params.id,
                include: 'tags,metaSets'
              }).catch(e => {
                if(e.status === 401) {
                  this.$router.push('/access-denied');
                } else if(e.status === 404) {
                  this.$router.push('/not-found')
                }
              });
          },
    },
}
</script>