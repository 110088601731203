<template>
  <one-column-sidebar-left>
    <h1 slot="mainContent">{{ $t('Search') }}</h1>
    <div slot="mainContent">
      <!--1 allow searching for an id and check what type it is-->
      <div class="form-group">
        <label for="searchId">{{ $t('SearchId') }}</label>
        <input type="text" class="form-control" id="searchId" v-model="searchId" @keyup.enter="searchById">
      </div>
      <!-- 2 allow searching for a name and search for all types-->
      <div class="form-group">
        <label for="searchName">{{ $t('SearchName') }}</label>
        <input type="text" class="form-control" id="searchName" v-model="searchName" @keyup.enter="searchByName">
      </div>
      <div class="form-error" v-if="error">{{ error }}</div>
      <loading-spinner v-if="loading" />
      <div class="search-results">
        <div v-for="result in searchResults" :key="result.id">
          <div>
            <div class="search-result mb-2">
              <div class="type">{{ result.type }} ({{ resultType }})</div>
              <div class="search-result__id">id: {{ result.id }}</div>
              <div class="search-result__name">name: {{ result.name }} (displayName: {{ result.displayName }}</div>
              <pre v-if="result.metaValues">{{ JSON.stringify(result.metaValues, null, 2) }}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </one-column-sidebar-left>
</template>

<script>
import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
import LoadingSpinner from "../components/LoadingSpinner";
export default {
  name: 'AccessDenied',
  components: {
    OneColumnSidebarLeft,
    LoadingSpinner
  },
  data() {
    return {
      resultType: '',
      searchId: '',
      searchName: '',
      searchResults: [],
      loading: false,
      error: ''
    }
  },
  methods: {
    async searchById() {
      this.loading = true;
      this.searchResults = [];
      this.resultType = '';
      this.error = '';
      await this.$store.dispatch('clientLoadAsset', {id: this.searchId}).then((asset) => {
        if (asset) {
          this.resultType = 'asset';
          this.searchResults.push(asset);
        }
      }).catch(() => {
        this.$store.dispatch('clientLoadProject', {id: this.searchId}).then((project) => {
          if (project) {
            this.resultType = 'project';
            this.searchResults.push(project);
          }
        }).catch(() => {
          this.$store.dispatch('clientLoadInstance', {
            id: this.searchId,
            include: 'metaValues'
          }).then((instance) => {
            console.log('instance', instance);
            this.resultType = 'instance';
            if (instance) {
              this.searchResults.push(instance[0]);
            }
          });
        });
      });
      this.loading = false;
    },
    async searchByName() {
      this.loading = true;
      this.searchResults = [];
      this.resultType = '';
      this.error = '';
      // todo: search like this: filter: type like '%this.searchName%' add this to the query params
      await this.$store.dispatch('clientLoadAssets', {
        filter: 'name eq ' + this.searchName,
      }).then((assets) => {
        if (assets && assets.length) {
          this.resultType = 'asset';
          this.searchResults = assets;
        } else {
          return this.$store.dispatch('clientLoadProjects', {
            filter: 'name eq ' + this.searchName,
          })
        }
      }).then((projects) => {
        console.log('projects', projects);
        if (projects && projects.length) {
          console.log('found projects')
          this.resultType = 'project';
          this.searchResults = projects;
        } else if(!this.searchResults.length) {
          console.log('searching for instances....')
          return this.$store.dispatch('clientGetCrossProjectInstances', {
            filter: 'name eq ' + this.searchName,
            include: 'metaValues'
          });
        }
      }).then((instances) => {
        if (instances && instances.length) {
          this.resultType = 'instance';
          this.searchResults = instances;
        } else if(!this.searchResults.length) {
          this.error = 'No results found';
        }
      });
      this.loading = false;
    }
  }
}
</script>
