<template>
  <div>
    <h2>Create project from blueprint</h2>
    <div class="constraint-list">
      <div class="constraint" v-for="(constraint, index) in bluePrint.constraints" :key="constraint.id + forceReRenderKey">
        <div class="constraint-header">
          <span>Position {{ index + 1 }}: {{ constraint.displayName }}</span>
        </div>
        <div class="metaValues-list">
          <template v-if="constraint && constraint.metaValues && constraint.metaValues.value">
            <div
                class="metaValues"
                v-for="(metaValues, metaValuesIndex) in constraint.metaValues.value"
                :key="metaValuesIndex"
                @click="selectMetaValue(index, metaValues)"
                :class="[isSelected(constraint, metaValues) ? 'selected': '']"
            >
              <input class="mr-2 ml-2" type="checkbox" :checked="isSelected(constraint, metaValues)" />
              <preview
                  class="mr-3 border-radius"
                  :removable="false"
                  :cover="true"
                  :preview-uri="getAssembly(metaValues).previewUri"
                  :preview-id="metaValues + 'preview'"
              />
              <span>{{ getAssemblyName(metaValues) }}</span>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div class="selection-summary mt-3">
      <label v-if="files && files.length" class="vform-label white">pre-existing blueprint-configurations (optional)</label>
      <drop-down
          v-if="files && files.length"
          class="dark"
          :values="files"
          :labels="files"
          @select="selectBluePrint"
      />
      <h4 class="mt-4">Selected Assemblies</h4>
      <div v-for="(constraint, index) in bluePrint.constraints" :key="constraint.id + 'summary'">
        <span class="lighter">Position {{ index + 1 }}: {{ constraint.displayName }}</span>
        <div v-if="constraint.selectedMetaValue">
          <div class="selected-metaValue">
            <span>{{ getAssemblyName(constraint.selectedMetaValue) }}</span>
          </div>
        </div>
        <div v-else>
          <div class="selected-metaValue">
            <span>No assembly selected</span>
          </div>
        </div>
      </div>

      <div @click="createProject" class="settings-button d-inline-block switched-colors">Create project</div><br /><br />

      <label class="vform-label white">Name for configuration</label>
      <input type="text" class="form-text form-text-dark" v-model="blueprintName" />
      <div @click="saveBlueprintConfiguration" class="settings-button d-inline-block switched-colors mt-2">Save as blueprint configuration</div>

      <div class="form-error p-3 mt-3" v-if="createdProject && createdProject.name">
        You find your created project here<br /><br />
        <div @click="$router.push('/project/'+createdProject.id+'/general')" class="settings-button d-inline-block">{{ createdProject.name }}</div>
      </div>
      <div class="form-error p-3 mt-3" v-if="msg">
        {{ msg }}
      </div>
    </div>

    <!-- Assembly Selector Modal -->
    <div v-if="isObjectSelectorVisible" class="modal">
      <div class="modal-content">
        <span class="close" @click="isObjectSelectorVisible = false">&times;</span>
        <h3 class="mt-3">Select Assemblies</h3>
      </div>
    </div>
  </div>
</template>

<script>
import {v4 as uuidv4} from 'uuid';
import Preview from "@/components/preview/Preview.vue";
import DropDown from "@/components/forms/DropDown.vue";
export default {
  name: 'BluePrintEditor',
  components: {Preview, DropDown},
  props: {
    bluePrintObject: {type: Object, required: true}
  },
  data() {
    return {
      msg: '',
      blueprintName: '',
      forceReRenderKey: 0,
      bluePrint: {
        id: uuidv4(),
        name: '',
        constraints: []
      },
      files: [],
      assemblies: [],
      isObjectSelectorVisible: false,
      createdProject: {}
    };
  },
  watch: {
    bluePrintObject() {
      this.setBluePrint();
    }
  },
  mounted() {
    this.setBluePrint();
    const {organizationId, id} = this.bluePrintObject;
    this.loadBluePrintFiles(id);
    this.loadAssemblies(organizationId);
  },
  methods: {
    async selectBluePrint(file) {
      const res = await this.$store.dispatch('clientDownloadProject', {
        id: this.bluePrintObject.id,
        key: file
      });
      const {body} = res;
      this.bluePrint.constraints = body;
      this.forceReRenderKey++;
      this.blueprintName = file.split('.')[0];
    },
    async loadBluePrintFiles(id) {
      console.log('loading files...')
      await this.$store.dispatch('clientListProjectParts', {
        id
      }).then(files => {
        if(!files.length) {
          return;
        }
        this.files = files.map(item => {return item.key});
      })
    },
    async saveBlueprintConfiguration() {
      this.msg = '';
      // todo: save as json file in storage
      const fileName = 'blueprint_' + (this.blueprintName ? this.blueprintName : Date.now()) + '.json';
      await this.$store.dispatch('createProjectTextFile', {
        id: this.bluePrintObject.id,
        fileName: fileName,
        fileContent: JSON.stringify(this.bluePrint.constraints)
      }).then(() => {
        this.msg = 'Blueprint configuration "' + fileName + '" saved';
      })
      //
    },
    async createProject() {
      this.msg = '';
      this.createdProject = null;
      const today = new Date();
      const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

      const proj = await this.createBaseProject(this.bluePrint.name + '_' + formattedDate);
      const assemblyIds = this.bluePrint.constraints.map(item => {
        return item.selectedMetaValue;
      });
      await this.$store.dispatch('clientAddProjectAsset', {
        id: proj.id,
        args: assemblyIds
      });
      await this.$store.dispatch('clientAddProjectAsset', {
        id: proj.id,
        args: ['6ddb3375-e47c-4e9f-a6db-d01f7faa9f03']
      });
      this.createdProject = proj;
    },
    async createBaseProject(name) {
      return this.$store.dispatch('clientCreateProject', {
        type: 'vstage',
        name,
        slug: name,
        teams: [],
        organizationId: this.bluePrintObject.organizationId
      });
    },
    getAssemblyName(id) {
      const ass = this.getAssembly(id);
      return ass && ass.name ? ass.name : '';
    },
    getAssembly(id) {
      const ass = this.assemblies.find(item => {
        return item.id === id;
      })
      return ass ? ass : {};
    },
    async setBluePrint() {
      if (this.bluePrintObject) {
        this.bluePrint = {};
        Object.keys(this.bluePrintObject).map(key => {
          this.bluePrint[key] = this.bluePrintObject[key];
        });
        const {assets} = this.bluePrintObject;
        this.bluePrint.constraints = assets;
        let assemblyIds = [];
        this.bluePrint.constraints.map(item => {
          const {metaValues} = item;
          const ids = metaValues && metaValues.value ? metaValues.value : [];
          for (let i = 0; i < ids.length; i++) {
            if (!assemblyIds.includes(ids[i])) {
              assemblyIds.push(ids[i])
            }
          }
        });
        await this.loadAssemblies(this.bluePrintObject.organizationId, assemblyIds);
        this.forceReRenderKey++;
      }
    },
    async loadAssemblies(organizationId, ids = []) {
      let filter = `type eq assembly,organizationId eq ${organizationId}`;
      if (ids && ids.length) {
        if (ids.length > 1) {
          filter += ",id in '" + ids.join("' '") + "'";
        } else {
          filter += ",id eq " + ids[0]
        }
      }
      return await this.$store.dispatch('clientLoadAssets', {
        filter,
        sorting: '-createdAt',
      }).then(data => {
        this.assemblies = [...data];
      });
    },
    selectMetaValue(constraintIndex, metaValue) {
      console.log('selecting...')
      if(this.bluePrint.constraints[constraintIndex].selectedMetaValue === metaValue) {
        this.bluePrint.constraints[constraintIndex].selectedMetaValue = null;
      } else {
        this.bluePrint.constraints[constraintIndex].selectedMetaValue = metaValue;
      }
      this.forceReRenderKey++;
    },
    isSelected(constraint, metaValue) {
      return constraint.selectedMetaValue === metaValue;
    }
  }
};
</script>

<style lang="scss" scoped>
.form-group {
  margin-bottom: 0.75rem;
}

.btn {
  margin-top: 0.25rem;
}

.constraint-list {
  margin-top: 0.75rem;
  width: 50%;
  float: left;
}

.constraint {
  border: 1px solid #ccc;
  padding: 0.75rem;
  margin-bottom: 0.75rem;
  border-radius: 4px;
}

.constraint-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.metaValues-list {
  margin-top: 0.5rem;
  min-width: 240px;
}

.metaValues {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.metaValues.selected {
  background-color: #1a1919;
}

.metaValues .preview {
  width: 80px;
}

.metaValues img.metaValues-preview {
  width: 40px;
  height: 40px;
  margin-right: 0.5rem;
}

.metaValues button {
  flex-shrink: 0;
}

.selection-summary {
  width: 45%;
  float: right;
}

.selection-summary .selected-metaValue {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.modal {
  display: block;
  position: fixed;
  z-index: 105;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  color: #000;
}

.modal-content {
  background-color: var(--vform-editor-ui-secondary-color);
  color: var(--vform-editor-ui-primary-color);
  margin: 10% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 70%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 24px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>