<template>
  <one-column-sidebar-left
      :id="$route.params.id"
      back-button-tab-selector="blueprintsbackbutton"
      tab-selector="blueprinttab"
      type="blueprint"
      sub-menu-selector="librarypanel"
  >
    <info-panel :id="$route.params.id"
                slot="sidebarLeft"
                :created="$store.getters.getBluePrintCreationDate($route.params.id)"
                :downloadable="true"
                :image-editable="true"
                :last-change="$store.getters.getBluePrintChangeDate($route.params.id)"
                :organization="$store.getters.getBluePrintOrganization($route.params.id)"
                :preview-uri="$store.getters.getBluePrintPreviewUri(this.$route.params.id)"
                :removable="true"
                preview-id="blueprintEdit"
                upload-type="BluePrint"
    >
      <div v-if="$store.getters.isSuperAdmin" slot="info" class="form-section">
        <div class="mt-2 mb-2"><span class="lighter"><icon type="folder"/> STORAGE</span><br/><span class="small">{{
            `${$store.getters.getBluePrintOrganization($route.params.id)}/blueprints/${this.$route.params.id}`
          }}</span></div>
      </div>
    </info-panel>

    <team-attacher
        v-if="$store.getters.getBluePrintOrganization($route.params.id)"
        :id="$route.params.id"
        slot="sidebarLeft"
        :limit-by-organization-id="$store.getters.getBluePrintOrganization($route.params.id)"
        :teams="teams"
        class="mt-3"
        store-name="BluePrint"
        @added="loadBluePrintTeams"
        @removed="loadBluePrintTeams"
    />
    <info-panel
        :id="$route.params.id"
        slot="sidebarLeft"
        :limit-tags-to-organization="$store.getters.getBluePrintOrganization($route.params.id)"
        :taggable="true"
        :tags="$store.getters.getBluePrintTags($route.params.id)"
        preview-id="blueprintEdit"
        @addTag="addTag"
        @removeTag="removeTag"
    />
    <template slot="mainContent">
      <content-title-block
          v-if="$store.getters.getBluePrintName(id)"
          :id="$route.params.id"
          :organization-id="$store.getters.getBluePrintOrganization($route.params.id)"
          :value="$store.getters.getBluePrintName(id)"
          icon="folder"
          store-name="BluePrint"
      />
      <content-description-block
          :id="$route.params.id"
          :value="$store.getters.getBluePrintDescription(id)"
          store-name="BluePrint"
      />
      <div class="row">
        <state-setter
            v-if="$store.getters.isSuperAdmin || $store.getters.isSFXMember"
            :given-state="$store.getters.getBluePrintState($route.params.id)"
            :target-id="$route.params.id"
            class="col-12 col-md-4 mb-2 mt-2"
            store-name="BluePrint"
        />
        <div class="w-100"/>
      </div>

      <div class="row mt-2">
        <div class="col-12 col-md-6 no-gutters">
          <info-panel
              v-if="$store.getters.isSuperAdmin"
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="sfx edit"
              preview-id="metasetattacher"
          >
            <meta-set-attacher
                slot="info"
                :given-meta-sets="$store.getters.getBluePrintMetaSets(id)"
                :target-id="$route.params.id"
                store-name="BluePrint"
                @added="loadBluePrint"
                @removed="loadBluePrint"
            />
          </info-panel>
        </div>
        <div class="w-100"/>
        <meta-panel
            v-for="(item) in $store.getters.getBluePrintMetaSets(id)"
            :key="item.id"
            :fields="item.metaFields"
            :organization-ids="[$store.getters.getBluePrintOrganization($route.params.id), $store.getters.getSuperOrg]"
            :title="item.description" class="col-12 col-md-6"
            icon-left="folder"
            store-name="BluePrint"
            @updated="loadBluePrint"
        />
        <div class="tabs ml-3 mb-3 clickable">
          <div :class="['tab', currentTab === 'editing' ? 'active' : '']" @click="currentTab = 'editing'">Editing</div>
          <div :class="['tab', currentTab === 'applying' ? 'active' : '']" @click="currentTab = 'applying'">Applying</div>
        </div>
        <template v-if="bluePrint">
          <blue-print-editor
              v-if="currentTab === 'editing'"
              class="ml-3 p-3 border-radius blueprint-editor col-5"
              :blue-print-object="bluePrint"
              @reload="loadBluePrint"
          />
          <blue-print-applier
              v-if="currentTab === 'applying'"
              class="ml-3 p-3 border-radius blueprint-editor col-6"
              :blue-print-object="bluePrint"
              @reload="loadBluePrint"
          />
        </template>

      </div>

    </template>
  </one-column-sidebar-left>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
import MetaPanel from "../components/widgets/meta/MetaPanel";
import ContentTitleBlock from "../components/ContentTitleBlock";
import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
import TeamAttacher from "@/components/organizations/TeamAttacher";
import StateSetter from "@/components/StateSetter";
import BluePrintEditor from "@/components/bluePrint/BluePrintEditor.vue";
import BluePrintApplier from "@/components/bluePrint/BluePrintApplier.vue";
import {SpecialUuids} from "@/enum";
import Icon from "@/components/Icon.vue";
export default {
  name: 'BluePrintEdit',
  components: {
    MetaPanel,
    InfoPanel,
    OneColumnSidebarLeft,
    ContentTitleBlock,
    ContentDescriptionBlock,
    MetaSetAttacher,
    TeamAttacher,
    StateSetter,
    BluePrintEditor,
    BluePrintApplier,
    Icon
  },
  data() {
    return {
      currentTab: 'editing',
      id: '',
      teams: [],
      selectedFolder: "",
      foldersLoaded: false,
      showInstanceViewer: false,
      instances: [],
      showGraph: false,
      hasvform: false,
      bluePrint: null
    };
  },
  beforeMount() {
    this.loadBluePrint();
    this.loadBluePrintTeams();
    this.id = this.$route.params.id;
  },
  mounted() {
    this.loadInstances();
  },
  methods: {
    initGraph() {
      this.showGraph = true;
      this.loadInstances();
    },
    loadInstances() {
      this.$store.dispatch('clientLoadProjectInstances', {id: this.id})
          .then(async instances => {
            this.instances = instances;
            this.hasvform = instances && instances.length ? !!instances.find(item => {return item.type === 'form'}) : false;
          })
    },
    loadBluePrintTeams() {
      this.$store.dispatch('clientLoadProjectTeams', {
        id: this.$route.params.id
      }).then(async data => {
        this.teams = data;
      });
    },
    async loadBluePrint() {
      const bluePrintId = this.$route.params.id;
      console.log('loading blueprint...', bluePrintId)
      const args = {
        id: bluePrintId,
        include: 'organization,tags,metaValues,assets'
      }
      console.log('loading blueprint...', args)
      return this.$store.dispatch('loadBluePrint', args)
          .then(async bluePrint => {
        bluePrint = await this.setInstanceMetaValuesToAssets(bluePrint);
        console.log('got blueprint', bluePrint)
        this.bluePrint = bluePrint;
        return bluePrint;
      }).catch(e => {
        console.log('error loading blueprint', e)
        if (e.status === 401) {
          this.$router.push('/access-denied');
        } else if (e.status === 404) {
          this.$router.push('/not-found')
        }
      });
    },
    async setInstanceMetaValuesToAssets(bluePrint) {
      const {assets} = bluePrint;
      if(assets && assets.length) {
        const instanceIds = assets.map(item => {
          return item.instance.id;
        })
        const metaVals = await this.$store.dispatch('clientLoadMetaValues', {
          filter: "targetId in '" + instanceIds.join("' '") + "'",
          id: SpecialUuids.BLUEPRINT_CONSTRAINT_METASET,
          cid: SpecialUuids.BLUEPRINT_CONSTRAINT_ASSEMBLIES
        });
        bluePrint.assets = bluePrint.assets.map(item => {
          const {id} = item.instance;
          const meta = metaVals.find(val => {
            return val.targetId === id;
          })
          if(meta) {
            const {metaFieldId, value} = meta;
            let parsed = value;
            try {
              parsed = JSON.parse(value);
            } catch {
              console.log('could not parse ' + value)
            }
            item.metaValues = {
              metaFieldId,
              value: parsed
            };
          }
          return item;
        })
      }
      return bluePrint;
    },
    removeTag: function (tag) {
      this.$store.dispatch('removeBluePrintTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    addTag: function (tag) {
      this.$store.dispatch('addBluePrintTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    updateTags: function (tags) {
      this.$store.dispatch('updateBluePrint', {
        id: this.$route.params.id,
        tags: tags,
      });
    },
  },
}
</script>

<style lang="scss" scoped>
.blueprint-editor {
  background-color: var(--vform-editor-ui-secondary-color);
}
.tab {
  display: inline-block;
  padding:3px;
  background-color: #444;
  width: 200px;
  color: #fff;
  &.active {
    background-color: #fff;
    color: #000;
  }
}
</style>