<template>
  <div class="vform-the-preview">
    <!--<div v-if="true" class="square-image bg-slight justify-content-center">
      <loading-spinner class="white" size="big" />
      <div class="loading-message lighter">Loading content</div>
    </div>-->
    <div
        v-if="isSvg($store.getters.getFormImageById(assetId))"
        ref="image"
        :class="['vform-image-preview svg', classes]"
        :style="{
                width: size ? size + '%' : '100%',
                flexBasis: size ? size + '%' : '100%',
                height: '100%',
            }"
        v-html="isSvg($store.getters.getFormImageById(assetId))"
    />
    <div
        v-else-if="backgroundMode && $store.getters.getFormImageById(assetId) && type === 'image'"
        ref="image"
        :class="['vform-image-preview', classes]"
        :style="{
                'background-image': 'url(' + $store.getters.getFormImageById(assetId) + ')',
                width: size ? size + '%' : '100%',
                flexBasis: size ? size + '%' : '100%',
            }"
    />
    <div
        v-else-if="backgroundMode && $store.getters.getFormImageById(assetId) && type === 'image'"
        ref="imagePreview"
        :class="['vform-image-preview-preview', classes]"
        :style="{
                'background-image': 'url(' + $store.getters.getFormImageById(assetId) + ')',
                width: size ? size + '%' : '100%',
                flexBasis: size ? size + '%' : '100%',
            }"
    />
    <img
        v-else-if="!backgroundMode && $store.getters.getFormImageById(assetId) && type === 'image'"
        :src="$store.getters.getFormImageById(assetId)"
        :style="imgAutoShrinkToFit ? {
            width: size ? size + '%' : '100%',
            flexBasis: size ? size + '%' : '100%',
            'max-width' : 'fit-content',
            'max-height': '100%',
    } : {
            width: size ? size + '%' : '100%',
            flexBasis: size ? size + '%' : '100%'
        }"
    />
    <div
        v-else-if="$store.getters.getFormImageById(assetId) && type === 'video'"
        :style="{
                width: size ? size + '%' : '100%',
                flexBasis: size ? size + '%' : '100%',
            }"
    >
      <video-preview
          ref="player"
          :auto-play="autoPlay"
          :initial-video-u-r-l="$store.getters.getFormImageById(assetId)"
          :muted="muted"
          class="w-100"/>
    </div>
    <div
        v-else-if="$store.getters.getFormImageById(assetId) && type === 'audio'"
        :style="{
                width: size ? size + '%' : '100%',
                flexBasis: size ? size + '%' : '100%',
            }"
    >
      <audio-preview
          ref="player"
          :auto-play="autoPlay"
          :preview-data="$store.getters.getFormImageById(assetId)"></audio-preview>
    </div>
    <!--<div
        v-else-if="$store.getters.getFormImageById(assetId) && type === 'pdf'"
        :style="{
                width: size ? size + '%' : '100%',
                flexBasis: size ? size + '%' : '100%',
            }"
    >
      <p-d-f-preview :preview-data="$store.getters.getFormImageById(assetId)"></p-d-f-preview>
    </div>-->
    <div v-else-if="forbidden" :class="['square-image lighter', type === 'video' ? 'lengthy-image' : 'square-image']">
      <icon :size="size ? (size / 5).toString() : '2'" class="lighter-lighter preview-icon" type="ban"/>
    </div>
    <div v-else-if="!$store.getters.getImagesError(assetId)"
         :class="['bg-slight text-center justify-content-center', type === 'video' ? 'lengthy-image' : 'square-image']">
      <loading-spinner :size="spinnerSize" class="white"/>
      <div v-if="showSpinnerMessage" class="loading-message lighter">Loading content</div>
    </div>
    <div v-else class="col-12 mt-5 mb-5 lighter">
      <icon v-if="$store.getters.getImagesError(assetId).code === 401" :size="size ? (size / 5).toString() : '3'"
            type="ban"/>
      <icon v-else :size="size ? (size / 5).toString() : '3'" type="image"/>
    </div>
  </div>
</template>

<script>
import VideoPreview from "@/components/preview/VideoPreview";
import AudioPreview from "@/components/preview/AudioPreview";
//import PDFPreview from "@/components/preview/PDFPreview";
import Icon from "@/components/Icon";
import LoadingSpinner from "../../LoadingSpinner";
import {mapState} from 'vuex';
import axios from "axios";

const config = {
  USE_PROFILES: { svg: true, svgFilters: true },
  // You can also allow specific tags and attributes explicitly
  ALLOWED_TAGS: ['svg', 'path', 'circle', 'rect'], // add all needed tags
  ALLOWED_ATTR: ['fill', 'stroke', 'd', 'width', 'height'], // add all needed attributes
};
import DOMPurify from 'dompurify';

export default {
  name: "Preview",
  components: {
    //PDFPreview,
    VideoPreview,
    AudioPreview,
    Icon,
    LoadingSpinner,
  },
  props: {
    localAssetName: {type: String, default: null},
    assetId: {type: String, default: null},
    mayLoadNow: {type: Boolean, default: false},
    size: {type: Number, default: null},
    backgroundMode: {type: Boolean, default: true},
    type: {type: String, default: "image"},
    classes: {type: String, default: "normal"},
    offlineMode: {type: Boolean, default: false},
    autoPlay: {type: Boolean, default: false},
    muted: {type: Boolean, default: false},
    spinnerSize: {type: String, default: "big"},
    showSpinnerMessage: {type: Boolean, default: true},
    imgAutoShrinkToFit: {type: Boolean, default: false},
  },
  data() {
    return {
      previewData: null,
      previewUri: "",
      key: "",
      forbidden: false,
      contentLoadedSent: false
    };
  },
  computed: mapState({images: state => state.formpage.images,}),
  watch: {
    images() {
      if (this.images[this.assetId]) {
        this.$emit('contentLoaded')
      }
    },
    mayLoadNow(val) {
      if (val) {
        this.loadConditionally();
      }
    },
    previewUri() {
      this.triggerReload();
    },
    assetId() {
      this.triggerReload();
    },
  },
  beforeMount() {
    this.loadConditionally();
  },
  methods: {
    getSvgDimensions(svgString) {
      // Parse the SVG string into a DOM element
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgString, "image/svg+xml");
      const svgElement = svgDoc.querySelector("svg");

      if (!svgElement) {
        console.log("Invalid SVG content");
      }

      // Get the width and height attributes
      const width = svgElement.getAttribute("width");
      const height = svgElement.getAttribute("height");

      return {
        width: width ? parseFloat(width) : null,
        height: height ? parseFloat(height) : null
      };
    },
    isSvg(code) {
      if(!code) {
        return null;
      }
      if(code.includes('<svg')) {
        if(!code.includes('viewBox')) {
          const {width, height} = this.getSvgDimensions(code);
          if(width && height) {
            code = code.replace('<svg', `<svg viewBox="0 0 ${width} ${height}"`);
          }
        }
        return DOMPurify.sanitize(code, config);
      }
      return null;
    },
    stop() {
      if (['video', 'audio'].includes(this.type)) {
        if (this.$refs.player) {
          this.$refs.player.stop();
        }
      }
    },
    start() {
      if (['video', 'audio'].includes(this.type)) {
        if (this.$refs.player) {
          this.$refs.player.start();
        }
      }
    },
    contentLoaded() {
      if (!this.contentLoadedSent) {
        this.$emit('contentLoaded')
        this.contentLoadedSent = true;
      }
    },
    async loadConditionally() {
      let valid = false;
      if (this.images[this.assetId]) {
        valid = await this.checkURLValidity(this.images[this.assetId]);
      }
      if (!this.counterIncreased && valid) {
        this.contentLoaded();
      } else {
        await this.$store.dispatch('resetFormImage', {id: this.assetId})
        await this.loadAll();
      }
    },
    /**
     * Checks whether a blob video url is still working or not
     * @params {url} - the url of an item
     * */
    async checkURLValidity(url) {
      return await axios.get(url, {})
          .then(res => {
            return !!res;
          }).catch(() => {
            console.log('url invalid')
            return false;
          })
    },
    triggerReload() {
      console.log("this.triggerReload();");
      this.loadAll(true);
    },
    async loadAll(force = false) {
      if (this.offlineMode) {
        if (this.assetId || this.localAssetName) {
          await this.loadFromDisk(force);
        }
      } else {
        if (this.assetId) {
          await this.$store.dispatch("loadFormImage", {
            id: this.assetId,
            force: force
          }).then(() => {
            this.contentLoaded();
          }).catch(e => {
            console.log(e.message);
            this.contentLoaded();
            if (e.message === "401") {
              this.forbidden = true;
            }
          })
        }
      }
    },
    async loadFromDisk() {
      const vformProjectId = this.$route.params.id;
      let contentType = "";
      let fileName = !this.localAssetName ? `${window.location.pathname}vforms/${vformProjectId}/assets/${this.assetId}` : `${window.location.pathname}${this.localAssetName}`;
      console.log('fetching item ' + fileName)
      await fetch(fileName)
          .then((response) => {
            contentType = response.headers["content-type"];
            return response.arrayBuffer();
          })
          .then((buff) => {
            const imgBase64 = new Buffer(buff, "binary").toString(
                "base64"
            );

            let code = `data:${contentType ? contentType : 'image/png'};base64,${imgBase64}`
            this.$store.dispatch("loadFormImageFromDisk", {uuid: this.assetId, imageData: code});
          }).catch(async (e) => {
            console.log('Failed loading asset from from disk.');
            console.log(e);
          });
    }
  },
};
</script>


<style lang="scss" scoped>
.vform-image-preview {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.vform-image-preview-preview {
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.square-image {
  .icon, .loading-spinner-widget, .loading-message {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .loading-message {
    font-size: 0.9rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 80px;
  }
}
</style>
