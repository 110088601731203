<template>
  <div>
    <div
        v-for="(key, index2) in Object.keys(element.label.dix)"
        v-if="$store.getters.getSelectedFormLanguage === key"
        :key="index2 + 1000000000 + forceReRenderKey"
        class="dflex"
    >
      <div class="col p-0">

        <!--ANSWERS-->

        <div class="col pl-0 pr-0 dflex mt-5">
          <div class="col pl-0">
            <div class="correct-answer">
              <div class="d-flex">
                <div class="col pl-0 pr-0 d-flex" style="position: relative; justify-content: space-between">
                  <label class="vform-editor-label">{{ $t("vform.possibleAnswers") }}</label>
                  <label class="vform-editor-label" style="float: right">{{ $t("vform.correct") }}</label>
                </div>

              </div>
            </div>
          </div>
          <label class="vform-editor-label answer">{{ $t("vform.learningDot") }}</label>
        </div>
        <div
            v-for="(item, index4) in element.options"
            :key="index4 + 98239283 + forceReRenderKey"
            class="dflex mb-1"
        >
          <div class="col pl-0 pr-0 dflex correct-answers-container">
            <div class="col pl-0">
              <div v-for="(answer, answerIndex) in item.answers"
                   :key="answerIndex + 'option' + index4"
                   class="correct-answer">
                <div class="d-flex">
                  <div class="col pl-0 pr-0" style="position: relative;">
                    <textarea
                        v-model="answer.text.dix[key]"
                        :placeholder="
                          key === 'Unknown'
                              ? placeholders.SHORT
                              : $t('vform.translationMessage', {
                                    key: key,
                                    content: answer.text.dix['Unknown'],
                                })
                      "
                        :title="answer.text.dix['Unknown']"
                        class="form-text v-form-label form-text-dark mb-2"
                        rows="300"
                        type="text"
                    />
                    <div v-if="answerIndex > 0"
                         class="icon-delete-option"
                         style="position: absolute; top: 0px; right: 0px"
                         @click="removeAnswer(item, answerIndex)"
                    >
                      <icon :size="'1.8'" type="fat-square-minus"/>
                    </div>
                    <div v-if="answerIndex + 1 === item.answers.length"
                         class="icon-delete-option"
                         style="position: absolute; top: 98%; right: 0px"
                         @click="addAnswer(item)"
                    >
                      <icon :size="'1.8'" type="fad-square-plus"/>
                    </div>
                  </div>
                  <checkbox-widget :class="'pl-0 ml-2 col-auto'" :initial-value="answer.isCorrect"
                                   :label-for="answerIndex + 'option' + index4"
                                   @change="(val) => { answer.isCorrect = val; }">
                  </checkbox-widget>
                </div>
              </div>

            </div>
            <div class="col-auto pl-0 pr-0">
              <div class="col-12 dflex pr-0 pl-0">
                <select v-model="item.learningDotNumber" class="custom-select form-text-dark answer">
                  <option v-for="(item) in getAnswersDataSource($store.getters.getSelectedFormLanguage)"
                          :key="item"
                          :value="item">
                    {{ item }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 mt-1 text-right col pr-0" style="padding-right: 34px">
          <span @click="addOption(key)">
              <icon :size="'2.0'" type="fad-square-plus"/>
          </span>
        </div>
      </div>
    </div>
    <label class="vform-editor-label section">Options</label>
    <!--ADD DIX TRANSLATION-->
    <div class="col row mt-2">
      <div class="col-12 pl-0 dflex">
        <label :for="'chkEmphasized' + $vnode.key" class="container vform-editor-body"
        >{{ $t("emphasized") }}
          <input
              :id="'chkEmphasized' + $vnode.key"
              v-model="element.emphasized"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <!--      <div class="col-12 pl-0 dflex">-->
      <!--        <label :for="'chkRequired' + $vnode.key" class="container vform-editor-body"-->
      <!--        >{{ $t("isRequired") }}-->
      <!--          <input-->
      <!--              :id="'chkRequired' + $vnode.key"-->
      <!--              v-model="element.isRequired"-->
      <!--              type="checkbox"-->
      <!--          />-->
      <!--          <span class="checkmark"></span>-->
      <!--        </label>-->
      <!--      </div>-->
      <div class="col-12 pl-0 dflex">
        <label :for="'chkCorrect' + $vnode.key" class="container vform-editor-body"
        >{{ $t("validateCorrectness") }}
          <input
              :id="'chkCorrect' + $vnode.key"
              v-model="element.validateCorrectness"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <!--DIX-->
    <!--FOR EACH TRANSLATION ONE FIELD-->
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import {vFormControls, placeholders} from "@/enum";
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";
import LearningDotsMixin from "@/components/vForm/viewer/LearningDotsMixin.js";
import CheckboxWidget from "@/components/widgets/editors/Checkbox.vue";

export default {
  name: "vFormListMatchTextInput",
  components: {
    Icon,
    CheckboxWidget
  },
  props: {
    element: {type: Object, required: true},
    stepNo: {type: Number, required: true},
    organizationId: {type: String, default: ""},
  },
  mixins: [vFormElementMixin, LearningDotsMixin],
  data() {
    return {
      forceReRenderKey: 0,
      editImage: "",
      vFormControls: vFormControls,
      placeholders: placeholders,
    };
  },
  watch: {
    element: {
      deep: true,
      handler() {
      },
    },
  },
  beforeMount() {
    this.element.formElementType = vFormControls["LIST_MATCH_TEXT_INPUT"];

    ///get all learning dots and generate corresponding options
    if (!this.element.options || this.element.options.length === 0) {
      const answers = this.getAnswersDataSource(this.$store.getters.getSelectedFormLanguage);
      if (answers) {
        answers.forEach((dotNumber) => {
          this.addOption("Unknown", dotNumber);
        });
      }
    }

    if (!this.element.options) {
      this.addOption("Unknown");
    }
  },
  methods: {
    newAnswerObject(lang) {
      return {
        text: {
          dix: {
            [lang]: "",
          },
        },
        isCorrect: false
      };
    },
    removeOption(index) {
      this.element.options.splice(index, 1);
    },
    removeAnswer(option, index) {
      option.answers.splice(index, 1);
    },
    addAnswer(option) {
      option.answers.push(this.newAnswerObject(this.$store.getters.getSelectedFormLanguage));
    },
    addOption(lang, dotNumber) {
      if (!this.element.options) {
        this.$set(this.element, "options", []);
      }
      this.element.options.push({
        text: {
          dix: {
            [lang]: "",
          },
          imageDix: {
            [lang]: "",
          },
        },
        answers: [this.newAnswerObject(lang), this.newAnswerObject(lang)],
        learningDotNumber: dotNumber,
        selectedAnswer: '',
      });
      this.forceReRenderKey++;
    },
  }
}
</script>
<style lang="scss" scoped>
.answer {
  width: 100px;
}

.correct-answer {
  position: relative;
}

.correct-answers-container {
  margin-bottom: 30px;
}
</style>
