import {vformTemplatableFields, vFormTemplatableSubFields} from "@/enum";

export const getTemplateFile = async (store, templateAssetId, filter = {}) => {
    const {metaFilter, files} = filter;
    let args = {id: templateAssetId};
    if(metaFilter) {
        args.metaFilter = metaFilter;
    }
    if(files) {
        args.files = files;
    }
    return await store.dispatch('clientLoadCombinedAssetPart', args)
        .catch(() => {
            return {}
    });
}

export const applyTemplate = ($this, element, template) => {
    if(!template) {
        throw new Error('template necessary for applying')
    }

    const {data} = template;
    const fields = vformTemplatableFields;
    const subFields = vFormTemplatableSubFields;

    fields.forEach(field => {
        const value = data[field];
        (value !== undefined) ? $this.$set(element, field, value) : $this.$set(element, field, null);
    });

    // Apply sub-fields to the corresponding nested objects
    subFields.forEach(subField => {
        const subData = data[subField];
        if (subData && Object.keys(subData).length) {
            fields.forEach(field => {
                (subData[field] !== undefined) ? $this.$set(element[subField], field, subData[field]) : $this.$set(element[subField], field, null);
            });
        }
    });
    
    $this.$set(element, 'templateId', template.key);
    return element;
}

export const findMatchingTemplate = (templateId, templates) => {
    if(!templateId) {
        throw new Error('findMatchingTemplate: no templateId given')
    }
    if(!templates || !templates.length) {
        throw new Error('findMatchingTemplate: no templates given')
    }
    return templates.find(item => {return item.key === templateId})
}
