<template>
  <!--Single choice list match-->
  <div
      :class="['mb-3 position-relative w-100 vform-checkboxes', config.emphasized ? 'vform-emphasized' : '']">
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        :can-have-template="false"
        @editProperty="editProperty"
        @editTemplate="editTemplate"
        @removeBlock="$emit('removeBlock')"
    />
    <div
        v-for="(option, index) in getOptions(config)"
        :key="index + forceReRenderKey + Math.random().toString(36).substring(7)"
        :class="['mt-3 pb-1', !editable && ((!option.isCorrectAnswer && option.selectedAnswer) || (isAnswerRequired && !option.selectedAnswer)) ? 'vform-error-hide pl-1 pt-1' : '']">
      <div class="d-flex justify-content-start">
        <div class="col-auto pr-0 pl-0">
          [ {{ option.learningDotNumber }} ]
        </div>
        <div class="col">
          <select v-model="option.selectedAnswer" class="text-answer-select">
            <option v-for="(answer, answerIndex) in option.answers"
                    :key="answerIndex + 1982">
              {{ answer.text.dix[lang] }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import LearningDotsMixin from "@/components/vForm/viewer/LearningDotsMixin.js";
import EditorOverlay from "./EditorOverlay";

export default {
  name: "LIST_MATCH_TEXT_INPUT",
  components: {
    EditorOverlay,
  },
  mixins: [ElementMixin, LearningDotsMixin],
  data() {
    return {
      selected: {},
      isUserChanged: false,
      isAnswerValid: true,
      isAnswerRequired: false,
    };
  },
  watch: {
    config: {
      deep: true,
      handler() {
        if (!this.isUserChanged) {
          //this.setListMatchPreSelected(this.config);
        }
      }
    },
  },
  beforeMount() {
    if (!this.config.choiceLocation) {
      this.$set(this.config, "choiceLocation", 'panel');
    }
  },
  mounted() {
    this.increaseCounter();
    this.validate();
  },
  methods: {
    selectAnswer(option, answer) {
      console.log('select answer ' + answer);
      this.$set(option, "selectedAnswer", answer);
      option.selectedAnswer = answer;
      console.log(option);
    },
    validate() {
      let isValid = this.validateListMatch();
      this.isAnswerValid = isValid;

      return isValid;
    },
    checkRequired() {
      const options = this.getOptions(this.config);
      for (let i = 0; i < options.length; i++) {
        let option = options[i];
        if (!option.selectedAnswer) {
          this.isAnswerRequired = true;
          return !this.isAnswerRequired;
        }
      }

      return true;
    },
    fillData(data) {
      if (data) {
        this.setOptions(this.config, data);
      } else {
        this.setListMatchPreSelected(this.config);
      }
    },
    retrieveData() {
      return this.getOptions(this.config);
    },
    getData() {
      if (!this.disabled) {
        return {
          data: {
            value: this.getListMatchData(this.config, this.$store.getters.getSelectedFormLanguage),
            question: this.getLabel(this.config, this.$store.getters.getSelectedFormLanguage)
          }, type: 'answers'
        };
      }
      return null;
    },
    getListMatchData(config, lang) {
      const options = this.getOptions(config);
      let data = {
        receivedPoints: 0,
        totalPossiblePoints: options.length,
        correctAnswers: [],
        wrongAnswers: []
      };
      for (let i = 0; i < options.length; i++) {
        const option = options[i];
        if (this.isCorrectOptionAnswer(option)) {
          data.correctAnswers.push({message: this.getText(option, lang)});
          data.receivedPoints++;
        } else {
          data.wrongAnswers.push({message: this.getText(option, lang)});
        }
      }
      return data;
    },
    validateListMatch() {
      this.validationIsCorrect = true;
      if (this.isCheckForCorrectness(this.config)) {
        const options = this.getOptions(this.config);
        for (let i = 0; i < options.length; i++) {
          let option = options[i];
          this.$set(option, "isCorrectAnswer", true);
          if (option.selectedAnswer && !this.isCorrectOptionAnswer(option)) {
            this.validationIsCorrect = false;
            option.isCorrectAnswer = false;
          }
          // let correctAnswer = this.getCorrectAnswer(option);
          // if (option.selectedAnswer && correctAnswer && option.selectedAnswer !== correctAnswer.text.dix[this.lang]) {
          //   this.validationIsCorrect = false;
          //   option.isCorrectAnswer = false;
          // }
        }
      }

      return this.validationIsCorrect;
    },
    isCorrectOptionAnswer(option) {
      let correctAnswer = this.getCorrectAnswer(option);
      if (!correctAnswer) {
        return true;
      }

      return option.selectedAnswer && correctAnswer && option.selectedAnswer === correctAnswer.text.dix[this.$store.getters.getSelectedFormLanguage];
    },
    getCorrectAnswer(option) {
      return option.answers.find((a) => a.isCorrect);
    },

    setListMatchPreSelected(config) {
      let options = this.getOptions(this.config);
      for (let i = 0; i < options.length; i++) {
        let option = options[i];
        option.selectedAnswer = '';
        this.$set(option, "isCorrectAnswer", true);
      }
      config.options = options;
    },
  }
}
</script>

<style lang="scss" scoped>
.answer-number-select {
  background-color: #cccccc;
  border-radius: 10px;
  width: 40px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-weight: 600;

  &.selected {
    background-color: cornflowerblue;
    color: white;
  }

  &.selected-editor-mode {
    background-color: #518000;
    color: white;
    opacity: 0.8;
  }
}

.text-answer-select {
  width: 100%;
}
</style>

<style lang="scss">


</style>