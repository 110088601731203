<template>
  <div class="position-relative w-100">
    <slot name="dropzone"></slot>
    <portal v-if="showTestLivePopup" to="slideShowPopupSection">
      <popup
          :is-not-closable="true"
          :elevated-z-index="1000"
      >
        <div slot="popupMainContent">
          {{ $t('ModeQuestion') }}<br />
          <div class="settings-button d-inline-block switched-colors mr-2" @click="setMode(mode)" :key="mode" v-for="mode in Object.values(vformModes)">
            {{ $t('vformMode.' + mode) }}
          </div>
        </div>
      </popup>
    </portal>
    <!--Overlay-->
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
      :has-template="!!config.templateId"
        @editProperty="editProperty"
        @editTemplate="editTemplate"
        @removeBlock="$emit('removeBlock')"
    />
    <!--Input fields-->
    <div v-if="templateMode">
      <div :key="1232123">
        <div class="vform-label-for-input">
          USERID *
        </div>
        <input
            :class="['vform-form-text w-100 mb-1']"
            :disabled="disabled"
            type="text"
        />
      </div>
    </div>

    <div v-else>
      <div v-for="(field, index) in config.fields" :key="index + 34534535">
        <div class="vform-label-for-input">{{ getLabel(field, $store.getters.getSelectedFormLanguage) ? getLabel(field, $store.getters.getSelectedFormLanguage) : $t('userId') }}
          {{ field.isID ? '*' : '' }}
        </div>
        <input
            v-model="values[index]"
            :class="['vform-form-text w-100 mb-1', markAsMissing[index] && markAsMissing[index].missing ? 'vform-error' : '', disabled ? 'lighter' : '']"
            :disabled="disabled"
            type="text"
            @keydown.enter="index === (config.fields.length - 1) ? click : () => {}"
            @change="$emit('storeData', config.uuid, retrieveData());"
        />
      </div>
    </div>
    <!--Button-->
    <div 
      :class="[
        'mt-2 clickable vform-slide-control-element',
        config.loginButton.alignment
      ]"
         @click="click">
      <div
          :class="['inner d-flex align-items-center BUTTON_ITEM', tiles ? 'p-2' : '', config.bottomDropZone ? 'next-button' : 'simple-button', config.loginButton.alignment]"
          :style="{
            backgroundColor: config.loginButton.backgroundColor ? config.loginButton.backgroundColor : '',
            color: config.loginButton.fontColor ? config.loginButton.fontColor : (fontColor ? fontColor : ''),
            borderColor: config.loginButton.borderColor ? config.loginButton.borderColor : (config.loginButton.backgroundColor ? config.loginButton.backgroundColor : ''),

        }"
      >
        <preview
            v-if="getImage(config.loginButton, $store.getters.getSelectedFormLanguage)"
            :asset-id="getImage(config.loginButton, $store.getters.getSelectedFormLanguage)"
            :may-load-now="isActive"
            :preview-id="getImage(config.loginButton, $store.getters.getSelectedFormLanguage)"
            :removable="false"

            class="mini vform-preview-img vform-icon-img mr-2"
            source="asset"
            @contentLoaded="increaseCounter"
            spinner-size="default"
            :show-spinner-message="false"
        />
        <div :class="['text', getImage(config.loginButton, $store.getters.getSelectedFormLanguage) ? 'with-icon' : 'without-icon']">
          <template v-if="!config.loginButton.buttonStyle ||  config.loginButton.buttonStyle === 'Text' || config.loginButton.buttonStyle === 'TextAndArrow'">
            {{ getLabel(config.loginButton, $store.getters.getSelectedFormLanguage) ? getLabel(config.loginButton, $store.getters.getSelectedFormLanguage) : $t('login') }}
          </template>
          <template v-else><div v-html="'&nbsp;'"></div></template>
        </div>
        <div class="ml-3" v-if="config.loginButton.buttonStyle === 'Arrow' || config.loginButton.buttonStyle === 'TextAndArrow'">
          <icon type="angle-right" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import EditorOverlay from "./EditorOverlay";
import Preview from "./Preview";
import Icon from "@/components/Icon";
import Popup from "@/components/Popup.vue";
import {vformModes} from "@/enum";
import {mapState} from "vuex";
export default {
  name: "LOGIN",
  components: {
    EditorOverlay,
    Preview,
    Icon,
    Popup
  },
  mixins: [ElementMixin],
  data() {
    return {
      value: '',
      markAsMissing: {},
      values: {},
      tiles: false,
      clicking: false,
      readonly: false,
      showTestLivePopup: false,
      vformModes
    };
  },
  computed: {
    ...mapState({
      /**
       * whether the user is still loading
       * */
      userId: state => state.formpage.vformUserId,
    }),
  },
  watch: {
    userId(val) {
      if (val) {
        this.setUserIdValue(val);
      }
    },
    editorMode() {
      this.showPopup();
    }
  },
  beforeMount() {
    this.showPopup();
  },
  mounted() {
    if(!this.getImage(this.config.loginButton, this.$store.getters.getSelectedFormLanguage)) {
      this.increaseCounter();
    }
    this.showPopup();
  },
  methods: {
    setMode(mode) {
      this.$emit('setMode', mode);
      this.$store.dispatch('setvFormMode', mode)
      this.showTestLivePopup = false;
    },
    showPopup() {
      if(this.config.askForTestOrLiveMode && !this.editorMode) {
        this.showTestLivePopup = true;
      }
    },
    checkRequired() {
      const fields = this.config.fields;
      for (let i = 0; i < fields.length; i++) {
        if (this.getRequired(fields[i]) || fields[i].isID) {
          let missing = false;
          if (!this.values[i]) {
            missing = true;
            return false;
          }
          if(!this.markAsMissing[i]) {
            this.markAsMissing[i] = {missing};
          } else {
            this.markAsMissing[i].missing = missing;
          }
        }
      }
      return true;
    },
    click() {
      if (this.clicking) {
        return;
      }

      this.clicking = true;
      this.handleGoTo(this.config.loginButton);
      this.readonly = true;
      const $this = this;
      setTimeout(() => {
        $this.clicking = false;
        $this.readonly = false;
      }, 100);
    },
    setInitialValues() {
      const fields = this.config.fields;
      if (fields) {
        for (let i = 0; i < fields.length; i++) {
          this.$set(this.values, i, fields[i].isID && this.$store.getters.getVFormUserId ? this.$store.getters.getVFormUserId : "");
        }
      }
    },
    validate() {
      const idFieldIndex = this.config.fields.findIndex(item => {
        return item.isID
      });
      return (idFieldIndex === -1) || !!this.values[idFieldIndex];
    },
    setUserIdValue(userId) {
      const fields = this.config.fields;
      if (fields) {
        for (let i = 0; i < fields.length; i++) {
          const field = fields[i];
          if (field.isID) {
            this.values[i] = userId;
          }
        }
      }
    },
    getJumpTarget() {
      return this.getJumpTargets(this.config.loginButton);
    },
    setupMarkAsMissing() {
      const fields = this.config.fields;
      if (fields) {
        for (let i = 0; i < fields.length; i++) {
          this.markAsMissing[i] = {missing: false};
        }
      }
    },
    fillData(data) {
      if (data) {
        this.values = data;
      } else {
        this.setInitialValues();
      }
      this.setupMarkAsMissing();
    },
    retrieveData() {
      return this.values;
    },
    getData() {
      if (!this.disabled) {
        let data = {
          fields: [],
          userId: null,
          type: 'login'
        }

        for (let i = 0; i < this.config.fields.length; i++) {
          const field = this.config.fields[i];
          data.fields.push({
            value: (this.getLabel(field, this.$store.getters.getSelectedFormLanguage) ? this.getLabel(field, this.$store.getters.getSelectedFormLanguage) : 'userId') + ';' + this.values[i],
            type: "default",
            rawValue: this.values[i]
          })
          if (field.isID) {
            data.userId = this.values[i];
          }
        }
        return data;
      }
      return null;
    }
  }
}
</script>
<style lang="scss" scoped>
.vform-form-text:disabled {
  background-color: transparent;
}

.with-icon {
  flex-grow: 1 !important;
}
.settings-button {
  padding: 8px;
}
</style>