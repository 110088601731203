var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((Object.keys(_vm.imageDix)),function(key,index3){return (_vm.$store.getters.getSelectedFormLanguage === key)?_c('div',{key:index3 + 1000000000 + _vm.forceReRenderKey,staticClass:"d-flex",staticStyle:{"cursor":"pointer"}},[(_vm.showMediaNameOnly() && (_vm.imageDix['Unknown'] || _vm.imageDix[key]))?_c('div',{staticClass:"mr-1 col row"},[_c('label',{staticClass:"vform-editor-label"},[_vm._v(_vm._s(_vm.mediaName)+" ")])]):_vm._e(),_c('div',{on:{"click":function($event){return _vm.selectImage(index3)}}},[(!_vm.showMediaNameOnly() && (_vm.imageDix['Unknown'] || _vm.imageDix[key]))?_c('preview',{class:[
                _vm.previewSize + '-thumbnail mb-1',
                _vm.showDefaultImage ? 'default-imageDix' : '' ],attrs:{"asset-or-project-id":_vm.imageDix[key],"preview-uri":_vm.$store.getters['getMediaPreviewUri'](
                    _vm.showDefaultImage ? _vm.imageDix['Unknown'] : _vm.imageDix[key]
                ),"type":'image',"preview-id":_vm.showDefaultImage ? _vm.imageDix['Unknown'] : _vm.imageDix[key],"removable":false,"title":_vm.showDefaultImage
                    ? 'Please select ' +
                      key +
                      ' image. Will use default image otherwise'
                    : ''}}):_vm._e()],1),(!_vm.vSTAGEContext && !_vm.imageDix['Unknown'] && !_vm.imageDix[key])?_c('div',{staticClass:"vform-alignment-button",on:{"click":_vm.uploadImage}},[_c('icon',{attrs:{"type":"upload","size":"1"}})],1):_vm._e(),(_vm.showMediaNameOnly()  && (_vm.imageDix['Unknown'] || _vm.imageDix[key]))?_c('div',{staticClass:"vform-alignment-button",on:{"click":function($event){return _vm.selectImage(index3)}}},[_c('icon',{attrs:{"type":"folder","size":"1"}})],1):_vm._e(),(!_vm.imageDix['Unknown'] && !_vm.imageDix[key])?_c('div',{staticClass:"vform-alignment-button",on:{"click":function($event){return _vm.selectImage(index3)}}},[_c('icon',{attrs:{"type":"folder","size":"1"}})],1):_vm._e()]):_vm._e()}),(_vm.showSelect)?_c('popup',{on:{"close":function () {
                _vm.showSelect = false;
            }}},[(_vm.showSelect)?_c('div',{staticClass:"w-100",attrs:{"slot":"popupMainContent"},slot:"popupMainContent"},_vm._l((Object.keys(_vm.imageDix)),function(key,index3){return (_vm.$store.getters.getSelectedFormLanguage === key)?_c('div',{key:index3 + 1000000000 + _vm.forceReRenderKey,staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-3 col-xl-4 col-xxl-3"},[_c('content-display',{staticClass:"bg-dark",attrs:{"list-name":"generalList","type":"media","value":_vm.imageDix[key],"show-preview":true,"hide-upper-name":true}}),(_vm.imageDix[key])?_c('span',{on:{"click":function($event){_vm.imageDix[key] = '';
                                    _vm.forceReRenderKey++;
                                    _vm.showSelect = false;}}},[_c('icon',{attrs:{"size":'1.5',"type":"trash"}})],1):_vm._e()],1),(_vm.editImage === '/' + index3)?_c('content-list',{staticClass:"col-12 col-md-9 col-lg-8",attrs:{"media-category":_vm.mediaCategory,"type":"media","organization-ids":[_vm.organizationId],"list-name":'vformImages' + _vm.mediaCategory,"show-preview":true,"thumbnail-view":true},on:{"save":function (id) {
                                    _vm.imageDix[key] = id;
                                    _vm.editImage = null;
                                    _vm.showSelect = false;
                                    _vm.loadImage();
                                }}}):_vm._e()],1):_vm._e()}),0):_vm._e()]):_vm._e(),(_vm.showUpload)?_c('popup',{on:{"close":function () {
                  _vm.showUpload = false;
              }}},[(_vm.showUpload)?_c('div',{staticClass:"w-100",attrs:{"slot":"popupMainContent"},slot:"popupMainContent"},[_c('media-add-form',{attrs:{"organization-id":_vm.organizationId},on:{"saved":_vm.mediaAdded}})],1):_vm._e()]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }