var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vform-the-preview"},[(_vm.isSvg(_vm.$store.getters.getFormImageById(_vm.assetId)))?_c('div',{ref:"image",class:['vform-image-preview svg', _vm.classes],style:({
              width: _vm.size ? _vm.size + '%' : '100%',
              flexBasis: _vm.size ? _vm.size + '%' : '100%',
              height: '100%',
          }),domProps:{"innerHTML":_vm._s(_vm.isSvg(_vm.$store.getters.getFormImageById(_vm.assetId)))}}):(_vm.backgroundMode && _vm.$store.getters.getFormImageById(_vm.assetId) && _vm.type === 'image')?_c('div',{ref:"image",class:['vform-image-preview', _vm.classes],style:({
              'background-image': 'url(' + _vm.$store.getters.getFormImageById(_vm.assetId) + ')',
              width: _vm.size ? _vm.size + '%' : '100%',
              flexBasis: _vm.size ? _vm.size + '%' : '100%',
          })}):(_vm.backgroundMode && _vm.$store.getters.getFormImageById(_vm.assetId) && _vm.type === 'image')?_c('div',{ref:"imagePreview",class:['vform-image-preview-preview', _vm.classes],style:({
              'background-image': 'url(' + _vm.$store.getters.getFormImageById(_vm.assetId) + ')',
              width: _vm.size ? _vm.size + '%' : '100%',
              flexBasis: _vm.size ? _vm.size + '%' : '100%',
          })}):(!_vm.backgroundMode && _vm.$store.getters.getFormImageById(_vm.assetId) && _vm.type === 'image')?_c('img',{style:(_vm.imgAutoShrinkToFit ? {
          width: _vm.size ? _vm.size + '%' : '100%',
          flexBasis: _vm.size ? _vm.size + '%' : '100%',
          'max-width' : 'fit-content',
          'max-height': '100%',
  } : {
          width: _vm.size ? _vm.size + '%' : '100%',
          flexBasis: _vm.size ? _vm.size + '%' : '100%'
      }),attrs:{"src":_vm.$store.getters.getFormImageById(_vm.assetId)}}):(_vm.$store.getters.getFormImageById(_vm.assetId) && _vm.type === 'video')?_c('div',{style:({
              width: _vm.size ? _vm.size + '%' : '100%',
              flexBasis: _vm.size ? _vm.size + '%' : '100%',
          })},[_c('video-preview',{ref:"player",staticClass:"w-100",attrs:{"auto-play":_vm.autoPlay,"initial-video-u-r-l":_vm.$store.getters.getFormImageById(_vm.assetId),"muted":_vm.muted}})],1):(_vm.$store.getters.getFormImageById(_vm.assetId) && _vm.type === 'audio')?_c('div',{style:({
              width: _vm.size ? _vm.size + '%' : '100%',
              flexBasis: _vm.size ? _vm.size + '%' : '100%',
          })},[_c('audio-preview',{ref:"player",attrs:{"auto-play":_vm.autoPlay,"preview-data":_vm.$store.getters.getFormImageById(_vm.assetId)}})],1):(_vm.forbidden)?_c('div',{class:['square-image lighter', _vm.type === 'video' ? 'lengthy-image' : 'square-image']},[_c('icon',{staticClass:"lighter-lighter preview-icon",attrs:{"size":_vm.size ? (_vm.size / 5).toString() : '2',"type":"ban"}})],1):(!_vm.$store.getters.getImagesError(_vm.assetId))?_c('div',{class:['bg-slight text-center justify-content-center', _vm.type === 'video' ? 'lengthy-image' : 'square-image']},[_c('loading-spinner',{staticClass:"white",attrs:{"size":_vm.spinnerSize}}),(_vm.showSpinnerMessage)?_c('div',{staticClass:"loading-message lighter"},[_vm._v("Loading content")]):_vm._e()],1):_c('div',{staticClass:"col-12 mt-5 mb-5 lighter"},[(_vm.$store.getters.getImagesError(_vm.assetId).code === 401)?_c('icon',{attrs:{"size":_vm.size ? (_vm.size / 5).toString() : '3',"type":"ban"}}):_c('icon',{attrs:{"size":_vm.size ? (_vm.size / 5).toString() : '3',"type":"image"}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }