<template>
  <div></div>
</template>

<script>
import {vFormControls, defaultPanelVersion} from "@/enum";

let playMode = false;

function setItemPosition(item, event) {
  let x = (parseFloat(item.getAttribute("data-x")) || 0) + event.dx;
  let y = (parseFloat(item.getAttribute("data-y")) || 0) + event.dy;
  item.style.transform = "translate(" + x + "px, " + y + "px)";
  item.setAttribute("data-x", x);
  item.setAttribute("data-y", y);
}

function dragMoveListener(event) {
  if (!playMode) {
    let target = event.target;

    let clone = document.querySelector('.drag-clone');

    // translate the element
    if(clone) {
     setItemPosition(clone, event);
    } else {
     setItemPosition(target, event);
    }

    target = null;
  }
}

import interact from "interactjs";
import {hotspotPopupAllowedElementTypes} from "@/enum";
export default {
  name: "VformDraggableDroppableMixin",
  props: {},
  data() {
    return {
      draggableTargetBlock: ".draggable",
      draggableTargetElements: ".element-draggable",
      draggableTargetElementsHotspotPopup: ".element-draggable-popup",
      draggableTargetPanels: ".panel-container-default",
      draggableTargetPanelsNoBottom: '.panel-container-no-bottom',
      draggableInfoDot: ".hotspot-dot-container",
      bottomDropZoneAllowedElementTypes: [vFormControls.BUTTON, vFormControls.LOGOUT],
      hotspotPopupAllowedElementTypes,
      slideShowId: 'slideshow-inner'
    };
  },
  methods: {
    disableDragDropElements() {
      interact(this.draggableTargetBlock).unset();
      interact(this.draggableTargetElements).unset();
      interact(this.draggableTargetElementsHotspotPopup).unset();
      interact(this.draggableTargetPanels).unset();
      interact(this.draggableTargetPanelsNoBottom).unset();
      interact(this.draggableInfoDot).unset();
    },
    enableDragDropElements() {
      let $this = this;
      interact(this.draggableTargetBlock).unset();
      interact(this.draggableTargetBlock).draggable({
        inertia: true,
        modifiers: [
          interact.modifiers.restrict({
            restriction: ".vform-container-2",
            endOnly: true,
          }),
        ],
        autoScroll: true,
        listeners: {
          move: dragMoveListener,
          start(event) {
            playMode = $this.viewerModeOnly;
            if (!playMode) {
              let target = event.target;
              let position = target.getBoundingClientRect();
              let flexer = document.getElementsByClassName('flexer');
              const parent = flexer[0];
              let parentEqualizer = 0;
              if (parent.classList.contains('panel-undocked')) {
                const parentPos = flexer[0].getBoundingClientRect();
                parentEqualizer = parentPos.top;
              }

              // if panel is undocked: apply transform of panel as well
              //target.style.transform = parent.style.transform;
              target.style.height = position.height + "px";
              target.style.width = position.width + "px";
              target.style.position = "fixed";
              target.style.top = (position.top - parentEqualizer) + "px";
              $this.state.dragging = true;

              target = null;
              flexer = null;
            }
          },
          end(event) {
            let target = event.target;
            target.style = {};
            target.setAttribute("data-x", 0);
            target.setAttribute("data-y", 0);
            $this.state.dragging = false;
            target = null;
          },
        },
      });

      //elements in panel tbd here
      interact(this.draggableTargetElements).unset();
      interact(this.draggableTargetElements).draggable({
        inertia: false,
        modifiers: [
          interact.modifiers.restrict({
            //restriction: "parent",
            endOnly: true,
          }),
        ],
        autoScroll: true,
        listeners: {
          move: dragMoveListener,
          start(event) {
              playMode = $this.viewerModeOnly;
              if (playMode) {
                return;
              }
            document.body.classList.add('no-select');

            $this.state.draggedElementUuid = event.target.getAttribute("element-uuid");
              $this.state.dragging = true;

              let {target} = event;

              console.log('mouseTop in start: ' + event.clientY)
              let clone = document.querySelector('.drag-clone');
              if (clone) {
                return;
              }
              clone = $this.applyElementDragStartStyle(target);
              $this.state.dragging = true;


          },
          end(event) {
            document.body.classList.remove('no-select');

            let target = event.target;
            let panelContainer = target.closest(".panel-container");
            if (panelContainer) {
              panelContainer.style.removeProperty("z-index");
            }

            //undo applied styles while dragging
            $this.applyElementDragEndStyle(target);
            $this.state.dragging = false;

            $this.state.draggedElementUuid = null;

            target = null;
          },
        },
      });

      //elements in popup
      interact(this.draggableTargetElementsHotspotPopup).unset();
      interact(this.draggableTargetElementsHotspotPopup).draggable({
        inertia: false,
        modifiers: [
          interact.modifiers.restrict({
            //restriction: "parent",
            endOnly: true,
          }),
        ],
        autoScroll: true,
        listeners: {
          move: dragMoveListener,
          start(event) {
            playMode = $this.viewerModeOnly;
            if (!playMode) {
              event.target.classList.add("element-dragging");
              event.target.style.transition = "revert";
              $this.state.dragging = true;
            }

            let target = event.target;
            $this.applyElementDragStartStyle(target);
            $this.state.draggedElementUuid = event.target.getAttribute("element-uuid");

            target = null;
          },
          end(event) {
            let target = event.target;

            //undo applied styles while dragging
            $this.applyElementDragEndStyle(target);
            $this.state.dragging = false;


            $this.state.draggedElementUuid = null;

            target = null;
          },
        },
      });

      // enable draggables to be dropped into this
      const dropZoneTarget = ".interactjs-drop-zone";
      interact(dropZoneTarget).unset();
      interact(dropZoneTarget).dropzone({
        // only accept elements matching this CSS selector
        accept: ".draggable, .element-draggable, .element-draggable-popup",
        // Require a 75% element overlap for a drop to be possible
        overlap: "pointer",

        ondropactivate: function (event) {
          // add active dropzone feedback
          event.target.classList.add("can-drop");
        },
        ondragenter: function (event) {
          const { target: dropzoneElement, relatedTarget: draggableElement } = event;
          dropzoneElement.classList.add("drop-active");
          dropzoneElement.classList.add("can-drop");
          let dropZoneId = dropzoneElement.getAttribute("dropZoneId");
          const elementType = draggableElement.getAttribute("elementType");

          if (dropZoneId && dropZoneId === "bottomDropZone") {
            if ($this.bottomDropZoneAllowedElementTypes.includes(elementType)) {
              dropzoneElement.classList.add("drop-active");
            } else {
              draggableElement.classList.add("drop-not-allowed");
              dropzoneElement.classList.add("drop-not-allowed");
            }
          } else {
            dropzoneElement.classList.add("drop-active");
          }

          // feedback the possibility of a drop
          //dropzoneElement.classList.add("drop-target");
          //draggableElement.classList.add("can-drop");
        },
        ondragleave: function (event) {
          // remove the drop feedback style
          event.target.classList.remove("drop-active");
          event.target.classList.remove("can-drop");
          event.target.classList.remove("drop-not-allowed");
          event.relatedTarget.classList.remove("drop-not-allowed");
        },
        ondrop: function (event) {
          console.log('ondrop')
          let draggableElement = event.relatedTarget;
          draggableElement.classList.remove("block-drop-not-allowed");

          let elementType = draggableElement.getAttribute("element-type");
          let sourcePanelUuid = draggableElement.getAttribute("panel-uuid");
          let sourceElementUuid = draggableElement.getAttribute("element-uuid");

          let dropzoneElement = event.target;
          let hotspotForSlide = dropzoneElement.getAttribute("hotspotForSlide");
          let targetPanelUuid = dropzoneElement.getAttribute("panel-uuid");

          let previousElement = dropzoneElement.getAttribute("previous-element");
          let isFirst = dropzoneElement.getAttribute("is-first");
          let isLast = dropzoneElement.getAttribute("is-last");
          let isBottom = dropzoneElement.getAttribute("is-bottom");

          // todo: console log all attributes
          console.log('dropzoneElement', dropzoneElement)
          console.log('previousElement: ' + previousElement)
          console.log('isFirst: ' + isFirst)
          console.log('isLast: ' + isLast)
          console.log('isBottom: ' + isBottom)
          console.log('sourceElementUuid: ' + sourceElementUuid)

          if (hotspotForSlide) {
            if (draggableElement.classList.contains("element-draggable-popup")) {
              $this.moveOrAddElementToPanel('hotspot', {
                previousElement,
                isFirst,
                isLast,
                isBottom,
                existingElementUuid: sourceElementUuid,
                sourcePanelUuid: sourcePanelUuid
              });
            } else {
              $this.moveOrAddElementToPanel('hotspot', {
                previousElement,
                isFirst,
                isLast,
                isBottom,
                addingElementString: elementType,
              });
            }
          } else if (draggableElement.classList.contains("element-draggable")) {
            // drag and drop when reordering blocks or moving them from one panel to another
            $this.moveOrAddElementToPanel(targetPanelUuid, {
              previousElement,
              isFirst,
              isLast,
              isBottom,
              existingElementUuid: sourceElementUuid,
              sourcePanelUuid: sourcePanelUuid
            });
          } else {
            // drag and drop from blocks panel
            if (isBottom) {
              if (![$this.vFormControls.BUTTON, $this.vFormControls.LOGOUT].includes(elementType)) {
                return;
              }
            }
            $this.moveOrAddElementToPanel(targetPanelUuid, {
              previousElement,
              isFirst,
              isLast,
              isBottom,
              addingElementString: elementType,
            });
          }
          $this.state.dragging = false;
          dropzoneElement = null;
        },
        ondropdeactivate: function (event) {
          // remove active dropzone feedback
          event.target.classList.remove("drop-active");
          event.target.classList.remove("drop-not-allowed");
          event.relatedTarget.classList.remove("block-drop-not-allowed");
        },
      });
    },
    disableDragDropPanels() {
      interact(this.draggableTargetPanels).unset();
    },
    getSlideShowRectScale() {
      const element = document.getElementById(this.slideShowId);
      const {width, height} = element.getBoundingClientRect();
      return {
        scaleX: width / element.offsetWidth,
        scaleY: height / element.offsetHeight,
        width,
        height
      }
    },
    /**
     * This enables drag and drop functionality for panels inside of a step
     * @params {String} - itemClass - the class of the panel
     * @params {Boolean} - noBottomResize - whether or not to allow vertical scaling
     * */
    enableDragDropPanels(itemClass, noBottomResize = false) {
      let $this = this;
      interact(itemClass).unset();
      interact(itemClass)
          .draggable({
            inertia: true,
            modifiers: [
              interact.modifiers.restrict({
                endOnly: true,
              }),
            ],
            autoScroll: true,
            listeners: {
              move: dragMoveListener,
              start(event) {
                document.body.classList.add('no-select');
                let target = event.target;
                $this.$set($this.state, "panelDragging", true);
                target.classList.add("panel-dragging");
              },
              end(event) {
                document.body.classList.remove('no-select');
                let target = event.target;
                const targetRect = target.getBoundingClientRect();
                target.classList.remove("panel-dragging");
                const {scaleX, scaleY, width, height} = $this.getSlideShowRectScale();

                let newOffsetLeft = Number(target.offsetLeft) * scaleX + Number(target.getAttribute("data-x") * scaleX);
                if (newOffsetLeft < 0) {
                  newOffsetLeft = 0;
                }

                //if offset + width > parent width: snap it back
                let rightBoundary = newOffsetLeft + Number(targetRect.width);
                if (rightBoundary > width) {
                  newOffsetLeft = width - targetRect.width;
                }

                let newOffsetTop = Number(target.offsetTop) * scaleY + Number(target.getAttribute("data-y")) * scaleY;
                if (newOffsetTop < 0) {
                  newOffsetTop = 0;
                }


                console.log('new offset now: ' + newOffsetLeft)
                let percentageOffsetLeft =
                    (newOffsetLeft / width) * 100;
                let percentageOffsetTop =
                    (newOffsetTop / height) * 100;

                console.log('new percentage: ' + percentageOffsetLeft)

                target.style.transform = "";
                target.setAttribute("data-x", 0);
                target.setAttribute("data-y", 0);

                let step = $this.config.steps.find((x) => x.uuid === target.getAttribute("stepUuid"));
                if (step) {
                  let panel = step.panels.find((p) => p.uuid === target.getAttribute("panelUuid"));

                  if (!panel) {
                    //search in global panels
                    panel = $this.config.global.panels.find((p) => p.uuid === target.getAttribute("panelUuid"));
                  }

                  if (panel) {
                    $this.$set(panel, "xLeft", Math.round(percentageOffsetLeft));
                    $this.$set(panel, "y", Math.floor(percentageOffsetTop));
                    $this.$set(panel, "version", defaultPanelVersion);
                  }
                }

                step = null;
                target = null;
                $this.$set($this.state, "panelDragging", false);
              },
            },
          })
          .resizable({
            // resize from all edges and corners
            edges: {left: false, right: true, bottom: !noBottomResize, top: false},

            listeners: {
              move(event) {
                document.body.classList.add('no-select');
                let target = event.target;
                target.classList.add("panel-dragging");
                let x = parseFloat(target.getAttribute("data-x")) || 0;
                let y = parseFloat(target.getAttribute("data-y")) || 0;

                const {scaleX, scaleY} = $this.getSlideShowRectScale();

                // update the element's style
                target.style.width = event.rect.width / scaleX + "px";
                target.style.height = event.rect.height / scaleY + "px";

                // translate when resizing from top or left edges
                x += event.deltaRect.left;
                y += event.deltaRect.top;

                target.style.transform = "translate(" + x + "px," + y + "px)";
                target.setAttribute("data-x", x);
                target.setAttribute("data-y", y);

                target = null;
              },
              end(event) {
                document.body.classList.remove('no-select');
                let {rect, deltaRect, target} = event;
                target.classList.remove("panel-dragging");
                //calculate panel.width and panel.height in percentages
                const {width, height} = $this.getSlideShowRectScale();

                let localWidth = (rect.width / width) * 100;
                let localHeight = (rect.height / height) * 100;

                target.style.transform = "";
                target.setAttribute("data-x", 0);
                target.setAttribute("data-y", 0);

                let step = $this.config.steps.find((x) => x.uuid === target.getAttribute("stepUuid"));
                if (step) {
                  let panel = step.panels.find((p) => p.uuid === target.getAttribute("panelUuid"));
                  if (!panel) {
                    //search in global panels
                    panel = $this.config.global.panels.find(
                        (p) => p.uuid === target.getAttribute("panelUuid")
                    );
                  }

                  if (panel) {
                    if(deltaRect.width !== 0) {
                      $this.$set(panel, "width", Math.min(Math.ceil(localWidth), 100));
                    }
                    if(deltaRect.height !== 0) {
                      $this.$set(panel, "height", Math.min(Math.ceil(localHeight), 100));
                    }
                  }
                }
                step = null;
                target = null;
              },
            },
            modifiers: [
              // keep the edges inside the parent
              interact.modifiers.restrictEdges({
                outer: "parent",
              }),
            ],

            inertia: true,
          });
    },
    applyElementDragStartStyle(target) {
      console.log('applying...')
      let { width, height, top, left } = target.getBoundingClientRect();

      let clone = target.cloneNode(true);
      clone.classList.add('drag-clone');
      clone.classList.add('element-dragging');
      document.body.appendChild(clone);
      clone.style.position = 'fixed';
      //clone.style.pointerEvents = 'none';
      clone.style.setProperty("left", left + 'px', "important");
      clone.style.setProperty("top", top + 'px', "important");

      // Apply the computed styles to the target element
      clone.style.setProperty("width", width + "px", "important");
      console.log('height of clone: ' + height)
      // todo: if clone is display flex, set height explicitly, otherwise don't
      if(clone.classList.contains('vform-tiles') || clone.classList.contains('vform-image-tiles')) {
        clone.style.setProperty("height", height + "px", "important");
      }
      clone.style.setProperty("position", "fixed", "important");
      clone.style.setProperty("z-index", "2000", "important");
      clone.style.setProperty("transition", "none", "important");

      if (!target.style.paddingTop) {
        clone.style.setProperty("padding-top", "0px", "");
      }

      // todo: remove dropzone from clone (child element)
      let dropZone = clone.querySelector('.element-dropzone');
      if (dropZone) {
        dropZone.remove();
      }


      clone.classList.add("element-dragging");

      console.log(clone.getAttribute('element-uuid'))

      // Adjust overlay size, keep it fixed
      let overlay = clone.querySelector(".vform-viewer-editor-overlay");
      if (overlay) {
        overlay.style.height = height + "px";
        overlay.style.width = width + "px";
      }
      return clone;
    },
    applyElementDragEndStyle(target) {
      // Remove the clone
      let clone = document.querySelector('.drag-clone');
      if (clone) {
        clone.remove();
      }
      target.style.removeProperty("width");
      target.style.removeProperty("height");
      target.style.removeProperty("position");
      target.style.removeProperty("z-index");
      target.style.removeProperty("top");
      target.style.removeProperty("transition");
      target.style.removeProperty("transform");
      if (target.style.paddingTop === "0px") {
        target.style.removeProperty("padding-top");
      }

      target.setAttribute("data-x", 0);
      target.setAttribute("data-y", 0);
      target.classList.remove("element-dragging");
    },
  },
};
</script>
<style lang="scss">
.element-dragging {
  border: 1px solid var(--vform-editor-gizmos-primary-color);
  background-color: var(--vform-editor-gizmos-primary-color-brighter);
  .vform-viewer-editor-overlay {
    display: none !important;
  }
  &.vform-tiles {
    display: flex !important;
  }
}

.panel-dragging {
  opacity: 0.8;
}
</style>
