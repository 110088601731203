var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
  'vform-part-legend',
  'position-relative',
  'w-100',
  'multi-line', 
  _vm.config.emphasized ? 'vform-emphasized' : ''
]},[_vm._t("dropzone"),_c('div',{staticClass:"w-100"}),(_vm.editable)?_c('editor-overlay',{attrs:{"active":_vm.editorSelected},on:{"removeBlock":function($event){return _vm.$emit('removeBlock')},"editProperty":_vm.editProperty}}):_vm._e(),_c('div',{staticClass:"text",attrs:{"contenteditable":"false","id":_vm.uniqueId},on:{"input":_vm.onInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.leaveEditing(_vm.uniqueId)},"click":function($event){$event.stopPropagation();return _vm.doNothing($event)}}},[(_vm.editable && !_vm.legend)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('vform.legendPlaceholder')))])]):(_vm.legend)?_c('div',[(_vm.loadingLegend)?_c('div',[_vm._v(" Loading... ")]):(!Object.values(_vm.legend).length)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('vform.noCaptionsDetected'))+" ")]):_vm._e(),_c('div',{class:[
        'vform-legend',
        'text-' + _vm.config.textAlignment ]},_vm._l((_vm.legend),function(item,index){return _c('div',{key:index,staticClass:"legend-item",style:(_vm.borderStyle)},[(item.letter)?_c('span',{staticClass:"letter-container"},[_vm._v(_vm._s(item.letter))]):_vm._e(),_c('div',{staticClass:"info-container mb-2"},[_c('div',{staticClass:"info-container-title"},[_vm._v(_vm._s(item.name))]),(_vm.$store.getters.getInstanceSquashedMetaValById(item.instanceId))?[_c('div',{staticClass:"mt-2"},_vm._l((_vm.$store.getters.getInstanceSquashedMetaValById(item.instanceId)),function(val,index2){return _c('div',{key:index2,class:['info-container-row row', index2 % 2 === 0 ? 'zebra-stripe' : ''],style:({
                        backgroundColor: _vm.config.backgroundColor ? _vm.config.backgroundColor : '',
                        color: _vm.config.fontColor ? _vm.config.fontColor : (_vm.fontColor ? _vm.fontColor : (_vm.contrastColor ? _vm.contrastColor : '')),
                        borderColor: _vm.config.borderColor ? _vm.config.borderColor : (_vm.fontColor ? _vm.fontColor : (_vm.contrastColor ? _vm.contrastColor : '')),
                       })},[_c('span',{staticClass:"col-4 info-container-name"},[_vm._v(_vm._s(_vm.getMetaValueKey(val)))]),(_vm.getFinalMetaValue(val))?_c('span',{staticClass:"col-8 info-container-meta"},[_vm._v(_vm._s(_vm.getFinalMetaValue(val)))]):_c('span',{staticClass:"col-8 info-container-meta"},[_vm._v(" - ")])])}),0)]:_vm._e()],2)])}),0)]):_c('div',[_vm._v(" "+_vm._s(_vm.$t('vform.partLegendLoading'))+" ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }