<template>
  <div>
    <content-filter
        @filter="filtering"
        type="miniFilter"
        :id="listName"
        store-name="Project"
        :initial-state-filter="['a']"
        :state-filter-items="['a']"
        :initial-type-filter="['assembly']"
        :type-filter-items="['assembly']"
        :tag-filtering="true"
        :display-inline="true"
        :limit-to-organization-ids="organizationId ? [organizationId] : []"
        class="mb-4 content-filter-bright content-filter-project-list"
    />
    <pagination
        slot="mainContentButtons"
        :limit="paginationLimit"
        :total="$store.getters.getSfxdataListOptions(listName, 'pagination_items')"
        id="sfxtablepage"
        @setActive="loadPaginatedData"
        ref="pagination"
    />
    <div class="object-list">
      <div :class="['object-item', isPreSelected(assembly.id) ? 'pre-selected' : '']" v-for="(assembly, objIndex) in $store.getters.getSfxdatasByListName(listName)" :key="objIndex">
        <preview :removable="false" :cover="true" :preview-uri="assembly.previewUri" :preview-id="assembly.id" />
        <div class="object-name d-flex">
          <input :disabled="isPreSelected(assembly.id)" :checked="isPreSelected(assembly.id)" type="checkbox" @change="selectUnselect(assembly.id)" />
          <span>{{ assembly.name }}</span>
        </div>
      </div>
    </div>
    <div @click="$emit('selectObjects', selectedObjects)" class="settings-button d-inline-block switched-colors mt-3">Confirm</div>
  </div>
</template>

<script>
import LoadOverviewMixinJs from "@/components/mixins/LoadOverviewMixin.js.vue";
import ContentFilter from "@/components/widgets/filter/ContentFilter.vue";
import Preview from "@/components/preview/Preview.vue";
import Pagination from "@/components/Pagination.vue";

export default {
  name: "AssemblySelector",
  components: {Pagination, ContentFilter, Preview},
  mixins: [LoadOverviewMixinJs],
  props: {
    organizationId: {type: String, required: true},
    preSelectedObjects: {type: Array, default: () => {return []}}
  },
  data() {
    return {
      listName: 'SfxdataListSelector',
      include: 'teams,tags',
      registerMethod: 'registerSfxdataQueryParams',
      loadMethod: 'loadSfxdatas',
      deleteMethod: 'deleteSfxdata',
      selectedObjects: [],
    }
  },
  watch: {
    preSelectedObjects(val) {
      this.selectedObjects = val;
    }
  },
  mounted() {
    try {
      this.selectedObjects = JSON.parse(JSON.stringify(this.preSelectedObjects));
    } catch {
      this.selectedObjects = [];
    }
  },
  methods: {
    isPreSelected(id) {
      const index = this.preSelectedObjects.findIndex(item => {
        return item.id === id;
      })
      return index !== -1;
    },
    selectUnselect(id) {
      const index = this.selectedObjects.findIndex(item => {
        return item.id === id;
      })
      if(index < 0) {
        this.selectedObjects.push({id})
      } else {
        this.selectedObjects.splice(index, 1);
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.object-list {
  display: flex;
  flex-wrap: wrap;
}

.object-item {
  display: block;
  width: 25%;
  margin: 0.25rem;
  &.pre-selected {
    opacity: 0.5;
  }
}

.object-item .preview {
  width: 90px;
}

.object-item span {
  flex: 1;
}

.object-item input[type="checkbox"] {
  margin-right: 0.5rem;
  float: left;
}
</style>