<template>
  <div id="template-applier-div" v-if="(compatibleTemplates && compatibleTemplates.length) || getTemplateId">
    <h3>{{ $t('vform.templates') }}</h3>
    <div class="form-error" v-if="object.templateId && !compatibleTemplates.find(item => {return item.key === object.templateId})">
      <icon type="exclamation-triangle" /> {{ $t('vform.missingTemplate') }}<br />
      <div @click="applyTemplate({}, true)" class="settings-button d-inline-block">{{ $t('vform.removeTemplate') }}</div>
    </div>
    <div class="mb-3">
        <text-input
            ref="textinput"
            type="text"
            :placeholder="$t('typeToSelect')"
            @typingAlert="setSearchQuery"
        />
    </div>
    <div class="chooser-template-container mb-2">
      <div 
        v-if="filteredTemplates.length > 0"
        v-for="templ in filteredTemplates"
        :key="templ.key"
        class="d-flex"
      >
        <div
          :class="['d-flex']"
          @click="applyTemplate(templ, object.templateId === templ.key)">
          <label class="container">
            <input
              v-if="object.templateId === templ.key"
              v-model="object.templateId"
              type="checkbox"
              disabled="true"
            />
            <input 
              v-else
              type="checkbox"
              disabled="true"
            />
            <span class="radio"></span>
          </label>
        </div>
        <div
          @click="applyTemplate(templ, object.templateId === templ.key)"
          :class="['w-100 chooser-template-item p-1 clickable d-flex justify-content-between', object.templateId === templ.key ? 'selected' : '']"
        >
          {{ templ.data.templateName }}
        </div>
        <div class="vform-alignment-button border border-dark ml-1 p-2" @click="editTemplate(templ)">
          <icon type="edit" size="1"/>
        </div>
        <div class="vform-alignment-button border border-dark ml-1 p-2" @click="templateDeleting = templ;">
          <icon type="trash" size="1"/>
        </div>
      </div>
      <div v-if="filteredTemplates.length === 0">
        {{ $t('vform.noTemplatesFound') }}
      </div>
    </div>
    <span @click="templatePreviewing = true" class="settings-button d-inline-block">
      {{ $t('vform.viewAllTemplates') }}
    </span>
    <portal ref="form-popup" to="form-popup">
      <div id="template-preview-vforms" class="p-4" v-if="templatePreviewing">
        <div @click="templatePreviewing = false" class="settings-button d-inline-block mb-5 float-right switched-colors">{{ $t('vform.close') }}</div>
        <template-previewer
          :compatible-templates="compatibleTemplates"
          :selected-template-id="object.templateId"
          @switchToTemplate="applyTemplate"
        />
      </div>
    </portal>
    <portal to="vformEditorPopup">
      <popup
        @close="() => {templateDeleting = null;}"
        v-if="templateDeleting"
      >
        <delete-prompt
          slot="popupMainContent"
          custom-message="vform.deleteTemplateQuestion"
          :custom-message-params="{templatename: templateDeleting.data.templateName}"
          @abort="templateDeleting = null"
          @confirm="deleteTemplate(templateDeleting)"
        />
      </popup>
    </portal>
    <portal to="vformEditorPopup">
      <popup
            @close="() => { templateEditing = null; }"
            v-if="templateEditing"
      >
        <div slot="popupMainContent">
            <template-editor
                  :object="templateEditing"
                  :template-asset-id="templateAssetId"
                  @templateSaved="templateEditing = null"
                  @templateFileChanged="$emit('templateFileChanged')"
              />
        </div>
      </popup>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {applyTemplate} from "@/components/vForm/templateFunctions";
import Icon from "@/components/Icon.vue";
import Popup from "@/components/Popup.vue";
import TemplatePreviewer from '@/components/vForm/TemplatePreviewer.vue';
import TextInput from "@/components/forms/TextInput";
import DeletePrompt from "@/components/forms/DeletePrompt";

export default {
  name: 'TemplateApplier',
  components: {
    Icon,
    Popup,
    TemplatePreviewer,
    TextInput,
    DeletePrompt,
    // The component doesn't get rendered as a standard component. It has to be rendered dynamically (It's actually better that way, but it's a bit of a pain to work with)
    // Vue is truly a mystery.
    'template-editor': function() {
      return import('@/components/vForm/TemplateEditor.vue');
    }
  },
  props: {
    object: {
      type: Object,
      required: true
    },
    templateAssetId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      searchQuery: '',
      templatePreviewing: false,
      templateEditing: null,
      templateDeleting: null,
    };
  },
  computed: {
    ...mapState({
      templates: state => state.formpage.templates
    }),
    compatibleTemplates() {
      if(this.templates) {
        const entries = Object.values(this.templates);

        entries.sort((a, b) => {
          return a.data.templateName.localeCompare(b.data.templateName);
        });

        // check to see if a template has been selected, and if yes, put it at the top of the list
        if(this.object.templateId) {
          const selectedTemplate = entries.find(item => {
            return item.key === this.object.templateId;
          });

          if(selectedTemplate) {
            entries.splice(entries.indexOf(selectedTemplate), 1);
            entries.unshift(selectedTemplate);
          }
        }

        return entries.filter(item => {
          const {formElementTypeString} = this.object;
          return item.data && item.data.formElementType === formElementTypeString;
        });
      } else {
        return [];
      }
    },
    filteredTemplates() {
      const compatibleTemplates = this.compatibleTemplates;
      const searchQuery = this.searchQuery;

      if(searchQuery === '') {
        return compatibleTemplates;
      }

      return compatibleTemplates.filter(template => {
        return template.data.templateName.toLowerCase().includes(searchQuery.toLowerCase())
      });
    },
    getTemplateId() {
      return this.object.templateId;
    },
  },
  methods: {
    setSearchQuery(input) {
      this.searchQuery = input.value;
    },
    editTemplate(template) {
      this.applyTemplate(template);
      this.templateEditing = this.object;
    },
    async deleteTemplate(template) {
      this.templateDeleting = null;
      const id = template.key;
      await this.$store.dispatch(`clientDeleteAssetPart`, {id: this.templateAssetId, key: id});
      await this.$store.dispatch('loadBlockTemplates', {id: this.templateAssetId});
    },
    applyTemplate(template, isSelected = false) {
      if(isSelected) {
        this.object.templateId = null;
      } else {
        applyTemplate(this, this.object, template);
      }
    },
  },
  mounted() {
    this.$store.dispatch('loadBlockTemplates', {id: this.templateAssetId});
  },
};
</script>

<style lang="scss" scoped>
#template-preview-vforms {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 80%;
  height: auto;
  background-color: var(--vform-editor-ui-secondary-color);
  z-index: 655;
  box-shadow: -10px 5px 10px #1f1f1f;
  color: #fff;
}

.chooser-template {

  &-container {
    max-height: 100px;
    overflow-y: scroll;
  }

  &-item {
    border-bottom: 1px solid #262626;
    background-color: var(--vform-editor-ui-quaternary-color);
    &:hover, &.selected {
      background-color: var(--vform-editor-ui-secondary-color);
    }
  }

}

</style>
